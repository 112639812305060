import { ReactNode } from 'react';
import './card.modules.scss';

export interface CardProps {
    title?: string | ReactNode | null;
    subheader?: ReactNode;
    children: ReactNode;
    className?: string;
}

export default function Card(props: CardProps) {
    return (
        <div className={`card card--container ${props.className}`}>
            {(props.title != null || props.subheader != null) && (
                <div className="card card--header">
                    <div className="card card--title">{props.title}</div>
                    {props.subheader}
                </div>
            )}
            <div className="card card--body">{props.children}</div>
        </div>
    );
}
