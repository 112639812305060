import { ButtonVariant } from '../../atoms/button/Button';
import Dialog, { DialogType } from '../../organisms/dialog/Dialog';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

export type DeleteIncidentConfirmationDialogProps = {
    isOpen: boolean;
    onRemove: () => void;
    onCancel: () => void;
};

const DeleteIncidentManuallyConfirmationDialog = ({
    isOpen,
    onRemove,
    onCancel,
}: DeleteIncidentConfirmationDialogProps) => {
    const { t } = useModuleTranslation();
    return (
        <Dialog
            isOpen={isOpen}
            showCloseButton
            type={DialogType.Confirmation}
            title={t('delete_incident_manually_modal.title') || undefined}
            text={t('delete_incident_manually_modal.close_manually_confirmation')}
            actions={[
                {
                    variantName: ButtonVariant.DANGER,
                    text: t('delete_incident_manually_modal.delete'),
                    onClick: () => onRemove(),
                },
                {
                    variantName: ButtonVariant.BASIC_INFO,
                    text: t('delete_incident_manually_modal.cancel'),
                    onClick: () => onCancel(),
                },
            ]}
            onClose={() => onCancel()}
        />
    );
};

export default DeleteIncidentManuallyConfirmationDialog;
