import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { AlertIcon } from '../icons';
import './input.modules.scss';

type NativeInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export type InputProps = Omit<NativeInputProps, 'className' | 'onChange' | 'ref'> & {
    onChange?: (value: string) => void;
    label?: string | null;
    errorMessage?: string;
    className?: string;
    touched?: boolean;
    setTouched?: () => void;
    suffix?: string;
};

const NumberFormatInputWrapper = ({ value, name, suffix, onChange, setTouched }: InputProps) => {
    return (
        <NumberFormat
            name={name}
            inputMode="numeric"
            suffix={suffix}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            value={Number(value)}
            onBlur={setTouched}
            onValueChange={({ value }) => {
                if (onChange) {
                    onChange(value);
                }
            }}
        />
    );
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            onChange,
            label,
            errorMessage,
            className,
            value = undefined,
            name,
            touched = true,
            setTouched,
            suffix,
            ...props
        },
        ref
    ) => {
        return (
            <div className={`input-field ${className ?? ''}`}>
                {suffix != null ? (
                    <NumberFormatInputWrapper
                        value={value}
                        name={name}
                        suffix={suffix}
                        onChange={onChange}
                        setTouched={() => setTouched?.()}
                        {...props}
                    />
                ) : (
                    <input
                        {...props}
                        name={name}
                        id={name}
                        ref={ref}
                        value={value}
                        onBlur={() => setTouched?.()}
                        onChange={(event) => onChange?.(event.target.value)}
                    />
                )}
                {label && <label htmlFor={name}>{label}</label>}
                {errorMessage && touched && <AlertIcon className="input-field--alert-icon" />}
                {errorMessage && touched && (
                    <p className="input-field-feedback input-field-feedback--alert">{errorMessage}</p>
                )}
            </div>
        );
    }
);
Input.displayName = 'Input';

export default Input;
