import { useState } from 'react';
import { CreationNote, Note } from '../../../domain/entities';
import { DefaultNoteOptions, DefaultNoteScopeOptions, NoteScopeOption } from '../../../domain/values';
import NoteInput from '../../atoms/note/NoteInput';
import Switch from '../../molecules/switch/Switch';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './notes-manager.modules.scss';

export type NotesManagerProps = {
    notes: Note[];
    onAddNote?: (note: CreationNote) => void;
    onDeleteNote?: (noteKey: Note) => void;
    readOnly?: boolean;
};

const NotesManager = ({ notes, onAddNote, onDeleteNote, readOnly = false }: NotesManagerProps) => {
    const { t } = useModuleTranslation();
    const defaultNoteOptions = DefaultNoteOptions();
    const defaultNoteScopeOptions = DefaultNoteScopeOptions();
    const filterNoteOptionsByScope = (selectedScopeOption: NoteScopeOption | undefined) => {
        if (!selectedScopeOption) return [];
        return defaultNoteOptions
            .filter((noteOption) => noteOption.scope == selectedScopeOption?.scope)
            .map((noteOption, index) => {
                if (index === 0) {
                    return { ...noteOption, selected: true };
                } else {
                    return { ...noteOption, selected: false };
                }
            });
    };
    const [scopeOptions, setScopeOptions] = useState(defaultNoteScopeOptions);
    const scopeSelected = scopeOptions.find((el) => el.selected);

    const [noteOptions, setNoteOptions] = useState(filterNoteOptionsByScope(scopeSelected));
    const [noteValue, setNoteValue] = useState<string | undefined>(undefined);

    const noteSelected = noteOptions.find((el) => el.selected);
    const onScopeSwitchChange = (value: string) => {
        setScopeOptions(scopeOptions.map((e) => ({ ...e, selected: e.value === value })));
        const selectedScopeOption = defaultNoteScopeOptions.find((scopeOption) => scopeOption.value == value);
        setNoteOptions(filterNoteOptionsByScope(selectedScopeOption));
    };
    const onSwitchChange = (value: string) => {
        setNoteOptions(noteOptions.map((e) => ({ ...e, selected: e.value === value })));
    };

    const handleAddNote = (value: string) => {
        if (onAddNote == null || !noteSelected) return;

        onAddNote({ type: noteSelected.type, scope: noteSelected.scope, description: value });
        setNoteValue('');
    };

    return (
        <div className="notes-container">
            <Switch options={scopeOptions} onValueChange={onScopeSwitchChange} />
            <Switch options={noteOptions} onValueChange={onSwitchChange} />
            {!readOnly && onAddNote != null && noteSelected && (
                <div className="notes-add-note">
                    <NoteInput
                        value={noteValue}
                        label={(scopeSelected ? scopeSelected.placeholder + ' ' : '') + noteSelected.placeholder}
                        onValueChange={setNoteValue}
                        onAdd={handleAddNote}
                        type={noteSelected.type}
                    ></NoteInput>
                </div>
            )}
            {notes.length > 0 && (
                <section>
                    {notes.length !== 0
                        ? notes
                              .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                              .map((note) => (
                                  <div key={`${note.key}-${note.description}`} className="analysis-notes-display-note">
                                      <NoteInput
                                          value={note.description}
                                          disabled={true}
                                          onDelete={readOnly ? undefined : () => onDeleteNote?.(note)}
                                          lastModified={note.updatedAt}
                                          scope={note.scope}
                                      />
                                  </div>
                              ))
                        : readOnly && (
                              <div className="analysis-notes-display-no-note--message">
                                  <span>{t('incident_notes.no_notes')}</span>
                              </div>
                          )}
                </section>
            )}
            {notes.length === 0 && readOnly && (
                <div className="analysis-notes-display-no-note--message">
                    <span>{t('incident_notes.read-only.no-notes')}</span>
                </div>
            )}
        </div>
    );
};

export default NotesManager;
