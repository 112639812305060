import { useState } from 'react';
import clsx from 'clsx';
import placeholder from '../../../../assets/images/placeholder.png';
import './avatar.modules.scss';

export interface AvatarProps {
    src?: string;
    alt?: string;
    className?: string;
}

const Avatar = ({ src, alt, className }: AvatarProps) => {
    const [image, setImage] = useState(src ?? placeholder);

    const onImageError = () => {
        setImage(placeholder);
    };

    return <img className={clsx('avatar', className)} src={image} alt={alt} onError={onImageError} />;
};

export default Avatar;
