import { FindContractDocument } from './FindContractDocument/FindContractDocument';
import { PolicyNomaRepository } from '../infraestructure/repositories/policy/PolicyNomaRepository';

const findContractDocument = new FindContractDocument(new PolicyNomaRepository());

export const useFindContractDocument = () => {
    return {
        viewContractDocument: async (policyIdentifier: string) => {
            await findContractDocument.execute(policyIdentifier).then((response) => {
                openFileInNewTab(policyIdentifier, response);
            });
        },
        downloadContractDocument: async (policyIdentifier: string) => {
            await findContractDocument.execute(policyIdentifier).then((response) => {
                downloadFile(policyIdentifier, response);
            });
        },
    };

    function openFileInNewTab(policyIdentifier: string, response: Blob) {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    function downloadFile(policyIdentifier: string, data: Blob) {
        const blob: Blob = new Blob([data], { type: 'application/pdf' });
        const fileName = `${policyIdentifier}-${Intl.DateTimeFormat('es').format(new Date())}-CONTRACT.pdf`;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
};
