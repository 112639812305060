import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import React from "react";


const initMonitoring = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT,
        release: `noma-frontend@${process.env.npm_package_version}`,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
    }       ),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                networkCaptureBodies: true,
            }),
        ],
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
    });
};

export default initMonitoring;
