export default class CostLine {
    public key: string;
    public concept: string;
    public units: number;
    public VAT: number;
    public priceInCents: number;

    constructor({
        key,
        concept,
        units,
        VAT,
        priceInCents,
    }: {
        key: string;
        concept: string;
        units: number;
        VAT: number;
        priceInCents: number;
    }) {
        this.key = key;
        this.concept = concept;
        this.units = units;
        this.VAT = VAT;
        this.priceInCents = priceInCents;
    }
    vatPercentage() {
        return this.VAT * 100;
    }
    priceInUnits() {
        return this.priceInCents / 100;
    }
}
