import { config, ServerError, urlQueryParamsBuilder, User } from '@barkibu/noma-commons';
import { toDomainAuthToken, toDomainUser } from './authMapper';
import { TokenResponseDto } from './dtos/TokenResponseDto';
import { UserResponseDto } from './dtos/UserResponseDto';
import { AuthCredentials } from '../../domain/auth/AuthCredentials';
import { AuthRepository } from '../../domain/auth/AuthRepository';
import { AuthToken } from '../../domain/auth/AuthToken';
import ForbiddenException from '../../domain/auth/ForbiddenException';

export const AuthNomaRepository = (): AuthRepository => {
    const getSessionTimeLeft = (): Promise<number> => {
        return fetch(urlQueryParamsBuilder('/v1/auth/session', {}), {
            method: 'GET',
            headers: config.API_HEADERS(),
        }).then((response) => {
            if (!response.ok) {
                new ServerError(String(response.status), String(response.statusText));
                return null;
            }
            return response.json();
        });
    };

    const getUser = (): Promise<User> => {
        return fetch(urlQueryParamsBuilder('/v1/auth/principal', {}), {
            method: 'GET',
            headers: config.API_HEADERS(),
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 403) throw new ForbiddenException('Invalid user or password');
                    new ServerError(String(response.status), String(response.statusText));
                    return null;
                }
                return response.json();
            })
            .then((userResponse: UserResponseDto) => {
                return toDomainUser(userResponse);
            });
    };

    const authenticate = (credentials: AuthCredentials): Promise<AuthToken> => {
        return fetch(urlQueryParamsBuilder('/v1/auth/authenticate', {}), {
            method: 'POST',
            headers: config.API_HEADERS(),
            body: JSON.stringify(credentials),
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 403) throw Error('Invalid user or password');
                    new ServerError(String(response.status), String(response.statusText));
                    return null;
                }
                return response.json();
            })
            .then((token: TokenResponseDto) => {
                return toDomainAuthToken(token);
            });
    };

    const refreshToken = (): Promise<AuthToken> => {
        return fetch(urlQueryParamsBuilder('/v1/auth/refresh', {}), {
            method: 'POST',
            headers: config.API_HEADERS(),
        })
            .then((response) => {
                if (!response.ok) {
                    new ServerError(String(response.status), String(response.statusText));
                    return null;
                }
                return response.json();
            })
            .then((token: TokenResponseDto) => {
                return toDomainAuthToken(token);
            });
    };

    return {
        getSessionTimeLeft,
        getUser,
        authenticate,
        refreshToken,
    };
};
