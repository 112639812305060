export enum SupportingDocumentType {
    CLINICAL_HISTORY = 'CLINICAL_HISTORY',
    INVOICE = 'INVOICE',
    VET_REPORT = 'VET_REPORT',
    MEDICAL_IMAGING = 'MEDICAL_IMAGING',
    MEDICAL_ANALYTIC = 'MEDICAL_ANALYTIC',
    ADMINISTRATIVE_DOCUMENTATION = 'ADMINISTRATIVE_DOCUMENTATION',
    VACCINATION_CARD = 'VACCINATION_CARD',
    PAYMENT_LETTER = 'PAYMENT_LETTER',
    NOT_AN_INVOICE = 'NOT_AN_INVOICE',
    TREATMENT_INSTRUCTIONS = 'TREATMENT_INSTRUCTIONS',
    PHOTOS = 'PHOTOS',
    CLIENT_NOTES = 'CLIENT_NOTES',
    OTHER = 'OTHER',
    INVOICE_WITH_MORE_THAN_ONE_PAGE = 'INVOICE_WITH_MORE_THAN_ONE_PAGE',
    VET_FORM = 'VET_FORM',
}

export type OtherSupportingDocumentTypes = Extract<
    SupportingDocumentType,
    | SupportingDocumentType.NOT_AN_INVOICE
    | SupportingDocumentType.TREATMENT_INSTRUCTIONS
    | SupportingDocumentType.PHOTOS
    | SupportingDocumentType.CLIENT_NOTES
    | SupportingDocumentType.OTHER
    | SupportingDocumentType.INVOICE_WITH_MORE_THAN_ONE_PAGE
>;

export const OtherSupportingDocumentTypes = {
    [SupportingDocumentType.NOT_AN_INVOICE]: SupportingDocumentType.NOT_AN_INVOICE,
    [SupportingDocumentType.TREATMENT_INSTRUCTIONS]: SupportingDocumentType.TREATMENT_INSTRUCTIONS,
    [SupportingDocumentType.PHOTOS]: SupportingDocumentType.PHOTOS,
    [SupportingDocumentType.CLIENT_NOTES]: SupportingDocumentType.CLIENT_NOTES,
    [SupportingDocumentType.OTHER]: SupportingDocumentType.OTHER,
    [SupportingDocumentType.INVOICE_WITH_MORE_THAN_ONE_PAGE]: SupportingDocumentType.INVOICE_WITH_MORE_THAN_ONE_PAGE,
} as const;

export type MainSupportingDocumentTypes = Omit<
    SupportingDocumentType,
    OtherSupportingDocumentTypes | SupportingDocumentType.PAYMENT_LETTER
>;

export const MainSupportingDocumentTypes = {
    [SupportingDocumentType.CLINICAL_HISTORY]: SupportingDocumentType.CLINICAL_HISTORY,
    [SupportingDocumentType.INVOICE]: SupportingDocumentType.INVOICE,
    [SupportingDocumentType.VET_REPORT]: SupportingDocumentType.VET_REPORT,
    [SupportingDocumentType.MEDICAL_IMAGING]: SupportingDocumentType.MEDICAL_IMAGING,
    [SupportingDocumentType.MEDICAL_ANALYTIC]: SupportingDocumentType.MEDICAL_ANALYTIC,
    [SupportingDocumentType.ADMINISTRATIVE_DOCUMENTATION]: SupportingDocumentType.ADMINISTRATIVE_DOCUMENTATION,
    [SupportingDocumentType.VACCINATION_CARD]: SupportingDocumentType.VACCINATION_CARD,
    [SupportingDocumentType.VET_FORM]: SupportingDocumentType.VET_FORM,
} as const;

export enum SupportingDocumentDeletionReason {
    NOT_RELEVANT = 'NOT_RELEVANT',
    ILLEGIBLE = 'ILLEGIBLE',
    DUPLICATED_IN_CLAIM = 'DUPLICATED_IN_CLAIM',
    DUPLICATED_IN_OTHER_CLAIM = 'DUPLICATED_IN_OTHER_CLAIM',
    OTHER = 'OTHER',
}
