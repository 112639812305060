import { SupportingDocument } from '../../domain/entities/supporting-document';
import { SupportingDocumentRepository } from '../../domain/repositories/SupportingDocumentRepository';
import { UpdateSupportingDocumentDto } from '../../infraestructure/repositories/incident/dtos/UpdateSupportingDocumentDto';

export interface UpdateIncidentSupportingDocumentResult {
    supportingDocument: SupportingDocument;
}
export class UpdateIncidentSupportingDocument {
    constructor(private supportingDocumentRepository: SupportingDocumentRepository) {}

    async execute(
        documentKey: string,
        document: UpdateSupportingDocumentDto
    ): Promise<UpdateIncidentSupportingDocumentResult> {
        return {
            supportingDocument: await this.supportingDocumentRepository.update(documentKey, {
                type: document.type,
                name: document.name,
                location: document.location!,
            }),
        };
    }
}
