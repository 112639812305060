import {
    CauseOfLoss,
    ClaimRequestStatus,
    ClaimRequestType,
    config,
    Contract,
    ContractDto,
    Diagnosis,
    DiagnosisDto,
    Note,
    NoteDto,
    NoteScope,
    Pet,
    PetDto,
    PetGender,
    Policy,
    PolicyDto,
    ResourceIdentifierObject,
    SupportingDocumentDto,
    SupportingDocumentType,
    Symptom,
    SymptomDto,
    toUserDomain,
} from '@barkibu/noma-commons';
import { SupportingDocument } from '@barkibu/noma-data-entry';
import { BillableConceptSummaryDto } from './dtos/BillableConceptSummaryDto';
import { ClaimRequestSummaryDto } from './dtos/ClaimRequestSummaryDto';
import { InvoiceSummaryDto } from './dtos/InvoiceSummaryDto';
import { BillableConceptSummary, ClaimRequestSummary } from '../../domain/ClaimRequestSummary';
import { InvoiceSummary } from '../../domain/ClaimRequestSummary/InvoiceSummary';
import { toDomain as toResolutionDomain } from '../resolution/ResolutionMapper';

export const toDomain = (
    claimRequestSummaryDto: ClaimRequestSummaryDto,
    prepaidExpenseIds: ResourceIdentifierObject[]
): ClaimRequestSummary => ({
    key: claimRequestSummaryDto.key,
    incidentKey: claimRequestSummaryDto.incidentKey,
    assessmentKey: claimRequestSummaryDto.assessmentKey,
    policyIdentifier: claimRequestSummaryDto.policyIdentifier,
    iban: claimRequestSummaryDto.iban,
    countryCode: claimRequestSummaryDto.countryCode,
    createdAt: new Date(claimRequestSummaryDto.createdAt),
    status: ClaimRequestStatus[claimRequestSummaryDto.status.toUpperCase() as keyof typeof ClaimRequestStatus],
    pet: toPetDomain(claimRequestSummaryDto.pet!),
    supportingDocuments: claimRequestSummaryDto.supportingDocuments.map((el) => toSupportingDocumentDomain(el)),
    notes: claimRequestSummaryDto.notes.map((el) => toNoteDomain(el)),
    diagnosis: claimRequestSummaryDto.diagnosis.map((el) => toDiagnosisDomain(el)),
    symptoms: claimRequestSummaryDto.symptoms.map((el) => toSymptomDomain(el)),
    invoices: claimRequestSummaryDto.invoices.map((el) => toInvoicesSummaryDomain(el)),
    userReservation:
        claimRequestSummaryDto && claimRequestSummaryDto.userReservation
            ? toUserDomain(claimRequestSummaryDto.userReservation)
            : undefined,
    paymentLetters: claimRequestSummaryDto.paymentLetters,
    policyClaimRequestStats: claimRequestSummaryDto.policyClaimRequestStats,
    statusDetails: claimRequestSummaryDto.statusDetails,
    causeOfLoss: CauseOfLoss[claimRequestSummaryDto.causeOfLoss as keyof typeof CauseOfLoss],
    visitReason: claimRequestSummaryDto.visitReason,
    type: ClaimRequestType[claimRequestSummaryDto.type as keyof typeof ClaimRequestType],
    resolution: claimRequestSummaryDto.resolution ? toResolutionDomain(claimRequestSummaryDto.resolution) : undefined,
    prepaidExpenseIds: prepaidExpenseIds.map((it) => ({ value: it.id })),
});

export const toPetDomain = (petDto: PetDto): Pet => ({
    key: petDto.key,
    petParentKey: petDto.petParentKey,
    petParentEmail: petDto.petParentEmail,
    name: petDto.name,
    chip: petDto.chip,
    birthDate: petDto.birthDate != null ? new Date(petDto.birthDate) : null,
    gender: petDto.sex.toLowerCase() === 'male' ? PetGender.Male : PetGender.Female,
    species: petDto.species,
    breed: petDto.breed,
    secured: petDto.secured,
    photo: config.APP_BACKEND_BASE_URL ? `${config.APP_BACKEND_BASE_URL}/v2/pets/${petDto.key}/avatar` : undefined,
    policies: petDto.policies.map((el: PolicyDto) => toPolicyDomain(el)),
});

export const toPolicyDomain = (policyDto: PolicyDto): Policy =>
    new Policy(
        policyDto.product,
        policyDto.status,
        policyDto.policyNumber,
        new Date(policyDto.startDate),
        new Date(policyDto.expirationDate),
        policyDto.claimRequestStats,
        policyDto.contracts.map((el) => toContractDomain(el))
    );

export const toContractDomain = (contractDto: ContractDto): Contract => ({
    identifier: contractDto.identifier,
    startDate: new Date(contractDto.startDate),
    expirationDate: new Date(contractDto.expirationDate),
    productVersionTag: contractDto.productVersionTag,
    status: contractDto.status,
});
export const toSupportingDocumentDomain = (supportingDocumentDto: SupportingDocumentDto): SupportingDocument => ({
    type: SupportingDocumentType[supportingDocumentDto.type as keyof typeof SupportingDocumentType],
    name: supportingDocumentDto.name,
    location: supportingDocumentDto.location,
    date: new Date(supportingDocumentDto.createdAt),
    isNewlyAddedByParent: supportingDocumentDto.newlyAddedByParent,
    transcriptKey: supportingDocumentDto.transcriptKey,
});
export const toNoteDomain = (noteDto: NoteDto): Note => ({
    key: noteDto.key,
    type: noteDto.type,
    description: noteDto.description,
    scope: NoteScope[noteDto.scope.toUpperCase() as keyof typeof NoteScope],
    updatedAt: new Date(noteDto.updatedAt),
});

export const toDiagnosisDomain = (diagnosisDto: DiagnosisDto): Diagnosis => ({
    key: diagnosisDto.key,
    description: diagnosisDto.descriptor,
    deletedAt: diagnosisDto.deletedAt ? new Date(diagnosisDto.deletedAt) : undefined,
});

export const toSymptomDomain = (symptomDto: SymptomDto): Symptom => ({
    key: symptomDto.key,
    description: symptomDto.descriptor,
});

export const toInvoicesSummaryDomain = (invoiceSummaryDto: InvoiceSummaryDto): InvoiceSummary => ({
    issuer: invoiceSummaryDto.issuer,
    number: invoiceSummaryDto.number,
    date: new Date(invoiceSummaryDto.date),
    concepts: invoiceSummaryDto.concepts.map((el) => toBillableConceptSummaryDomain(el)),
});
export const toBillableConceptSummaryDomain = (
    billableConceptSummaryDto: BillableConceptSummaryDto
): BillableConceptSummary => ({
    concept: billableConceptSummaryDto.concept,
    totalAmountInUnits: billableConceptSummaryDto.totalAmountInCents / 100,
    recommendation: billableConceptSummaryDto.recommendation?.description,
    covered: billableConceptSummaryDto.recommendation ? billableConceptSummaryDto.recommendation.cover : false,
});
