import React from 'react';
import clsx from 'clsx';
import './attribute.modules.scss';

export interface AttributeProps {
    icon: React.FC<{ className: string }>;
    description: string;
    className?: string;
    highlight?: boolean;
}

const Attribute = ({ icon: Icon, description, className, highlight = false }: AttributeProps): JSX.Element => {
    return (
        <span className={clsx('attribute-container', { 'attribute-container--highlight': highlight }, className)}>
            <Icon className="icon" />
            <p className="attribute-description">{description}</p>
        </span>
    );
};

export default Attribute;
