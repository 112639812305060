import { ClinicalHistoryDocument } from '../../domain/entities';
import { ClinicalHistoryRepository } from '../../domain/repositories';

export class FindClinicalHistoryDocumentsByPolicyIdentifier {
    constructor(private clinicalHistoryRepository: ClinicalHistoryRepository) {}

    async execute(policyIdentifier: string): Promise<ClinicalHistoryDocument[] | undefined> {
        return await this.clinicalHistoryRepository.findDocumentsByPolicyIdentifier(policyIdentifier);
    }
}
