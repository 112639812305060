import { useEffect, useState } from 'react';
import { AddDiseaseToClinicalHistory } from '../../application/AddDiseaseToClinicalHistory/AddDiseaseToClinicalHistory';
import { DeleteDiseaseFromClinicalHistory } from '../../application/DeleteDiseaseFromClinicalHistory/DeleteDiseaseFromClinicalHistory';
import { FindClinicalHistoryByPolicyIdentifier } from '../../application/FindClinicalHistoryByPolicyIdentifier/FindClinicalHistoryByPolicyIdentifier';
import { UpdateClinicalHistoryNote } from '../../application/UpdateClinicalHistoryNote/UpdateClinicalHistoryNote';
import { UpdateClinicalHistoryValidation } from '../../application/UpdateClinicalHistoryValidation/UpdateClinicalHistoryValidation';
import { ClinicalHistory } from '../../domain/entities/ClinicalHistory';
import { ClinicalHistoryNomaRepository } from '../../infraestructure';

const clinicalHistoryNomaRepository = new ClinicalHistoryNomaRepository();

const findClinicalHistoryByPolicyIdentifier = new FindClinicalHistoryByPolicyIdentifier(clinicalHistoryNomaRepository);

const updateClinicalHistoryValidation = new UpdateClinicalHistoryValidation(clinicalHistoryNomaRepository);
const updateClinicalHistoryNote = new UpdateClinicalHistoryNote(clinicalHistoryNomaRepository);
const addDiseaseToClinicalHistory = new AddDiseaseToClinicalHistory(clinicalHistoryNomaRepository);
const deleteDiseaseFromClinicalHistory = new DeleteDiseaseFromClinicalHistory(clinicalHistoryNomaRepository);

export const useClinicalHistory = (policyIdentifier: string | undefined) => {
    const [clinicalHistory, setClinicalHistory] = useState<ClinicalHistory | undefined>(undefined);

    useEffect(() => {
        if (policyIdentifier != null) {
            findClinicalHistoryByPolicyIdentifier.execute(policyIdentifier).then(setClinicalHistory);
        }
    }, [policyIdentifier]);

    const updateValidation = (validation: boolean) => {
        if (policyIdentifier != null) updateClinicalHistoryValidation.execute(policyIdentifier, validation);
    };

    const updateNote = (note: string) => {
        if (policyIdentifier != null) updateClinicalHistoryNote.execute(policyIdentifier, note);
    };

    const addDisease = (diseaseKey: string) => {
        if (policyIdentifier != null) addDiseaseToClinicalHistory.execute(policyIdentifier, diseaseKey);
    };

    const removeDisease = (diseaseKey: string) => {
        if (policyIdentifier != null) deleteDiseaseFromClinicalHistory.execute(policyIdentifier, diseaseKey);
    };

    return { clinicalHistory, updateValidation, updateNote, addDisease, removeDisease };
};
