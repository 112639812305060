import { ClinicalHistory, Disease } from '../../../domain/entities';
import { ClinicalHistoryDto } from '../dtos/ClinicalHistoryDto';

export const toDomain = (clinicalHistoryDto: ClinicalHistoryDto): ClinicalHistory => {
    return {
        key: clinicalHistoryDto.key,
        policyIdentifier: clinicalHistoryDto.policyIdentifier,
        isValidated: clinicalHistoryDto.isValidated,
        preexistentDiseases: clinicalHistoryDto.preexistentDiseases?.map((disease): Disease => {
            return {
                key: disease.key,
                diagnosisKey: disease.diagnosisKey,
                description: disease.description,
            };
        }),
        note: clinicalHistoryDto.note,
    };
};
