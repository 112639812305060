import { Transcript } from '../domain/entities';
import { SupportingDocumentTranscriptRepository } from '../domain/repositories/SupportingDocumentTranscriptRepository';
import { FindSupportingDocumentTranscription } from '../infraestructure/repositories/supportingDocumentTranscript/FindSupportingDocumentTranscription';

export const useTranscript = (repository: SupportingDocumentTranscriptRepository) => {
    const findSupportingDocumentTranscription = new FindSupportingDocumentTranscription(repository);

    return () => {
        const findTranscription = async (transcriptKey: string): Promise<Transcript | undefined> => {
            return await findSupportingDocumentTranscription.execute(transcriptKey);
        };

        return {
            findTranscription,
        };
    };
};
