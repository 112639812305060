import { useState } from 'react';
import { useCloseClaimReason } from '../../../application/useCloseClaimReason';
import { CloseClaimReason } from '../../../domain/entities';
import { CountryCode } from '../../../domain/values/CountryCode';
import { CloseClaimReasonsStaticRepository } from '../../../infraestructure';
import { GroupedData, NoteInput } from '../../atoms';
import { ButtonVariant } from '../../atoms/button/Button';
import Dropdown from '../../atoms/dropdown/Dropdown';
import Dialog, { DialogType } from '../../organisms/dialog/Dialog';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './CloseIncidentManuallyConfirmation.modules.scss';

export type CloseIncidentConfirmationDialogProps = {
    isOpen: boolean;
    onCloseClaim: (reason: string | undefined, explanationForParent: string | undefined) => void;
    onCancel: () => void;
    countryCode: CountryCode;
};

const closeClaimReasonRepository = new CloseClaimReasonsStaticRepository();

const CloseIncidentManuallyConfirmationDialog = ({
    isOpen,
    onCloseClaim,
    onCancel,
    countryCode,
}: CloseIncidentConfirmationDialogProps) => {
    const { t } = useModuleTranslation();
    const { closeReasons } = useCloseClaimReason(closeClaimReasonRepository)();
    const [closeReason, setCloseReason] = useState<CloseClaimReason | undefined>(undefined);
    const [explanationForParent, setExplanationForParentValue] = useState<string>('');

    const onCloseDialog = () => {
        onCancel();
        setCloseReason(undefined);
    };

    return (
        <Dialog
            className="close-claim-manually-confirmation-dialog"
            isOpen={isOpen}
            showCloseButton
            type={DialogType.Confirmation}
            title={t('close_incident_manually_modal.title') || undefined}
            text={t('close_incident_manually_modal.close_manually_confirmation')}
            actions={[
                {
                    variantName: ButtonVariant.DANGER,
                    text: t('close_incident_manually_modal.close'),
                    onClick: () => onCloseClaim(closeReason?.key, explanationForParent),
                    disabled: !closeReason,
                },
                {
                    variantName: ButtonVariant.BASIC_INFO,
                    text: t('close_incident_manually_modal.cancel'),
                    onClick: () => onCancel(),
                },
            ]}
            onClose={onCloseDialog}
        >
            <Dropdown
                values={closeReasons.map((el) => ({ label: el.description, value: el.key }))}
                selectedOption={closeReason && { label: closeReason?.description, value: closeReason?.key }}
                handleOptionClicked={(option) => setCloseReason(closeReasons.find((el) => el.key === option.value))}
                label={t('close_incident_manually_modal.close_reason')}
            />
            <div className="selector">
                <Dropdown
                    label={t('close_incident_manually_modal.close_template')}
                    values={
                        t(`templates`, {
                            ns: 'close_claim',
                            lng: countryCode.toLowerCase(),
                            returnObjects: true,
                        }) as GroupedData<string>[]
                    }
                    handleOptionClicked={(option) => setExplanationForParentValue(option.value)}
                    name={'claimCloseTemplate'}
                ></Dropdown>
            </div>

            <NoteInput
                value={explanationForParent}
                label={t('close_incident_manually_modal.add_note')}
                onValueChange={setExplanationForParentValue}
            />
        </Dialog>
    );
};

export default CloseIncidentManuallyConfirmationDialog;
