import { CreateIncident } from '../../../application/ClaimRequest/CreateIncident';
import { ClaimRequestNomaRepository } from '../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';
import { CreateIncidentDto } from '../../../infraestructure/repositories/dtos/CreateIncidentDto';

const createIncident = new CreateIncident(ClaimRequestNomaRepository);
export const useCreateIncident = () => {
    const newIncident = (createIncidentDto: CreateIncidentDto) => {
        return createIncident.execute(createIncidentDto).then((incidentKey: string) => {
            return incidentKey;
        });
    };

    return { newIncident };
};
