import { ClaimRequestDto } from './repositories/dtos/ClaimRequestDto';
import { toUserDomain } from './repositories/userReservation/UserReservationMapper';
import { ClaimRequest } from '../domain/entities/ClaimRequest';
import { ClaimRequestStatus } from '../domain/values';
import { ResourceIdentifierObject } from './api';

export const toDomain = (el: ClaimRequestDto, prepaidExpenseIds: ResourceIdentifierObject[] = []): ClaimRequest => {
    return {
        key: el.key,
        policyIdentifier: el.policyIdentifier,
        countryCode: el.countryCode,
        createdAt: new Date(el.createdAt),
        updatedAt: el.updatedAt != null ? new Date(el.updatedAt) : undefined,
        originalDateOfLoss: el.originalDateOfLoss != null ? new Date(el.originalDateOfLoss) : undefined,
        causeOfLoss: el.causeOfLoss,
        incidentId: el.incidentId,
        assessmentId: el.assessmentId,
        claimKey: el.claimKey,
        iban: el.iban,
        email: el.email,
        status: ClaimRequestStatus[el.status.toUpperCase() as keyof typeof ClaimRequestStatus],
        userReservation: el.userReservation ? toUserDomain(el.userReservation) : undefined,
        prepaidExpenseIds: prepaidExpenseIds.map((it) => ({ value: it.id })),
    };
};
