import { useState } from 'react';
import { Card, MultiChipSelector, Switch } from '@barkibu/noma-commons';
import { DiagnosisType } from '../../../../domain/values/DiagnosisType';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './diagnosis-selector.modules.scss';

export type DiagnosisSelectorProps = {
    options: { key: string; description: string; deletedAt?: Date }[];
    values: { type: DiagnosisType; value: string }[];
    handleDiagnosisAdded: (type: DiagnosisType, value: string) => void;
    handleDiagnosisDeleted: (type: DiagnosisType, value: string) => void;
    readOnly?: boolean;
};

const DiagnosisSelector = ({
    options,
    values,
    handleDiagnosisAdded,
    handleDiagnosisDeleted,
    readOnly = false,
}: DiagnosisSelectorProps) => {
    const { t } = useModuleTranslation();

    const diagnosisTypeKeys = {
        [DiagnosisType.INTERNAL]: {
            name: t(`assessment.internal_diagnosis`),
            placeholder: t('assessment.add_internal_diagnosis'),
        },
        [DiagnosisType.EXTERNAL]: {
            name: t(`assessment.external_diagnosis`),
            placeholder: t('assessment.add_external_diagnosis'),
        },
    };

    const [diagnosisType, setDiagnosisType] = useState(DiagnosisType.EXTERNAL);
    const diagnosisTypeOptions = Object.values(DiagnosisType).map((type) => ({
        value: diagnosisTypeKeys[type].name,
        selected: type === diagnosisType,
    }));
    const onSwitchChange = (newType: string) => {
        const [newDiagnosisType] = Object.entries(diagnosisTypeKeys).find(([_, value]) => value.name === newType) ?? [];
        setDiagnosisType(newDiagnosisType as DiagnosisType);
    };
    const diagnosisValues = values
        .filter((el) => el.type === diagnosisType)
        .map((el) => ({
            label: options.find((option) => option.key === el.value)?.description!,
            value: el.value,
        }));
    const diagnosisOptions = options
        .filter((option) => !option.deletedAt)
        .map((option) => ({
            label: option.description,
            value: option.key,
        }))
        .filter((el) => !diagnosisValues.some((value) => value.value === el.value));

    return (
        <Card title={t('assessment.diagnoses')} className="diagnosis-selector">
            <Switch options={diagnosisTypeOptions} onValueChange={onSwitchChange} />
            <MultiChipSelector
                options={diagnosisOptions}
                label={diagnosisTypeKeys[diagnosisType].placeholder}
                values={diagnosisValues}
                handleDeleteValue={(option) => handleDiagnosisDeleted(diagnosisType, option.value)}
                handleNewValue={(option) => handleDiagnosisAdded(diagnosisType, option.value)}
                readOnly={readOnly}
                emptyLabel={t('assessment.no_diagnosis_selected') || undefined}
            />
        </Card>
    );
};

export default DiagnosisSelector;
