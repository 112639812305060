import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCookie, getCookie, setCookie, UserProvider } from '@barkibu/noma-commons';
import { AuthCredentials } from './AuthCredentials';
import { AuthToken } from './AuthToken';
import ForbiddenException from './ForbiddenException';
import { AuthNomaRepository } from '../../infrastructure/auth/AuthNomaRepository';

const MARGIN_TO_CHECK_IF_TOKEN_IS_EXPIRED = 5;
const MIN_TIME_LEFT_TO_REFRESH = 10;
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
const COOKIE_ACCESS_TOKEN_KEY = 'access_token';

type AuthContextType = {
    signIn: (credentials: AuthCredentials) => void;
    isLoggedIn: boolean;
};

export const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children?: ReactElement }> = ({ children }) => {
    const navigate = useNavigate();
    const repository = AuthNomaRepository();

    const [accessToken, setAccessToken] = useState<string | null>(getCookie(COOKIE_ACCESS_TOKEN_KEY));
    const [accessTokenTimeLeftInSeconds, setAccessTokenTimeLeftInSeconds] = useState<number>(0);

    useEffect(() => {
        if (accessTokenTimeLeftInSeconds == 0) return;

        const timeout = setTimeout(
            async () => {
                const needRefresh = await shouldRefreshAccessToken();
                if (needRefresh) {
                    await refreshAccessToken();
                }
            },
            (accessTokenTimeLeftInSeconds - MARGIN_TO_CHECK_IF_TOKEN_IS_EXPIRED) * 1000,
        );
        return () => clearTimeout(timeout);
    }, [accessTokenTimeLeftInSeconds]);

    useEffect(() => {
        if (accessToken == null) return;
        setCookie(COOKIE_ACCESS_TOKEN_KEY, accessToken);
    }, [accessToken]);

    const signIn = async (credentials: AuthCredentials) => {
        const token = await repository.authenticate(credentials);
        updateSessionData(token);
    };

    const refreshAccessToken = async () => {
        const token = await repository.refreshToken();
        updateSessionData(token);
    };

    const shouldRefreshAccessToken = async () => {
        const timeLeft = await repository.getSessionTimeLeft();
        return timeLeft < MIN_TIME_LEFT_TO_REFRESH;
    };

    const updateSessionData = (token: AuthToken) => {
        if (token.refresh == null) {
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
        } else {
            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, token.refresh);
        }

        setAccessToken(token.access);
        setAccessTokenTimeLeftInSeconds(token.accessTimeLeft);
    };

    const clearSessionData = () => {
        setAccessToken(null);
        setAccessTokenTimeLeftInSeconds(0);
        deleteCookie(COOKIE_ACCESS_TOKEN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    };

    const getUser = async () => {
        try {
            return await repository.getUser();
        } catch (e) {
            if (e instanceof ForbiddenException) {
                clearSessionData();
                navigate('/login');
            }
            throw e;
        }
    };

    const isLoggedIn = accessToken != null;

    return (
        <AuthContext.Provider
            value={{
                signIn,
                isLoggedIn,
            }}
        >
            <UserProvider getUser={getUser} isLoggedIn={isLoggedIn}>
                {children}
            </UserProvider>
        </AuthContext.Provider>
    );
};
