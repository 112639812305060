import { PolicyRepository } from '../../../domain/repositories/PolicyRepository';
import { config } from '../../../infraestructure';

export class PolicyNomaRepository implements PolicyRepository {
    findDocumentPolicyIdentifier(policyIdentifier: string): Promise<Blob> {
        return fetch(`/v1/policies/${policyIdentifier}/document`, {
            method: 'GET',
            headers: config.API_HEADERS(),
        }).then((response) => {
            return response.blob();
        });
    }
}
