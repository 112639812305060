import { EventRecordDto } from './EventRecordDto';
import { toDomain } from './EventRecordMapper';
import { EventRecord } from '../../../domain/entities/EventRecord';
import { EventAnalyticsRepository } from '../../../domain/repositories/EventAnalyticsRepository';
import { CollectionEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '../../api';

export const EventAnalyticsNomaRepository: EventAnalyticsRepository = {
    getClaimRequestLifecycle(claimRequestKey: string): Promise<EventRecord[]> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/lifecycle`, OperationMethod.GET, {}).then(
            (dto: CollectionEntityResponse<EventRecordDto>) => {
                const eventRecordsDto = dto.data.map((el) => flattenEntity(el, dto.included || []));
                return eventRecordsDto.map((eventRecord) => toDomain(eventRecord));
            }
        );
    },
};
