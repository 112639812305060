interface StylesModProps {
    prefix: string;
    with: string | undefined;
    modes: (string | undefined)[];
}

export default function stylesMode(props: StylesModProps) {
    const styles = props.modes.filter((style) => !!style).map((style) => `${props.prefix}--${style}`);

    return [props.with ?? '', ...styles].join(' ').trim();
}
