import { config, Contract, PetGender, Policy } from '@barkibu/noma-commons';
import { FindPetDto } from './dtos/FindPetDto';
import { Pet } from '../../../domain/entities/Pet';

export const toDomain = (el: FindPetDto): Pet => {
    return new Pet({
        name: el.name,
        chip: el.chip,
        birth: new Date(el.birthDate),
        gender: el.sex.toLowerCase() === 'male' ? PetGender.Male : PetGender.Female,
        breed: el.breed,
        petParent: {
            email: el.petParentEmail,
        },
        policies: el.policies.map(
            (policy) =>
                new Policy(
                    policy.product,
                    policy.status,
                    policy.policyNumber,
                    new Date(policy.startDate),
                    new Date(policy.expirationDate),
                    policy.claimRequestStats,
                    policy.contract.map(
                        (contract): Contract => ({
                            identifier: contract.contractNumber,
                            startDate: new Date(contract.startDate),
                            expirationDate: new Date(contract.expirationDate),
                            productVersionTag: contract.productVersionTag,
                            status: contract.status,
                        })
                    )
                )
        ),
        photo: config.APP_BACKEND_BASE_URL ? `${config.APP_BACKEND_BASE_URL}/v2/pets/${el.key}/avatar` : undefined,
    });
};
