import { useEffect, useState } from 'react';
import { Diagnosis } from '../domain/entities/index';
import { DiagnosisRepository } from '../domain/repositories/DiagnosisRepository';

export const useDiagnosis = (repository: DiagnosisRepository) => () => {
    const [diagnosis, setDiagnosis] = useState<Diagnosis[]>([]);

    useEffect(() => {
        repository.findAll().then(setDiagnosis);
    }, []);

    return {
        diagnosis,
    };
};
