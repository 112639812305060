import { useState } from 'react';
import { CreationNote, Note } from '../../../domain/entities';
import { NoteScope } from '../../../domain/values';
import Card from '../../atoms/card/Card';
import NoteInput from '../../atoms/note/NoteInput';
import Switch from '../../molecules/switch/Switch';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './incident-notes.modules.scss';

export enum NoteTypeValue {
    PET_PARENT = 'PET_PARENT',
    INTERNAL = 'INTERNAL',
    MISSING_INFO = 'MISSING_INFO',
    FRAUD = 'FRAUD',
}

export type IncidentNotesProps = {
    notes: Note[];
    onAddNote?: (note: CreationNote) => void;
    onDeleteNote?: (note: Note) => void;
    readOnly?: boolean;
};

const IncidentNotes = ({ notes, onAddNote, onDeleteNote, readOnly = false }: IncidentNotesProps) => {
    const { t } = useModuleTranslation();
    const [noteTypeOptions, setNoteTypeOptions] = useState([
        {
            value: t('incident_notes.internal_note'),
            type: NoteTypeValue.INTERNAL,
            selected: true,
            placeholder: t('incident_notes.internal_note_placeholder'),
        },
        {
            value: t('incident_notes.pet_parent_note'),
            type: NoteTypeValue.PET_PARENT,
            placeholder: t('incident_notes.pet_parent_note_placeholder'),
        },
        {
            value: t('incident_notes.fraud'),
            type: NoteTypeValue.FRAUD,
            placeholder: t('incident_notes.fraud_note_placeholder'),
        },
    ]);

    const [noteValue, setNoteValue] = useState<string | undefined>(undefined);
    const noteSelected = noteTypeOptions.find((value) => value.selected)!;
    const onSwitchChange = (value: string) => {
        setNoteTypeOptions(noteTypeOptions.map((e) => ({ ...e, selected: e.value === value })));
    };

    const handleAddNote = (value: string) => {
        if (onAddNote == null) return;

        onAddNote({ type: noteSelected.type, scope: NoteScope.CLAIM_REQUEST, description: value });
        setNoteValue('');
    };

    // TODO: We need to show Internal and Missing Info notes in the Internal category because the vets requested it. Should change in the future
    const categoryNotes = notes.filter((note) =>
        noteSelected?.type == 'INTERNAL'
            ? note.type == 'INTERNAL' || note.type == 'MISSING_INFO'
            : note.type == noteSelected?.type
    );

    return (
        <Card title={t('incident_notes.notes')} className="vet-view--notes">
            <div className="incident-notes-container">
                <Switch options={noteTypeOptions} onValueChange={onSwitchChange} />
                {!readOnly && onAddNote != null && (
                    <div className="incident-notes-add-note">
                        <NoteInput
                            value={noteValue}
                            label={noteSelected.placeholder}
                            onValueChange={setNoteValue}
                            onAdd={handleAddNote}
                        ></NoteInput>
                    </div>
                )}
                {notes.length > 0 && (
                    <section>
                        {categoryNotes.length !== 0
                            ? categoryNotes.map((note) => (
                                  <div key={`${note.key}-${note.description}`} className="analysis-notes-display-note">
                                      <NoteInput
                                          value={note.description}
                                          disabled={true}
                                          onDelete={readOnly ? undefined : () => onDeleteNote?.(note)}
                                          lastModified={note.updatedAt}
                                      />
                                  </div>
                              ))
                            : readOnly && (
                                  <div className="analysis-notes-display-no-note--message">
                                      <span>{t('incident_notes.no_notes')}</span>
                                  </div>
                              )}
                    </section>
                )}
                {notes.length === 0 && readOnly && (
                    <div className="analysis-notes-display-no-note--message">
                        <span>{t('incident_notes.read-only.no-notes')}</span>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default IncidentNotes;
