import { SupportingDocument } from '../../domain/entities/supporting-document';
import { SupportingDocumentRepository } from '../../domain/repositories/SupportingDocumentRepository';
import { CreateSupportingDocumentDto } from '../../infraestructure/repositories/supporting_document/dtos/CreateSupportingDocumentDto';

export interface CreateIncidentSupportingDocumentResult {
    supportingDocument: SupportingDocument;
}
export class CreateIncidentSupportingDocument {
    constructor(private supportingDocumentRepository: SupportingDocumentRepository) {}

    async execute(
        claimRequestKey: string,
        document: CreateSupportingDocumentDto
    ): Promise<CreateIncidentSupportingDocumentResult> {
        return {
            supportingDocument: await this.supportingDocumentRepository.create(claimRequestKey, {
                type: document.type,
                name: document.name,
                location: document.location,
            }),
        };
    }
}
