import ImagePreview from './ImagePreviewFile';
import { useModuleTranslation } from '../../../ui/utils/useModuleTranslation';
import { DownloadIcon, Icon, IconModes, IconSize } from '../icons';
import './preview-file.modules.scss';

const SUPPORTED_IMAGE_FILES = ['png', 'jpeg', 'jpg', 'svg', 'webp', 'heic'] as const;
const SUPPORTED_DOCUMENT_FILES = ['pdf'] as const;

type SUPPORTED_FILES = typeof SUPPORTED_IMAGE_FILES | typeof SUPPORTED_DOCUMENT_FILES;

export type PreviewFileProps = {
    url: string;
    alt?: string;
};

const isSupported = (supportedFiles: SUPPORTED_FILES, fileUrl: string) =>
    supportedFiles.some((extension) => fileUrl.toLowerCase().endsWith(`.${extension}`));

const PreviewFile = ({ url, alt }: PreviewFileProps) => {
    const isImage = isSupported(SUPPORTED_IMAGE_FILES, url);
    const isDocument = isSupported(SUPPORTED_DOCUMENT_FILES, url);
    const { t } = useModuleTranslation();

    return isImage ? (
        <ImagePreview url={url} alt={alt} />
    ) : isDocument ? (
        <object className="preview-file" aria-label={alt} data={`${url}#view=fitV`} />
    ) : (
        <div className="preview-file preview-file--unsupported">
            <h3>{t(`preview_file.unsupported`)}</h3>
            <a href={url}>
                <Icon icon={DownloadIcon} mode={IconModes.SHADOWED} size={IconSize.L} />
            </a>
        </div>
    );
};

export default PreviewFile;
