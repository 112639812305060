import { UseCase } from '../../../src';
import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories';

export class UpdateEmail implements UseCase<ClaimRequest> {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(claimRequestKey: string, email: string): Promise<ClaimRequest> {
        return this.claimRequestRepository.updateEmail(claimRequestKey, email);
    }
}
