import { SupportingDocument } from '../../domain/entities/supporting-document';
import { SupportingDocumentRepository } from '../../domain/repositories/SupportingDocumentRepository';
import { UpdateIsNewlyAddedByParentDto } from '../../infraestructure/repositories/supporting_document/dtos/UpdateIsNewlyAddedByParentDto';

export interface UpdateIncidentSupportingDocumentIsNewlyAddedByParentResult {
    supportingDocument: SupportingDocument;
}
export class UpdateIncidentSupportingDocumentIsNewlyAddedByParent {
    constructor(private supportingDocumentRepository: SupportingDocumentRepository) {}

    async execute(
        key: string,
        document: UpdateIsNewlyAddedByParentDto
    ): Promise<UpdateIncidentSupportingDocumentIsNewlyAddedByParentResult> {
        return {
            supportingDocument: await this.supportingDocumentRepository.updateIsNewlyAddedByParent(key, {
                isNewlyAddedByParent: document.isNewlyAddedByParent,
            }),
        };
    }
}
