import { flattenEntity, OperationMethod, processFetchOperation, ResourceIdentifierObject, SingleEntityResponse } from '@barkibu/noma-commons';
import { toDomain } from './ClaimRequestSummaryMapper';
import { ClaimRequestSummaryDto } from './dtos/ClaimRequestSummaryDto';
import { ClaimRequestSummary, ClaimRequestSummaryRepository } from '../../domain/ClaimRequestSummary';

export const ClaimRequestSummaryNomaRepository: ClaimRequestSummaryRepository = {
    findClaimRequestSummary: (claimRequestKey: string): Promise<ClaimRequestSummary> => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/summary`, OperationMethod.GET, {
            params: { include: 'pet,notes,supportingDocuments,diagnosis,symptoms,invoices,userReservation,resolution,resolution.inspection' },
        }).then((dto: SingleEntityResponse<ClaimRequestSummaryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenses: { data: ResourceIdentifierObject[] } = dto.data.relationships?.prepaidExpenses || { data: [] };
            return toDomain(entity, prepaidExpenses.data);
        });
    },
};
