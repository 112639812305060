import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { UseCase } from '../../domain/utils';

export interface ClaimRequestRelatedByCoverageResult {
    claimRequestsList: ClaimRequest[];
}

export class FindClaimRequestRelatedByCoverage implements UseCase<ClaimRequest[]> {
    constructor(private claimRequestRepository: ClaimRequestRepository) {}

    async execute(claimRequestKey: string, coverageKey: string): Promise<ClaimRequest[]> {
        try {
            const result = await this.claimRequestRepository.findClaimRequestRelatedByCoverage(
                claimRequestKey,
                coverageKey
            );
            return result;
        } catch (e) {
            throw new Error('Claim request not found');
        }
    }
}
