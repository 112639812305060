import { InspectionStatistics } from 'src/domain/entities';
import { ClaimRequestRepository } from '../../domain/repositories';

export class GetInspectionStatistics {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(
        claimType: string,
        countryCode: string,
        days: number,
        service?: string | null,
        interceptableClaimsOnly?: boolean | null,
        threshold?: number | null,
        evaluateThresholdHigher?: boolean | null
    ): Promise<InspectionStatistics> {
        return this.claimRequestRepository.getInspectionStatistics(
            claimType,
            countryCode,
            days,
            service,
            interceptableClaimsOnly,
            threshold,
            evaluateThresholdHigher
        );
    }
}
