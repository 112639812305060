import { useEffect, useState } from 'react';
import { FindClinicalHistoryDocumentsByPolicyIdentifier } from '../../application/FindClinicalHistoryDocumentsByPolicyIdentifier/FindClinicalHistoryDocumentsByPolicyIdentifier';
import { ClinicalHistoryDocument } from '../../domain/entities';
import { ClinicalHistoryNomaRepository } from '../../infraestructure';

const clinicalHistoryNomaRepository = new ClinicalHistoryNomaRepository();

const findClinicalHistoryDocumentsByPolicyIdentifier = new FindClinicalHistoryDocumentsByPolicyIdentifier(
    clinicalHistoryNomaRepository
);

export const useClinicalHistoryDocuments = (policyIdentifier: string | undefined) => {
    const [clinicalHistoryDocuments, setClinicalHistoryDocuments] = useState<ClinicalHistoryDocument[] | undefined>(
        undefined
    );

    useEffect(() => {
        if (policyIdentifier != null) {
            findClinicalHistoryDocumentsByPolicyIdentifier.execute(policyIdentifier).then(setClinicalHistoryDocuments);
        }
    }, [policyIdentifier]);

    return { clinicalHistoryDocuments };
};
