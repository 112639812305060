import { PetGender, Policy } from '@barkibu/noma-commons';
import { PetParent } from './PetParent';

export class Pet {
    public readonly name: string;
    public readonly breed: string;
    public readonly gender: PetGender;
    public readonly birth: Date;
    public readonly chip: string;
    public readonly petParent: PetParent | undefined;
    public readonly policies: Policy[];
    public readonly photo: string | undefined;

    constructor({
        name,
        breed,
        gender,
        birth,
        chip,
        petParent,
        policies,
        photo,
    }: {
        name: string;
        breed: string;
        gender: PetGender;
        birth: Date;
        chip: string;
        petParent?: PetParent;
        policies: Policy[];
        photo?: string;
    }) {
        this.name = name;
        this.breed = breed;
        this.gender = gender;
        this.birth = birth;
        this.chip = chip;
        this.petParent = petParent;
        this.policies = policies;
        this.photo = photo;
    }

    getPolicyByIdentifier(identifier: string) {
        const result = this.policies.find((policy) => policy.identifier === identifier);
        if (result == null) {
            throw Error(`Policy with identifier ${identifier} not found`);
        }
        return result;
    }
}
