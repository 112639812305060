export enum InspectionErrorCodes {
    OVERPAID_COST_LINE = 'overpaid_cost_line',
    OVERPAID_AMOUNT = 'overpaid_amount',
    UNDERPAID_COST_LINE = 'underpaid_cost_line',
    UNDERPAID_AMOUNT = 'underpaid_amount',
    TPV_TICKET = 'tpv_ticket',
    DUPLICATED_PAYMENT = 'duplicated_payment',
    ERRONEOUS_INVOICE_DATA = 'erroneous_invoice_data',
    ERRONEOUS_COVERAGE = 'erroneous_coverage',
    TECHNICAL_ERROR = 'technical_error',
    MEDICAL_ERROR_PREEXISTENCE = 'medical_error_preexistence',
    MEDICAL_ERROR_WAITING_PERIOD = 'medical_error_waiting_period',
    MEDICAL_ERROR_MISSING_INFO = 'medical_error_missing_info',
    MEDICAL_ERROR_NO_VET_REPORT = 'medical_error_no_vet_report',
    MEDICAL_ERROR_OTHER = 'medical_error_other',
}