import { useState } from 'react';
import { Button, Dropdown, GroupedData, Modal, NoteInput } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './pet-parent-message-modal.scss';

export interface PetParentMessageModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (noteValue: string | undefined) => void;
    prefilledText?: string;
    countryCode?: string;
    petName?: string;
}

const PetParentMessageModal = ({
    isOpen,
    onClose,
    onSave,
    prefilledText,
    countryCode,
    petName,
}: PetParentMessageModalProps) => {
    const { t } = useModuleTranslation();
    const [noteValue, setNoteValue] = useState<string>(prefilledText || '');
    const [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        setIsSaving(true);
        onSave(noteValue ? noteValue : undefined);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title={t('pet_parent_message.title')}
            className="pet-parent-message-modal"
        >
            <div className="pet-parent-message-modal__body">
                <div className="pet-parent-message-modal__content">
                    <span className="label">{t('pet_parent_message.label')}</span>
                    {countryCode && petName && (
                        <div className="selector">
                            <Dropdown
                                label={t(`pet_parent_message.templates`)}
                                values={
                                    t(`templates`, {
                                        ns: 'resolution',
                                        lng: countryCode.toLowerCase(),
                                        returnObjects: true,
                                        petName,
                                    }) as GroupedData<string>[]
                                }
                                handleOptionClicked={(option) =>
                                    setNoteValue((noteValue + '\n\n' + option.value).trimStart())
                                }
                                name={'messageTemplates'}
                            ></Dropdown>
                        </div>
                    )}
                    <NoteInput
                        value={noteValue}
                        label={t('pet_parent_message.placeholder')}
                        onValueChange={setNoteValue}
                    />
                </div>
                <div className="pet-parent-message-modal__actions">
                    <Button
                        disabled={isSaving}
                        text={t('pet_parent_message.save')}
                        accessibilityText={t('pet_parent_message.save_description')}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PetParentMessageModal;
