import { ArrowLeftIcon, Icon, IconModes, IconSize } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './invoice-banner.scss';

export interface InvoiceBannerProps {
    petName: string;
    handleIncidentRedirection: () => void;
}

const InvoiceBanner = ({ petName, handleIncidentRedirection }: InvoiceBannerProps) => {
    const { t } = useModuleTranslation();
    return (
        <div className="invoice-details--banner">
            <div className="invoice-details--banner-heading" onClick={() => handleIncidentRedirection()}>
                <Icon icon={ArrowLeftIcon} mode={IconModes.SHADOWED} size={IconSize.M} />
                <h2 className="title">{t('incident.of', { petName })}</h2>
            </div>
        </div>
    );
};

export default InvoiceBanner;
