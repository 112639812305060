import { useGetOnHoldClaimRequest } from './useGetOnHoldClaimRequests';
import { UseAsyncStatus } from '../../../../domain/utils';
import { useQueryParams } from '../../../hooks';
import { BlockingLoader } from '../../../molecules';
import { ClaimRequestsList } from '../../../organisms';

const COUNTRY_CODE_PARAM = 'countryCode';
const OnHoldListPage = () => {
    const { getQueryParamValue } = useQueryParams();
    const countryCode = getQueryParamValue(COUNTRY_CODE_PARAM) || '';
    const { claimRequestsResult, claimRequestsFetchStatus } = useGetOnHoldClaimRequest(countryCode);

    return (
        <section className={'on-hold-list-page-container'}>
            {claimRequestsFetchStatus == UseAsyncStatus.SUCCESS ? (
                <ClaimRequestsList claimRequests={claimRequestsResult?.claimRequestsList!}></ClaimRequestsList>
            ) : (
                <BlockingLoader />
            )}
        </section>
    );
};
export { OnHoldListPage };
