import { TranscriptDto } from './TranscriptDto';
import { toDomain } from './TranscriptMapper';
import { Transcript } from '../../../domain/entities';
import { SupportingDocumentTranscriptRepository } from '../../../domain/repositories/SupportingDocumentTranscriptRepository';
import { flattenEntity, OperationMethod, processFetchOperation, SingleEntityResponse } from '../../api';

export const SupportingDocumentTranscriptNomaRepository: SupportingDocumentTranscriptRepository = {
    findTranscription(transcriptKey: string): Promise<Transcript> {
        return processFetchOperation(`/v1/transcript/${transcriptKey}`, OperationMethod.GET, {}).then(
            (dto: SingleEntityResponse<TranscriptDto>) => {
                return toDomain(flattenEntity(dto.data, []));
            }
        );
    },
};
