import { ClinicalHistoryDocument } from '../../../domain/entities';
import { ClinicalHistoryDocumentDto } from '../dtos/ClinicalHistoryDocument';

export const documentToDomain = (el: ClinicalHistoryDocumentDto): ClinicalHistoryDocument => {
    return new ClinicalHistoryDocument({
        key: el.key,
        name: el.name,
        location: el.location,
        date: new Date(el.createdAt),
        deleted: false,
    });
};
