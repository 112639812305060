import { UseCase } from '@barkibu/noma-commons';
import { AdjustmentRecommendation } from '../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentRepository } from '../../domain/adjustment/AdjustmentRepository';

export const FindAllAdjustmentRecommendations = (
    repository: AdjustmentRepository
): UseCase<AdjustmentRecommendation[]> => ({
    execute: async (adjustmentKey: string): Promise<AdjustmentRecommendation[]> => {
        const result = await Promise.all([
            repository.findAllCoverages(adjustmentKey).catch((_) => []),
            repository.findAllDenialReasons(adjustmentKey),
        ]);
        return result.flat();
    },
});
