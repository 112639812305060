import { ResolutionRepository } from '../../domain/resolution/ResolutionRepository';
import { Inspection } from '../../domain/resolution/Inspection';
import { SingleEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '@barkibu/noma-commons';
import { ResolutionDto } from './dtos/ResolutionDto';
import { toDomain } from './ResolutionMapper';
import { Resolution } from '../../domain/resolution/Resolution';
import { useOverpayMapper } from '../../utils/useOverpayMapper';
const { getOverpayLowerBoundErrorInCents, getOverpayUpperBoundErrorInCents } = useOverpayMapper();

export const ResolutionNomaRepository: ResolutionRepository = {
    saveResolutionInspection(claimRequestKey: string, resolutionInspection: Inspection): Promise<Resolution> {
        return processFetchOperation(
            `/v1/claim-request/${claimRequestKey}/resolution-inspection`,
            OperationMethod.POST,
            {
                body: JSON.stringify({
                    isValid: resolutionInspection.isValid,
                    errorCodes: resolutionInspection.errorCodes,
                    detail: resolutionInspection.detail,
                    lowerBoundErrorInCents: getOverpayLowerBoundErrorInCents(resolutionInspection.overpay),
                    upperBoundErrorInCents: getOverpayUpperBoundErrorInCents(resolutionInspection.overpay),
                }),
            }
        ).then((dto: SingleEntityResponse<ResolutionDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },
};
