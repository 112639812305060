import { User } from '../domain/entities';
import { ClaimRequestRepository } from '../domain/repositories';
import { StealClaimRequestReservation } from '../infraestructure/repositories/reservation/StealClaimRequestReservation';

export const useReservation = (repository: ClaimRequestRepository) => {
    const stealClaimRequestReservation = new StealClaimRequestReservation(repository);

    return () => {
        const stealReservation = async (claimRequestKey: string): Promise<User> => {
            return await stealClaimRequestReservation.execute(claimRequestKey);
        };

        return {
            stealReservation,
        };
    };
};
