export class ApiError extends Error {
    readonly id?: string;
    readonly links?: Map<string, string>;
    readonly status?: string;
    readonly code?: string;
    readonly title?: string;
    readonly details?: string;
    readonly source: Map<string, string>;
    readonly meta: Map<string, string>;

    constructor(
        id?: string,
        links?: Map<string, string>,
        message?: string,
        status?: string,
        code?: string,
        title?: string,
        details?: string,
        source?: Map<string, string>,
        meta?: Map<string, string>
    ) {
        super(message);
        this.id = id;
        this.links = links;
        this.status = status;
        this.code = code;
        this.title = title;
        this.details = details;
        this.source = source || new Map();
        this.meta = meta || new Map();
    }
}
