import { useEffect, useState } from 'react';
import { Card, Chip, ChipType, Input } from '../../atoms';
import { useValidations } from '../../hooks';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './parent-card.modules.scss';

export type ParentCardProps = {
    email?: string;
    iban?: string;
    readOnly?: boolean;
    onUpdateEmail?: (email: string) => void;
    onUpdateIban?: (iban: string) => void;
    onValidationEmail?: (isValid: boolean) => void;
    onValidationIban?: (isValid: boolean) => void;
    hideIban?: boolean;
    tags?: string[];
};

export default function ParentCard({
    email,
    iban,
    onUpdateEmail,
    onUpdateIban,
    onValidationIban,
    onValidationEmail,
    readOnly = false,
    hideIban = false,
    tags,
}: ParentCardProps) {
    const { t } = useModuleTranslation();
    const { isValidIban, isValidEmail } = useValidations();
    const parentCardTitle = t('parent_card.title');
    const emailLabel = t('parent_card.email');
    const ibanLabel = t('parent_card.iban');
    const emailValidationError = t('parent_card.error.email');
    const ibanValidationError = t('parent_card.error.iban');
    const [emailValue, setEmailValue] = useState(email);
    const [ibanValue, setIbanValue] = useState(iban);
    const [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState('');
    const [ibanValidationErrorMessage, setIbanValidationErrorMessage] = useState('');

    useEffect(() => {
        setEmailValue(email);
        validateEmail();
    }, [email]);

    useEffect(() => {
        setIbanValue(iban);
        validateIban();
    }, [iban]);

    const handleChangeEmail = (emailValue: string) => {
        setEmailValue(emailValue);
    };
    const handleChangeIban = (ibanValue: string) => {
        setIbanValue(ibanValue);
    };

    const validateIban = () => {
        if (onValidationIban) {
            const valid = isValidIban(ibanValue);
            if (!valid) setIbanValidationErrorMessage(ibanValidationError);
            else setIbanValidationErrorMessage('');
            onValidationIban(valid);
        }
    };

    const validateEmail = () => {
        if (onValidationEmail) {
            const valid = isValidEmail(emailValue);
            if (!valid) setEmailValidationErrorMessage(emailValidationError);
            else setEmailValidationErrorMessage('');

            onValidationEmail(valid);
        }
    };

    const handleTouchedEmail = () => {
        if (onUpdateEmail) {
            validateEmail();
            onUpdateEmail(emailValue!);
        }
    };
    const handleTouchedIban = () => {
        if (onUpdateIban) {
            validateIban();
            onUpdateIban(ibanValue!);
        }
    };

    return (
        <Card title={parentCardTitle} className="card--pet-parent">
            <>
                {tags && tags.length > 0 && (
                    <div className="tags">
                        {tags.map((tag, index) => (
                            <Chip type={ChipType.NEUTRAL} text={tag} accessibilityText={tag} key={index} />
                        ))}
                    </div>
                )}
                <Input
                    value={emailValue}
                    name="email"
                    type="email"
                    onChange={handleChangeEmail}
                    setTouched={handleTouchedEmail}
                    errorMessage={emailValidationErrorMessage}
                    label={emailLabel}
                    disabled={readOnly}
                />
                { !hideIban && <Input
                    value={ibanValue}
                    name="iban"
                    onChange={handleChangeIban}
                    setTouched={handleTouchedIban}
                    errorMessage={ibanValidationErrorMessage}
                    label={ibanLabel}
                    disabled={readOnly}
                />}
            </>
        </Card>
    );
}
