import { useEffect, useState } from 'react';
import { ClaimRequest } from '@barkibu/noma-commons';
import { InvoiceNomaRepository } from '../infraestructure/repositories/invoice/InvoiceNomaRepository';

const invoiceRepository = new InvoiceNomaRepository();

export const useInvoiceRelatedClaimRequests = (taxIdNumber: string | undefined, number: string | undefined) => {
    const [claimRequests, setClaimRequests] = useState<ClaimRequest[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setClaimRequests([]);

        if (taxIdNumber == null || number == null) {
            return;
        }

        setLoading(true);

        invoiceRepository
            .findInvoiceRelatedClaimRequests(taxIdNumber, number)
            .then(setClaimRequests)
            .catch(() => {
                console.error('Error while fetching invoice related claim requests');
                setClaimRequests([]);
            })
            .finally(() => setLoading(false));
    }, [taxIdNumber, number]);

    return { claimRequests, loading };
};
