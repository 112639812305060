import { FilesRepository } from '../../domain/repositories/FilesRepository';

export class UploadFileToTemporaryStorage {
    constructor(private filesRepository: FilesRepository) {}

    execute(file: File): Promise<string> {
        const result = this.filesRepository.uploadTemporary(file);
        return result;
    }
}
