import { useEffect, useRef, useState } from 'react';
import { PreviewFileProps } from './PreviewFile';
import { transformHeicFile } from '../../../infraestructure/utils/imageUtils';
import Button, { ButtonVariant } from '../button/Button';
import { DownloadIcon, RefreshIcon } from '../icons';
import './image-preview-file.modules.scss';

const ZOOM_STEP = 0.1;

const ImagePreview = ({ url, alt }: PreviewFileProps) => {
    const imagRef = useRef<HTMLImageElement>(null);
    const [zoom, setZoom] = useState(0);
    const [initialZoom, setInitialZoom] = useState(0);
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [rotate, setRotate] = useState(0);

    useEffect(() => {
        (async () => {
            let finalUrl = url;
            if (url.endsWith('heic')) {
                const imageData = await transformHeicFile(url);
                finalUrl = URL.createObjectURL(imageData);
            }
            setImageUrl(finalUrl);
        })();
    }, [url]);

    const onImageLoad = () => {
        if (imagRef.current) {
            setRotate(0);
            const heightZoom = imagRef.current.parentElement!.clientHeight / imagRef.current.naturalHeight;
            const widthZoom = imagRef.current.parentElement!.clientWidth / imagRef.current.naturalWidth;
            setInitialZoom(Math.min(heightZoom, widthZoom));
        }
    };

    useEffect(() => {
        setZoom(initialZoom);
    }, [initialZoom]);

    useEffect(() => {
        if (imagRef.current && zoom != 0) {
            imagRef.current.style.width = `${imagRef.current.naturalWidth * zoom}px`;
            imagRef.current.style.height = `${imagRef.current.naturalHeight * zoom}px`;
        }
    }, [zoom]);

    const applyZoom = (newZoom: number) => {
        if (newZoom >= 0.01 && newZoom <= 5) {
            setZoom(newZoom);
        }
    };

    const onZoomIn = () => {
        const newZoom = zoom + ZOOM_STEP;
        applyZoom(newZoom);
    };

    const onZoomOut = () => {
        const newZoom = zoom - ZOOM_STEP;
        applyZoom(newZoom);
    };

    const resetZoom = () => {
        setZoom(initialZoom);
    };

    const downloadFile = () => {
        const aElement = document.createElement('a');
        aElement.href = imageUrl!;
        aElement.download = imageUrl!.split('/').at(-1)!;
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
    };

    const rotateImage = () => {
        setRotate((oldValue) => oldValue + 90);
    };

    const normalizedRotate = rotate % 360;
    const translateX = normalizedRotate == 0 || normalizedRotate == 90 ? 0 : 100;
    const translateY = normalizedRotate == 90 || normalizedRotate == 180 ? 100 : 0;

    return (
        <div className="preview-file--image__container">
            <div className="image-controls">
                <div className="image-controls--info"></div>
                <div className="image-controls--zoom">
                    <Button
                        text="-"
                        variantName={ButtonVariant.SECONDARY}
                        onClick={onZoomOut}
                        accessibilityText="Reducir zoom"
                    />
                    <span className="image-zoom--text" onDoubleClick={resetZoom}>
                        {(zoom * 100).toFixed(0)}%
                    </span>
                    <Button
                        text="+"
                        variantName={ButtonVariant.SECONDARY}
                        onClick={onZoomIn}
                        accessibilityText="Ampliar zoom"
                    />
                </div>
                <div className="image-controls--other">
                    <Button
                        icon={RefreshIcon}
                        variantName={ButtonVariant.SECONDARY}
                        onClick={rotateImage}
                        accessibilityText="Rotar imagen"
                    />
                    <Button
                        icon={DownloadIcon}
                        variantName={ButtonVariant.SECONDARY}
                        onClick={downloadFile}
                        accessibilityText="Descargar archivo"
                    />
                </div>
            </div>
            <figure className="figure">
                {imageUrl && (
                    <img
                        ref={imagRef}
                        alt={alt}
                        src={imageUrl}
                        onLoad={onImageLoad}
                        style={{
                            transform: `rotate(${rotate}deg) translateX(-${translateX}%) translateY(-${translateY}%)`,
                        }}
                    />
                )}
            </figure>
        </div>
    );
};

export default ImagePreview;
