import clsx from 'clsx';
import { ClinicalHistory } from '../../../domain/entities';
import { Button, ButtonVariant, Card } from '../../../ui/atoms';
import ClinicalHistoryCard, {
    ClinicalHistoryCardStyle,
} from '../../../ui/molecules/clinical-history-card/ClinicalHistoryCard';
import { useHref } from '../../hooks';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './clinical-history-wrapper.modules.scss';

export type ClinicalHistoryWrapperProps = {
    clinicalHistory: ClinicalHistory | undefined;
    policyIdentifier: string;
    style?: ClinicalHistoryCardStyle;
};

const ClinicalHistoryWrapper = ({
    clinicalHistory,
    policyIdentifier,
    style = ClinicalHistoryCardStyle.NORMAL,
}: ClinicalHistoryWrapperProps) => {
    const { t } = useModuleTranslation();
    const { to } = useHref();

    const handleRedirectionClinicalHistoryScreen = () => {
        to(`/policy/${policyIdentifier}/clinical-history`);
    };
    return (
        <div>
            {clinicalHistory?.isValidated != undefined ? (
                <ClinicalHistoryCard
                    clinicalHistory={clinicalHistory}
                    moveToClinicalHistoryScreen={handleRedirectionClinicalHistoryScreen}
                    style={style}
                />
            ) : (
                <Card>
                    <div
                        className={clsx('clinical-history-validation', {
                            'clinical-history-validation--big': style == ClinicalHistoryCardStyle.BIG,
                        })}
                    >
                        <span className="clinical-history-validation__title">{t('clinical_history.title')}</span>
                        <Button
                            text={'Validate'}
                            variantName={ButtonVariant.BASIC_INFO}
                            onClick={() => handleRedirectionClinicalHistoryScreen()}
                        ></Button>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default ClinicalHistoryWrapper;
