export const useValidations = () => {
    const isValidEmail = (email: string | undefined) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return email != undefined && emailRegex.test(email);
    };

    const isValidIban = (iban: string | undefined) => {
        const ibanRegex = /^[A-Za-z]{2}[A-Za-z\d]{14,29}$/;
        return iban != undefined && ibanRegex.test(iban.replaceAll(' ', ''));
    };

    return { isValidEmail, isValidIban };
};
