import { Fragment } from 'react';
import { Card, Divider } from '@barkibu/noma-commons';
import { AdjustmentRecommendation } from '../../../../domain/adjustment/AdjustmentRecommendation';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import CoverageAdjustmentDetail, {
    CoverageAdjustmentsProps,
} from '../coverage-adjustment-detail/CoverageAdjustmentDetail';
import './CoverageAdjustmentsContainer.scss';

export type CoverageAdjustmentsContainerProps = {
    claimRequestKey: CoverageAdjustmentsProps['claimRequestKey'];
    adjustments: CoverageAdjustmentsProps['adjustment'][];
    recommendations: Map<string, AdjustmentRecommendation[] | undefined>;
    onRecommendationSelected: CoverageAdjustmentsProps['onRecommendationSelected'];
    onFindDuplicatedInvoiceRelated: CoverageAdjustmentsProps['onFindDuplicatedInvoiceRelated'];
    readOnly?: boolean;
};

const CoverageAdjustmentsContainer = ({
    claimRequestKey,
    adjustments,
    recommendations,
    onRecommendationSelected,
    onFindDuplicatedInvoiceRelated,
    readOnly = false,
}: CoverageAdjustmentsContainerProps) => {
    const { t } = useModuleTranslation();
    return (
        <Card className="coverage-page--content-card" title={t('coverages.select_coverages')}>
            {adjustments.map((adjustment, index) => (
                <Fragment key={adjustment.key}>
                    <CoverageAdjustmentDetail
                        claimRequestKey={claimRequestKey}
                        adjustment={adjustment}
                        recommendations={recommendations.get(adjustment.key)}
                        onRecommendationSelected={onRecommendationSelected}
                        onFindDuplicatedInvoiceRelated={onFindDuplicatedInvoiceRelated}
                        readOnly={readOnly}
                    />
                    {adjustments.length !== index + 1 && <Divider />}
                </Fragment>
            ))}
        </Card>
    );
};

export default CoverageAdjustmentsContainer;
