import { CountryCode, toUserDomain } from '@barkibu/noma-commons';
import { ClaimInfoDto } from './dtos/ClaimInfoDto';
import { PaymentLetterDto } from './dtos/PaymetLetterDto';
import { ClaimInfo } from '../../../domain/entities/ClaimInfo';
import PaymentLetter from '../../../domain/entities/PaymentLetter';
import { toDomain as supportingDocumentDtoToDomainMapper } from '../supporting_document/SupportingDocumentMapper';

export const toDomain = (claimInfoDto: ClaimInfoDto): ClaimInfo => {
    return {
        incidentKey: claimInfoDto.key,
        status: claimInfoDto.status,
        amountRefunded: claimInfoDto.amountRefunded,
        createdAt: new Date(claimInfoDto.createdAt),
        supportingDocuments: claimInfoDto.supportingDocuments.map(supportingDocumentDtoToDomainMapper),
        claimRequestKey: claimInfoDto.key,
        userReservation:
            claimInfoDto && claimInfoDto.userReservation ? toUserDomain(claimInfoDto.userReservation) : undefined,
        diagnosisValues: claimInfoDto.diagnosisValues,
        symptomValues: claimInfoDto.symptomValues,
        paymentLetters: claimInfoDto.paymentLetters.map(paymentLetterDtoToDomainMapper),
        statusDetails: claimInfoDto.statusDetails,
        countryCode: CountryCode[claimInfoDto.countryCode as keyof typeof CountryCode],
    };
};

const paymentLetterDtoToDomainMapper = (paymentLetterDto: PaymentLetterDto): PaymentLetter =>
    new PaymentLetter({
        key: paymentLetterDto.key,
        location: paymentLetterDto.location,
    });
