import { SupportingDocumentDto, SupportingDocumentType } from '@barkibu/noma-commons';
import { IncidentFromClaimRequestDto } from './dtos/IncidentFromClaimRequestDto';
import { Incident } from '../../../domain/entities/Incident';
import { SupportingDocument } from '../../../domain/entities/supporting-document';

export const incidentToDomain = (
    incidentDto: IncidentFromClaimRequestDto,
    supportingDocumentsDto?: SupportingDocumentDto[]
): Incident => {
    const supportingDocuments = (supportingDocumentsDto || incidentDto.supportingDocuments)?.map(
        (supportingDocument) => {
            return new SupportingDocument({
                key: supportingDocument.key,
                name: supportingDocument.name,
                type: SupportingDocumentType[supportingDocument.type as keyof typeof SupportingDocumentType],
                location: supportingDocument.location,
                date: new Date(supportingDocument.createdAt),
                deleted: false,
                isNewlyAddedByParent: supportingDocument.newlyAddedByParent,
                transcriptKey: supportingDocument.transcriptKey,
            });
        }
    );

    // TODO: Remove this logic. It's only for the provisional feature of sorting by update date.
    const getSupportingDocumentMaxDate = (supportingDocuments: Incident['supportingDocuments']) => {
        const dates = supportingDocuments?.map((supportingDocument) => supportingDocument.date!.getTime());
        return dates != null && dates.length > 0 ? new Date(Math.max(...dates)) : null;
    };
    const updatedAt = new Date(
        Math.max(
            getSupportingDocumentMaxDate(supportingDocuments)?.getTime() ?? 0,
            new Date(incidentDto.updatedAt).getTime()
        )
    );

    return {
        key: incidentDto.key,
        date: new Date(incidentDto.date),
        updatedAt: updatedAt,
        description: incidentDto.description,
        note: incidentDto.details?.note,
        supportingDocuments: supportingDocuments,
    };
};
