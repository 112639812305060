import './preview-item.modules.scss';

export type PreviewItemProps = {
    title: string;
    content: string[];
};

const PreviewItem = ({ title, content }: PreviewItemProps) => {
    return (
        <div className="preview-item preview-item-yellow">
            <h3 className="preview-title preview-title-yellow">{title}</h3>
            <ul className="preview-content">
                {content.map(
                    (item, index) =>
                        item.length > 0 && (
                            <li key={index} className="content-item">
                                {item}
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};

export default PreviewItem;
