import { NoteScope } from './NoteScope';
import { NoteTypeValue } from './NoteTypeValue';
import { useModuleTranslation } from '../../../ui/utils/useModuleTranslation';

export type NoteOption = {
    value: string;
    type: NoteTypeValue;
    placeholder: string;
    scope: NoteScope;
    selected?: boolean;
};

export const DefaultNoteOptions: () => NoteOption[] = () => {
    const { t } = useModuleTranslation();

    return [
        {
            value: t('note_option.internal_note'),
            type: NoteTypeValue.INTERNAL,
            placeholder: t('note_option.internal_note_placeholder'),
            scope: NoteScope.PET,
        },
        {
            value: t('note_option.internal_note'),
            type: NoteTypeValue.INTERNAL,
            placeholder: t('note_option.internal_note_placeholder'),
            scope: NoteScope.POLICY,
        },
        {
            value: t('note_option.internal_note'),
            type: NoteTypeValue.INTERNAL,
            placeholder: t('note_option.internal_note_placeholder'),
            scope: NoteScope.PET_PARENT,
        },
        {
            value: t('note_option.fraud'),
            type: NoteTypeValue.FRAUD,
            placeholder: t('note_option.fraud_note_placeholder'),
            scope: NoteScope.PET_PARENT,
        },
        {
            value: t('note_option.tag'),
            type: NoteTypeValue.TAG,
            placeholder: t('note_option.tag_note_placeholder'),
            scope: NoteScope.PET,
        },
        {
            value: t('note_option.tag'),
            type: NoteTypeValue.TAG,
            placeholder: t('note_option.tag_note_placeholder'),
            scope: NoteScope.POLICY,
        },
        {
            value: t('note_option.tag'),
            type: NoteTypeValue.TAG,
            placeholder: t('note_option.tag_note_placeholder'),
            scope: NoteScope.PET_PARENT,
        },
    ];
};
