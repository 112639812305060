import React, { useEffect, useRef } from 'react';
import { CloseIcon } from '../icons';
import './modal.modules.scss';

export type ModalProps = {
    children: React.ReactNode;
    isOpen: boolean;
    title?: string | null;
    onClose?: () => void;
    className?: string;
    showCloseButton?: boolean;
};

const Modal = ({ children, isOpen, onClose, title, className, showCloseButton = !!title }: ModalProps) => {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            !ref.current?.open && ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [isOpen]);

    return (
        <dialog ref={ref} onCancel={onClose} className={className}>
            {(title || showCloseButton) && (
                <header>
                    {title && <h2>{title}</h2>}
                    {showCloseButton && (
                        <button type="button" onClick={onClose} aria-label="Botón de cerrar">
                            <CloseIcon />
                        </button>
                    )}
                </header>
            )}
            {children}
        </dialog>
    );
};

export default Modal;
