import { AssessmentDiagnosisDto } from './AssessmentDiagnosisDto';
import { AssessmentDto } from './AssessmentDto';
import { AssessmentSymptomDto } from './AssessmentSymptomDto';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentDiagnosis } from '../../domain/assessment/AssessmentDiagnosis';
import { AssessmentSymptom } from '../../domain/assessment/AssessmentSymptom';
import { DiagnosisType } from '../../domain/values/DiagnosisType';

export const toDomain = (assessmentDto: AssessmentDto): Assessment => ({
    key: assessmentDto.key,
    symptoms: assessmentDto.symptoms.map(toSymptomDomain),
    diagnosis: assessmentDto.assessmentDiagnoses.map(toDiagnosisDomain),
});

const toSymptomDomain = (symptomDto: AssessmentSymptomDto): AssessmentSymptom => ({
    key: symptomDto.key,
    description: symptomDto.descriptor,
});

const toDiagnosisDomain = (diagnosisDto: AssessmentDiagnosisDto): AssessmentDiagnosis => ({
    key: diagnosisDto.key,
    description: diagnosisDto.descriptor,
    type: DiagnosisType[diagnosisDto.type as keyof typeof DiagnosisType],
});
