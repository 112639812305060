import { useEffect } from 'react';
import { DeleteClaimRequest } from './DeleteClaimRequest';
import { DuplicateClaimRequest } from './DuplicateClaimRequest';
import { UpdateCauseOfLoss } from './UpdateCauseOfLoss';
import { UpdateDateOfLoss } from './UpdateDateOLoss';
import { UpdateEmail } from './UpdateEmail';
import { UpdateIban } from './UpdateIban';
import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestDetails } from '../../domain/entities/ClaimRequestDetails';
import { ClaimRequestRepository } from '../../domain/repositories';
import { useAsync } from '../../domain/utils';
import { CauseOfLoss } from '../../domain/values';
import { FindClaimRequestDetails } from '../FindClaimRequestDetails/FindClaimRequestDetails';
import { FindClaimRequestRelatedByCoverage } from '../FindClaimRequestRelatedByCoverage/FindClaimRequestRelatedByCoverage';

export const useClaimRequest = (repository: ClaimRequestRepository) => {
    const findClaimRequestDetails = new FindClaimRequestDetails(repository);
    const findClaimRequestRelatedByCoverage = new FindClaimRequestRelatedByCoverage(repository);
    const updateCause = new UpdateCauseOfLoss(repository);
    const updateDate = new UpdateDateOfLoss(repository);
    const updateIncidentIban = new UpdateIban(repository);
    const updateIncidentEmail = new UpdateEmail(repository);
    const deleteClaimRequestUseCase = new DeleteClaimRequest(repository);
    const duplicateClaimRequestUseCase = new DuplicateClaimRequest(repository);

    return (claimRequestKey: string) => {
        const { execute, value, setValue } = useAsync<ClaimRequestDetails | undefined>(findClaimRequestDetails);
        useEffect(() => {
            if (claimRequestKey != undefined) execute(claimRequestKey);
            else setValue(new ClaimRequestDetails());
        }, [claimRequestKey]);

        const updateCauseOfLoss = async (causeOfLoss: CauseOfLoss) => {
            await updateCause.execute(claimRequestKey, causeOfLoss);
        };

        const updateDateOfLoss = async (dateOfLoss: Date) => {
            await updateDate.execute(claimRequestKey, dateOfLoss);
            const updatedClaimRequestDetails = value?.clone({ dateOfLoss });
            setValue(updatedClaimRequestDetails);
        };

        const updateIban = async (iban: string) => {
            await updateIncidentIban.execute(claimRequestKey, iban);
        };

        const updateEmail = async (email: string) => {
            await updateIncidentEmail.execute(claimRequestKey, email);
        };

        const findClaimRequestRelated = async (coverageKey: string): Promise<ClaimRequest[]> => {
            return await findClaimRequestRelatedByCoverage.execute(claimRequestKey, coverageKey);
        };

        const deleteClaimRequest = async () => {
            await deleteClaimRequestUseCase.execute(claimRequestKey);
        };

        const duplicateClaimRequest = async (): Promise<ClaimRequest> => {
            return await duplicateClaimRequestUseCase.execute(claimRequestKey);
        };

        const reassignPolicy = async (policyIdentifier: string) => {
            await repository.changePolicy(claimRequestKey, policyIdentifier);
            const updatedClaimRequestDetails = value?.clone({ policyIdentifier });
            setValue(updatedClaimRequestDetails);
        };

        const overturnVetAnalysisDecision = async () => {
            await repository.overturnVetAnalysisDecision(claimRequestKey);
        };

        return {
            claimRequestDetails: value,
            updateCauseOfLoss,
            updateDateOfLoss,
            updateIban,
            updateEmail,
            findClaimRequestRelated,
            deleteClaimRequest,
            duplicateClaimRequest,
            reassignPolicy,
            overturnVetAnalysisDecision,
        };
    };
};
