import { ReactNode } from 'react';
import './Tooltip.modules.scss';

export type TooltipProps = {
    position: 'top' | 'bottom' | 'right' | 'left';
    children: ReactNode;
};

const Tooltip = ({ children, position }: TooltipProps) => {
    return (
        <div role="tooltip" data-tip-position={position}>
            {children}
        </div>
    );
};

export default Tooltip;
