import { useEffect } from 'react';
import { useAsync, UseAsyncStatus } from '@barkibu/noma-commons';
import { FindClaimRequestSummary } from './FindClaimRequestSummary/FindClaimRequestSummary';
import { ClaimRequestSummary, ClaimRequestSummaryRepository } from '../../domain/ClaimRequestSummary';

const AsyncStatusPriority = [UseAsyncStatus.ERROR, UseAsyncStatus.PENDING, UseAsyncStatus.SUCCESS, UseAsyncStatus.IDLE];

export const useClaimRequestSummary = (repository: ClaimRequestSummaryRepository) => {
    const findClaimRequestSummary = new FindClaimRequestSummary(repository);

    return (claimRequestKey: string) => {
        const {
            execute,
            status: findSummaryStatus,
            error: findSummaryError,
            value: claimRequestSummary,
        } = useAsync<ClaimRequestSummary | undefined>(findClaimRequestSummary);

        const syncStatus = [findSummaryStatus].reduce((status, useCaseStatus) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return AsyncStatusPriority.find(
                (refStatus) => findSummaryStatus == refStatus || useCaseStatus == refStatus
            )!;
        }, UseAsyncStatus.IDLE);

        const syncError = findSummaryError;

        useEffect(() => {
            if (claimRequestKey != undefined) {
                execute(claimRequestKey);
            }
        }, [claimRequestKey]);

        return {
            claimRequestSummary: claimRequestSummary,
            findClaimRequestSummary: (claimRequestKey: string) => execute(claimRequestKey),
            syncStatus,
            syncError,
        };
    };
};
