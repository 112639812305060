import { SymptomDto } from './SymptomDto';
import { toDomain } from './SymptomMapper';
import { Symptom } from '../../../domain/entities/Symptom';
import { SymptomRepository } from '../../../domain/repositories/SymptomRepository';
import { CollectionEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '../../api/index';

export const SymptomNomaRepository = (): SymptomRepository => ({
    findAll: async (): Promise<Symptom[]> => {
        return processFetchOperation(`/v1/symptoms`, OperationMethod.GET).then(
            (dto: CollectionEntityResponse<SymptomDto>) => {
                return dto.data.map((el) => toDomain(flattenEntity(el, [])));
            }
        );
    },
});
