import React, { useState } from 'react';
import {
    Button,
    ButtonVariant,
    Divider,
    Input,
    MoreIcon,
    SearchIcon,
    ChipGroup,
    Chip,
    ChipType,
    InfoIcon,
} from '../../atoms';
import InboxItem from '../../atoms/inbox-item/InboxItem';
import { BlockingLoader, Switch } from '../../molecules';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './inbox.modules.scss';
import Select from '../../atoms/select/Select';

export interface InboxProps<T = string, N = number> {
    title: string;
    readonly?: boolean;
    menuItems: {
        title: T;
        actionName?: T;
        count?: N;
        suffixCount?: T;
        reservedCount?: N;
        onClick: () => void;
        disabled?: boolean;
    }[];
    onSearch?: (policyKey: string) => void;
    onAdd?: (policyKey: string) => Promise<void>;
    countryCode: string;
    countryCodeOptions: string[];
    onUpdateCountryCode: (countryCode: string) => void;
    claimType?: string;
    claimTypeOptions?: string[];
    onUpdateClaimType?: (claimType: string) => void;
    selectorOptions?: { label: string; value: number; selected?: boolean }[];
    onSelectorOption?: (optionSelected: number) => void;
    claimStatusFilter?: { label: string; selected: boolean }[];
    onClaimStatusFilter?: (selected: string) => void;
}

export const Inbox = ({
    title,
    menuItems,
    onSearch,
    onAdd,
    countryCode,
    countryCodeOptions,
    onUpdateCountryCode,
    claimType,
    claimTypeOptions,
    onUpdateClaimType,
    selectorOptions,
    onSelectorOption,
    claimStatusFilter,
    onClaimStatusFilter,
    readonly,
}: InboxProps) => {
    const { t } = useModuleTranslation();
    const [policyNumber, setPolicyNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const countryOptions = countryCodeOptions.map((el) => ({
        value: el,
        selected: el === countryCode,
    }));

    const handleCountryOptionsChange = (value: string) => {
        onUpdateCountryCode(value);
    };
    const handleClaimTypeChange = (value: string) => {
        if (onUpdateClaimType) onUpdateClaimType(value);
    };

    const handleSearchByPolicy = () => {
        if (onSearch) {
            setLoading(true);
            onSearch(policyNumber);
            setLoading(false);
        }
    };

    const handleAddByPolicy = async () => {
        if (onAdd) {
            setLoading(true);
            await onAdd(policyNumber);
            setLoading(false);
        }
    };

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (onSelectorOption) onSelectorOption(Number(event.target.value));
    };
    const setLoadingWrapper =
        <T extends never[]>(fn: (...args: never[]) => Promise<void> | void) =>
        async (...args: T) => {
            if (loading) return;
            setLoading(true);
            await fn(...args);
            setLoading(false);
        };

    return (
        <>
            <div className={'inbox'}>
                <h1 className={'inbox-title'}>{title}</h1>
                {claimStatusFilter && onClaimStatusFilter && (
                    <div className={'inbox-status-filter'}>
                        <Switch
                            options={claimStatusFilter.map((el) => ({
                                value: el.label,
                                selected: el.selected,
                            }))}
                            onValueChange={onClaimStatusFilter}
                        />
                    </div>
                )}
                {selectorOptions && selectorOptions.length > 0 && (
                    <div className={'inbox-selector'}>
                        <Select defaultValue={selectorOptions.find((it) => it.selected)?.value} onChange={handleSelect}>
                            {selectorOptions.map((it) => (
                                <option key={it.value} value={it.value}>
                                    {it.label}
                                </option>
                            ))}
                        </Select>
                    </div>
                )}
                {claimTypeOptions && claimTypeOptions.length > 1 && (
                    <div className={'inbox-claim-type-selector'}>
                        <Switch
                            options={claimTypeOptions.map((el) => ({
                                value: el,
                                selected: el === claimType,
                            }))}
                            onValueChange={handleClaimTypeChange}
                        />
                    </div>
                )}
                <div className={'inbox-country-selector'}>
                    <Switch options={countryOptions} onValueChange={handleCountryOptionsChange} />
                </div>
                <div className={'inbox-items-content'}>
                    {menuItems.map((item, index) => (
                        <InboxItem
                            key={index}
                            title={item.title}
                            actionName={item.actionName}
                            count={item.count}
                            suffixCount={item.suffixCount}
                            reservedCount={item.reservedCount}
                            onClick={setLoadingWrapper(item.onClick)}
                            disabled={item.disabled}
                        />
                    ))}
                </div>
                {(onAdd || onSearch) && (
                    <>
                        <Divider></Divider>
                        <div className={'inbox-search-box'}>
                            <Input
                                value={policyNumber}
                                name={'policy-box'}
                                onChange={(value) => {
                                    setPolicyNumber(value);
                                }}
                                label={t('inbox.input_policy_label')}
                                disabled={readonly}
                            />
                        </div>
                        {onSearch && (
                            <div className={'inbox-button-left'}>
                                <Button
                                    text={t('inbox.search')}
                                    accessibilityText={t('inbox.search_detail')}
                                    icon={SearchIcon}
                                    variantName={ButtonVariant.SECONDARY}
                                    onClick={handleSearchByPolicy}
                                    disabled={readonly || loading || policyNumber.length <= 0}
                                />
                            </div>
                        )}
                        {onAdd && (
                            <div className={'inbox-button-right'}>
                                <Button
                                    text={t('inbox.add')}
                                    accessibilityText={t('inbox.add_detail')}
                                    icon={MoreIcon}
                                    variantName={ButtonVariant.SECONDARY}
                                    onClick={setLoadingWrapper(handleAddByPolicy)}
                                    disabled={readonly || loading || policyNumber.length <= 0}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {loading && <BlockingLoader />}
        </>
    );
};

export default Inbox;
