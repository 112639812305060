import { ClinicalHistory } from '../../domain/entities';
import { ClinicalHistoryRepository } from '../../domain/repositories';

export class DeleteDiseaseFromClinicalHistory {
    constructor(private clinicalHistoryRepository: ClinicalHistoryRepository) {}

    async execute(policyIdentifier: string, diagnosisKey: string): Promise<ClinicalHistory | undefined> {
        return await this.clinicalHistoryRepository.deleteDisease(policyIdentifier, { diagnosisKey });
    }
}
