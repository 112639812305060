import { PetGender } from '../../../domain/values/PetGender';
import Avatar from '../../atoms/avatar/Avatar';
import Chip, { ChipType } from '../../atoms/chip/Chip';
import { CalendarIcon, ChipIcon, DogIcon, InvoiceIcon, MaleIcon } from '../../atoms/icons/Icon';
import Attribute from '../../molecules/attribute/Attribute';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './pet-profile.modules.scss';

export interface PetProfileProps {
    name?: string;
    photo?: string;
    breed?: string;
    gender?: PetGender;
    birth?: Date | null;
    chip?: string;
    totalClaimRequestCount?: number;
    openClaimRequestCount?: number;
    tags?: string[];
}

const PetProfile = ({
    name,
    photo,
    breed,
    gender,
    birth,
    chip,
    totalClaimRequestCount,
    openClaimRequestCount,
    tags,
}: PetProfileProps) => {
    const { t } = useModuleTranslation();
    const unknownValue = t('value.unknown');
    const genderDescription = gender ? t(`pet.gender.${gender}`) : unknownValue;

    // TODO: This should be formatted for a i18n library that set the right locale.
    //  We will use 'es' while we don't support other languages
    const birthFormattedDate = birth ? Intl.DateTimeFormat('es').format(birth) : undefined;

    const totalClaimRequestCountDescription = t(`pet.claim_requests.total`, { count: totalClaimRequestCount });
    const openClaimRequestCountDescription = t(`pet.claim_requests.open`, { count: openClaimRequestCount });
    const claimRequestsStatisticDescription = `${totalClaimRequestCountDescription} (${openClaimRequestCountDescription})`;

    return (
        <div className="pet-profile-container">
            <Avatar className="pet-profile-avatar" src={photo} alt="Fotografía de la mascota" />
            <div className="pet-profile-info">
                <h2 className="pet-profile-name">{name ?? unknownValue}</h2>
                {tags && tags.length > 0 && (
                    <div className="pet-profile-tags">
                        {tags.map((tag, index) => (
                            <Chip type={ChipType.NEUTRAL} text={tag} accessibilityText={tag} key={index} />
                        ))}
                    </div>
                )}
                <div className="pet-profile-attributes">
                    <Attribute highlight icon={DogIcon} description={breed ?? unknownValue} />
                    <Attribute highlight icon={MaleIcon} description={genderDescription ?? unknownValue} />
                    <Attribute highlight icon={CalendarIcon} description={birthFormattedDate ?? unknownValue} />
                    <Attribute highlight icon={ChipIcon} description={chip ?? unknownValue} />
                    <Attribute
                        className="no-capitalize"
                        highlight
                        icon={InvoiceIcon}
                        description={claimRequestsStatisticDescription}
                    />
                </div>
            </div>
        </div>
    );
};

export default PetProfile;
