import { UseCase } from '@barkibu/noma-commons';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';

export class DeleteSymptomFromAssessment implements UseCase<Assessment> {
    private assessmentRepository: AssessmentRepository;

    constructor(assessmentRepository: AssessmentRepository) {
        this.assessmentRepository = assessmentRepository;
    }

    async execute(assessmentKey: string, symptomKey: string): Promise<Assessment> {
        return this.assessmentRepository.deleteSymptom(assessmentKey, {
            symptomKey,
        });
    }
}
