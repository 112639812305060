import React, { useEffect, useState } from 'react';
import { Divider, PreviewFile } from '@barkibu/noma-commons';
import CostLine from '../../../../../domain/entities/CostLine';
import Invoice from '../../../../../domain/entities/Invoice';
import InvoiceCostLines from '../invoice-cost-lines/InvoiceCostLines';
import InvoiceTotals from '../invoice-totals/InvoiceTotals';
import './invoice-form.modules.scss';

export type InvoiceFormProps = {
    children: React.ReactElement;
    invoice: Invoice;
    readOnly: boolean;
    onUpdateClinic: (key: string, clinic: string) => void;
    onUpdateCostLines: (key: string, costLines: CostLine[]) => void;
    onUpdateDiscount: (key: string, discount: number) => void;
    onUpdateReimbursablePercentage: (key: string, reimbursablePercentage: number) => void;
    isFullCoverage?: boolean;
};

const InvoiceForm = ({
    children,
    invoice,
    readOnly,
    onUpdateClinic,
    onUpdateCostLines,
    onUpdateDiscount,
    onUpdateReimbursablePercentage,
    isFullCoverage = false,
}: InvoiceFormProps) => {
    const [clinicSelected, setClinicSelected] = useState(invoice.providerName);
    const [discountSelected, setDiscountSelected] = useState(invoice.discountInCents);
    const [reimbursablePercentageSelected, setReimbursablePercentageSelected] = useState(
        invoice.reimbursablePercentage
    );

    useEffect(() => {
        setClinicSelected(invoice.providerName);
        setDiscountSelected(invoice.discountInCents);
        setReimbursablePercentageSelected(invoice.reimbursablePercentage);
    }, [invoice]);
    const handleClinicChange = (clinic: string) => {
        setClinicSelected(clinic);
    };
    const handleClinicTouched = () => {
        if (clinicSelected != null) {
            onUpdateClinic(invoice.key!, clinicSelected);
        }
    };
    const handleDiscountChange = (discountInCents: number) => {
        setDiscountSelected(discountInCents);
    };
    const handleDiscountTouched = () => {
        if (discountSelected != null) {
            onUpdateDiscount(invoice.key!, discountSelected);
        }
    };
    const handleReimbursablePercentageChange = (reimbursablePercentage: number) => {
        setReimbursablePercentageSelected(reimbursablePercentage);
        onUpdateReimbursablePercentage(invoice.key!, reimbursablePercentage);
    };

    const handleUpdateCostLine = (costLines: CostLine[]) => {
        onUpdateCostLines(invoice.key!, costLines);
    };

    return (
        <div className="invoice-details--body">
            {children}
            <Divider />
            <div className="invoice-details--body-content">
                <div className={'invoice-details--body-content--viewer'}>
                    <PreviewFile url={invoice.supportingDocument.location!} />
                </div>
                <div className={'invoice-details--body-content--information'}>
                    <InvoiceCostLines
                        costLinesDefault={invoice.costLines}
                        readOnly={readOnly}
                        onUpdateCostLines={handleUpdateCostLine}
                    />
                    <div className={'invoice-details--body-content--information--footer'}>
                        <div>
                            <Divider />
                            <InvoiceTotals
                                readOnly={readOnly}
                                costLines={invoice.costLines}
                                clinic={clinicSelected ?? ''}
                                discount={discountSelected ?? 0}
                                reimbursablePercentage={reimbursablePercentageSelected}
                                handleClinicChange={handleClinicChange}
                                handleClinicTouched={handleClinicTouched}
                                handleDiscountChange={handleDiscountChange}
                                handleDiscountTouched={handleDiscountTouched}
                                handleReimbursablePercentageChange={handleReimbursablePercentageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceForm;
