import { Button, Chip, ChipType, CloseIcon, Dropdown, Input, Modal, Option, Switch } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import { useOverpayMapper } from '../../../../utils/useOverpayMapper';
import { useState } from 'react';
import './inspection-modal.modules.scss';
import { InspectionOverpay } from '../../../../domain/values/InspectionOverpay';
import { Inspection } from '../../../../domain/resolution/Inspection';
import { InspectionErrorCodes } from '../../../../domain/values/InspectionErrorCodes';

export type InspectionDialogProps = {
    inspection?: Inspection;
    isOpen: boolean;
    onSubmit: (inspection: Inspection) => Promise<void>;
    onClose: () => void;
};

const InspectionModal = ({ inspection, isOpen, onSubmit, onClose }: InspectionDialogProps) => {
    const { t } = useModuleTranslation();
    const { getInspectionOverpayFromString } = useOverpayMapper();
    const [isValid, setIsValid] = useState(inspection?.isValid || false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errorsCodesList, setErrorsCodesList] = useState<InspectionErrorCodes[]>(inspection?.errorCodes || []);
    const [detail, setDetail] = useState<string>(inspection?.detail || '');
    const [overpay, setOverpay] = useState<InspectionOverpay>(inspection?.overpay || InspectionOverpay.NONE);

    const errorOptions = [
        {
            group: 'Quality Control errors',
            options: Object.keys(InspectionErrorCodes).map((key) => {
                return {
                    label: t(
                        `inspection_modal.error_codes.${InspectionErrorCodes[key as keyof typeof InspectionErrorCodes]}`
                    ),
                    value: key,
                };
            }),
        },
    ];

    const handleOptionClicked = (option: Option<string>) => {
        setErrorsCodesList([
            ...errorsCodesList,
            InspectionErrorCodes[option.value as keyof typeof InspectionErrorCodes],
        ]);
    };
    const handleIsValid = (value: string) => {
        setIsValid(value.toUpperCase() === 'OK');
    };

    const removeErrorCode = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const toDeleteErrorCode = event.currentTarget.textContent?.toUpperCase();
        if (toDeleteErrorCode && toDeleteErrorCode.length > 0)
            setErrorsCodesList(errorsCodesList.filter((it) => it.toUpperCase() !== toDeleteErrorCode));
    };

    const handleOverpay = (value: string) => {
        setOverpay(getInspectionOverpayFromString(value));
    };

    const handleSave = async () => {
        setIsSubmitting(true);
        const inspection: Inspection = {
            isValid,
            errorCodes: errorsCodesList,
            detail,
            overpay,
        };
        await onSubmit(inspection);
        onClose();
        setIsSubmitting(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            showCloseButton
            title={t('inspection_modal.title') || undefined}
            onClose={() => onClose()}
        >
            <div className="inspection-modal__body">
                <div className="inspection-modal__body__valid">
                    <Switch
                        options={[
                            { value: t('inspection_modal.option_invalid'), selected: !isValid },
                            { value: t('inspection_modal.option_valid'), selected: isValid },
                        ]}
                        onValueChange={handleIsValid}
                    ></Switch>
                </div>
                <div className="inspection-modal__body__error-codes">
                    <Dropdown
                        values={errorOptions.map((option) => {
                            return {
                                ...option,
                                options: option.options.filter(
                                    (opt) =>
                                        !errorsCodesList.includes(
                                            InspectionErrorCodes[opt.value as keyof typeof InspectionErrorCodes]
                                        )
                                ),
                            };
                        })}
                        handleOptionClicked={handleOptionClicked}
                        label={t('inspection_modal.error_codes.title')}
                    />
                    <div className="inspection-modal__body__error-codes__display">
                        {errorsCodesList.map((errorCode) => (
                            <Chip
                                key={errorCode}
                                text={errorCode}
                                type={ChipType.FILTER}
                                icon={CloseIcon}
                                onClick={removeErrorCode}
                            />
                        ))}
                    </div>
                </div>
                <div className="inspection-modal__detail">
                    <Input
                        label={t('inspection_modal.detail')}
                        value={detail}
                        onChange={(value) => {
                            setDetail(value);
                        }}
                    ></Input>
                </div>
                <div className="inspection-modal__body__overpay">
                    <label>{t('inspection_modal.overpay')}</label>
                    <br />
                    <div className="inspection-modal__body__overpay_switch">
                        <Switch
                            options={[
                                { value: InspectionOverpay.NONE, selected: overpay === InspectionOverpay.NONE },
                                {
                                    value: InspectionOverpay.LESS_THAN_5,
                                    selected: overpay === InspectionOverpay.LESS_THAN_5,
                                },
                                {
                                    value: InspectionOverpay.FROM_5_TO_25,
                                    selected: overpay === InspectionOverpay.FROM_5_TO_25,
                                },
                                {
                                    value: InspectionOverpay.FROM_25_TO_50,
                                    selected: overpay === InspectionOverpay.FROM_25_TO_50,
                                },
                                {
                                    value: InspectionOverpay.FROM_50_TO_100,
                                    selected: overpay === InspectionOverpay.FROM_50_TO_100,
                                },
                                {
                                    value: InspectionOverpay.MORE_THAN_100,
                                    selected: overpay === InspectionOverpay.MORE_THAN_100,
                                },
                            ]}
                            onValueChange={handleOverpay}
                        ></Switch>
                    </div>
                </div>
                <div className="inspection-modal__body__actions">
                    <Button text={t('inspection_modal.submit')} disabled={isSubmitting} onClick={handleSave} />
                </div>
            </div>
        </Modal>
    );
};

export default InspectionModal;
