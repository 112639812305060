import { stylesMode } from '../../styles';
import './progress-indicator.modules.scss';

const CIRCULAR_STROKE = 126;

export enum ProgressIndicatorType {
    CIRCULAR = 'circular',
}

export interface ProgressIndicatorProps {
    type?: ProgressIndicatorType;
    determinate?: boolean;
    value?: number;
}

const ProgressIndicator = ({
    type = ProgressIndicatorType.CIRCULAR,
    determinate = false,
    value,
}: ProgressIndicatorProps) => {
    const classNames = stylesMode({
        with: 'progress-indicator',
        prefix: 'progress-indicator',
        modes: [type, determinate ? 'determinate' : 'indeterminate', value?.toString()].filter(Boolean),
    });

    const strokeOffset = value ? CIRCULAR_STROKE * ((100 - value) / 100) : CIRCULAR_STROKE;
    return (
        <div role="progressbar" className={classNames}>
            <svg viewBox="22 22 44 44">
                <circle
                    data-testid="progress-indicator-circular-circle"
                    cx="44"
                    cy="44"
                    r="20.2"
                    strokeDasharray={`${CIRCULAR_STROKE}px`}
                    strokeDashoffset={`${strokeOffset}px`}
                ></circle>
            </svg>
        </div>
    );
};

export default ProgressIndicator;
