import { SupportingDocumentDto, SupportingDocumentType } from '@barkibu/noma-commons';
import { SupportingDocument } from '../../../domain/entities/supporting-document';

export const toDomain = (el: SupportingDocumentDto): SupportingDocument => {
    return new SupportingDocument({
        key: el.key,
        type: SupportingDocumentType[el.type as keyof typeof SupportingDocumentType],
        name: el.name,
        location: el.location,
        date: new Date(el.createdAt),
        deleted: false,
        isNewlyAddedByParent: el.newlyAddedByParent,
        transcriptKey: el.transcriptKey,
    });
};
