import { useState } from 'react';
import {
    Card,
    CauseOfLoss,
    ClaimDetails,
    ClaimLifecycle,
    ClaimRequestStatus,
    ClaimRequestType,
    CreationNote,
    IncidentNotes,
    Note,
} from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import IncidentDates from '../incident-dates/IncidentDates';
import './incident-information.modules.scss';

interface IncidentInformationProps {
    claimRequestKey?: string;
    readOnly?: boolean;
    requestedDate: Date;
    dateOfLoss?: Date;
    causeOfLoss?: CauseOfLoss;
    claimRequestType?: ClaimRequestType;
    notes: Note[];
    status?: {
        details?: string[];
        description: ClaimRequestStatus;
    };
    onUpdateCauseOfLoss?: (causeOfLoss: CauseOfLoss) => void;
    onUpdateDateOfLoss?: (date: Date) => void;
    onAddNote?: (note: CreationNote) => void;
    onDeleteNote?: (note: Note) => void;
    visitReason?: string;
}

const IncidentInformation = (props: IncidentInformationProps) => {
    const { t } = useModuleTranslation();
    const [dateOfLossSelected, setDateOfLossSelected] = useState<Date | null | undefined>(props.dateOfLoss);
    const [causeOfLossSelected, setCauseOfLossSelected] = useState(props.causeOfLoss);
    const handleDateOfLossChange = (dateOfLoss: Date | null) => {
        setDateOfLossSelected(dateOfLoss);
        if (props.onUpdateDateOfLoss && dateOfLoss != null) props.onUpdateDateOfLoss(dateOfLoss);
    };
    const handleCauseOfLossChange = (causeOfLoss: CauseOfLoss) => {
        setCauseOfLossSelected(causeOfLoss);
        if (props.onUpdateCauseOfLoss) props.onUpdateCauseOfLoss(causeOfLoss);
    };

    return (
        <div className={'card--incident-information'}>
            {props.claimRequestType == ClaimRequestType.HEALTH && (
                <>
                    <ClaimLifecycle
                        status={props.status?.description!}
                        claimRequestKey={props.claimRequestKey!}
                        statusDetails={props.status?.details}
                    />
                    <Card title={t('incident.dates')} className="card--incident-information--dates">
                        <IncidentDates
                            readOnly={props.readOnly}
                            requestedDate={props.requestedDate}
                            dateOfLoss={dateOfLossSelected}
                            onUpdate={handleDateOfLossChange}
                        />
                    </Card>
                    <ClaimDetails
                        visitReason={props.visitReason}
                        causeOfLoss={causeOfLossSelected}
                        handleCauseOfLossChange={handleCauseOfLossChange}
                        readOnly={props.readOnly}
                    />
                </>
            )}
            {props.claimRequestKey && (
                <IncidentNotes
                    notes={props.notes}
                    onAddNote={props.onAddNote}
                    onDeleteNote={props.onDeleteNote}
                    readOnly={props.readOnly}
                ></IncidentNotes>
            )}
        </div>
    );
};

export default IncidentInformation;
