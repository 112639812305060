import { FraudAlert } from '../../domain/entities';
import { UseCase } from '../../domain/utils';
import { FraudAlertsNomaRepository } from '../../infraestructure/repositories/FraudAlertsNomaRepository';

export class FindClaimRequestFraudAlerts implements UseCase<FraudAlert[]> {
    constructor(private fraudAlertsRepository: FraudAlertsNomaRepository) {}

    async execute(claimRequestKey: string): Promise<FraudAlert[]> {
        try {
            return await this.fraudAlertsRepository.find(claimRequestKey);
        } catch (e) {
            throw new Error('Claim request not found');
        }
    }
}
