import { ChangeEvent, TextareaHTMLAttributes, useEffect, useRef } from 'react';

export type TextareaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    onChange?: (value: string) => void;
};

const Textarea = ({ value, onChange, ...props }: TextareaProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        if (onChange) {
            onChange(event.target.value);
        }
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height =
                value && textareaRef.current.scrollHeight > 0 ? `${textareaRef.current.scrollHeight}px` : 'auto';
        }
    }, [value, textareaRef.current]);

    return <textarea {...props} value={value} onChange={handleChange} ref={textareaRef} style={{ resize: 'none' }} />;
};

export default Textarea;
