import { Card, DatePicker } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './vet-view-info-date.modules.scss';

export type VetViewInfoDatesProps = {
    invoiceDate: Date | undefined | null;
    firstSymptomsDate: Date | undefined | null;
    handleFirstSymptomsDateChange: (value: Date | null) => void;
    readOnly?: boolean;
};

const VetViewInfoDates = ({
    invoiceDate,
    firstSymptomsDate,
    handleFirstSymptomsDateChange,
    readOnly = false,
}: VetViewInfoDatesProps) => {
    const { t } = useModuleTranslation();
    return (
        <Card title={t('assessment.dates')} className="vet-view--info-dates">
            <DatePicker value={invoiceDate} label={t('assessment.invoice') || undefined} disabled />
            <DatePicker
                value={firstSymptomsDate}
                label={t('assessment.first_symptoms') || undefined}
                onChange={handleFirstSymptomsDateChange}
                disabled={readOnly}
            />
        </Card>
    );
};

export default VetViewInfoDates;
