import { useEffect, useState } from 'react';
import Chip, { ChipType } from '../../atoms/chip/Chip';
import './switch.modules.scss';

export type SwitchProps<T = string> = {
    options: { value: T; selected?: boolean }[];
    onValueChange: (option: T) => void;
};

const Switch = ({ options, onValueChange }: SwitchProps) => {
    const selectedOptionIndex = options.findIndex((el) => el.selected);

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
        selectedOptionIndex != -1 ? selectedOptionIndex : undefined
    );

    useEffect(() => {
        setSelectedIndex(selectedOptionIndex);
    }, [selectedOptionIndex]);

    const onChipClick = (index: number) => {
        if (index != selectedIndex) {
            setSelectedIndex(index);
            onValueChange(options[index].value);
        }
    };

    return (
        <div className="switch__container">
            {options.map((option, index) => {
                const className =
                    selectedIndex === undefined
                        ? 'no-selection'
                        : index < selectedIndex
                        ? 'left'
                        : index > selectedIndex
                        ? 'right'
                        : '';
                return (
                    <Chip
                        key={index}
                        className={className}
                        type={ChipType.OPTION}
                        text={option.value}
                        checked={index === selectedIndex}
                        onClick={() => onChipClick(index)}
                    />
                );
            })}
        </div>
    );
};

export default Switch;
