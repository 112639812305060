import { useEffect, useState } from 'react';
import { CloseClaimReason } from '../domain/entities';
import { CloseClaimReasonRepository } from '../domain/repositories';

export const useCloseClaimReason = (repository: CloseClaimReasonRepository) => () => {
    const [closeReasons, setCloseReasons] = useState<CloseClaimReason[]>([]);

    useEffect(() => {
        repository.findAll().then(setCloseReasons);
    }, []);

    return {
        closeReasons,
    };
};
