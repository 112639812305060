import { ClinicalHistory } from '../../domain/entities';
import { ClinicalHistoryRepository } from '../../domain/repositories';

export class UpdateClinicalHistoryValidation {
    constructor(private clinicalHistoryRepository: ClinicalHistoryRepository) {}

    async execute(policyIdentifier: string, validation: boolean): Promise<ClinicalHistory | undefined> {
        return await this.clinicalHistoryRepository.updateValidation(policyIdentifier, { validation });
    }
}
