import { SupportingDocumentType } from '@barkibu/noma-commons';
import { RequestSummary } from '../../domain/entities/RequestSummary';
import { SupportingDocument } from '../../domain/entities/supporting-document';
import { ClaimInfoRepository } from '../../domain/repositories/ClaimInfoRepository';

export class FindIncidentsByPolicyIdentifier {
    constructor(private claimInfoRepository: ClaimInfoRepository) {}

    async execute(policyIdentifier: string): Promise<RequestSummary[]> {
        const criteria = { policyIdentifier: policyIdentifier };
        const claimInfos = await this.claimInfoRepository.findAllByCriteria(criteria);

        const map: RequestSummary[] = claimInfos.map((claimInfo) => {
            return {
                incidentKey: claimInfo.incidentKey,
                status: claimInfo.status,
                date: claimInfo.createdAt,
                amountRefunded: claimInfo.amountRefunded,
                supportingDocuments: [
                    ...claimInfo.supportingDocuments,
                    ...claimInfo.paymentLetters.map(
                        (el) =>
                            new SupportingDocument({
                                key: el.key,
                                type: SupportingDocumentType.PAYMENT_LETTER,
                                location: el.location,
                            })
                    ),
                ],
                claimRequestKey: claimInfo.claimRequestKey,
                userReservation: claimInfo.userReservation,
                diagnosisValues: claimInfo.diagnosisValues,
                symptomValues: claimInfo.symptomValues,
                statusDetails: claimInfo.statusDetails,
                countryCode: claimInfo.countryCode,
            };
        });

        return map;
    }
}
