import {
    Chip,
    ChipType,
    ClaimRequest,
    DirectionUpIcon as ArrowRight,
    Dropdown,
    GroupedData,
    Option,
    RelatedClaimRequest,
} from '@barkibu/noma-commons';
import { AdjustmentRecommendation } from '../../../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentWarningType } from '../../../../domain/values/AdjustmentWarningType';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './CoverageAdjustmentDetail.scss';

type CoverageConcept = {
    key: string;
    concept: string;
    costInCents: number;
    recommendation?: {
        key: string;
        description: string;
        cover: boolean;
    };
};

type CoveragePageAdjustment = {
    key: string;
    name?: string;
    date: Date;
    reimbursablePercentage: number;
    discountInCents: number;
    warnings?: {
        type: AdjustmentWarningType;
        description: string;
        payload?: string; // @ts-ignore
        claimRequestsRelated?: ClaimRequest[] | undefined;
    }[];
    concepts: CoverageConcept[];
};

export type CoverageAdjustmentsProps = {
    claimRequestKey: string;
    adjustment: CoveragePageAdjustment;
    recommendations: AdjustmentRecommendation[] | undefined;
    onRecommendationSelected: ({
        conceptKey,
        recommendation,
    }: {
        conceptKey: string;
        recommendation: AdjustmentRecommendation;
    }) => void;
    onFindDuplicatedInvoiceRelated: (adjustmentKey: string) => Promise<ClaimRequest[]>;
    readOnly?: boolean;
};

type CoverageAdjustmentConceptRowProps = {
    concept: CoverageConcept;
    recommendationOptions: GroupedData<AdjustmentRecommendation>[];
    onRecommendationSelected: CoverageAdjustmentsProps['onRecommendationSelected'];
    readOnly?: boolean;
};

const CoverageAdjustmentConceptRow = ({
    concept,
    recommendationOptions,
    onRecommendationSelected,
    readOnly = false,
}: CoverageAdjustmentConceptRowProps) => {
    const { t } = useModuleTranslation();

    const conceptRecommendation = recommendationOptions
        .flatMap((el) => el.options)
        .find((el) => el.value.key === concept.recommendation?.key);

    const handleOptionClicked = (option: Option<AdjustmentRecommendation>) => {
        onRecommendationSelected({
            conceptKey: concept.key,
            recommendation: option.value,
        });
    };

    const displayCoverageUsage = () => {
        if (conceptRecommendation) {
            const usageCount = conceptRecommendation.value.claimRequestWithCoverageUsages?.length;
            const usageLimit = conceptRecommendation.value.usageLimit;
            if (usageLimit && usageLimit > 0) return `(${usageCount}/${usageLimit})`;
            else return `(${usageCount})`;
        }
    };

    const currencyFormatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    return (
        <>
            <span>
                {concept.concept}
                {(conceptRecommendation?.value?.claimRequestWithCoverageUsages?.length ?? 0) > 0 && (
                    <RelatedClaimRequest
                        title={t('coverages.dialog_title', { usage: displayCoverageUsage() })}
                        subtitle={concept.concept}
                        claimRequestsRelated={conceptRecommendation?.value?.claimRequestWithCoverageUsages || []}
                    >
                        <span className={'coverage-use'}>{displayCoverageUsage()}</span>
                    </RelatedClaimRequest>
                )}
            </span>
            <span>{currencyFormatter.format(concept.costInCents / 100).replace(',', '.')}</span>
            <Dropdown
                values={recommendationOptions}
                selectedOption={conceptRecommendation}
                handleOptionClicked={handleOptionClicked}
                label={t('coverages.reason')}
                disabled={readOnly}
            />
        </>
    );
};

type TransformRecommendations = {
    coverage: Option<AdjustmentRecommendation>[];
    exclusion: Option<AdjustmentRecommendation>[];
};

const CoverageAdjustmentDetail = ({
    adjustment,
    recommendations,
    onRecommendationSelected,
    onFindDuplicatedInvoiceRelated,
    readOnly = false,
}: CoverageAdjustmentsProps) => {
    const { t } = useModuleTranslation();
    const invoiceDateFormatted = Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(adjustment.date);

    const transformedOptions = Object.entries(
        recommendations?.reduce(
            (result: TransformRecommendations, el) => {
                const key: keyof TransformRecommendations = el.cover ? 'coverage' : 'exclusion';
                result[key].push({ label: el.description, value: el });
                return result;
            },
            { coverage: [], exclusion: [] }
        ) ?? {}
    ).map(
        ([key, options]): GroupedData<AdjustmentRecommendation> => ({
            group: key.charAt(0).toUpperCase() + key.slice(1),
            options,
        })
    );

    return (
        <div className="coverage-page--content-adjustment">
            <header>
                <h3>
                    {adjustment.name} - {invoiceDateFormatted}
                </h3>
                {adjustment.warnings?.map((tag) => (
                    <div key={`${adjustment.name}-${tag.description}`}>
                        {tag.type == AdjustmentWarningType.DUPLICATED_INVOICE ? (
                            <RelatedClaimRequest
                                title={'Claims with duplicated invoices'}
                                getClaimRequestsRelated={() => onFindDuplicatedInvoiceRelated(adjustment.key)}
                            >
                                <Chip
                                    type={ChipType.ACTIONABLE}
                                    text={tag.description}
                                    icon={ArrowRight}
                                    onClick={() => null}
                                    color={'orange'}
                                />
                            </RelatedClaimRequest>
                        ) : (
                            <Chip type={ChipType.WARNING} text={tag.description} />
                        )}
                    </div>
                ))}
            </header>
            <div className="coverage-page--content-adjustment__details">
                <header>{t('coverages.concept')}</header>
                <header>{t('coverages.amount')}</header>
                <header>{t('coverages.coverage')}</header>
                {adjustment.concepts.map((concept) => {
                    return (
                        <CoverageAdjustmentConceptRow
                            key={concept.key}
                            concept={concept}
                            onRecommendationSelected={onRecommendationSelected}
                            recommendationOptions={transformedOptions}
                            readOnly={readOnly}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CoverageAdjustmentDetail;
