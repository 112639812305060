import { Button, ButtonVariant, DownloadIcon, EyeIcon, LegalIcon, SearchIcon } from '../../../ui/atoms';
import { stylesMode } from '../../../ui/styles';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

export interface PolicyCardPolicyIdentifierDetailsProps {
    onViewDocument?: () => void;
    onDownloadDocument?: () => void;
    policyIdentifier?: string;
    onShowPoliciesClick?: () => void;
}

export default function PolicyCardPolicyIdentifierDetails({
    onViewDocument,
    onDownloadDocument,
    policyIdentifier,
    onShowPoliciesClick,
}: PolicyCardPolicyIdentifierDetailsProps) {
    const { t } = useModuleTranslation();

    const policyItemStyle = stylesMode({ with: 'policy', prefix: 'policy', modes: ['description-item'] });
    const policyActionItemStyle = stylesMode({ with: 'policy', prefix: 'policy', modes: ['action-item'] });

    const text = policyIdentifier ? policyIdentifier : t('value.unknown', { ns: 'commons' });
    const actions = (
        <div className={policyActionItemStyle}>
            {onShowPoliciesClick && (
                <Button onClick={onShowPoliciesClick} variantName={ButtonVariant.BASIC_INFO} icon={SearchIcon} />
            )}
            <Button onClick={onDownloadDocument} variantName={ButtonVariant.BASIC_INFO} icon={DownloadIcon} />
            <Button onClick={onViewDocument} variantName={ButtonVariant.BASIC_INFO} icon={EyeIcon} />
        </div>
    );

    return (
        <div className={policyItemStyle}>
            <LegalIcon className="icon" />
            {text}
            {policyIdentifier && actions}
        </div>
    );
}
