import React, { ReactNode } from 'react';
import Divider from '../divider/Divider';
import { GroupedData, isGroupedData, Option } from '../dropdown/Dropdown.types';
import './popover-list.modules.scss';

export type PopoverListProps<T> = {
    values: GroupedData<T>[] | Option<T>[];
    handleClick: (option: Option<T>) => void;
    className?: string;
    fallback?: ReactNode;
};

const renderOption = <T,>(
    option: Option<T>,
    elementHandleClick: (option: Option<T>) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
) => {
    return (
        <li key={`${option.value}`} className="popover-list-item" onClick={elementHandleClick(option)}>
            {option.label}
        </li>
    );
};

const PopoverList = <T,>({ values, handleClick, className, fallback }: PopoverListProps<T>) => {
    const elementHandleClick = (option: Option<T>) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        handleClick(option);
    };
    return (
        <div className={`popover-list-container ${className ?? ''}`}>
            {fallback && values.length === 0 ? (
                <div className={'popover-fallback'}>{fallback}</div>
            ) : (
                values.map((el, index) => (
                    <React.Fragment key={isGroupedData(el) ? el.group : `${el.value}`}>
                        <ul className="popover-group-container">
                            {isGroupedData(el) ? (
                                <li>
                                    <h3>{el.group}</h3>
                                    <ul className="popover-group-options">
                                        {el.options.map((option) => renderOption(option, elementHandleClick))}
                                    </ul>
                                </li>
                            ) : (
                                renderOption(el, elementHandleClick)
                            )}
                        </ul>
                        {isGroupedData(el) && values.length - 1 != index && <Divider />}
                    </React.Fragment>
                ))
            )}
        </div>
    );
};

export default PopoverList;
