import { ReactNode, useState } from 'react';
import { ClaimRequest } from '../../../domain/entities';
import BodyLead from '../../atoms/body-lead/BodyLead';
import InboxItem from '../../atoms/inbox-item/InboxItem';
import Modal from '../../atoms/modal/Modal';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './RelatedClaimRequest.modules.scss';

export type RelatedClaimRequestProps = {
    title: string;
    subtitle?: string;
    children: ReactNode;
    claimRequestsRelated?: ClaimRequest[];
    getClaimRequestsRelated?: () => Promise<ClaimRequest[]>;
    showUsages?: boolean;
};

type ClaimRequestCoverageUsage = {
    claimRequest: ClaimRequest;
    usageCount: number;
};

function groupByClaimRequestWithCoverageCount(claimRequests: ClaimRequest[]): ClaimRequestCoverageUsage[] {
    const claimRequestDic = claimRequests.reduce(
        (carry, claimRequest) => {
            carry[claimRequest.key] = claimRequest;
            return carry;
        },
        {} as Record<string, ClaimRequest>
    );
    const claimRequestUsageCount = claimRequests.reduce(
        (counter, claimRequest) => {
            const key = claimRequest.key;
            if (counter[key] == undefined) {
                counter[key] = 0;
            }
            counter[key]++;
            return counter;
        },
        {} as Record<string, number>
    );

    return Object.keys(claimRequestUsageCount).map((key) => ({
        claimRequest: claimRequestDic[key],
        usageCount: claimRequestUsageCount[key],
    }));
}

const RelatedClaimRequest = ({
    title,
    subtitle,
    children,
    claimRequestsRelated,
    getClaimRequestsRelated,
    showUsages = true,
}: RelatedClaimRequestProps) => {
    const { t } = useModuleTranslation();

    const [claimRequestList, setClaimRequestList] = useState<ClaimRequestCoverageUsage[]>(
        claimRequestsRelated && claimRequestsRelated.length > 0
            ? groupByClaimRequestWithCoverageCount(claimRequestsRelated)
            : []
    );

    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = () => {
        if (getClaimRequestsRelated) {
            getClaimRequestsRelated().then((related) => {
                setClaimRequestList(groupByClaimRequestWithCoverageCount(related));
                setIsOpen(true);
            });
        } else {
            setIsOpen(true);
        }
    };
    return (
        <>
            <span className={'related-claim-request'}>
                <div onClick={handleOpenModal}>{children}</div>
            </span>
            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={title}
                className="related-claim-request-modal"
            >
                {subtitle && <BodyLead text={subtitle}></BodyLead>}
                <div className={'related-claim-request-content'}>
                    {claimRequestList.map((item, index) => {
                        const title = `${item.claimRequest.createdAt.toLocaleDateString()} ${
                            showUsages ? `(${t('related_claim_request.usage_count', { count: item.usageCount })})` : ''
                        }`;
                        return (
                            <a
                                href={`/claim-requests/${item.claimRequest.key}/summary`}
                                className="transparent-link"
                                key={index}
                            >
                                <InboxItem title={title} actionName={t('related_claim_request.see_claim')}></InboxItem>
                            </a>
                        );
                    })}
                </div>
            </Modal>
        </>
    );
};

export default RelatedClaimRequest;
