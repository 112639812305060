import { CountryCode, countryCodeValueToEnum } from '../../domain/values/CountryCode';

const COUNTRY_CODE_KEY = 'preference_country_code';
export const useCountryPreferences = () => {
    const getCountryCodeLocalStorage = () => {
        const value = localStorage.getItem(COUNTRY_CODE_KEY);
        return value != null ? countryCodeValueToEnum(value) : null;
    };

    const setCountryCodeLocalStorage = (countryCode: CountryCode) => {
        localStorage.setItem(COUNTRY_CODE_KEY, countryCode.toString());
    };

    return { getCountryCodeLocalStorage, setCountryCodeLocalStorage };
};
