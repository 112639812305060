import { AuthCredentials } from '../../domain/auth/AuthCredentials';
import { AuthContext } from '../../domain/auth/AuthProvider';
import { useSafeContext } from '../../domain/utils/context.utils';

export const useAuth = () => {
    const { signIn: signInMethod, isLoggedIn } = useSafeContext(AuthContext);

    const signIn = async (credentials: AuthCredentials) => {
        return signInMethod(credentials);
    };

    return {
        signIn,
        isLoggedIn,
    };
};
