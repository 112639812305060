import { useEffect, useState } from 'react';
import { Symptom } from '../domain/entities/index';
import { SymptomRepository } from '../domain/repositories/index';

export const useSymptoms = (repository: SymptomRepository) => () => {
    const [symptoms, setSymptoms] = useState<Symptom[]>([]);

    useEffect(() => {
        repository.findAll().then(setSymptoms);
    }, []);

    return {
        symptoms,
    };
};
