import Input from '../input/Input';

export type DatePickerProps = {
    value: Date | string | undefined | null;
    onChange?: (value: Date | null) => void;
    name?: string;
    label?: string;
    errorMessage?: string;
    className?: string;
    disabled?: boolean;
};

const DatePicker = ({ value, onChange, name, label, errorMessage, className, disabled }: DatePickerProps) => {
    const transformDate = (date: DatePickerProps['value']) => {
        if (typeof date === 'string') return date;
        return date?.toISOString().split('T')[0];
    };

    const handleValueChange = (value: string) => {
        const resultDate = new Date(value);
        const isValidDate = resultDate instanceof Date && !isNaN(resultDate.getTime());
        onChange?.(isValidDate ? resultDate : null);
    };

    return (
        <Input
            name={name}
            value={transformDate(value) ?? undefined}
            onChange={handleValueChange}
            label={label}
            errorMessage={errorMessage}
            className={className}
            disabled={disabled}
            type="date"
        />
    );
};

export default DatePicker;
