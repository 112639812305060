import { SupportingDocumentType } from '@barkibu/noma-commons';

export default abstract class EditableSupportingDocument {
    name: string;
    type: SupportingDocumentType | undefined;
    location: string;
    toUpload = false;
    toDelete = false;
    toModify = false;

    protected constructor(name: string, type: SupportingDocumentType | undefined, location: string) {
        this.name = name;
        this.type = type;
        this.location = location;
    }
}
