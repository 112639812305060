import {
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
    SupportingDocumentDeletionReason,
    SupportingDocumentDto,
} from '@barkibu/noma-commons';
import { CreateSupportingDocumentDto } from './dtos/CreateSupportingDocumentDto';
import { UpdateIsNewlyAddedByParentDto } from './dtos/UpdateIsNewlyAddedByParentDto';
import { UpdateSupportingDocumentDto } from './dtos/UpdateSupportingDocumentDto';
import { toDomain } from './SupportingDocumentMapper';
import { SupportingDocument } from '../../../domain/entities/supporting-document';
import { SupportingDocumentRepository } from '../../../domain/repositories/SupportingDocumentRepository';

export class SupportingDocumentNomaRepository implements SupportingDocumentRepository {
    create(
        claimRequestKey: string,
        createSupportingDocumentDto: CreateSupportingDocumentDto
    ): Promise<SupportingDocument> {
        return processFetchOperation(`/v1/incidents/${claimRequestKey}/documents`, OperationMethod.POST, {
            body: JSON.stringify(createSupportingDocumentDto),
        }).then((dto: SingleEntityResponse<SupportingDocumentDto>) => {
            return toDomain(flattenEntity(dto.data, []));
        });
    }

    update(
        supportingDocumentKey: string,
        updateSupportingDocumentDto: UpdateSupportingDocumentDto
    ): Promise<SupportingDocument> {
        return processFetchOperation(`/v1/incidents/documents/${supportingDocumentKey}`, OperationMethod.PUT, {
            body: JSON.stringify(updateSupportingDocumentDto),
        }).then((dto: SingleEntityResponse<SupportingDocumentDto>) => {
            return toDomain(flattenEntity(dto.data, []));
        });
    }

    updateIsNewlyAddedByParent(
        supportingDocumentKey: string,
        updateIsNewlyAddedByParentDto: UpdateIsNewlyAddedByParentDto
    ): Promise<SupportingDocument> {
        return processFetchOperation(
            `/v1/incidents/documents/${supportingDocumentKey}/is-newly-added-by-parent`,
            OperationMethod.PUT,
            {
                body: JSON.stringify(updateIsNewlyAddedByParentDto),
            }
        ).then((dto: SingleEntityResponse<SupportingDocumentDto>) => {
            return toDomain(flattenEntity(dto.data, []));
        });
    }

    delete(supportingDocumentKey: string, deletionReason: SupportingDocumentDeletionReason): Promise<any> {
        return processFetchOperation(`/v1/incidents/documents/${supportingDocumentKey}`, OperationMethod.DELETE, {
            params: { deletionReason },
        });
    }
}
