import { useState } from 'react';
import { CreationNote, Note, User } from '../../../domain/entities';
import { ClaimRequestStatus, PrepaidExpenseId } from '../../../domain/values';
import { useUser } from '../../../infraestructure/context/user/useUser';
import { Button, ButtonVariant, Chip, ChipType } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import ClaimStatusChip from '../claim-status-chip/ClaimStatusChip';
import NotesModal from '../notes-modal/NotesModal';
import './request-detail-header.modules.scss';

export type RequestDetailHeaderProps = {
  date?: Date;
  status?: {
    details?: string[];
    description: ClaimRequestStatus;
  };
  prepaidExpenseIds?: PrepaidExpenseId[];
  userReservation?: User;
  seePreviousUrl?: string;
  onStealReservation?: () => void;
  policyNotes?: Note[];
  petNotes?: Note[];
  petParentNotes?: Note[];
  onAddNote: (note: CreationNote) => void;
  onDeleteNote: (note: Note) => void;
};

const PrepaidExpenseChip = ({ prepaidExpenseIds }: { prepaidExpenseIds: PrepaidExpenseId[] }) => {
    const { t } = useModuleTranslation();
    const prepaidExpenseIdsString = prepaidExpenseIds.map((it) => it.value).join(', ');
  const handleBarkibuCardChipClick = () => navigator.clipboard.writeText(prepaidExpenseIdsString);
  return (
    <>
      {prepaidExpenseIds?.length > 0 && (
        <Chip
          text={t('request_header.barkibu_card.chip_name')}
          type={ChipType.NEUTRAL}
          color="orange"
          accessibilityText={t('request_header.barkibu_card.chip_click', { prepaidExpenses: prepaidExpenseIdsString })}
          onClick={handleBarkibuCardChipClick}
        />
      )}
    </>
  );
};

const RequestDetailHeader = ({
  date,
  status,
  prepaidExpenseIds,
  userReservation,
  seePreviousUrl,
  onStealReservation,
  policyNotes = [],
  petNotes = [],
  petParentNotes = [],
  onAddNote,
  onDeleteNote,
}: RequestDetailHeaderProps) => {
  const formattedDate = Intl.DateTimeFormat('es').format(date ?? new Date());
  const { t } = useModuleTranslation();
  const { user } = useUser();

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const handleOpenNotesModal = () => {
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const notes = [...policyNotes, ...petParentNotes, ...petNotes];

  return (
    <>
      <div className="request-detail-header">
        <div className="request-detail-header__title">
          <h2 className="request-detail-header__title-date">{t('request_header.title', { date: formattedDate })}</h2>
          {status && <ClaimStatusChip status={status.description} details={status.details} colored />}
          {prepaidExpenseIds && <PrepaidExpenseChip prepaidExpenseIds={prepaidExpenseIds} />}
          <span>
            {userReservation && userReservation.name
              ? t('request_header.reserved_by', {
                  userName: userReservation.name,
                })
              : t('request_header.not_reserved')}
          </span>
          {onStealReservation && user?.email !== userReservation?.email && (
            <Button
              accessibilityText={t('request_header.steal_reservation')}
              text={t('request_header.steal_reservation')}
              onClick={() => onStealReservation()}
              variantName={ButtonVariant.SECONDARY}
            />
          )}
          <Button
            accessibilityText={t('request_header.notes_accesibility')}
            text={t('request_header.notes', {
              notesCount: notes?.length ?? 0,
            })}
            onClick={() => handleOpenNotesModal()}
            variantName={notes && notes.length > 0 ? ButtonVariant.INFO : ButtonVariant.FILLED_NEUTRAL}
          />
        </div>
        {seePreviousUrl && (
          <a className="request-detail-header__subtitle" href={seePreviousUrl}>
            {t('request_header.view_previous')}
          </a>
        )}
      </div>
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={() => handleCloseNotesModal()}
        notes={notes}
        onAddNote={onAddNote}
        onDeleteNote={onDeleteNote}
      />
    </>
  );
};

export default RequestDetailHeader;
