import { CreationNote, Note } from '../../../domain/entities';
import Modal from '../../atoms/modal/Modal';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import NotesManager from '../notes-manager/NotesManager';
import './notes-modal.scss';

export type NotesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    notes?: Note[];
    onAddNote?: (note: CreationNote) => void;
    onDeleteNote?: (noteKey: Note) => void;
};

const NotesModal = ({ isOpen, onClose, notes, onAddNote, onDeleteNote }: NotesModalProps) => {
    const { t } = useModuleTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={t('notes')} className="notes-modal">
            <div className="notes-modal__body">
                <div className="notes-modal__content">
                    <NotesManager notes={notes ?? []} onAddNote={onAddNote} onDeleteNote={onDeleteNote} />
                </div>
            </div>
        </Modal>
    );
};

export default NotesModal;
