import { useState } from 'react';
import { Button, ButtonVariant, Input, TrashIcon } from '@barkibu/noma-commons';
import CostLine from '../../../../domain/entities/CostLine';

export type InvoiceCostLineProps = {
    costLine: CostLine;
    handleSave: (costLine: CostLine) => void;
    handleRemove: (costLine: CostLine) => void;
    readOnly: boolean;
};
const InvoiceCostLine = ({ costLine, handleSave, handleRemove, readOnly }: InvoiceCostLineProps) => {
    const key = costLine.key;
    const [concept, setConcept] = useState(costLine.concept);
    const [units, setUnits] = useState(costLine.units);
    const [VAT, setVAT] = useState(costLine.vatPercentage());
    const [price, setPrice] = useState(costLine.priceInUnits());
    const [warningSave, setWarningSave] = useState(false);
    const readonlyConceptEmpty = concept.length <= 0;
    const saveCostLine = () => {
        if (
            concept != costLine.concept ||
            units != costLine.units ||
            VAT != costLine.vatPercentage() ||
            price != costLine.priceInUnits()
        ) {
            if (concept.trim().length > 0) {
                const updatedCostLine = new CostLine({
                    key,
                    concept: concept.trimEnd(),
                    units,
                    VAT: VAT / 100,
                    priceInCents: Math.round(price * 100),
                });
                handleSave(updatedCostLine);
            } else {
                setWarningSave(true);
            }
        }
    };
    const updateConcept = (value: string) => {
        setWarningSave(false);
        setConcept(value);
    };
    const updateUnits = (value: string) => {
        setUnits(Number(value));
    };
    const updateVAT = (value: string) => {
        setVAT(Number(value));
    };

    const updatePrice = (value: string) => {
        setPrice(Number(value));
    };
    const deleteCostLine = () => {
        const removableCostLine = new CostLine({
            key,
            concept,
            units,
            VAT,
            priceInCents: price * 100,
        });
        handleRemove(removableCostLine);
    };
    return (
        <>
            <div className="invoice-details__row">
                <Input
                    autoFocus
                    name={`Concept`}
                    value={concept}
                    onChange={updateConcept}
                    setTouched={saveCostLine}
                    className="full-width"
                    readOnly={readOnly}
                    errorMessage={warningSave ? 'Concepto Requerido' : undefined}
                />
            </div>
            <div className="invoice-details__row">
                <Input
                    name={`units`}
                    type={`number`}
                    value={units}
                    onChange={updateUnits}
                    setTouched={saveCostLine}
                    className="full-width"
                    readOnly={readOnly || readonlyConceptEmpty}
                />
            </div>
            <div className="invoice-details__row">
                <Input
                    name={`VAT`}
                    type={`number`}
                    value={VAT}
                    onChange={updateVAT}
                    setTouched={saveCostLine}
                    suffix={'%'}
                    className="full-width"
                    readOnly={readOnly || readonlyConceptEmpty}
                />
            </div>
            <div className="invoice-details__row">
                <Input
                    name={`price`}
                    type={`number`}
                    value={price}
                    onChange={updatePrice}
                    setTouched={saveCostLine}
                    suffix={'€'}
                    className="full-width"
                    readOnly={readOnly || readonlyConceptEmpty}
                />
            </div>
            {!readOnly && (
                <div className="invoice-details__row-actions">
                    <div className="delete-icon">
                        <Button onClick={deleteCostLine} variantName={ButtonVariant.DANGER} icon={TrashIcon}></Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceCostLine;
