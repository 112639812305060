import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';

export class AssignNextReadyToReviewIncident {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(countryCode: string): Promise<string> {
        return this.claimRequestRepository.assignNextReadyToReviewIncident(countryCode);
    }
}
