import { useEffect } from 'react';
import { useAsync } from '@barkibu/noma-commons';
import { FindAllAdjustmentRecommendations } from './FindAllAdjustmentRecommendations';
import { AdjustmentRecommendation } from '../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentRepository } from '../../domain/adjustment/AdjustmentRepository';

export const useAdjustmentRecommendations = (repository: AdjustmentRepository) => {
    const findAllAdjustmentRecommendations = FindAllAdjustmentRecommendations(repository);

    return (adjustmentKey: string) => {
        const {
            execute,
            status,
            value: adjustmentRecommendations,
        } = useAsync<AdjustmentRecommendation[] | undefined>(findAllAdjustmentRecommendations);

        useEffect(() => {
            execute(adjustmentKey);
        }, [adjustmentKey]);

        return {
            adjustmentRecommendations,
            adjustmentRecommendationsFetchStatus: status,
        };
    };
};
