import {
    flattenEntity,
    Operation,
    OperationDto,
    OperationDtoToDomain,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '@barkibu/noma-commons';
import { AddDiagnosisDto } from './AddDiagnosisDto';
import { AssessmentDto } from './AssessmentDto';
import { toDomain } from './AssessmentMapper';
import { AddSymptomDto } from '../../domain/assessment/AddSymptomDto';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';
import { DeleteDiagnosisDto } from '../../domain/assessment/DeleteDiagnosisDto';
import { DeleteSymptomDto } from '../../domain/assessment/DeleteSymptomDto';

export const AssessmentNomaRepository: AssessmentRepository = {
    addDiagnosis: (assessmentKey: string, addDiagnosisDto: AddDiagnosisDto) => {
        return processFetchOperation(`/v1/assessments/${assessmentKey}/diagnoses`, OperationMethod.POST, {
            body: JSON.stringify(addDiagnosisDto),
        }).then((dto: SingleEntityResponse<AssessmentDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },

    addSymptom: (assessmentKey: string, addSymptomDto: AddSymptomDto) => {
        return processFetchOperation(`/v1/assessments/${assessmentKey}/symptoms`, OperationMethod.POST, {
            body: JSON.stringify(addSymptomDto),
        }).then((dto: SingleEntityResponse<AssessmentDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },

    deleteDiagnosis: (assessmentKey: string, deleteDiagnosisDto: DeleteDiagnosisDto) => {
        return processFetchOperation(
            `/v1/assessments/${assessmentKey}/diagnoses/${deleteDiagnosisDto.diagnosisKey}`,
            OperationMethod.DELETE,
            {
                params: { diagnosisType: deleteDiagnosisDto.diagnosisType },
            }
        ).then((dto: SingleEntityResponse<AssessmentDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },
    deleteSymptom: (assessmentKey: string, deleteSymptomDto: DeleteSymptomDto) => {
        return processFetchOperation(
            `/v1/assessments/${assessmentKey}/symptoms/${deleteSymptomDto.symptomKey}`,
            OperationMethod.DELETE
        ).then((dto: SingleEntityResponse<AssessmentDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },

    findById: function (key: string): Promise<Assessment> {
        return processFetchOperation(`/v1/assessments/${key}`, OperationMethod.GET).then(
            (dto: SingleEntityResponse<AssessmentDto>) => {
                const entity = flattenEntity(dto.data, dto.included || []);
                return toDomain(entity);
            }
        );
    },

    findByClaimRequestId(key: string): Promise<Assessment> {
        return processFetchOperation(`/v1/claim-requests/${key}/assessment`, OperationMethod.GET).then(
            (dto: SingleEntityResponse<AssessmentDto>) => {
                const entity = flattenEntity(dto.data, dto.included || []);
                return toDomain(entity);
            }
        );
    },

    completeVetAnalysis(claimRequestKey: string, note?: string): Promise<void> {
        return processFetchOperation(`/v1/assessments/${claimRequestKey}/complete`, OperationMethod.PUT, {
            body: JSON.stringify({ note: note }),
        });
    },

    async findInitializationStatus(claimRequestKey: string): Promise<Operation> {
        const dto: SingleEntityResponse<OperationDto> = await processFetchOperation(
            `/v1/claim-request/${claimRequestKey}/assessment-initialization`,
            OperationMethod.GET
        );
        const entity = flattenEntity(dto.data, dto.included || []);
        return OperationDtoToDomain(entity);
    },
};
