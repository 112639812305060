import { Button, ButtonVariant, DATAENTRY_REDIRECT_VETVIEW, usePrivilege } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './invoice-footer.scss';

export interface InvoiceFooterProps {
    disableActions?: boolean;

    handleContinueClick(moveToVetView: boolean): Promise<void>;
}

const InvoiceFooter = ({ handleContinueClick, disableActions = false }: InvoiceFooterProps) => {
    const { t } = useModuleTranslation();
    const { hasPrivilege } = usePrivilege();

    const hasRedirectPrivilege = hasPrivilege(DATAENTRY_REDIRECT_VETVIEW);

    return (
        <div className="invoice-details--footer">
            <Button
                disabled={disableActions}
                text={hasRedirectPrivilege ? t('invoice.continue') : t('invoice.let_continue')}
                variantName={ButtonVariant.INFO}
                onClick={() => handleContinueClick(hasRedirectPrivilege)}
            />
            {!hasPrivilege(DATAENTRY_REDIRECT_VETVIEW) && (
                <Button
                    disabled={disableActions}
                    text={t('invoice.continue_myself')}
                    onClick={() => handleContinueClick(true)}
                    variantName={ButtonVariant.SECONDARY}
                />
            )}
        </div>
    );
};

export default InvoiceFooter;
