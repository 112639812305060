import { useState, useEffect, useRef } from 'react';
import { Chip, ChipType, GroupedData, NoteInput, Option } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import {
    MissingInfoCategory,
    MissingInfoCategoryDetail,
    MissingInfoCategoryWithDetails,
    SupportingDocumentType,
} from '../../../domain/values';
import { ClaimRequestType } from '../../../domain/entities';
import { defaultCountryCode } from '../../../domain/values/CountryCode';

export interface MissingInfoCategorySelectorProps {
    missingInfoCategoriesAlreadySelected: MissingInfoCategory[];
    onMissingInfoCategorySelectedHandler: (missingInfoCategoryWithDetails: MissingInfoCategoryWithDetails) => void;
    onMissingInfoCategoryRemoveHandler: (missingInfoCategory: MissingInfoCategory) => void;
    options: SupportingDocumentType[];
    claimRequestType: ClaimRequestType;
    countryCode?: string;
    petName?: string;
}

const MissingInfoCategorySelector = ({
    missingInfoCategoriesAlreadySelected,
    onMissingInfoCategorySelectedHandler,
    onMissingInfoCategoryRemoveHandler,
    options,
    claimRequestType,
    countryCode = defaultCountryCode,
    petName,
}: MissingInfoCategorySelectorProps) => {
    const controlledTemplates = ['VET_REPORT', 'CLINICAL_HISTORY', 'INVOICE', 'VET_FORM'];
    const [selectedCategoryWithDetails, setSelectedCategoryWithDetails] =
        useState<MissingInfoCategoryWithDetails | null>(null);
    const [manualTemplateText, setManualTemplateText] = useState('');
    const [manualTemplateSelected, setManualTemplateSelected] = useState(false);
    const { t } = useModuleTranslation();
    const defaultMissingInfoCategories = () => {
        if (missingInfoCategoriesAlreadySelected.length > 0)
            return options
                .map((option) => option as MissingInfoCategory)
                .filter((it) => {
                    const hasOtherSelected = missingInfoCategoriesAlreadySelected?.some(
                        (selected) => selected && selected !== SupportingDocumentType.VET_FORM
                    );
                    return !(hasOtherSelected && it === SupportingDocumentType.VET_FORM);
                });
        else return options.map((option) => option as MissingInfoCategory);
    };
    const templatesGrouped = t(`${claimRequestType.toString().toLowerCase()}.templates`, {
        ns: 'missing_info',
        lng: countryCode ? countryCode.toLowerCase() : defaultCountryCode.toLowerCase(),
        returnObjects: true,
        petName,
    }) as GroupedData<string | GroupedData<string>>[];

    const getTemplatesBySupportingDocument = (
        category: MissingInfoCategory
    ): Option<string | GroupedData<string>>[] => {
        const result = templatesGrouped.find((it) => it.group.toUpperCase() == category);

        if (!result)
            return templatesGrouped
                .filter((template) => !controlledTemplates.includes(template.group))
                .flatMap((it) => it.options);

        return result.options;
    };

    const previousMissingInfoCategoryWithDetailsSelected = useRef<MissingInfoCategoryWithDetails | null>(null);
    const [missingInfoOptions, setMissingInfoOptions] = useState<MissingInfoCategory[]>(defaultMissingInfoCategories());
    const [missingInfoTemplates, setMissingInfoTemplates] = useState<Option<string | GroupedData<string>>[]>([]);
    const [multiOptions, setMultiOptions] = useState<GroupedData<string>[]>([]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.category) {
            setMissingInfoOptions([selectedCategoryWithDetails.category]);
            onMissingInfoCategorySelectedHandler(selectedCategoryWithDetails);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
            if (previousMissingInfoCategoryWithDetailsSelected.current) {
                onMissingInfoCategoryRemoveHandler(previousMissingInfoCategoryWithDetailsSelected.current.category);
            }
        }
    }, [selectedCategoryWithDetails]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.category) {
            setMissingInfoOptions([selectedCategoryWithDetails.category]);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
        }
    }, [missingInfoCategoriesAlreadySelected]);

    const handleMissingInfoClicked = (description) => {
        const missingInfoCategoryDescription = description as MissingInfoCategory;

        if (selectedCategoryWithDetails?.category === missingInfoCategoryDescription) {
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return null;
            });
            setMissingInfoTemplates([]);
            setMultiOptions([]);

            setMissingInfoOptions((prevOptions) => {
                return defaultMissingInfoCategories().filter(
                    (category) => !selectedCategoryWithDetails?.values.some((detail) => detail.label === category)
                );
            });
        } else {
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return {
                    category: missingInfoCategoryDescription,
                    values: [],
                };
            });
            setMissingInfoTemplates(getTemplatesBySupportingDocument(missingInfoCategoryDescription));

            setMissingInfoOptions([missingInfoCategoryDescription]);
        }
    };

    const handleTemplateSelector = (template: Option<string | GroupedData<string>>) => {
        if (typeof template.value === 'string') {
            if (selectedCategoryWithDetails && selectedCategoryWithDetails.category) {
                const missingInfoDetail: MissingInfoCategoryDetail = { label: template.label, value: template.value };
                const prevDetails = selectedCategoryWithDetails?.values || [];

                const updatedDetails = prevDetails.flatMap((it) => it.label).includes(missingInfoDetail.label)
                    ? prevDetails.filter((item) => item.label !== missingInfoDetail.label)
                    : [...prevDetails, missingInfoDetail];

                setSelectedCategoryWithDetails((prevState) => {
                    if (prevState && prevState.category) {
                        return {
                            category: prevState.category,
                            values: updatedDetails,
                        };
                    }
                    return prevState;
                });
            }
        } else {
            const isGroupSelected = multiOptions.some((group) => group.group === template.value.group);

            if (isGroupSelected) {
                setMultiOptions((prevState) => prevState.filter((group) => group.group !== template.value.group));

                setSelectedCategoryWithDetails((prevState) => {
                    if (prevState && prevState.category) {
                        const updatedValues = prevState.values.filter((item) => item.label !== template.label);
                        return {
                            ...prevState,
                            values: updatedValues,
                        };
                    }
                    return prevState;
                });
            } else {
                setMultiOptions((prevState) => [...prevState, template.value as GroupedData<string>]);

                setSelectedCategoryWithDetails((prevState) => {
                    if (prevState && prevState.category) {
                        const updatedValues = [...(prevState.values || []), { label: template.label, value: '' }];
                        return {
                            ...prevState,
                            values: updatedValues,
                        };
                    }
                    return prevState;
                });
            }
        }
    };

    const handleManualTemplateSelection = () => {
        if (manualTemplateSelected) {
            setManualTemplateSelected(false);
            setManualTemplateText('');
            setSelectedCategoryWithDetails((prevState) => {
                if (prevState) {
                    const updatedValues = prevState.values.filter((item) => item.label !== 'Manual Template');
                    return {
                        ...prevState,
                        values: updatedValues,
                    };
                }
                return prevState;
            });
        } else {
            setManualTemplateSelected(true);
            setSelectedCategoryWithDetails((prevState) => {
                if (prevState && prevState.category) {
                    const updatedValues = [
                        ...(prevState.values || []),
                        { label: 'Manual Template', value: manualTemplateText },
                    ];
                    return {
                        ...prevState,
                        values: updatedValues,
                    };
                }
                return prevState;
            });
        }
    };

    const handleManualTemplateTextChange = (value: string) => {
        setManualTemplateText(value);
        setSelectedCategoryWithDetails((prevState) => {
            if (prevState && prevState.category) {
                const updatedValues = prevState.values.map((item) =>
                    item.label === 'Manual Template' ? { ...item, value } : item
                );
                return {
                    ...prevState,
                    values: updatedValues,
                };
            }
            return prevState;
        });
    };

    const renderGroupedOptions = (groupedData: GroupedData<string>) => (
        <section key={groupedData.group}>
            <span className="label">{groupedData.group}</span>
            <div className="missing-info-category-selector">
                {groupedData.options.map((option) => (
                    <Chip
                        key={option.label}
                        type={ChipType.OPTION}
                        text={option.label}
                        checked={selectedCategoryWithDetails?.values.flatMap((it) => it.label).includes(option.label)}
                        onClick={() => handleTemplateSelector(option)}
                    />
                ))}
            </div>
        </section>
    );

    return (
        <>
            <section>
                <span className="label">{t(`missing_info.which`)}</span>
                <div className="missing-info-category-selector">
                    {missingInfoOptions.map((description) => (
                        <Chip
                            key={description}
                            type={ChipType.OPTION}
                            text={t(`missing_info.type.${description}`)}
                            checked={selectedCategoryWithDetails?.category === description}
                            onClick={() => handleMissingInfoClicked(description)}
                        />
                    ))}
                </div>
            </section>
            {missingInfoTemplates.length > 0 && (
                <section>
                    <span className="label">{t(`missing_info.what`)}</span>
                    <div className="missing-info-category-selector">
                        {missingInfoTemplates.map((option) => (
                            <Chip
                                key={option.label}
                                type={ChipType.OPTION}
                                text={option.label}
                                checked={selectedCategoryWithDetails?.values
                                    .flatMap((it) => it.label)
                                    .includes(option.label)}
                                onClick={() => handleTemplateSelector(option)}
                            />
                        ))}
                        <Chip
                            type={ChipType.OPTION}
                            text={t('missing_info.add_template_manually')}
                            checked={manualTemplateSelected}
                            onClick={handleManualTemplateSelection}
                        />
                    </div>
                </section>
            )}
            {manualTemplateSelected && (
                <section>
                    <div>
                        <span className="label">{t('missing_info.write_your_own_template')}</span>
                        <NoteInput value={manualTemplateText} onValueChange={handleManualTemplateTextChange} />
                    </div>
                </section>
            )}
            {multiOptions.length > 0 && multiOptions.map(renderGroupedOptions)}
        </>
    );
};

export default MissingInfoCategorySelector;
