import { ChipType, CloseIcon, Option } from '../../atoms';
import Chip from '../../atoms/chip/Chip';
import Dropdown, { DropdownProps } from '../../atoms/dropdown/Dropdown';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './multi-chip-selector.modules.scss';

export type MultiChipSelectorProps<T> = {
    options: DropdownProps<T>['values'];
    label: string;
    handleNewValue: (option: Option<T>) => void;
    handleDeleteValue: (option: Option<T>) => void;
    values?: Option<T>[];
    readOnly?: boolean;
    emptyLabel?: string;
};

const MultiChipSelector = <T,>({
    options,
    label,
    handleDeleteValue,
    handleNewValue,
    values = [],
    readOnly = false,
    emptyLabel,
}: MultiChipSelectorProps<T>) => {
    const { t } = useModuleTranslation();
    const emptyLabelText = emptyLabel ?? t('selector.no_elements')!;
    return (
        <div className="multi-chip-selector">
            {!readOnly && <Dropdown values={options} handleOptionClicked={handleNewValue} label={label} />}
            <section className="multi-chip-selector__chip-list">
                {values?.length !== 0 ? (
                    values?.map((el) => (
                        <Chip
                            key={el.label}
                            type={ChipType.FILTER}
                            text={el.label}
                            icon={readOnly ? undefined : CloseIcon}
                            onClick={readOnly ? () => null : () => handleDeleteValue(el)}
                        />
                    ))
                ) : (
                    <Chip type={ChipType.NEUTRAL} text={emptyLabelText} />
                )}
            </section>
        </div>
    );
};

export default MultiChipSelector;
