import { useEffect, useState } from 'react';
import {
    BlockingLoader,
    Button,
    ButtonGroup,
    ButtonVariant,
    CauseOfLoss,
    ClaimDetails,
    ClaimLifecycle,
    ClaimRequestDetails,
    ClaimRequestType,
    ClinicalHistory,
    ClinicalHistoryWrapper,
    CreationNote,
    Diagnosis,
    Dialog,
    DuplicateClaimConfirmationDialog,
    FraudAlert,
    getDialogDetailsFromError,
    IncidentNotes,
    MarkIncidentMissingInfoStatusModal,
    MissingInfoCategory,
    MissingInfoCategoryWithDetails,
    Modal,
    Note,
    NoteScope,
    NoteTypeValue,
    ParentCard,
    PetProfile,
    Policy,
    PolicyCard,
    ProgressIndicator,
    RequestDetailHeader,
    SuccessIcon,
    SupportingDocumentType,
    Symptom,
    useDetectClickOutside,
    useDialog,
    useNotes,
    User,
} from '@barkibu/noma-commons';
import { IncidentDetails, SupportingDocuments } from '@barkibu/noma-data-entry';
import { Assessment } from '../../../domain/assessment/Assessment';
import { DiagnosisType } from '../../../domain/values/DiagnosisType';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import DiagnosisSelector from '../../components/molecules/diagnosis-selector/DiagnosisSelector';
import SymptomSelector from '../../components/molecules/symptoms-selector/SymptomSelector';
import VetViewInfoDates from '../../components/molecules/vet-view-info-dates/VetViewInfoDates';
import './vet-view-page.modules.scss';

export type VetViewPageProps = {
    claimRequestDetails?: ClaimRequestDetails;
    incidentDetails?: IncidentDetails;
    clinicalHistory?: ClinicalHistory;
    assessment?: Assessment;
    assessmentUserReservation?: User;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    symptomOptions: Symptom[];
    diagnosisOptions: Diagnosis[];
    notes: Note[];
    fraudAlerts?: FraudAlert[];
    onAddDiagnosis: (diagnosisKey: string, diagnosisType: DiagnosisType) => void;
    onDeleteDiagnosis: (diagnosisKey: string, diagnosisType: DiagnosisType) => void;
    onAddSymptom: (symptomKey: string) => void;
    onDeleteSymptom: (symptomKey: string) => void;
    onUpdateCauseOfLoss: (causeOfLoss: CauseOfLoss) => void;
    onAddNote: (note: CreationNote) => void;
    onDeleteNote: (note: Note) => void;
    onUpdateDateOfLoss: (date: Date) => void;
    isSyncing: boolean;
    syncError: Error | undefined;
    readOnly?: boolean;
    onMarkMissingInfo: (missingInfoCategoriesWithDetails: MissingInfoCategoryWithDetails[], noteValue: string) => void;
    onContinue: () => void;
    onMoveBackToDataentry: () => void;
    onStealReservation?: () => void;
    onChangePolicy: (policyIdentifier: string) => void;
    onDuplicateClaimRequest: () => Promise<void>;
    contractIdsActiveOnClaimRequest: string[];
};

const getInvoicesMaxDateFromSupportingDocuments = (supportingDocuments: IncidentDetails['supportingDocuments']) => {
    if (supportingDocuments == null || supportingDocuments.length === 0) return undefined;
    return supportingDocuments
        .filter((supportingDocument) => supportingDocument.type === SupportingDocumentType.INVOICE)
        .reduce((result, current) => (result.date! > current.date! ? result : current)).date;
};

const VetViewPage = ({
    claimRequestDetails,
    incidentDetails,
    clinicalHistory,
    assessment,
    assessmentUserReservation,
    loading,
    setLoading,
    symptomOptions = [],
    diagnosisOptions = [],
    notes = [],
    fraudAlerts = [],
    onAddDiagnosis,
    onDeleteDiagnosis,
    onAddSymptom,
    onDeleteSymptom,
    onUpdateCauseOfLoss,
    onAddNote,
    onDeleteNote,
    onUpdateDateOfLoss: onUpdateDateOfLoss,
    isSyncing,
    syncError,
    readOnly = false,
    onMarkMissingInfo,
    onContinue,
    onMoveBackToDataentry,
    onStealReservation,
    onChangePolicy,
    onDuplicateClaimRequest,
    contractIdsActiveOnClaimRequest,
}: VetViewPageProps) => {
    const { t } = useModuleTranslation();
    const { dialogOptions, openDialog, closeDialog } = useDialog();
    const {
        notes: policyNotes,
        createNote: addPolicyNote,
        deleteNote: deletePolicyNote,
    } = useNotes(
        NoteScope.POLICY,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const {
        notes: petParentNotes,
        createNote: addPetParentNote,
        deleteNote: deletePetParentNote,
    } = useNotes(
        NoteScope.PET_PARENT,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const {
        notes: petNotes,
        createNote: addPetNote,
        deleteNote: deletePetNote,
    } = useNotes(
        NoteScope.PET,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const handleAddNote = (note: CreationNote) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                addPolicyNote(note);
                break;
            case NoteScope.PET:
                addPetNote(note);
                break;
            case NoteScope.PET_PARENT:
                addPetParentNote(note);
                break;
        }
    };
    const handleDeleteNote = (note: Note) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                deletePolicyNote(note.key);
                break;
            case NoteScope.PET:
                deletePetNote(note.key);
                break;
            case NoteScope.PET_PARENT:
                deletePetParentNote(note.key);
                break;
        }
    };
    useEffect(() => {
        if (syncError) openDialog(t('assessment.error_syncing'), getDialogDetailsFromError(syncError));
    }, [syncError]);

    const invoiceDate = getInvoicesMaxDateFromSupportingDocuments(incidentDetails?.supportingDocuments);

    const [diagnosis, setDiagnosis] = useState<{ type: DiagnosisType; value: string }[]>([]);

    const [showOtherActionsWrapper, setShowOtherActionsWrapper] = useState(false);
    const [showDuplicateClaimConfirmationDialog, setShowDuplicateClaimConfirmationDialog] = useState(false);

    const ref = useDetectClickOutside({
        onTriggered: () => {
            if (showOtherActionsWrapper) {
                setShowOtherActionsWrapper(false);
            }
        },
    });
    useEffect(() => {
        setDiagnosis(
            assessment?.diagnosis?.map((el) => ({
                type: el.type,
                value: el.key,
            })) || []
        );
    }, [assessment?.diagnosis]);

    const [symptoms, setSymptoms] = useState<{ value: string }[]>([]);

    useEffect(() => {
        setSymptoms(
            assessment?.symptoms?.map((el) => ({
                value: el.key,
            })) || []
        );
    }, [assessment?.symptoms]);

    const [dateOfLoss, setDateOfLoss] = useState<Date | null | undefined>(claimRequestDetails?.dateOfLoss);

    useEffect(() => {
        setDateOfLoss(claimRequestDetails?.dateOfLoss);
    }, [claimRequestDetails?.dateOfLoss]);

    const handleDateOfLossChange = (date: Date | null) => {
        setDateOfLoss(date);
        if (date != null) {
            onUpdateDateOfLoss(date);
        }
    };

    const [causeOfLoss, setCauseOfLoss] = useState<string | null | undefined>(claimRequestDetails?.causeOfLoss);

    useEffect(() => {
        setCauseOfLoss(claimRequestDetails?.causeOfLoss);
    }, [claimRequestDetails?.causeOfLoss]);

    const handleCauseOfLossChange = (causeOfLoss: string | null) => {
        setCauseOfLoss(causeOfLoss);
        if (causeOfLoss != null) {
            onUpdateCauseOfLoss(CauseOfLoss[causeOfLoss as keyof typeof CauseOfLoss]);
        }
    };

    const handleDiagnosisAdded = (type: DiagnosisType, key: string) => {
        onAddDiagnosis(key, type);
        setDiagnosis((prev) => [...prev, { value: key, type }]);
    };

    const handleDiagnosisDeleted = (type: DiagnosisType, key: string) => {
        setDiagnosis((prev) => prev.filter((el) => el.type != type || el.value != key));
        onDeleteDiagnosis(key, type);
    };

    const handleSymptomAdded = (key: string) => {
        setSymptoms((prev) => [...prev, { value: key }]);
        onAddSymptom(key);
    };

    const handleSymptomDeleted = (key: string) => {
        setSymptoms((prev) => prev.filter((el) => el.value != key));
        onDeleteSymptom(key);
    };

    const goToCostLines = () => onContinue();

    const [showMarkIncidentMissingInfoStatusModal, setShowMarkIncidentMissingInfoStatusModal] = useState(false);

    const handleUpdateIncidentStatusModalClose = () => setShowMarkIncidentMissingInfoStatusModal(false);

    const handleMarkIncidentMissingInfoStatusModalSave = (
        infoCategoriesWithDetails: MissingInfoCategoryWithDetails[],
        noteValue: string
    ) => {
        setShowMarkIncidentMissingInfoStatusModal(false);
        try {
            onMarkMissingInfo(infoCategoriesWithDetails, noteValue);
        } catch (e) {
            openDialog('Error converting Incident into Legacy Claim', getDialogDetailsFromError(e));
        }
    };

    const handleChangeIncidentPolicy = (policy: Policy) => {
        onChangePolicy(policy.identifier);
    };

    const saving = isSyncing;
    return (
        <>
            {!loading && incidentDetails && assessment ? (
                <div className="vet-view">
                    <section className="vet-view__pet-details">
                        <PetProfile
                            name={incidentDetails.petName}
                            birth={incidentDetails.petBirth}
                            breed={incidentDetails.petBreed}
                            gender={incidentDetails.petGender}
                            chip={incidentDetails.petChip}
                            photo={incidentDetails.petPhoto}
                            totalClaimRequestCount={incidentDetails.policy?.claimRequestStats?.totalClaimRequestCount}
                            openClaimRequestCount={incidentDetails.policy?.claimRequestStats?.openClaimRequestCount}
                            tags={petNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((petNote) => petNote.description)}
                        />
                        {incidentDetails?.policy && claimRequestDetails && (
                            <PolicyCard
                                setLoading={setLoading}
                                fraudAlerts={fraudAlerts}
                                policyIdentifier={claimRequestDetails.policyIdentifier!}
                                policies={incidentDetails.petPolicies!}
                                onPolicyClick={handleChangeIncidentPolicy}
                                petName={incidentDetails.petName ?? ''}
                                countryCode={incidentDetails.countryCode!}
                                tags={policyNotes
                                    .filter((note) => note.type == NoteTypeValue.TAG)
                                    .map((policyNote) => policyNote.description)}
                                contractIdsActiveOnClaimRequest={contractIdsActiveOnClaimRequest}
                            />
                        )}
                        <ParentCard
                            readOnly
                            email={claimRequestDetails?.email ?? ''}
                            iban={claimRequestDetails?.iban ?? ''}
                            onUpdateEmail={() => print()}
                            onUpdateIban={() => print()}
                            hideIban={claimRequestDetails?.isPrepaid()}
                            tags={petParentNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((petParentNote) => petParentNote.description)}
                        />
                    </section>
                    <section className="vet-view__request-details">
                        {claimRequestDetails?.claimRequestKey && (
                            <RequestDetailHeader
                                date={incidentDetails?.date}
                                userReservation={assessmentUserReservation}
                                seePreviousUrl={`/dataentry/policy/${claimRequestDetails.policyIdentifier!}/incidents`}
                                onStealReservation={onStealReservation}
                                policyNotes={policyNotes}
                                petNotes={petNotes}
                                petParentNotes={petParentNotes}
                                onAddNote={handleAddNote}
                                onDeleteNote={handleDeleteNote}
                                prepaidExpenseIds={claimRequestDetails.prepaidExpenseIds}
                            />
                        )}
                        {!readOnly && (
                            <div className="vet-view__request-details__syncing">
                                {saving ? <ProgressIndicator /> : <SuccessIcon />}
                            </div>
                        )}
                    </section>
                    <div className="vet-view__info-container">
                        <section className="vet-view__info-container__incident-details">
                            <ClaimLifecycle
                                status={incidentDetails.status.description!}
                                claimRequestKey={claimRequestDetails?.claimRequestKey!}
                                statusDetails={incidentDetails.status.details}
                            />

                            <VetViewInfoDates
                                invoiceDate={invoiceDate}
                                firstSymptomsDate={dateOfLoss}
                                handleFirstSymptomsDateChange={handleDateOfLossChange}
                                readOnly={readOnly}
                            />
                            {claimRequestDetails?.type == ClaimRequestType.HEALTH && (
                                <ClaimDetails
                                    visitReason={incidentDetails.description}
                                    causeOfLoss={CauseOfLoss[causeOfLoss as keyof typeof CauseOfLoss]}
                                    handleCauseOfLossChange={handleCauseOfLossChange}
                                    readOnly={readOnly}
                                />
                            )}
                            <IncidentNotes
                                notes={notes}
                                onAddNote={onAddNote}
                                onDeleteNote={onDeleteNote}
                                readOnly={readOnly}
                            />
                        </section>
                        <section className="vet-view__info-container__vet-details">
                            <DiagnosisSelector
                                options={diagnosisOptions}
                                values={diagnosis}
                                handleDiagnosisAdded={handleDiagnosisAdded}
                                handleDiagnosisDeleted={handleDiagnosisDeleted}
                                readOnly={readOnly}
                            />
                            <SymptomSelector
                                options={symptomOptions}
                                values={symptoms}
                                handleSymptomAdded={handleSymptomAdded}
                                handleSymptomDeleted={handleSymptomDeleted}
                                readOnly={readOnly}
                            />
                        </section>
                        <section className="vet-view__info-container__supporting-documents">
                            {clinicalHistory && claimRequestDetails?.policyIdentifier && (
                                <ClinicalHistoryWrapper
                                    clinicalHistory={clinicalHistory}
                                    policyIdentifier={claimRequestDetails?.policyIdentifier}
                                />
                            )}
                            <SupportingDocuments
                                documents={incidentDetails.supportingDocuments ?? []}
                                allowEdit={false}
                                showFooter={false}
                            />
                        </section>
                    </div>
                    <div className="vet-view__actions-container--footer">
                        <Button text={t('assessment.go_to_cost_lines')} onClick={goToCostLines} disabled={saving} />
                        <Button
                            text={t('assessment.other_actions')}
                            onClick={(e) => {
                                setShowOtherActionsWrapper(true);
                                e.stopPropagation();
                            }}
                            variantName={ButtonVariant.SECONDARY}
                            disabled={loading}
                        />
                    </div>
                </div>
            ) : (
                <BlockingLoader />
            )}
            <Modal isOpen={showOtherActionsWrapper}>
                <ButtonGroup variantName={''} ref={ref}>
                    <Button
                        text={t('assessment.mark_as_missing_info')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            setShowMarkIncidentMissingInfoStatusModal(true);
                        }}
                        variantName={ButtonVariant.SECONDARY}
                        disabled={loading}
                    />
                    <Button
                        text={t('assessment.go_back_to_dataentry')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            onMoveBackToDataentry();
                        }}
                        variantName={ButtonVariant.SECONDARY}
                        disabled={loading}
                    />
                    <Button
                        text={t('assessment.duplicate_claim')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            setShowDuplicateClaimConfirmationDialog(true);
                        }}
                        variantName={ButtonVariant.SECONDARY}
                        disabled={loading}
                    />
                </ButtonGroup>
            </Modal>
            <MarkIncidentMissingInfoStatusModal
                isOpen={showMarkIncidentMissingInfoStatusModal}
                onClose={handleUpdateIncidentStatusModalClose}
                onSave={handleMarkIncidentMissingInfoStatusModalSave}
                countryCode={incidentDetails?.countryCode}
                petName={incidentDetails?.petName}
                claimRequestType={claimRequestDetails?.type}
                hideNoteInput={false}
                claimRequestKey={claimRequestDetails?.claimRequestKey}
            />
            <DuplicateClaimConfirmationDialog
                isOpen={showDuplicateClaimConfirmationDialog}
                onSave={() => {
                    setShowDuplicateClaimConfirmationDialog(false);
                    onDuplicateClaimRequest();
                }}
                onCancel={() => setShowDuplicateClaimConfirmationDialog(false)}
            />
            <Dialog
                isOpen={dialogOptions.isOpen}
                text={dialogOptions.text}
                details={dialogOptions.details}
                onClose={() => closeDialog()}
                actions={[
                    {
                        text: t('modal.close', { ns: 'commons' }),
                        variantName: ButtonVariant.PRIMARY,
                        onClick: () => closeDialog(),
                    },
                ]}
            />
        </>
    );
};

export default VetViewPage;
