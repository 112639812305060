import { Card, MultiChipSelector } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './symptom-selector.modules.scss';

export type SymptomSelectorProps = {
    options: { key: string; description: string }[];
    values: { value: string }[];
    handleSymptomAdded: (value: string) => void;
    handleSymptomDeleted: (value: string) => void;
    readOnly?: boolean;
};

const SymptomSelector = ({
    options,
    values,
    handleSymptomAdded,
    handleSymptomDeleted,
    readOnly = false,
}: SymptomSelectorProps) => {
    const { t } = useModuleTranslation();
    const symptomValues = values.map((el) => ({
        label: options.find((option) => option.key === el.value)?.description!,
        value: el.value,
    }));
    const symptomOptions = options
        .map((option) => ({
            label: option.description,
            value: option.key,
        }))
        .filter((el) => !symptomValues.some((value) => value.value === el.value));

    return (
        <Card title={t('assessment.symptoms')} className="symptom-selector">
            <MultiChipSelector
                options={symptomOptions}
                label={t('assessment.add_symptom')}
                values={symptomValues}
                handleDeleteValue={(option) => handleSymptomDeleted(option.value)}
                handleNewValue={(option) => handleSymptomAdded(option.value)}
                readOnly={readOnly}
                emptyLabel={t('assessment.no_symptom_selected') || undefined}
            />
        </Card>
    );
};

export default SymptomSelector;
