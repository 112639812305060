import { ClaimRequest } from '../../../domain/entities/ClaimRequest';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './claim-requests-list.modules.scss';

export interface ClaimRequestsListProps {
    claimRequests: ClaimRequest[];
    className?: string;
}

const DEFAULT_DATE_FORMAT_OPTIONS = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
} as const;
const dateTimeFormat = Intl.DateTimeFormat('es', DEFAULT_DATE_FORMAT_OPTIONS);
export default function ClaimsRequestsList({ claimRequests, className = '' }: ClaimRequestsListProps) {
    const incidentBaseUrl = '/dataentry/incidents/';
    const { t } = useModuleTranslation();

    return (
        <table className={`claim-request-list ${className}`}>
            <thead>
                <tr>
                    <th>{t('claim_request_list.table.headers.link')}</th>
                    <th>{t('claim_request_list.table.headers.policyIdentifier')}</th>
                    <th>{t('claim_request_list.table.headers.creationDate')}</th>
                    <th>{t('claim_request_list.table.headers.updateDate')}</th>
                </tr>
            </thead>
            <tbody>
                {claimRequests.map((claimRequest, index) => (
                    <tr key={index}>
                        <td>
                            <a href={`${incidentBaseUrl}${claimRequest.key}`}>
                                {t('claim_request_list.table.body.link')}
                            </a>
                        </td>
                        <td>{claimRequest.policyIdentifier}</td>
                        <td>{dateTimeFormat.format(claimRequest.createdAt)}</td>
                        <td>
                            {claimRequest.updatedAt
                                ? dateTimeFormat.format(claimRequest.updatedAt)
                                : dateTimeFormat.format(claimRequest.createdAt)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
