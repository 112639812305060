export enum ClaimRequestStatus {
    CREATED = 'CREATED',
    READY_FOR_VET_ANALYSIS = 'READY_FOR_VET_ANALYSIS',
    MISSING_INFO = 'MISSING_INFO',
    PENDING_DATA_REVIEW = 'PENDING_DATA_REVIEW',
    ON_HOLD = 'ON_HOLD',
    VET_ANALYSIS_COMPLETED = 'VET_ANALYSIS_COMPLETED',
    RESOLUTION_COMPLETED = 'RESOLUTION_COMPLETED',
    DISBURSEMENT_REGISTERED_IN_PAS = 'DISBURSEMENT_REGISTERED_IN_PAS',
    DISBURSEMENT_ACCEPTED_BY_PAS = 'DISBURSEMENT_ACCEPTED_BY_PAS',
    PENDING_MUNICH_RE = 'PENDING_MUNICH_RE',
    CLOSED = 'CLOSED',
}
