import { Card } from '@barkibu/noma-commons';
import { InvoiceSummary } from '../../../../domain/ClaimRequestSummary/InvoiceSummary';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './billable-concept-summary-table.modules.scss';

export interface BillableConceptSummaryTableProps {
    title: string;
    invoiceSummaryList: InvoiceSummary[];
}

export const BillableConceptSummaryTable = ({ title, invoiceSummaryList }: BillableConceptSummaryTableProps) => {
    const { t } = useModuleTranslation();

    const currency = '€';
    const billableConceptSummaryList = invoiceSummaryList.flatMap((invoice) => invoice.concepts);

    const totalReimbursable = billableConceptSummaryList
        .reduce((acc, el) => acc + (el.covered ? el.totalAmountInUnits : 0), 0)
        .toFixed(2);

    return (
        <Card title={title} className="card--cost-lines-summary-table-title">
            <table className={'invoice-summary-table'}>
                <tbody>
                    {invoiceSummaryList
                        .sort((a, b) => a.date.getTime() - b.date.getTime())
                        .map((invoice) => {
                            return (
                                <tr key={invoice.number}>
                                    <td colSpan={3}>
                                        <table className={'cost-lines-summary-table'}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>{`${invoice.date.toLocaleDateString()} - ${
                                                        invoice.number
                                                    } ${invoice.issuer}`}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.concepts.map((billableConceptSummary) => {
                                                    const recommendation =
                                                        billableConceptSummary.recommendation?.toLowerCase() ??
                                                        'not_reviewed';
                                                    return (
                                                        <tr key={billableConceptSummary.concept}>
                                                            <td>
                                                                <span>{billableConceptSummary.concept}</span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {billableConceptSummary.totalAmountInUnits}
                                                                    {currency}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={
                                                                        billableConceptSummary.covered
                                                                            ? 'covered'
                                                                            : 'not-covered'
                                                                    }
                                                                >
                                                                    {t(
                                                                        `summary_view.billable-concept.recommendation.${recommendation}`,
                                                                        recommendation
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <span>{t('total_reimbursable')}</span>
                        </td>
                        <td colSpan={2}>
                            <span>
                                {totalReimbursable}
                                {currency}
                            </span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </Card>
    );
};

export default BillableConceptSummaryTable;
