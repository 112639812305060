import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../../application/auth/useAuth';

const RequireAuthRoutes = () => {
    const { isLoggedIn } = useAuth();
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default RequireAuthRoutes;
