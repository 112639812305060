import { config } from '../config/config';

export const urlQueryParamsBuilder = (url: string, params: Record<string, string | Date | number | boolean>) => {
    return (
        config.API_ENDPOINT +
        url +
        '?' +
        Object.entries(params)
            .filter((param) => param[1] !== undefined && param[1] !== null)
            .map((param) => param[0] + '=' + param[1])
            .join('&')
    );
};

export const nonQueryParamsUrlBuilder = (url: string) => {
    return config.API_ENDPOINT + url;
};
