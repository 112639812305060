import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './inbox-item.modules.scss';

export interface InboxItemProps {
    title: string;
    actionName?: string | undefined;
    count?: number | string | undefined;
    suffixCount?: string | undefined;
    reservedCount?: number | undefined;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
}

export const InboxItem = ({
    title,
    actionName,
    count,
    suffixCount,
    reservedCount,
    onClick,
    disabled,
}: InboxItemProps) => {
    const { t } = useModuleTranslation();
    const action = actionName || t('inbox_item.manage');

    return (
        <button aria-label={title} className={`inbox-item `} type={'button'} onClick={onClick} disabled={disabled}>
            <span className={`inbox-item-title`}>{title}</span>
            <span className={`inbox-item-count`}>
                {count != null && (
                    <span className={`inbox-item-count-text`}>{`${count}${suffixCount ? suffixCount : ''}`}</span>
                )}
                {!!reservedCount && <span className={`inbox-item-reserved-count-text`}>({reservedCount})</span>}
            </span>
            <a className={'inbox-item-action'}>{action}</a>
        </button>
    );
};

export default InboxItem;
