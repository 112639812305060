import { processJsonResponse } from './processJsonResponse';
import { config } from '../config/config';
import { ServerError } from '../utils/ServerError';
import { nonQueryParamsUrlBuilder, urlQueryParamsBuilder } from '../utils/urlQueryParamsBuilder';

export function processFetchOperation(
    url: string,
    method: OperationMethod,
    options: { body?: string; params?: Record<string, string | number | boolean> } = {}
): Promise<any> {
    const urlWithParams = options.params ? urlQueryParamsBuilder(url, options.params) : nonQueryParamsUrlBuilder(url);
    return fetch(urlWithParams, {
        method: method,
        headers: config.API_HEADERS(),
        body: options.body ?? null,
    })
        .catch((error) => {
            throw new ServerError(error.code, error.message);
        })
        .then((response) => processJsonResponse(response));
}

export enum OperationMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
