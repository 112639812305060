import { ArrowLeftIcon, Icon, IconModes, IconSize } from '../../../../ui/atoms';
import { useModuleTranslation } from '../../../../ui/utils/useModuleTranslation';
import './clinical-history-banner.modules.scss';
export interface ClinicalHistoryBannerProps {
    handleIncidentRedirection: () => void;
}

const ClinicalHistoryBanner = ({ handleIncidentRedirection }: ClinicalHistoryBannerProps) => {
    const { t } = useModuleTranslation();
    return (
        <div className="clinical-history-details--banner">
            <div className="clinical-history-details--banner-heading" onClick={() => handleIncidentRedirection()}>
                <Icon icon={ArrowLeftIcon} mode={IconModes.SHADOWED} size={IconSize.M} />
                <h2 className="title">{t('clinical_history.validation.banner')}</h2>
            </div>
        </div>
    );
};

export default ClinicalHistoryBanner;
