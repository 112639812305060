import { UseCase } from '../../../src';
import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';

export class UpdateIban implements UseCase<ClaimRequest> {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(claimRequestKey: string, iban: string): Promise<ClaimRequest> {
        return this.claimRequestRepository.updateIban(claimRequestKey, iban);
    }
}
