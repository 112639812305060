// Select.tsx
import React, { SelectHTMLAttributes } from 'react';
import './select.modules.scss';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {}

const Select: React.FC<SelectProps> = ({ children, ...props }) => {
    return (
        <select className={'select'} {...props}>
            {children}
        </select>
    );
};

export default Select;
