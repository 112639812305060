import Backdrop from '../../atoms/backdrop/Backdrop';
import ProgressIndicator from '../../atoms/progress-indicator/ProgressIndicator';
import './blocking-loader.modules.scss';

const BlockingLoader = () => {
    return (
        <Backdrop>
            <ProgressIndicator />
        </Backdrop>
    );
};

export default BlockingLoader;
