import React from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    CloseIcon,
    Divider,
    DocumentIcon,
    InfoIcon,
    PencilIcon,
    SupportingDocumentType,
} from '@barkibu/noma-commons';
import clsx from 'clsx';
import { SupportingDocument } from '../../../../domain/entities/supporting-document';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './supporting-documents-table.modules.scss';

export type SupportingDocumentsTableProps = {
    supportingDocuments: SupportingDocument[];
    onEditClick?: (document: SupportingDocument) => void;
    onDocumentDetail?: (document: SupportingDocument) => void;
    visibleRows?: number;
    onUpdateDocumentIsNewlyAddedByParent?: (document: SupportingDocument) => void;
};

const SupportingDocumentsTable = ({
    supportingDocuments,
    onEditClick,
    onDocumentDetail,
    onUpdateDocumentIsNewlyAddedByParent,
}: SupportingDocumentsTableProps) => {
    const { t } = useModuleTranslation();
    return (
        <div className={clsx('supporting-documents-table', { scrollable: true })}>
            {supportingDocuments.map((doc, index) => {
                const showSeparator = index !== supportingDocuments.length - 1;
                const date = doc.date?.toLocaleDateString(undefined, {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                });
                const isPaymentLetter = doc.type === 'PAYMENT_LETTER';

                return (
                    <React.Fragment key={index}>
                        <div className="supporting-documents__row-details">
                            <Button
                                text={doc.name ?? ''}
                                variantName={ButtonVariant.BASIC_INFO}
                                icon={DocumentIcon}
                                onClick={() => window.open(doc.location, '_blank')}
                            />
                        </div>
                        <span className="supporting-documents__row-date">{date}</span>
                        <div className="supporting-documents__row-actions">
                            {doc.isNewlyAddedByParent && onUpdateDocumentIsNewlyAddedByParent && (
                                <div className="delete-icon">
                                    <Chip
                                        text={'NEW'}
                                        type={ChipType.FILTER}
                                        icon={CloseIcon}
                                        onClick={() => onUpdateDocumentIsNewlyAddedByParent(doc)}
                                        accessibilityText={t('incident.document.delete_new') || undefined}
                                    />
                                </div>
                            )}
                            {onDocumentDetail && doc.transcriptKey && doc.type == SupportingDocumentType.VET_REPORT ? (
                                <Chip
                                    text={t(`document.type.${doc.type}`)}
                                    type={ChipType.ACTIONABLE}
                                    accessibilityText={t('incident.document.type') || undefined}
                                    icon={InfoIcon}
                                    onClick={() => onDocumentDetail(doc)}
                                />
                            ) : (
                                <Chip
                                    text={t(`document.type.${doc.type}`)}
                                    type={ChipType.NEUTRAL}
                                    accessibilityText={t('incident.document.type') || undefined}
                                />
                            )}

                            {onEditClick && !isPaymentLetter && (
                                <div className="edit-icon">
                                    <Button
                                        onClick={() => onEditClick(doc)}
                                        variantName={ButtonVariant.BASIC_INFO}
                                        icon={PencilIcon}
                                        accessibilityText={t('incident.document.edit')}
                                    ></Button>
                                </div>
                            )}
                        </div>
                        <div className={clsx('separator', !showSeparator && 'hide')}>
                            <Divider />
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default SupportingDocumentsTable;
