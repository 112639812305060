import { ApiError } from './ApiError';

export class MultiApiError extends Error {
    readonly apiErrors: ApiError[] = [];
    readonly status: number;

    constructor(apiErrors: ApiError[], status: number) {
        super();
        Object.setPrototypeOf(this, MultiApiError.prototype);

        this.apiErrors = apiErrors;
        this.status = status;
    }
}
