export type Option<T> = {
    label: string;
    value: T;
    deletedAt?: Date;
};

export type GroupedData<T> = {
    group: string;
    options: Option<T>[];
};

export const isGroupedData = <T>(data: GroupedData<T> | Option<T>): data is GroupedData<T> => {
    return (data as GroupedData<T>).group !== undefined;
};

export const isGroupedDataArray = <T>(data: GroupedData<T>[] | Option<T>[]): data is GroupedData<T>[] => {
    return data.length === 0 || (data[0] as GroupedData<T>).group !== undefined;
};
