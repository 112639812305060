import { MarkClaimRequestAsMissingInfoDto } from './MarkClaimRequestAsMissingInfoDto';
import { ClaimRequestStatusRepository } from '../../../domain/repositories/ClaimRequestStatusRepository';
import { OperationMethod, processFetchOperation } from '../../../infraestructure/api';

export class ClaimRequestStatusNomaRepository implements ClaimRequestStatusRepository {
    markStatusAsVetAnalysisCompleted(claimRequestKey: string): Promise<void> {
        return processFetchOperation(
            `/v1/claim-requests/${claimRequestKey}/vet-analysis-completed`,
            OperationMethod.PUT,
            {}
        );
    }

    markStatusAsMissingInfo(
        claimRequestKey: string,
        markIncidentAsMissingInfoDto: MarkClaimRequestAsMissingInfoDto
    ): Promise<void> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/missing-info`, OperationMethod.PUT, {
            params: { include: 'supportingDocuments' },
            body: JSON.stringify(markIncidentAsMissingInfoDto),
        });
    }

    markStatusAsOnHold(claimRequestKey: string): Promise<void> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/on-hold`, OperationMethod.PUT, {});
    }

    markStatusAsOnCreated(claimRequestKey: string): Promise<void> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/created`, OperationMethod.PUT, {});
    }

    markStatusAsManuallyClosed(
        claimRequestKey: string,
        closeReason?: string,
        explanationForParent?: string
    ): Promise<void> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/manually-close`, OperationMethod.PUT, {
            body: JSON.stringify({ closeReason: closeReason, explanationForParent: explanationForParent }),
        });
    }
}
