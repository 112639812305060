import { useEffect, useState } from 'react';
import {
    Button,
    ButtonVariant,
    ClaimRequest,
    ClaimRequestDetails,
    Dialog,
    getDialogDetailsFromError,
    Note,
    useDialog,
} from '@barkibu/noma-commons';
import { useAdjustmentRecommendations as useAdjustmentRecommendationsWithRepository } from '../../../application/adjustment/useAdjustmentRecommendations';
import { AdjustmentRecommendation } from '../../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentNomaRepository } from '../../../infrastructure/adjustment/AdjustmentNomaRepository';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import CoverageAdjustmentsContainer, {
    CoverageAdjustmentsContainerProps,
} from '../../components/molecules/coverage-adjustments-container/CoverageAdjustmentsContainer';
import CoverageHeader from '../../components/molecules/coverage-header/CoverageHeader';
import PetParentMessageModal from '../../components/organisms/pet-parent-message-modal/PetParentMessageModal';
import './CoveragePage.scss';

export type CoveragePageProps = {
    claimRequestKey: string;
    claimRequestDetails: ClaimRequestDetails;
    adjustments: CoverageAdjustmentsContainerProps['adjustments'];
    countryCode?: string;
    petName?: string;
    petParentNotes?: Note[];
    onCompleteRevision: (note?: string) => Promise<void>;
    onRecommendationSelected: CoverageAdjustmentsContainerProps['onRecommendationSelected'];
    onFindDuplicatedInvoiceRelated: (adjustmentKey: string) => Promise<ClaimRequest[]>;
    isSyncing?: boolean;
    syncError?: Error | undefined;
    readOnly?: boolean;
    goBackText: string;
    onGoBack: () => void;
};

const repository = AdjustmentNomaRepository();
const useAdjustmentRecommendations = useAdjustmentRecommendationsWithRepository(repository);

const CoveragePage = ({
    claimRequestKey,
    claimRequestDetails,
    adjustments,
    countryCode,
    petName,
    petParentNotes,
    onCompleteRevision,
    onRecommendationSelected,
    onFindDuplicatedInvoiceRelated,
    isSyncing = false,
    syncError = undefined,
    readOnly = false,
    goBackText,
    onGoBack,
}: CoveragePageProps) => {
    const { t } = useModuleTranslation();
    const { dialogOptions, openDialog, closeDialog } = useDialog();
    const [petParentMessageModalOpened, setPetParentMessageModalOpened] = useState(false);
    const recommendationsPerAdjustment = adjustments.reduce((recommendationMap, adjustment) => {
        recommendationMap.set(adjustment.key, useAdjustmentRecommendations(adjustment.key).adjustmentRecommendations);
        return recommendationMap;
    }, new Map<string, AdjustmentRecommendation[] | undefined>());

    useEffect(() => {
        if (syncError) openDialog(t('coverages.error.sync_fail'), getDialogDetailsFromError(syncError));
    }, [syncError]);
    const prefilledNote = petParentNotes
        ? petParentNotes
              .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
              .map((note) => note.description)
              .join('\n')
        : '';

    const totalReimbursableInCents = adjustments.reduce((result, current) => {
        const adjustmentLinesTotal = current.concepts.reduce((carry, costLine) => carry + costLine.costInCents, 0);
        const coverages = recommendationsPerAdjustment.get(current.key) || [];
        const adjustmentConceptsAmount = current.concepts.reduce((costLinesResult, costLine) => {
            if (costLine.recommendation?.cover) {
                const coveragePercentage =
                    coverages.find((it) => it.key == costLine.recommendation?.key)?.coveragePercentage || 100;
                costLinesResult +=
                    (costLine.costInCents * (1 - current.discountInCents / adjustmentLinesTotal) * coveragePercentage) /
                    100;
            }
            return costLinesResult;
        }, 0);
        return result + adjustmentConceptsAmount * current.reimbursablePercentage;
    }, 0);

    const disableCompleteRevisionButton =
        adjustments.some((adjustment) => adjustment.concepts.some((concept) => concept.recommendation == null)) ||
        isSyncing;

    const handleCompleteRevision = (note?: string) => {
        onCompleteRevision(note);
    };

    const totalReimbursable = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
        .format(totalReimbursableInCents / 100)
        .replace(',', '.');
    return (
        <>
            <div className="coverage-page">
                <div className="coverage-page--content">
                    <CoverageHeader onGoBack={onGoBack} goBackText={goBackText} />
                    <CoverageAdjustmentsContainer
                        claimRequestKey={claimRequestKey}
                        adjustments={adjustments}
                        recommendations={recommendationsPerAdjustment}
                        onFindDuplicatedInvoiceRelated={onFindDuplicatedInvoiceRelated}
                        onRecommendationSelected={onRecommendationSelected}
                        readOnly={readOnly}
                    />
                    <span className="coverage-page--content__reimbursable-total">
                        {t('coverages.reimbursable_total')}
                        <span>{totalReimbursable}</span>
                    </span>
                </div>
                <Button
                    text={readOnly ? t('coverages.see_claim') : t('coverages.finalise_review')}
                    onClick={() => setPetParentMessageModalOpened(true)}
                    disabled={!readOnly && disableCompleteRevisionButton}
                />
            </div>
            <PetParentMessageModal
                isOpen={petParentMessageModalOpened}
                onClose={() => setPetParentMessageModalOpened(false)}
                onSave={handleCompleteRevision}
                prefilledText={prefilledNote}
                countryCode={countryCode}
                petName={petName}
            />
            <Dialog
                isOpen={dialogOptions.isOpen}
                text={dialogOptions.text}
                details={dialogOptions.details}
                onClose={() => closeDialog()}
                actions={[
                    {
                        text: t('modal.close', { ns: 'commons' }),
                        variantName: ButtonVariant.PRIMARY,
                        onClick: () => closeDialog(),
                    },
                ]}
            />
        </>
    );
};

export default CoveragePage;
