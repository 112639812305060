import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import './chip-group.modules.scss';

type NativeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type ChipGroupProps = Omit<NativeProps, 'ref'> & {
    children: React.ReactNode;
};

const ChipGroup = forwardRef<HTMLDivElement, ChipGroupProps>(({ children }, ref) => {
    return (
        <div className={`chip-group`} ref={ref}>
            {children}
        </div>
    );
});
ChipGroup.displayName = 'ChipGroup';

export default ChipGroup;
