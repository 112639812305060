import { Transcript } from '../../../domain/entities';
import { SupportingDocumentTranscriptRepository } from '../../../domain/repositories/SupportingDocumentTranscriptRepository';

export class FindSupportingDocumentTranscription {
    constructor(private supportingDocumentTranscriptRepository: SupportingDocumentTranscriptRepository) {}

    async execute(transcriptKey: string): Promise<Transcript | undefined> {
        return await this.supportingDocumentTranscriptRepository.findTranscription(transcriptKey);
    }
}
