import { OperationStatus, UseCase } from '@barkibu/noma-commons';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';

export class FindAssessmentInitializationStatus implements UseCase<OperationStatus> {
    private assessmentRepository: AssessmentRepository;

    constructor(assessmentRepository: AssessmentRepository) {
        this.assessmentRepository = assessmentRepository;
    }

    async execute(claimRequestId: string): Promise<OperationStatus> {
        return this.assessmentRepository.findInitializationStatus(claimRequestId).then((operation) => operation.status);
    }
}
