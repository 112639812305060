import { UseCase } from '../../../src';
import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';

export class UpdateDateOfLoss implements UseCase<ClaimRequest> {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(claimRequestKey: string, dateOfLoss: Date): Promise<ClaimRequest> {
        return this.claimRequestRepository.updateDateOfLoss(claimRequestKey, dateOfLoss);
    }
}
