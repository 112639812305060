import CostLine from './CostLine';
import { SupportingDocument } from './supporting-document';
import { DigitisationStatus } from '../values/DigitisationStatus';

export default class Invoice {
    public readonly key?: string;
    public readonly date?: Date;
    public readonly number?: string;
    public readonly taxIdNumber?: string;
    public readonly providerName?: string;
    public readonly reimbursablePercentage: number;
    public readonly discountInCents?: number;
    public readonly supportingDocument: SupportingDocument;
    public readonly costLines: CostLine[];
    public readonly digitisationStatus?: DigitisationStatus;

    constructor({
        key,
        date,
        number,
        taxIdNumber,
        providerName,
        reimbursablePercentage,
        discountInCents,
        supportingDocument,
        costLines,
        digitisationStatus,
    }: {
        key?: string;
        date?: Date;
        number?: string;
        taxIdNumber?: string;
        providerName?: string;
        reimbursablePercentage: number;
        discountInCents?: number;
        supportingDocument: SupportingDocument;
        costLines: CostLine[];
        digitisationStatus?: DigitisationStatus;
    }) {
        this.key = key;
        this.date = date;
        this.number = number;
        this.taxIdNumber = taxIdNumber;
        this.providerName = providerName;
        this.reimbursablePercentage = reimbursablePercentage;
        this.discountInCents = discountInCents;
        this.supportingDocument = supportingDocument;
        this.costLines = costLines;
        this.digitisationStatus = digitisationStatus;
    }

    get isDigitalised(): boolean {
        return this.costLines.length !== 0;
    }
}
