import { EntityDto, ResourceIdentifierObject } from './responseEntities/EntityDto';
import { ApiError } from './responseEntities/errors/ApiError';
import { ErrorsEntityResponse } from './responseEntities/errors/ErrorsEntityResponse';
import { MultiApiError } from './responseEntities/errors/MultiApiError';
import { ServerError } from '../utils/ServerError';

export async function processJsonResponse(response: Response) {
    if (!response.ok) {
        if (response.status < 500) {
            const jsonResponse: ErrorsEntityResponse = await response.json();
            const errors = jsonResponse.errors.map((error) => {
                return new ApiError(
                    error.id,
                    error.links,
                    error.message,
                    error.status,
                    error.code,
                    error.title,
                    error.details,
                    new Map(Object.entries(error.source || {})),
                    new Map(Object.entries(error.meta || {}))
                );
            });
            throw new MultiApiError(errors, response.status);
        } else {
            const responseText = await response.text();
            throw new ServerError(response.status.toString(), responseText);
        }
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
    }
    return response.text();
}

type ApiDtoFromJsonEntity<T> = T extends EntityDto<infer X, any> ? X : never;

const flattenRelationship = <R extends EntityDto<any, any>>(
    relation: ResourceIdentifierObject | undefined,
    included: R[]
): R | null => {
    if (relation == null) {
        return null;
    }
    const details = (included || []).find((item) => item.type == relation.type && item.id == relation.id);
    return details ? flattenEntity(details, included) : null;
};

export const flattenEntity = <T extends EntityDto<any, any>, R extends EntityDto<any, any>>(
    record: T,
    included: R[]
): ApiDtoFromJsonEntity<T> => {
    const related = Object.keys(record?.relationships || {}).reduce(
        (carry, item) => {
            const relationships = record?.relationships?.[item];
            if (relationships == null) {
                carry[item] = null;
                return carry;
            } else if (Array.isArray(relationships.data)) {
                const plainRelationships = relationships.data
                    .map((it) => flattenRelationship(it, included))
                    .filter(Boolean) as R[];
                if (plainRelationships != null) {
                    carry[item] = plainRelationships;
                }
                return carry;
            } else {
                carry[item] = flattenRelationship(relationships.data, included);
                return carry;
            }
        },
        {} as Record<string, R | R[] | null>
    );
    return {
        key: record?.id,
        ...record?.attributes,
        ...related,
    } as ApiDtoFromJsonEntity<T>;
};
