import { Navigate, Route } from 'react-router-dom';
import { SummaryViewPage } from '@barkibu/noma-claim-info';
import { ClinicalHistoryContainer, InboxPage, OnHoldListPage } from '@barkibu/noma-commons';
import {
    IncidentDetailsContainer,
    InvoicesDetailsContainer,
    Layout,
    PolicyIncidentsPage,
} from '@barkibu/noma-data-entry';
import { CoveragePageContainer, VetViewPageContainer } from '@barkibu/noma-veterinary-analysis';
import RequireAuthRoutes from '../../components/routes/RequireAuthRoutes';
import WithRouterParams from '../../components/routes/WithRouterParams';
import LoginContainer from '../Login/LoginContainer';
import Routes from '../Routes/Routes';

export default function App() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<LoginContainer />} />
            <Route element={<Layout />}>
                <Route element={<RequireAuthRoutes />}>
                    <Route path="/claim/inbox" element={<InboxPage />} />
                    <Route
                        path={'/dataentry/incidents/:claimRequestKey'}
                        element={<WithRouterParams element={IncidentDetailsContainer} />}
                    />
                    <Route
                        path={'/dataentry/incidents/:claimRequestKey/invoices'}
                        element={<WithRouterParams element={InvoicesDetailsContainer} />}
                    />
                    <Route
                        path={'/dataentry/policy/:policyIdentifier/incidents'}
                        element={<WithRouterParams element={PolicyIncidentsPage} />}
                    />
                    <Route
                        path={'/vetview/incidents/:claimRequestKey'}
                        element={<WithRouterParams element={VetViewPageContainer} />}
                    />
                    <Route
                        path={'/vetview/incidents/:claimRequestKey/coverages'}
                        element={<WithRouterParams element={CoveragePageContainer} />}
                    />
                    <Route path={'/claim-requests/on-hold'} element={<OnHoldListPage />} />
                    <Route
                        path={'/claim-requests/:claimRequestKey/summary'}
                        element={<WithRouterParams element={SummaryViewPage} />}
                    />
                    <Route
                        path={'/policy/:policyIdentifier/clinical-history'}
                        element={<WithRouterParams element={ClinicalHistoryContainer} />}
                    />
                </Route>
            </Route>
        </Routes>
    );
}
