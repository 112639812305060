import { InspectionStatistics } from '../../../domain/entities';
import { GetInspectionStatistics } from '../../../application/ClaimRequest/GetInspectionStatistics';
import { ClaimRequestNomaRepository } from '../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';
import { useEffect, useState } from 'react';
import { InspectionStatistic } from '../../../domain/values/inspectionStatistic';
import { AutoApprovalServices } from '../../../domain/values/AutoApprovalServices';

const getInspectionStatistics = new GetInspectionStatistics(ClaimRequestNomaRepository);

const THRESHOLD = 8000;
export const useGetInspectionStatistics = (
    claimType: string,
    countryCode: string,
    days: number,
    interceptableClaimsOnly: boolean
) => {
    const getInspectionStatisticsLower = async (
        claimType: string,
        countryCode: string,
        days: number,
        interceptableClaimsOnly: boolean
    ) => {
        return getStatisticFormat(
            await getInspectionStatistics.execute(
                claimType,
                countryCode,
                days,
                null,
                interceptableClaimsOnly,
                THRESHOLD,
                false
            ),
            !interceptableClaimsOnly
        );
    };

    const getInspectionStatisticsUpper = async (
        claimType: string,
        countryCode: string,
        days: number,
        interceptableClaimsOnly: boolean
    ) => {
        return getStatisticFormat(
            await getInspectionStatistics.execute(
                claimType,
                countryCode,
                days,
                null,
                interceptableClaimsOnly,
                THRESHOLD,
                true
            ),
            !interceptableClaimsOnly
        );
    };

    const getInspectionStatisticsWithService = async (
        claimType: string,
        countryCode: string,
        days: number,
        service: string,
        interceptableClaimsOnly: boolean
    ) => {
        return getStatisticFormat(
            await getInspectionStatistics.execute(claimType, countryCode, days, service, interceptableClaimsOnly, null),
            !interceptableClaimsOnly
        );
    };

    const [inspectionStatistic, setInspectionStatistic] = useState<InspectionStatistic>({
        autoPartialApproval: 0,
        higherThreshold: 0,
        dubiousLineAccepted: 0,
        autoHigherThresholdDiagnosesApproval: 0,
        autoInvoiceWithThreshold: 0,
        lowerThreshold: 0,
        autoDenial: 0,
        autoApprovalFrench: 0,
    });


    useEffect(() => {
        let isCurrentTransactionValid = true;

        const fetchInspectionStatistics = async () => {

            const autoPartialApproval = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.AUTO_PARTIAL_APPROVAL,
                interceptableClaimsOnly
            );
            const higherThreshold = await getInspectionStatisticsUpper(
                claimType,
                countryCode,
                days,
                interceptableClaimsOnly
            );
            const dubiousLineAccepted = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.DUBIOUS_LINE_ACCEPTED,
                interceptableClaimsOnly
            );
            const autoHigherThresholdDiagnosesApproval = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.AUTO_HIGHER_THRESHOLD_DIAGNOSES_APPROVAL,
                interceptableClaimsOnly
            );
            const autoInvoiceWithThreshold = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.AUTO_INVOICE_WITH_THRESHOLD,
                interceptableClaimsOnly
            );
            const lowerThreshold = await getInspectionStatisticsLower(
                claimType,
                countryCode,
                days,
                interceptableClaimsOnly
            );
            const autoDenial = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.AUTO_DENIAL,
                interceptableClaimsOnly
            );
            const autoApprovalFrench = await getInspectionStatisticsWithService(
                claimType,
                countryCode,
                days,
                AutoApprovalServices.AUTO_APPROVAL_FRENCH,
                interceptableClaimsOnly
            );

            if (isCurrentTransactionValid) {
                setInspectionStatistic({
                    autoPartialApproval,
                    higherThreshold,
                    dubiousLineAccepted,
                    autoHigherThresholdDiagnosesApproval,
                    autoInvoiceWithThreshold,
                    lowerThreshold,
                    autoDenial,
                    autoApprovalFrench,
                });
            }   
        };

        fetchInspectionStatistics();
        return () => {
            isCurrentTransactionValid = false;
        }
    }, [claimType, countryCode, days, interceptableClaimsOnly]);

    return inspectionStatistic;
};

const getStatisticFormat = (inspectionStatistics: InspectionStatistics, inPercentage: Boolean = true): number => {
    if (inPercentage) {
        if (inspectionStatistics.numberOfClaims == 0) return 100;
        return Math.trunc((inspectionStatistics.numberOfClaimsInspected / inspectionStatistics.numberOfClaims) * 100);
    } else {
        return inspectionStatistics.numberOfClaims - inspectionStatistics.numberOfClaimsInspected;
    }
};
