import { useEffect, useState } from 'react';
import { useAsync } from '@barkibu/noma-commons';
import { FindIncidentInvoicesDetails } from '../../../application/FindIncidentInvoicesDetails/FindIncidentInvoicesDetails';
import Invoice from '../../../domain/entities/Invoice';
import { InvoicesDetails } from '../../../domain/entities/InvoicesDetails';
import { IncidentNomaRepository } from '../../../infraestructure/repositories/incident/IncidentNomaRepository';
import { UpdateInvoiceCostLinesDto } from '../../../infraestructure/repositories/invoice/dtos/UpdateInvoiceCostLinesDto';
import { InvoiceNomaRepository } from '../../../infraestructure/repositories/invoice/InvoiceNomaRepository';
import { PetNomaRepository } from '../../../infraestructure/repositories/pet/PetNomaRepository';

const invoiceRepository = new InvoiceNomaRepository();

const findInvoicesDetails = new FindIncidentInvoicesDetails(
    invoiceRepository,
    new IncidentNomaRepository(),
    new PetNomaRepository()
);

export const useInvoicesDetails = (claimRequestKey?: string) => {
    const { execute, status, value, setValue } = useAsync<InvoicesDetails | undefined>(findInvoicesDetails);
    const [syncError, setSyncError] = useState<Error | undefined>(undefined);
    const [pendingRequests, setPendingRequests] = useState<number>(0);

    useEffect(() => {
        if (claimRequestKey != undefined) execute(claimRequestKey);
    }, [claimRequestKey]);

    const requestWrapper = <T extends any[]>(fn: (...args: T) => Promise<Invoice | undefined>) => {
        return async (...args: T) => {
            try {
                setPendingRequests((pendingRequests) => pendingRequests + 1);
                debouncedPromiseCallback(fn(...args));
            } catch (e) {
                if (e instanceof Error) {
                    setSyncError(e);
                }
            } finally {
                setPendingRequests((pendingRequests) => pendingRequests - 1);
            }
        };
    };

    let debouncerPromiseIdentifier;
    const debouncedPromiseCallback = async (promise: Promise<Invoice | undefined>) => {
        const currentDebouncerPromiseIdentifier = Math.random();
        debouncerPromiseIdentifier = currentDebouncerPromiseIdentifier;
        const updatedInvoice = await promise;
        if (updatedInvoice != undefined && debouncerPromiseIdentifier == currentDebouncerPromiseIdentifier) {
            setValue((invoicesDetails) => invoicesDetails?.updateInvoices(updatedInvoice));
        }
    };

    const updateInvoiceCostLines = async (invoiceKey: string, values: UpdateInvoiceCostLinesDto): Promise<Invoice> => {
        return await invoiceRepository.updateCostLines(invoiceKey, values);
    };

    const updateClinic = async (key: string, clinic: string): Promise<Invoice | undefined> => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.providerName != clinic) {
            return await invoiceRepository.updateClinic(key, clinic);
        }
    };

    const updateDate = async (key: string, date: Date): Promise<Invoice | undefined> => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.date != date) {
            return await invoiceRepository.updateDate(key, date);
        }
    };

    const updateDiscount = async (key: string, discount: number) => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.discountInCents != discount) {
            return await invoiceRepository.updateDiscount(key, discount);
        }
    };

    const updateNumber = async (key: string, number: string) => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.number != number) {
            return await invoiceRepository.updateNumber(key, number);
        }
    };

    const updateTaxIdNumber = async (key: string, taxIdNumber: string) => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.taxIdNumber != taxIdNumber) {
            return await invoiceRepository.updateTaxIdNumber(key, taxIdNumber);
        }
    };

    const updateReimbursablePercentage = async (key: string, reimbursablePercentage: number) => {
        const invoice = value?.invoices.find((invoice) => invoice.key == key);
        if (invoice?.reimbursablePercentage != reimbursablePercentage) {
            return await invoiceRepository.updateReimbursablePercentage(key, reimbursablePercentage);
        }
    };

    return {
        invoicesDetails: value,
        invoicesDetailsFetchStatus: status,
        updateInvoiceCostLines: requestWrapper(updateInvoiceCostLines),
        refreshInvoiceDetails: () => execute(claimRequestKey),
        updateClinic: requestWrapper(updateClinic),
        updateDate: requestWrapper(updateDate),
        updateDiscount: requestWrapper(updateDiscount),
        updateNumber: requestWrapper(updateNumber),
        updateReimbursablePercentage: requestWrapper(updateReimbursablePercentage),
        updateTaxIdNumber: requestWrapper(updateTaxIdNumber),
        isSyncing: pendingRequests > 0,
        syncError,
    };
};
