import {
    ClaimRequestDto,
    claimRequestDtoToDomain,
    CollectionEntityResponse,
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '@barkibu/noma-commons';
import { mapCoverage, mapDenialReason, toDomain } from './AdjustmentMapper';
import { AdjustmentCoverageDto } from './response/AdjustmentCoverageDto';
import { AdjustmentDenialReasonDto } from './response/AdjustmentDenialReasonDto';
import { AdjustmentDto } from './response/AdjustmentDto';
import { AdjustmentRecommendation } from '../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentRepository } from '../../domain/adjustment/AdjustmentRepository';

export const AdjustmentNomaRepository = (): AdjustmentRepository => ({
    findAllByClaimRequestKey: (claimRequestKey: string) => {
        return processFetchOperation(
            `/v1/claim-requests/${claimRequestKey}/assessment/adjustments`,
            OperationMethod.GET
        ).then((dto: CollectionEntityResponse<AdjustmentDto>) => {
            const entities = dto.data.map((adjustmentDto) => flattenEntity(adjustmentDto, dto.included || []));
            return entities.map((entity) => toDomain(entity));
        });
    },
    findAllCoverages: (adjustmentKey) => {
        return processFetchOperation(`/v1/adjustments/${adjustmentKey}/coverages`, OperationMethod.GET).then(
            (dto: CollectionEntityResponse<AdjustmentCoverageDto>) => {
                const coverages: AdjustmentRecommendation[] = [];
                dto.data.forEach((adjustmentDto) => {
                    const coverage = flattenEntity(adjustmentDto, dto.included || []);
                    coverages.push(
                        mapCoverage(
                            coverage.key,
                            coverage.claimRequestWithCoverageUsages,
                            coverage.coveragePercentage,
                            undefined,
                            coverage.usageLimit
                        )
                    );
                    coverage.subCoverages.forEach((it) =>
                        coverages.push(
                            mapCoverage(
                                it.key,
                                it.claimRequestWithCoverageUsages,
                                it.coveragePercentage,
                                coverage.key,
                                it.usageLimit
                            )
                        )
                    );
                });

                return coverages;
            }
        );
    },
    findAllDenialReasons: (adjustmentKey) => {
        return processFetchOperation(`/v1/adjustments/${adjustmentKey}/denial-reasons`, OperationMethod.GET).then(
            (dto: CollectionEntityResponse<AdjustmentDenialReasonDto>) => {
                const entities = dto.data.map((adjustmentDto) => flattenEntity(adjustmentDto, dto.included || []));
                return entities.map((entity) => mapDenialReason(entity));
            }
        );
    },
    findAdjustmentDuplicatedClaimRequests: (adjustmentKey) => {
        return processFetchOperation(`/v1/adjustments/${adjustmentKey}/duplicated-invoice`, OperationMethod.GET).then(
            (dto: CollectionEntityResponse<ClaimRequestDto>) => {
                const entities = dto.data.map((it) => {
                    return flattenEntity(it, dto.included || []);
                });
                return entities.map((it) => claimRequestDtoToDomain(it));
            }
        );
    },
    changeConceptRecommendation: (
        adjustmentKey: string,
        conceptKey: string,
        recommendation: string,
        covered: boolean
    ) => {
        return processFetchOperation(`/v1/adjustments/${adjustmentKey}/concepts`, OperationMethod.PUT, {
            body: JSON.stringify({
                conceptKey,
                recommendation,
                covered,
            }),
        }).then((dto: SingleEntityResponse<AdjustmentDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    },
});
