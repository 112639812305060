import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { ErrorBoundary } from '@sentry/react';
import { AuthProvider } from './domain/auth/AuthProvider';
import initMonitoring from './infrastructure/monitoring/sentry.config';
import App from './ui/containers/App/App';
import './index.scss';

initMonitoring();

const container = document.getElementById('root');
if (container == null) throw new Error('No root element found');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorBoundary>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ErrorBoundary>
        </BrowserRouter>
    </React.StrictMode>
);
