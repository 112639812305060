import { useEffect, useState } from 'react';
import { FindClaimRequestFraudAlerts } from '../../application/FindClaimRequestFraudAlerts/FindClaimRequestFraudAlerts';
import { FraudAlert } from '../../domain/entities';
import { FraudAlertsNomaRepository } from '../../infraestructure/repositories/FraudAlertsNomaRepository';

const fraudAlertsNomaRepository = new FraudAlertsNomaRepository();

const findFraudAlerts = new FindClaimRequestFraudAlerts(fraudAlertsNomaRepository);

export const useFraudAlerts = (claimRequestKey: string | undefined) => {
    const [fraudAlerts, setFraudAlerts] = useState<FraudAlert[] | undefined>([]);

    useEffect(() => {
        if (claimRequestKey != null) {
            findFraudAlerts.execute(claimRequestKey).then(setFraudAlerts);
        }
    }, [claimRequestKey]);

    return { fraudAlerts };
};
