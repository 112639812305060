import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { UseCase } from '../../domain/utils';
import { CountryCode } from '../../domain/values';

export interface GetOnHoldClaimRequestResult {
    claimRequestsList: ClaimRequest[];
}
export class GetOnHoldClaimRequests implements UseCase<GetOnHoldClaimRequestResult> {
    private claimRequestRepository: ClaimRequestRepository;
    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }
    async execute(countryCode: CountryCode): Promise<GetOnHoldClaimRequestResult> {
        const result = await this.claimRequestRepository.getOnHoldClaimRequests(countryCode);
        return { claimRequestsList: result };
    }
}
