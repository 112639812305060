import { useReducer } from 'react';
import { dialogOptionsReducer } from './DialogOptionsReducer';

export default function useDialog() {
    const [dialogOptions, setDialogOptions] = useReducer(dialogOptionsReducer, { isOpen: false, text: '' });

    const openDialog = (text: string, details?: string[]) => {
        setDialogOptions({ type: 'open', payload: { text, details } });
    };

    const closeDialog = () => {
        setDialogOptions({ type: 'close' });
    };

    return {
        dialogOptions,
        openDialog,
        closeDialog,
    };
}
