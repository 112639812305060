import { useState } from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    MainSupportingDocumentTypes,
    Modal,
    OtherSupportingDocumentTypes,
    SupportingDocumentType,
} from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './suppporting-document-type-selector.modules.scss';

export interface SupportingDocumentTypeSelectorProps {
    documentType?: SupportingDocumentType;
    onDocumentTypeSelectedHandler: (newDocumentType: SupportingDocumentType) => void;
}

const SupportingDocumentTypeSelector = ({
    documentType,
    onDocumentTypeSelectedHandler,
}: SupportingDocumentTypeSelectorProps) => {
    const { t } = useModuleTranslation();
    const [isOtherModalOpen, setIsOtherModalOpen] = useState<boolean>(false);
    const [otherOption, setOtherOption] = useState<OtherSupportingDocumentTypes | undefined>();

    const confirmOtherOption = () => {
        if (otherOption) {
            onDocumentTypeSelectedHandler(otherOption);
        }
        setIsOtherModalOpen(false);
    };

    const clearSelection = () => {
        setOtherOption(undefined);
        setIsOtherModalOpen(false);
    };

    return (
        <>
            <span className="label">{t('incident.document.edit_type')}</span>
            <div className="supporting-document-type-selector">
                {Object.values(MainSupportingDocumentTypes).map((description) => (
                    <Chip
                        key={description}
                        type={ChipType.OPTION}
                        text={t(`document.type.${description}`)}
                        checked={description === documentType}
                        onClick={() => onDocumentTypeSelectedHandler(description)}
                    />
                ))}
                <Chip
                    key={SupportingDocumentType.OTHER}
                    type={ChipType.OPTION}
                    text={t(`document.type.OTHER`)}
                    checked={documentType! in OtherSupportingDocumentTypes}
                    onClick={() => setIsOtherModalOpen(true)}
                />
            </div>
            <Modal
                isOpen={isOtherModalOpen}
                onClose={clearSelection}
                title={t('document.other_details.title')}
                className="document-other-details-modal"
            >
                <div className="document-other-details-modal__body">
                    <div className="supporting-document-type-selector">
                        {Object.values(OtherSupportingDocumentTypes).map((description) => (
                            <Chip
                                key={description}
                                type={ChipType.OPTION}
                                text={t(`document.type.${description}`)}
                                checked={(otherOption || documentType) === description}
                                onClick={() => setOtherOption(description)}
                            />
                        ))}
                    </div>
                    <Button
                        type="button"
                        variantName={ButtonVariant.SECONDARY}
                        text={t('document.other_details.confirm')}
                        onClick={confirmOtherOption}
                        disabled={!otherOption}
                    />
                </div>
            </Modal>
        </>
    );
};

export default SupportingDocumentTypeSelector;
