import { ClaimRequestDetails } from '../../domain/entities/ClaimRequestDetails';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { UseCase } from '../../domain/utils';

export class FindClaimRequestDetails implements UseCase<ClaimRequestDetails> {
    constructor(private claimRequestRepository: ClaimRequestRepository) {}

    async execute(claimRequestKey: string): Promise<ClaimRequestDetails> {
        try {
            const claimRequest = await this.claimRequestRepository.findClaimRequest(claimRequestKey);

            return new ClaimRequestDetails(claimRequest);
        } catch (e) {
            throw new Error('Claim request not found');
        }
    }
}
