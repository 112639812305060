import {
    ClinicalHistory,
    ClinicalHistoryCardStyle,
    ClinicalHistoryWrapper,
    SupportingDocumentDeletionReason,
} from '@barkibu/noma-commons';
import { RequestSummary } from '../../../domain/entities/RequestSummary';
import { UpdateSupportingDocumentDto } from '../../../infraestructure/repositories/incident/dtos/UpdateSupportingDocumentDto';
import PolicyIncidentsBanner from '../../components/organisms/policy-incidents-banner/PolicyIncidentsBanner';
import PolicyIncidentsList from '../../components/organisms/policy-incidents-list/PolicyIncidentsList';
import './policy-incidents-details.modules.scss';

export type PolicyIncidentsDetailsProps = {
    requestSummaries: RequestSummary[];
    onGoBack: () => void;
    onRemoveDocument: (key: string, deletionReason: SupportingDocumentDeletionReason) => Promise<void>;
    onUpdateDocument: (document: UpdateSupportingDocumentDto) => Promise<void>;
    onCloseClaimRequest: (claimRequestKey: string) => void;
    clinicalHistory: ClinicalHistory | undefined;
    policyIdentifier: string;
};

const PolicyIncidentsDetails = ({
    requestSummaries,
    onGoBack,
    onRemoveDocument,
    onUpdateDocument,
    onCloseClaimRequest,
    clinicalHistory,
    policyIdentifier,
}: PolicyIncidentsDetailsProps) => {
    return (
        <div className="policy-incidents__details">
            <PolicyIncidentsBanner onGoBack={onGoBack} />
            <ClinicalHistoryWrapper
                clinicalHistory={clinicalHistory}
                policyIdentifier={policyIdentifier}
                style={ClinicalHistoryCardStyle.BIG}
            />
            <PolicyIncidentsList
                requestSummaries={requestSummaries}
                onRemoveDocument={onRemoveDocument}
                onUpdateDocument={onUpdateDocument}
                onCloseClaimRequest={onCloseClaimRequest}
            />
        </div>
    );
};

export default PolicyIncidentsDetails;
