import { SupportingDocumentType } from '@barkibu/noma-commons';

export default class SupportingDocument {
    public readonly key?: string;
    public readonly type?: SupportingDocumentType;
    public readonly location?: string;
    public readonly valid?: boolean;
    public readonly details?: Record<string, any>;
    public readonly date?: Date;
    public readonly deleted?: boolean;
    public readonly isNewlyAddedByParent?: boolean;
    public readonly transcriptKey?: string;
    private readonly _name?: string;

    constructor({
        key,
        type,
        location,
        valid,
        details,
        name,
        date,
        deleted,
        isNewlyAddedByParent,
        transcriptKey,
    }: {
        key?: string;
        type?: SupportingDocumentType;
        location?: string;
        valid?: boolean;
        details?: Record<string, any>;
        name?: string;
        date?: Date;
        deleted?: boolean;
        isNewlyAddedByParent?: boolean;
        transcriptKey?: string;
    }) {
        this.key = key;
        this.type = type;
        this.location = location;
        this.valid = valid;
        this.details = details;
        this._name = name;
        this.date = date;
        this.deleted = deleted;
        this.isNewlyAddedByParent = isNewlyAddedByParent;
        this.transcriptKey = transcriptKey;
    }

    get name(): string {
        const locationFileName = this.location?.split('/').pop();
        return this._name ?? locationFileName ?? this.key ?? 'NA';
    }
}
