import { useContext } from 'react';
import { UserContext } from './UserContext';

export const useUser = () => {
    const { user } = useContext(UserContext);

    return {
        user,
    };
};
