import { CreateNoteDto } from './dtos/CreateNoteDto';
import { NoteDto } from './dtos/NoteDto';
import { toDomain } from './NoteMapper';
import { Note } from '../../domain/entities/note';
import { NotesRepository } from '../../domain/repositories';
import { NoteScope } from '../../domain/values/Notes/NoteScope';
import {
    CollectionEntityResponse,
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '../api';

export class NotesNomaRepository implements NotesRepository {
    entityName: string;
    entityKey?: string;

    constructor(entityName: NoteScope, claimRequestKey?: string, policyIdentifier?: string) {
        this.entityName = entityName.replaceAll('_', '-').toLowerCase();
        switch (entityName) {
            case NoteScope.CLAIM_REQUEST:
                this.entityName = 'incidents';
                this.entityKey = claimRequestKey!;
                break;
            default:
                if (policyIdentifier) this.entityKey = policyIdentifier;
                break;
        }
    }

    findNotesByEntityKey(): Promise<Note[]> {
        if (!this.entityName || !this.entityKey) return Promise.resolve([]);
        return processFetchOperation(`/v1/${this.entityName}/${this.entityKey}/notes`, OperationMethod.GET, {}).then(
            (dto: CollectionEntityResponse<NoteDto>) => {
                return dto.data.map((el) => toDomain(flattenEntity(el, [])));
            }
        );
    }

    create(entityKey: string, createNoteDto: CreateNoteDto): Promise<Note> {
        return processFetchOperation(`/v1/incidents/${entityKey}/notes`, OperationMethod.POST, {
            body: JSON.stringify(createNoteDto),
        }).then((dto: SingleEntityResponse<NoteDto>) => {
            return toDomain(flattenEntity(dto.data, []));
        });
    }

    delete(noteKey: string): Promise<void> {
        return processFetchOperation(`/v1/incidents/notes/${noteKey}`, OperationMethod.DELETE);
    }
}
