import { useNavigate } from 'react-router-dom';

export const useHref = () => {
    const navigate = useNavigate();

    const to = (path: string) => {
        navigate(path);
    };

    const goBack = () => {
        navigate(-1);
    };

    const toVetView = (claimRequestKey: string) => to(`/vetview/incidents/${claimRequestKey}`);

    const toClaimInbox = () => to('/claim/inbox');

    const toDataEntry = (claimRequestKey: string) => to(`/dataentry/incidents/${claimRequestKey}`);

    const toCoverages = (claimRequestKey: string) => to(`/vetview/incidents/${claimRequestKey}/coverages`);

    const toInvoiceDigitisation = (claimRequestKey: string) => to(`/dataentry/incidents/${claimRequestKey}/invoices`);

    const toInspection = (claimRequestKey: string) => to(`/claim-requests/${claimRequestKey}/summary?inspection=true`);

    const toOnHold = (countryCode: string) => to(`/claim-requests/on-hold?countryCode=${countryCode}`);

    const toDocumentLibrary = (policyNumber: string) => to(`/dataentry/policy/${policyNumber}/incidents`);

    return {
        to,
        goBack,
        toDataEntry,
        toVetView,
        toClaimInbox,
        toCoverages,
        toInvoiceDigitisation,
        toInspection,
        toOnHold,
        toDocumentLibrary,
    };
};
