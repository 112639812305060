import { ClinicalHistory } from '../../domain/entities/ClinicalHistory';
import { ClinicalHistoryRepository } from '../../domain/repositories';

export class FindClinicalHistoryByPolicyIdentifier {
    constructor(private clinicalHistoryRepository: ClinicalHistoryRepository) {}

    async execute(policyIdentifier: string): Promise<ClinicalHistory | undefined> {
        return await this.clinicalHistoryRepository.findByPolicyIdentifier(policyIdentifier);
    }
}
