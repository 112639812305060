import { useState } from 'react';
import {
    Button,
    ButtonVariant,
    Card,
    Chip,
    ChipType,
    ClaimRequestStatus,
    ClaimStatusChip,
    CloseIncidentManuallyConfirmationDialog,
    CountryCode,
    getDialogDetailsFromError,
    SupportingDocumentDeletionReason,
    TrashIcon,
    useDialog,
    User,
    useUser,
} from '@barkibu/noma-commons';
import { RequestSummary } from '../../../../domain/entities/RequestSummary';
import { EditableSupportingDocument, SupportingDocument } from '../../../../domain/entities/supporting-document';
import { UpdateSupportingDocumentDto } from '../../../../infraestructure/repositories/incident/dtos/UpdateSupportingDocumentDto';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import SupportingDocumentsTable from '../supporting-documents-table/SupportingDocumentsTable';
import UpdateDocumentModal from '../update-document-modal/UpdateDocumentModal';
import './policy-incidents-list.modules.scss';

export type PolicyIncidentsListProps = {
    requestSummaries: RequestSummary[];
    onRemoveDocument: (key: string, deletionReason: SupportingDocumentDeletionReason) => Promise<void>;
    onUpdateDocument: (document: UpdateSupportingDocumentDto) => Promise<void>;
    onCloseClaimRequest: (key: string) => void;
};

const PolicyIncidentsList = ({
    requestSummaries,
    onRemoveDocument,
    onUpdateDocument,
    onCloseClaimRequest,
}: PolicyIncidentsListProps) => {
    const { t } = useModuleTranslation();
    const { user } = useUser();

    const { openDialog } = useDialog();

    const [documentToEdit, setDocumentToEdit] = useState<SupportingDocument | undefined>();
    const [showCloseIncidentModal, setShowCloseIncidentModal] = useState(false);
    const [keyToRemove, setKeyToRemove] = useState<string | undefined>();
    const [claimCountryCode, setClaimCountryCode] = useState<CountryCode | undefined>();

    const handleCloseIncidentDialogClose = () => {
        setShowCloseIncidentModal(false);
        setKeyToRemove(undefined);
        setClaimCountryCode(undefined);
    };
    const handleCloseIncidentDialogOpen = (claimRequestKey: string, countryCode: CountryCode) => {
        setKeyToRemove(claimRequestKey);
        setShowCloseIncidentModal(true);
        setClaimCountryCode(countryCode);
    };
    const handleCloseIncident = async () => {
        try {
            await onCloseClaimRequest(keyToRemove!);
            setKeyToRemove(undefined);
            setShowCloseIncidentModal(false);
            setClaimCountryCode(undefined);
        } catch (e) {
            openDialog(t('incident.error.delete_incident'), getDialogDetailsFromError(e));
        }
    };
    const closeModal = () => {
        setDocumentToEdit(undefined);
    };

    const handleRemoveDocument = (deletionReason: SupportingDocumentDeletionReason) => {
        onRemoveDocument(documentToEdit?.key!, deletionReason);
        closeModal();
    };

    const retrieveClaimRequestRedirectionUrl = (
        claimRequestKey: string,
        status: ClaimRequestStatus,
        reservation: User | undefined
    ) => {
        if (reservation && reservation.email == user?.email) {
            switch (status) {
                case ClaimRequestStatus.CREATED:
                case ClaimRequestStatus.MISSING_INFO:
                case ClaimRequestStatus.PENDING_DATA_REVIEW:
                    return `/dataentry/incidents/${claimRequestKey}`;
                case ClaimRequestStatus.READY_FOR_VET_ANALYSIS:
                    return `/vetview/incidents/${claimRequestKey}`;
                default:
                    return `/claim-requests/${claimRequestKey}/summary`;
            }
        } else {
            return `/claim-requests/${claimRequestKey}/summary`;
        }
    };

    const handleUpdateDocument = (editedDocuments: EditableSupportingDocument[]) => {
        const editedDocument = editedDocuments[0];
        const toUpdateSupportingDocument: UpdateSupportingDocumentDto = {
            key: documentToEdit!.key,
            type: editedDocument.type?.toString()!,
            name: editedDocument.name,
            location: editedDocument.location,
            deleted: false,
        };

        onUpdateDocument(toUpdateSupportingDocument);
        closeModal();
    };

    return (
        <>
            <div className="policy-incidents-list__container">
                {requestSummaries.map((summary) => {
                    return (
                        <Card key={summary.incidentKey} className="policy-incident__detail">
                            <div className="policy-incident-detail__header">
                                <a
                                    href={retrieveClaimRequestRedirectionUrl(
                                        summary.claimRequestKey,
                                        summary.status,
                                        summary.userReservation
                                    )}
                                >
                                    {summary.date?.toLocaleDateString()}
                                </a>{' '}
                                -{' '}
                                <ClaimStatusChip
                                    status={summary.status}
                                    details={summary.statusDetails}
                                    amountRefunded={summary.amountRefunded}
                                    colored
                                />
                                <div className="delete-icon">
                                    <Button
                                        onClick={() =>
                                            handleCloseIncidentDialogOpen(summary.claimRequestKey, summary.countryCode)
                                        }
                                        variantName={ButtonVariant.DANGER}
                                        icon={TrashIcon}
                                        accessibilityText={t('incident.document.delete')}
                                    ></Button>
                                </div>
                                <div>
                                    {summary.diagnosisValues.length > 0
                                        ? summary.diagnosisValues.map((diagnosis) => (
                                              <Chip key={diagnosis} text={diagnosis} type={ChipType.NEUTRAL}></Chip>
                                          ))
                                        : summary.symptomValues.map((symptom) => (
                                              <Chip key={symptom} text={symptom} type={ChipType.NEUTRAL}></Chip>
                                          ))}
                                </div>
                                <span>
                                    {summary.userReservation && summary.userReservation.name
                                        ? t('policy.reserved_by', {
                                              userName: summary.userReservation.name,
                                          })
                                        : t('policy.not_reserved')}
                                </span>
                            </div>
                            <SupportingDocumentsTable
                                supportingDocuments={summary.supportingDocuments ?? []}
                                onEditClick={(doc) => setDocumentToEdit(doc)}
                                onUpdateDocumentIsNewlyAddedByParent={undefined}
                            />
                        </Card>
                    );
                })}
            </div>
            <UpdateDocumentModal
                isOpen={documentToEdit != null}
                document={documentToEdit}
                onClose={closeModal}
                onSave={handleUpdateDocument}
                onRemove={handleRemoveDocument}
            />
            {showCloseIncidentModal && claimCountryCode && (
                <CloseIncidentManuallyConfirmationDialog
                    isOpen={showCloseIncidentModal}
                    onCloseClaim={handleCloseIncident}
                    onCancel={handleCloseIncidentDialogClose}
                    countryCode={claimCountryCode}
                />
            )}
        </>
    );
};

export default PolicyIncidentsList;
