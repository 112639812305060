import { ClaimRequestNomaRepository } from '../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';
import { AssignNextClaimToInspect } from '../../../application/ClaimRequest/AssignNextClaimToInspect';

const assignNextClaimToInspect = new AssignNextClaimToInspect(ClaimRequestNomaRepository);

const THRESHOLD = 8000;
export const useAssignInspection = () => {
    const assignNextInspectionLower = async (
        claimType: string,
        countryCode: string,
        days: number,
        interceptableClaimsOnly: boolean
    ) => {
        return await assignNextClaimToInspect.execute(
            claimType,
            countryCode,
            days,
            null,
            interceptableClaimsOnly,
            THRESHOLD,
            false
        );
    };

    const assignNextInspectionUpper = async (
        claimType: string,
        countryCode: string,
        days: number,
        interceptableClaimsOnly: boolean
    ) => {
        return await assignNextClaimToInspect.execute(
            claimType,
            countryCode,
            days,
            null,
            interceptableClaimsOnly,
            THRESHOLD,
            true
        );
    };

    const assignNextInspectionWithService = async (
        claimType: string,
        countryCode: string,
        days: number,
        service: string,
        interceptableClaimsOnly?: boolean
    ) => {
        return await assignNextClaimToInspect.execute(
            claimType,
            countryCode,
            days,
            service,
            interceptableClaimsOnly,
            null,
            null
        );
    };

    return {
        assignNextInspectionLower,
        assignNextInspectionUpper,
        assignNextInspectionWithService,
    };
};
