import { InspectionOverpay } from '../domain/values/InspectionOverpay';

export const useOverpayMapper = () => {
    const getInspectionOverpayFromString = (value: string): InspectionOverpay => {
        switch (value) {
            case '<5€':
                return InspectionOverpay.LESS_THAN_5;
            case '5-25€':
                return InspectionOverpay.FROM_5_TO_25;
            case '25-50€':
                return InspectionOverpay.FROM_25_TO_50;
            case '50-100€':
                return InspectionOverpay.FROM_50_TO_100;
            case '>100€':
                return InspectionOverpay.MORE_THAN_100;
            default:
                return InspectionOverpay.NONE;
        }
    };

    const getInspectionOverpay = (
        lowerBoundErrorInCents?: number,
        upperBoundErrorInCents?: number
    ): InspectionOverpay => {
        if (!lowerBoundErrorInCents && upperBoundErrorInCents == 500) return InspectionOverpay.LESS_THAN_5;
        if (lowerBoundErrorInCents == 10000 && !upperBoundErrorInCents) return InspectionOverpay.MORE_THAN_100;
        if (!lowerBoundErrorInCents && !upperBoundErrorInCents) return InspectionOverpay.NONE;

        return getInspectionOverpayFromString(`${lowerBoundErrorInCents! / 100}-${upperBoundErrorInCents! / 100}€`);
    };
    const getOverpayLowerBoundErrorInCents = (overpay?: InspectionOverpay): number | null => {
        switch (overpay) {
            case InspectionOverpay.NONE:
                return null;
            case InspectionOverpay.LESS_THAN_5:
                return null;
            case InspectionOverpay.FROM_5_TO_25:
                return 500;
            case InspectionOverpay.FROM_25_TO_50:
                return 2500;
            case InspectionOverpay.FROM_50_TO_100:
                return 5000;
            case InspectionOverpay.MORE_THAN_100:
                return 10000;
            default:
                return null;
        }
    };

    const getOverpayUpperBoundErrorInCents = (overpay?: InspectionOverpay): number | null => {
        switch (overpay) {
            case InspectionOverpay.NONE:
                return null;
            case InspectionOverpay.LESS_THAN_5:
                return 500;
            case InspectionOverpay.FROM_5_TO_25:
                return 2500;
            case InspectionOverpay.FROM_25_TO_50:
                return 5000;
            case InspectionOverpay.FROM_50_TO_100:
                return 10000;
            case InspectionOverpay.MORE_THAN_100:
                return null;
            default:
                return null;
        }
    };
    return {
        getInspectionOverpayFromString,
        getInspectionOverpay,
        getOverpayLowerBoundErrorInCents,
        getOverpayUpperBoundErrorInCents,
    };
};
