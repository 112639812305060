import { useEffect } from 'react';
import { ClaimRequestRepository } from '../domain/repositories';
import { useAsync } from '../domain/utils';
import { FindContractIdsActiveOnClaimRequest } from './FindContractIdsActiveInClaimRequest/FindContractIdsActiveOnClaimRequest';

export const useContractIdsActiveOnClaimRequest = (repository: ClaimRequestRepository) => {
    const findContractIdsActiveOnClaimRequest = new FindContractIdsActiveOnClaimRequest(repository);
    return (claimRequestKey: string) => {
        const { execute, value, setValue } = useAsync<String[] | undefined>(findContractIdsActiveOnClaimRequest);
        useEffect(() => {
            if (claimRequestKey != undefined) execute(claimRequestKey);
            else setValue([]);
        }, [claimRequestKey]);

        return {
            contractIdsActiveOnClaimRequest: value,
        };
    };
};
