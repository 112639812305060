import { PolicyStatus } from '../../../domain/values';
import { Chip, ChipType } from '../../../ui/atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

const policyStatusMap = {
    [PolicyStatus.ACTIVE]: ChipType.SUCCESS,
    [PolicyStatus.SUSPENDED]: ChipType.WARNING,
    [PolicyStatus.CANCELLED]: ChipType.ERROR,
    [PolicyStatus.CLOSED]: ChipType.ERROR,
} as const;

export interface PolicyChipProps {
    status: PolicyStatus;
}

export const PolicyChip = ({ status }: PolicyChipProps) => {
    const { t } = useModuleTranslation();
    const chipType = policyStatusMap[status.toLowerCase() as keyof typeof policyStatusMap];
    const chipText = t(`policy.${status.toLowerCase()}`);

    return <Chip text={chipText} type={chipType} />;
};
