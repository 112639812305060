import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../application/auth/useAuth';
import { Login } from '../../components/login/Login';

const LoginContainer: FC = () => {
    const { signIn, isLoggedIn } = useAuth();
    const location = useLocation();

    if (isLoggedIn) {
        return <Navigate to={location.state?.from || '/claim/inbox'} replace />;
    }

    return <Login handleSubmit={signIn} />;
};

export default LoginContainer;
