import { ReactElement, useEffect, useState } from 'react';
import { ClinicalHistory, ClinicalHistoryDocument, Diagnosis } from '../../../../../domain/entities';
import {
    Button,
    ButtonVariant,
    Divider,
    Dropdown,
    Input,
    MoreIcon,
    NoteInput,
    Option,
    PreviewFile,
    TrashIcon,
} from '../../../../atoms';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './clinical-history-form.modules.scss';

export type ClinicalHistoryFormProps = {
    children: ReactElement;
    clinicalHistory?: ClinicalHistory;
    readOnly: boolean;
    onUpdateValidation: (validation: boolean) => void;
    onUpdateNote: (note: string) => void;
    onAddDisease: (key: string) => void;
    onRemoveDisease: (key: string) => void;
    clinicalHistoryDocument: ClinicalHistoryDocument;
    options: Diagnosis[];
};

const ClinicalHistoryForm = ({
    children,
    clinicalHistory,
    readOnly,
    onUpdateValidation,
    onUpdateNote,
    onAddDisease,
    onRemoveDisease,
    clinicalHistoryDocument,
    options,
}: ClinicalHistoryFormProps) => {
    const { t } = useModuleTranslation();
    const [validationSelected, setValidationSelected] = useState(clinicalHistory?.isValidated);
    const [noteValue, setNoteValue] = useState(clinicalHistory?.note);
    const [diseases, setDiseases] = useState(clinicalHistory?.preexistentDiseases || []);
    const [newDiseaseToAdd, setNewDiseaseToAdd] = useState(false);

    useEffect(() => {
        setValidationSelected(clinicalHistory?.isValidated);
        setNoteValue(clinicalHistory?.note);
    }, [clinicalHistory]);

    const handleValidationChange = (validation: boolean) => {
        setValidationSelected(validation);
        onUpdateValidation(validation);
    };
    const handleNoteTouched = () => {
        if (noteValue != null) {
            onUpdateNote(noteValue);
        }
    };
    const handleNoteChange = (note: string) => {
        setNoteValue(note);
    };

    const handleDiseaseAdded = (diagnosis: Diagnosis) => {
        const disease = {
            key: '',
            diagnosisKey: diagnosis.key,
            description: diagnosis.description,
        };
        const updatedDiseases = [...diseases, disease];
        setDiseases(updatedDiseases);
        onAddDisease(diagnosis.key);
        setNewDiseaseToAdd(false);
    };

    const handleDiseaseLineAddition = () => {
        setNewDiseaseToAdd(true);
    };
    const handleDiseaseDeleted = (key: string) => {
        const updatableDiseases = diseases.filter((el) => {
            return el.diagnosisKey !== key;
        });
        setDiseases(updatableDiseases);
        onRemoveDisease(key);
    };

    const validationValues: Option<boolean>[] = [
        { label: t('clinical_history.complete'), value: true },
        { label: t('clinical_history.incomplete'), value: false },
    ];

    const diagnosisValues = diseases?.map((el) => ({
        label: options.find((option) => option.key === el.diagnosisKey)?.description,
        value: el.diagnosisKey,
    }));

    const diagnosisOptions = options
        .map((option) => ({
            label: option.description,
            value: option,
            deletedAt: option.deletedAt,
        }))
        .filter((el) => !diagnosisValues?.some((value) => value.value === el.value.key));

    return (
        <div className="clinical-history-details--body">
            {children}
            <Divider />
            <div className="clinical-history-details--body-content">
                {clinicalHistoryDocument != null && (
                    <div className={'clinical-history-details--body-content--viewer'}>
                        <PreviewFile url={clinicalHistoryDocument.location!} />
                    </div>
                )}
                <div className={'clinical-history-details--body-content--information'}>
                    <div className="clinical-history-details--body-content--information--disease-list">
                        {diagnosisValues?.map((el) => (
                            <div
                                key={el.value}
                                className="clinical-history-details--body-content--information--disease-list-row"
                            >
                                <Input
                                    name="disease"
                                    value={el.label}
                                    label={t('clinical_history.validation.preexistent_disease_label')}
                                    disabled={true}
                                />
                                {!readOnly && (
                                    <Button
                                        onClick={() => handleDiseaseDeleted(el.value)}
                                        variantName={ButtonVariant.DANGER}
                                        icon={TrashIcon}
                                        accessibilityText={t('clinical_history')}
                                    ></Button>
                                )}
                            </div>
                        ))}

                        {!readOnly && newDiseaseToAdd && (
                            <div className="clinical-history-details--body-content--information--disease-list-row">
                                <Dropdown
                                    values={diagnosisOptions}
                                    selectedOption={undefined}
                                    handleOptionClicked={(option) => handleDiseaseAdded(option.value)}
                                    label={t('clinical_history.validation.preexistent_disease_label')}
                                    disabled={readOnly}
                                />
                            </div>
                        )}
                        <div className="clinical-history-details--body-content--information--disease-list-row">
                            <div className="addition-button">
                                <Button
                                    text={t('clinical_history.validation.addition_label')}
                                    onClick={() => handleDiseaseLineAddition()}
                                    variantName={ButtonVariant.SECONDARY}
                                    icon={MoreIcon}
                                    accessibilityText={t('clinical_history')}
                                ></Button>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <Dropdown
                        values={validationValues}
                        selectedOption={validationValues.find((it) => it.value == validationSelected)}
                        handleOptionClicked={(option) => handleValidationChange?.(option.value)}
                        label={t('clinical_history.validation.validation_label')}
                        disabled={readOnly}
                    />
                    <Divider />
                    <div className="clinical-history-details--body-content--information--note-container">
                        <NoteInput
                            name="note"
                            value={noteValue}
                            onValueChange={handleNoteChange}
                            setTouched={handleNoteTouched}
                            label={
                                noteValue
                                    ? t('clinical_history.validation.note_label')
                                    : t('clinical_history.validation.no_note_label')
                            }
                            disabled={readOnly}
                        ></NoteInput>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClinicalHistoryForm;
