import { Contract } from './Contract';
import { ClaimRequestStats, PolicyStatus } from '../values';

export class Policy {
    contracts: Contract[];

    constructor(
        public readonly productType: string,
        public readonly status: PolicyStatus,
        public readonly identifier: string,
        public readonly startDate: Date,
        public readonly expirationDate: Date,
        public readonly claimRequestStats: ClaimRequestStats,
        contracts: Contract[]
    ) {
        this.contracts = contracts.sort((a, b) => (b.expirationDate < a.startDate ? -1 : 1));
    }

    get claimableContracts(): Contract[] {
        return this.contracts.filter((contract) => ['active', 'closed'].includes(contract.status.toLowerCase()));
    }

    getActiveContracts(contractIdsActiveOnClaimRequest: string[]): Contract[] {
        return this.claimableContracts.filter((contract) => {
            return Array.isArray(contractIdsActiveOnClaimRequest) && contractIdsActiveOnClaimRequest.includes(contract.identifier);
        });    }
}
