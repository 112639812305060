import { flattenEntity, OperationMethod, processFetchOperation, SingleEntityResponse } from '@barkibu/noma-commons';
import { IncidentClaimRequestDto } from './dtos/IncidentClaimRequestDto';
import { IncidentDto } from './dtos/IncidentDto';
import { toDomain as incidentClaimRequestToDomain } from './IncidentClaimRequestMapper';
import { toDomain } from './IncidentMapper';
import { Incident } from '../../../domain/entities/Incident';
import { IncidentClaimRequest } from '../../../domain/entities/IncidentClaimRequest';
import { IncidentRepository } from '../../../domain/repositories/IncidentRepository';

export class IncidentNomaRepository implements IncidentRepository {
    find(incidentKey: string): Promise<Incident> {
        return processFetchOperation(`/v1/incidents/${incidentKey}`, OperationMethod.GET, {
            params: { include: 'supportingDocuments' },
        }).then((dto: SingleEntityResponse<IncidentDto, 'supportingDocuments'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    findIncidentClaimRequest(claimRequestKey: string): Promise<IncidentClaimRequest> {
        return processFetchOperation(`/v1/claim-requests/${claimRequestKey}/incident`, OperationMethod.GET, {
            params: { include: 'incident,supportingDocuments,userReservation' },
        }).then(
            (
                dto: SingleEntityResponse<
                    IncidentClaimRequestDto,
                    'incident' | 'supportingDocuments' | 'userReservation'
                >
            ) => {
                // @ts-ignore
                const entity = flattenEntity(dto.data, dto.included || []);
                return incidentClaimRequestToDomain(entity);
            }
        );
    }

    completeDataEntry(incidentKey: string, releaseClaimRequest: boolean): Promise<Incident> {
        return processFetchOperation(`/v1/incidents/${incidentKey}/complete`, OperationMethod.PUT, {
            params: { releaseClaimRequest },
        });
    }
}
