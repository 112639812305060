import { ReactNode } from 'react';
import { Button, ButtonVariant, Modal } from '../../atoms';
import './dialog.modules.scss';

export interface DialogProps {
    isOpen: boolean;
    title?: string;
    text: string;
    details?: string[];
    actions: { text: string; variantName: ButtonVariant; onClick: () => void; disabled?: boolean }[];
    onClose: () => void;
    type?: DialogType;
    showCloseButton?: boolean;
    children?: ReactNode;
    className?: string;
}

export enum DialogType {
    Normal = 'normal',
    Confirmation = 'confirmation',
}

const Dialog = ({
    isOpen,
    text,
    details,
    actions,
    onClose,
    title,
    type = DialogType.Normal,
    showCloseButton = false,
    children,
    className,
}: DialogProps) =>
    isOpen ? (
        <Modal
            isOpen={isOpen}
            className={`dialog dialog-${type} ${className}`}
            onClose={onClose}
            showCloseButton={showCloseButton}
        >
            {title && <h3>{title}</h3>}
            <p>{text}</p>
            {details && (
                <details>
                    <summary>Ver detalle</summary>
                    <ul>
                        {details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                </details>
            )}
            {children != null && <section className="dialog--body">{children}</section>}
            <div className="dialog--footer">
                {actions.map((action, index) => {
                    return (
                        <Button
                            key={index}
                            text={action.text}
                            variantName={action.variantName}
                            onClick={() => action.onClick()}
                            disabled={action.disabled}
                        />
                    );
                })}
            </div>
        </Modal>
    ) : null;

export default Dialog;
