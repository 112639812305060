import { useEffect, useMemo, useState } from 'react';
import {
    Button,
    ButtonVariant,
    Card,
    Chip,
    ChipType,
    Divider,
    MoreIcon,
    StructuredContent,
    SupportingDocumentDeletionReason,
    SupportingDocumentTranscriptNomaRepository,
    SupportingDocumentType,
    useTranscript as useTranscriptWithRepository,
} from '@barkibu/noma-commons';
import {
    EditableSupportingDocument,
    SupportingDocument,
    SupportingDocumentToModify,
} from '../../../../domain/entities/supporting-document';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import AddDocumentsModal from '../add-documents-modal/AddDocumentsModal';
import DetailDocumentModal from '../detail-document-modal/DetailDocumentModal';
import SupportingDocumentsTable from '../supporting-documents-table/SupportingDocumentsTable';
import UpdateDocumentModal from '../update-document-modal/UpdateDocumentModal';
import './supporting-documents.modules.scss';

export type SupportingDocumentsProps = {
    documents: SupportingDocument[];
    onChange?: (documents: SupportingDocument[]) => void;
    onCreateDocument?: (document: SupportingDocument) => void;
    onUpdateDocument?: (document: SupportingDocument) => void;
    onDeleteDocument?: (document: SupportingDocument, deletionReason: SupportingDocumentDeletionReason) => void;
    onUpdateDocumentIsNewlyAddedByParent?: (document: SupportingDocument) => void;
    onMarkAsMissingInfo?: (supportingDocumentTypes: SupportingDocumentType[]) => void;
    title?: string | null;
    showFooter?: boolean;
    allowEdit?: boolean;
};

type SupportingDocumentsChipsProps = {
    chips: { text: string }[];
};

const SupportingDocumentsChips = ({ chips }: SupportingDocumentsChipsProps) => {
    return (
        <div className="supporting-documents__chips">
            {chips.map((chip, index) => (
                <Chip key={index} type={ChipType.WARNING} text={chip.text} />
            ))}
        </div>
    );
};

const SupportingDocuments = ({
    documents,
    onChange,
    onCreateDocument,
    onUpdateDocument,
    onDeleteDocument,
    onMarkAsMissingInfo,
    onUpdateDocumentIsNewlyAddedByParent,
    showFooter = true,
    allowEdit = true,
    title,
}: SupportingDocumentsProps) => {
    const { t } = useModuleTranslation();
    const useTranscript = useMemo(() => useTranscriptWithRepository(SupportingDocumentTranscriptNomaRepository), []);
    const { findTranscription } = useTranscript();
    const [showAddDocumentsModal, setShowAddDocumentsModal] = useState(false);
    const [showUpdateDocumentModal, setShowUpdateDocumentModal] = useState(false);

    const [documentToDetail, setDocumentToDetail] = useState<SupportingDocument | undefined>();
    const [documentToEdit, setDocumentToEdit] = useState<SupportingDocument | undefined>();
    const [documentsDetail, setDocumentsDetail] = useState<Record<string, StructuredContent>>({});

    useEffect(() => {
        documents.forEach((document) => {
            if (document.transcriptKey) {
                findTranscription(document.transcriptKey).then((transcription) => {
                    if (transcription) {
                        setDocumentsDetail((prevDetails) => ({
                            ...prevDetails,
                            [document.transcriptKey as string]: transcription?.structuredContent as StructuredContent,
                        }));
                    }
                });
            }
        });
    }, [documents]);

    const titleText = title ?? t('supporting_documents.title');

    const openAddDocumentsModal = () => setShowAddDocumentsModal(true);
    const openUpdateDocumentModal = () => setShowUpdateDocumentModal(true);

    const onEdit = (document: SupportingDocument) => {
        setDocumentToEdit(document);
        openUpdateDocumentModal();
    };

    const closeAddDocumentsModal = () => {
        setDocumentToEdit(undefined);
        setShowAddDocumentsModal(false);
    };

    const closeUpdateDocumentModal = () => {
        setDocumentToEdit(undefined);
        setShowUpdateDocumentModal(false);
    };

    const onDelete = (document: SupportingDocument, deletionReason: SupportingDocumentDeletionReason) => {
        if (onDeleteDocument) onDeleteDocument(document, deletionReason);
        let newDocuments = [...documents];
        newDocuments = documents.map((doc) => {
            if (doc.key === document.key) {
                return new SupportingDocument({
                    key: doc.key,
                    type: doc.type,
                    location: doc.location,
                    valid: doc.valid,
                    details: doc.details,
                    name: doc.name,
                    date: doc.date,
                    deleted: true,
                });
            }
            return doc;
        });

        onChange?.(newDocuments);
    };

    const syncDocuments = (editedDocuments: EditableSupportingDocument[]) => {
        let newDocuments = [...documents];
        editedDocuments.forEach((editedDoc) => {
            if (editedDoc.toModify) {
                newDocuments = documents.map((doc) => {
                    if (doc.key == (editedDoc as SupportingDocumentToModify).document.key) {
                        const supportingDocument = new SupportingDocument({
                            key: doc.key,
                            type: editedDoc.type,
                            name: editedDoc.name,
                            location: doc.location,
                        });
                        if (onUpdateDocument) onUpdateDocument(supportingDocument);
                        return supportingDocument;
                    }
                    return doc;
                });
            }
            if (editedDoc.toUpload) {
                if (onCreateDocument) onCreateDocument(editedDoc);
                newDocuments = documents.filter((document) => document.location != editedDoc.location);

                newDocuments.push(
                    new SupportingDocument({
                        type: editedDoc.type,
                        name: editedDoc.name,
                        location: editedDoc.location,
                    })
                );
            }
        });
        onChange?.(newDocuments);
        closeAddDocumentsModal();
        closeUpdateDocumentModal();
    };

    const nonDeletedDocuments = documents.filter((doc) => !doc.deleted);

    const missingDocumentTypes = Object.values(SupportingDocumentType).filter(
        (type) =>
            (type == SupportingDocumentType.CLINICAL_HISTORY ||
                type == SupportingDocumentType.INVOICE ||
                type == SupportingDocumentType.VET_REPORT) &&
            !nonDeletedDocuments.some((doc) => doc.type === type)
    );
    const missingDocumentChips = missingDocumentTypes.map((type) => ({
        text: t('incident.document.missing', { type: t(`document.type.${type}`) }),
    }));

    const handleOpenDetailDocument = (doc: SupportingDocument) => {
        setDocumentToDetail(doc);
    };
    return (
        <>
            <Card title={titleText} className="card--incident-documents">
                <SupportingDocumentsTable
                    supportingDocuments={nonDeletedDocuments}
                    onEditClick={allowEdit ? onEdit : undefined}
                    onDocumentDetail={(doc) => handleOpenDetailDocument(doc)}
                    onUpdateDocumentIsNewlyAddedByParent={onUpdateDocumentIsNewlyAddedByParent}
                />

                {showFooter && allowEdit && (
                    <div className="supporting-documents__footer">
                        {missingDocumentChips.length > 0 && <SupportingDocumentsChips chips={missingDocumentChips} />}
                        <Divider />
                        <Button
                            text={t('incident.document.add')}
                            variantName={ButtonVariant.SECONDARY}
                            icon={MoreIcon}
                            onClick={openAddDocumentsModal}
                        />
                    </div>
                )}
            </Card>
            {showFooter && allowEdit && (
                <AddDocumentsModal
                    isOpen={showAddDocumentsModal}
                    document={documentToEdit}
                    onClose={closeAddDocumentsModal}
                    onSave={syncDocuments}
                />
            )}
            <DetailDocumentModal
                isOpen={documentToDetail != undefined}
                documentDetail={
                    documentToDetail && documentToDetail.transcriptKey
                        ? documentsDetail[documentToDetail.transcriptKey]
                        : undefined
                }
                onClose={() => setDocumentToDetail(undefined)}
                onMarkAsMissingInfo={
                    onMarkAsMissingInfo != null
                        ? () => {
                              onMarkAsMissingInfo(
                                  documentToDetail && documentToDetail.type ? [documentToDetail.type] : []
                              );
                              setDocumentToDetail(undefined);
                          }
                        : undefined
                }
            />

            {allowEdit && (
                <UpdateDocumentModal
                    isOpen={showUpdateDocumentModal}
                    document={documentToEdit}
                    onClose={closeUpdateDocumentModal}
                    onSave={syncDocuments}
                    onRemove={(deletionReason: SupportingDocumentDeletionReason) => {
                        onDelete(documentToEdit!, deletionReason);
                        closeUpdateDocumentModal();
                    }}
                />
            )}
        </>
    );
};

export default SupportingDocuments;
