export enum CountryCode {
    ES = 'ES',
    DE = 'DE',
    FR = 'FR',
}

export const countryCodeOptionValues = () => {
    return Object.values(CountryCode);
};

export const defaultCountryCode = CountryCode.ES;

export const countryCodeValueToEnum = (value: string) => {
    return CountryCode[value as keyof typeof CountryCode];
};
