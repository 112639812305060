import { DiagnosisDto } from './DiagnosisDto';
import { Diagnosis } from '../../../domain/entities/Diagnosis';

export const toDomain = (diagnosisDto: DiagnosisDto): Diagnosis => {
    return {
        key: diagnosisDto.key,
        description: diagnosisDto.descriptor,
        deletedAt: diagnosisDto.deletedAt ? new Date(diagnosisDto.deletedAt) : undefined,
    };
};
