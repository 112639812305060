import { useEffect, useState } from 'react';
import { ClaimRequestStatusNomaRepository, SupportingDocumentDeletionReason } from '@barkibu/noma-commons';
import { FindIncidentsByPolicyIdentifier } from '../../../application/FindIncidentsByPolicyIdentifier/FindIncidentsByPolicyIdentifier';
import { RequestSummary } from '../../../domain/entities/RequestSummary';
import { ClaimInfoNomaRepository } from '../../../infraestructure/repositories/claiminfo/ClaimInfoNomaRepository';
import { UpdateSupportingDocumentDto } from '../../../infraestructure/repositories/incident/dtos/UpdateSupportingDocumentDto';
import { UpdateSupportingDocumentIsNewlyAddedByParentDto } from '../../../infraestructure/repositories/incident/dtos/UpdateSupportingDocumentIsNewlyAddedByParentDto';
import { SupportingDocumentNomaRepository } from '../../../infraestructure/repositories/supporting_document/SupportingDocumentRepository';

const findIncidentsByPolicyIdentifier = new FindIncidentsByPolicyIdentifier(new ClaimInfoNomaRepository());
const supportingDocumentRepository = new SupportingDocumentNomaRepository();
const claimRequestStatusRepository = new ClaimRequestStatusNomaRepository();

export const usePolicyIncidents = (policyIdentifier: string) => {
    const [requestSummaries, setRequestSummaries] = useState<RequestSummary[] | undefined>(undefined);

    useEffect(() => {
        findIncidentsByPolicyIdentifier.execute(policyIdentifier).then(setRequestSummaries);
    }, [policyIdentifier]);

    const removeDocument = async (documentKey: string, deletionReason: SupportingDocumentDeletionReason) => {
        await supportingDocumentRepository.delete(documentKey, deletionReason);
        setRequestSummaries(
            (incidents) =>
                incidents?.map((incident) => ({
                    ...incident,
                    supportingDocuments: incident.supportingDocuments?.filter((el) => el.key !== documentKey),
                }))
        );
    };
    const updateDocument = async (document: UpdateSupportingDocumentDto) => {
        const editedDocument = await supportingDocumentRepository.update(document.key!, {
            type: document.type!,
            name: document.name,
            location: document.location!,
        });
        setRequestSummaries(
            (incidents) =>
                incidents?.map((incident) => ({
                    ...incident,
                    supportingDocuments: incident.supportingDocuments?.map((document) =>
                        document.key !== editedDocument.key ? document : editedDocument,
                    ),
                })),
        );
    };

    const updateDocumentIsNewlyAddedByParent = async (document: UpdateSupportingDocumentIsNewlyAddedByParentDto) => {
        const editedDocument = await supportingDocumentRepository.updateIsNewlyAddedByParent(document.key!, {
            isNewlyAddedByParent: document.isNewlyAddedByParent,
        });
        setRequestSummaries(
            (incidents) =>
                incidents?.map((incident) => ({
                    ...incident,
                    supportingDocuments: incident.supportingDocuments?.map((document) =>
                        document.key !== editedDocument.key ? document : editedDocument,
                    ),
                })),
        );
    };

    const markClaimRequestAsManuallyClosed = async (claimRequestKey: string) => {
        await claimRequestStatusRepository.markStatusAsManuallyClosed(claimRequestKey);
    };

    return {
        requestSummaries,
        removeDocument,
        updateDocument,
        updateDocumentIsNewlyAddedByParent,
        markClaimRequestAsManuallyClosed,
    };
};
