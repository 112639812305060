import { UseCase } from '@barkibu/noma-commons';
import { ClaimRequestSummary, ClaimRequestSummaryRepository } from '../../../domain/ClaimRequestSummary';

export class FindClaimRequestSummary implements UseCase<ClaimRequestSummary> {
    private claimRequestSummaryRepository: ClaimRequestSummaryRepository;

    constructor(claimRequestSummaryRepository: ClaimRequestSummaryRepository) {
        this.claimRequestSummaryRepository = claimRequestSummaryRepository;
    }

    async execute(claimRequestKey: string): Promise<ClaimRequestSummary> {
        return this.claimRequestSummaryRepository.findClaimRequestSummary(claimRequestKey);
    }
}
