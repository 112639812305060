import { ApiError, MultiApiError, ServerError } from '../../infraestructure';

export const getErrorDetails = (e: Error): { details: string[] } | undefined => {
    let details: string[] | undefined = undefined;
    if (e instanceof ApiError && e.details) {
        details = [e.details];
    } else if (e instanceof MultiApiError) {
        details = e.apiErrors.reduce((acc: string[], curr, _) => {
            const erroredProperty = curr.source.get('pointer') || curr.source.get('parameter');
            const currentError: string | undefined =
                curr.title && `${erroredProperty} ${downcaseFirstLetter(curr.title)}`;
            if (!currentError) {
                return acc;
            } else {
                return [...acc, currentError];
            }
        }, []);
    } else if (e instanceof ServerError) {
        details = [e.message];
    }

    if (!details) {
        return;
    }

    return { details };
};

function downcaseFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
