import { ClaimRequest } from './ClaimRequest';
import { ClaimRequestType } from './ClaimRequestType';

export class ClaimRequestDetails {
    claimRequestKey?: ClaimRequest['key'];
    causeOfLoss?: ClaimRequest['causeOfLoss'];
    dateOfLoss?: ClaimRequest['originalDateOfLoss'];
    iban?: ClaimRequest['iban'];
    email?: ClaimRequest['email'];
    type: ClaimRequestType;
    assessmentId?: ClaimRequest['assessmentId'];
    userReservation?: ClaimRequest['userReservation'];
    policyIdentifier?: ClaimRequest['policyIdentifier'];
    prepaidExpenseIds?: ClaimRequest['prepaidExpenseIds'];


    constructor(claimRequest?: ClaimRequest) {
        this.claimRequestKey = claimRequest?.key;
        this.causeOfLoss = claimRequest?.causeOfLoss;
        this.dateOfLoss = claimRequest?.originalDateOfLoss;
        this.iban = claimRequest?.iban;
        this.email = claimRequest?.email;
        this.type = claimRequest?.causeOfLoss != null ? ClaimRequestType.HEALTH : ClaimRequestType.PREVENTION;
        this.assessmentId = claimRequest?.assessmentId;
        this.userReservation = claimRequest?.userReservation;
        this.policyIdentifier = claimRequest?.policyIdentifier;
        this.prepaidExpenseIds =claimRequest?.prepaidExpenseIds;
    }

    clone(newData: Partial<ClaimRequestDetails>): ClaimRequestDetails {
        return Object.assign(new ClaimRequestDetails(), this, { ...newData });
    }

    isPrevention(): boolean {
        return this.type === ClaimRequestType.PREVENTION;
    }

    isPrepaid(): boolean {
        return !!this.prepaidExpenseIds && this.prepaidExpenseIds?.length > 0;
    }
}
