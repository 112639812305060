import { FraudAlertDto } from './dtos/FraudAlertDto';
import { toDomain } from './FraudAlertMapper';
import { FraudAlert } from '../../domain/entities';
import { FraudAlertsRepository } from '../../domain/repositories';
import { CollectionEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '../api';

export class FraudAlertsNomaRepository implements FraudAlertsRepository {
    find(claimRequestKey: string): Promise<FraudAlert[]> {
        return processFetchOperation(
            `/v1/claim-requests/${claimRequestKey}/fraud-alerts`,
            OperationMethod.GET,
            {}
        ).then((dto: CollectionEntityResponse<FraudAlertDto>) => {
            return dto.data.map((el) => toDomain(flattenEntity(el, [])));
        });
    }
}
