import { Policy } from '../../../domain/entities/index';
import { CalendarIcon, RefreshIcon } from '../../atoms/index';
import Modal from '../../atoms/modal/Modal';
import Attribute from '../../molecules/attribute/Attribute';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import { PolicyChip } from '../policy-card/PolicyChip';
import './PetPoliciesModal.modules.scss';

export type PetPoliciesModalProps = {
    isOpen?: boolean;
    onClose?: () => void;
    name: string;
    policies: Policy[];
    onPolicyClick?: (policy: Policy) => void;
};

const dateFormatter = (date: Date) =>
    Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);

type PetPolicyCardProps = {
    policy: Policy;
    onClick?: (policy: Policy) => void;
};

const PetPolicyCard = ({ policy, onClick }: PetPolicyCardProps) => {
    const { t } = useModuleTranslation();

    const startDateFormatted = dateFormatter(policy.startDate);
    const expirationDateFormatted = dateFormatter(policy.expirationDate);

    const policyStatusChip = <PolicyChip status={policy.status} />;

    return (
        <div
            className={`pet-policies-modal-body__policy ${onClick != null ? 'selectable' : ''}`}
            onClick={() => onClick?.(policy)}
        >
            <header className="pet-policies-modal-body__policy-header">
                <section className="pet-policies-modal-body__policy-header-info">
                    <h4 className="pet-policies-modal-body__policy-header-info-title">{t(policy.identifier)}</h4>
                    <p className="pet-policies-modal-body__policy-header-info-subtitle">
                        {t(
                            `policy.${policy.productType.replace(/(.*)_\w{2}$/, '$1').toLowerCase()}.name`,
                            policy.productType
                        )}
                    </p>
                </section>

                {policyStatusChip}
            </header>
            <div className="pet-policies-modal-body__policy-body">
                <Attribute
                    className="pet-policies-modal-body__policy-start-date"
                    icon={CalendarIcon}
                    description={t('policy.started_on', { startDate: startDateFormatted })}
                />
                <Attribute
                    className="pet-policies-modal-body__policy-expiration-date"
                    icon={RefreshIcon}
                    description={t('policy.expires_on', { expirationDate: expirationDateFormatted })}
                />
            </div>
        </div>
    );
};

const PetPoliciesModal = ({ isOpen = true, onClose, name, policies, onPolicyClick }: PetPoliciesModalProps) => {
    const { t } = useModuleTranslation();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('pet.policies.modal.title', { name })}
            className="pet-policies-modal"
        >
            <section className="pet-policies-modal-body">
                {policies.map((policy) => (
                    <PetPolicyCard key={policy.identifier} policy={policy} onClick={onPolicyClick} />
                ))}
            </section>
        </Modal>
    );
};

export default PetPoliciesModal;
