import { CollectionEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '@barkibu/noma-commons';
import { toDomain } from './ClaimInfoMapper';
import { ClaimInfoDto } from './dtos/ClaimInfoDto';
import { ClaimInfo } from '../../../domain/entities/ClaimInfo';
import { ClaimInfoRepository } from '../../../domain/repositories/ClaimInfoRepository';

export class ClaimInfoNomaRepository implements ClaimInfoRepository {
    findAllByCriteria(criteria: { policyIdentifier: string }): Promise<ClaimInfo[]> {
        return processFetchOperation('/v1/claim-informations', OperationMethod.GET, {
            params: { ...criteria, include: 'supportingDocuments,userReservation,paymentLetters' },
        }).then((dto: CollectionEntityResponse<ClaimInfoDto>) => {
            const entities = dto.data.map((it) => {
                return flattenEntity(it, dto.included || []);
            });
            return entities.map((it) => toDomain(it));
        });
    }
}
