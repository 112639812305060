import { TranscriptDto } from './TranscriptDto';
import { VetReportStructuredContentDto } from './VetReportStructuredContentDto';
import { Transcript } from '../../../domain/entities';
import { StructuredContent } from '../../../domain/entities/Transcript/StructuredContent';
import { VetReportStructuredContent } from '../../../domain/entities/Transcript/VetReportStructuredContent';
import { SupportingDocumentType } from '../../../domain/values';

export const toDomain = (el: TranscriptDto): Transcript => {
    let structuredContent: StructuredContent | undefined;

    if (el.type === SupportingDocumentType.VET_REPORT && el.structuredContent) {
        structuredContent = toVetReportStructuredContent(el.structuredContent as VetReportStructuredContentDto);
    }

    return {
        key: el.key,
        type: el.type,
        structuredContent: structuredContent,
    };
};

const toVetReportStructuredContent = (dto: VetReportStructuredContentDto): VetReportStructuredContent => ({
    presentSymptoms: dto.presentSymptoms,
    diagnosticTestsPerformed: dto.diagnosticTestsPerformed,
    diagnoses: dto.diagnoses,
    treatments: dto.treatments,
    score: dto.score,
    isValid: dto.score >= 80,
    visitReason: dto.visitReason,
    dateOnsetSymptoms: dto.dateOnsetSymptoms ? new Date(dto.dateOnsetSymptoms) : undefined,
    reviewDate: dto.reviewDate ? new Date(dto.reviewDate) : undefined,
});
