import { useState } from 'react';
import { useAssignClaimRequest } from './useAssignClaimRequest';
import { useCountPendingClaimRequests } from './useCountPendingClaimRequests';
import { useCreateIncident } from './useCreateIncident';
import { ClaimRequestStatus, CountryCode } from '../../../domain/values';
import {
    countryCodeOptionValues,
    countryCodeValueToEnum,
    defaultCountryCode,
} from '../../../domain/values/CountryCode';
import { CreateIncidentDto } from '../../../infraestructure/repositories/dtos/CreateIncidentDto';
import { useHref } from '../../hooks';
import { useCountryPreferences } from '../../hooks/useCountryPreferences';
import Inbox from '../../organisms/inbox/Inbox';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './inbox-page.modules.scss';
import { Button, ButtonVariant } from '../../atoms/button/Button';
import { ClaimRequestType } from '../../../domain/entities';
import { useAssignInspection } from './useAssignInspection';
import { AutoApprovalServices } from '../../../domain/values/AutoApprovalServices';
import { useGetInspectionStatistics } from './useGetInspectionStatistics';
import { Modal } from '../../../ui/atoms';

const TAB_SELECTED = 'inbox_tab_selected';
const CountryCodeOptions = countryCodeOptionValues();
const ClaimTypeOptions = Object.values(ClaimRequestType);

const daySelectorOptions = [
    { label: '1 day', value: 1 },
    { label: '2 days', value: 2 },
    { label: '3 days', value: 3 },
    { label: '5 days', value: 5 },
    { label: '7 days', value: 7, selected: true },
    { label: '30 days', value: 30 },
];
const defaultSelectedDays = daySelectorOptions.find((it) => it.selected);

const InboxPage = () => {
    const { t } = useModuleTranslation();
    const { toInspection, toDataEntry, toVetView, toCoverages, toOnHold, toDocumentLibrary } = useHref();
    const { getCountryCodeLocalStorage, setCountryCodeLocalStorage } = useCountryPreferences();
    const initialiseCountryCode = (): string => {
        const initialValue = getCountryCodeLocalStorage() || defaultCountryCode;
        setCountryCodeLocalStorage(initialValue);
        return initialValue;
    };
    const claimManagementTab = t('inbox_page.page_title');
    const qualityControlTab = t('inbox_page.quality_control.title');
    const [countryCode, setCountryCode] = useState<string>(initialiseCountryCode());
    const [claimType, setClaimType] = useState<string>(ClaimRequestType.HEALTH);
    const [interceptableClaimsOnly, setInterceptableClaimsOnly] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>(localStorage.getItem(TAB_SELECTED) || claimManagementTab);
    const [filterDays, setFilterDays] = useState(defaultSelectedDays ? defaultSelectedDays.value : 7);
    const [inspectionAssignationError, setInspectionAssignationError] = useState<string>('');
    const numberOfPendingClaimRequest = useCountPendingClaimRequests(countryCode);
    const {
        assignNewAssessment,
        assignNewIncident,
        assignReadyToReviewIncident,
        assignNewFastClaim,
        assignNewPreventionClaim,
        assignPreventionClaimWithNewDocumentation,
    } = useAssignClaimRequest();
    const { assignNextInspectionLower, assignNextInspectionUpper, assignNextInspectionWithService } =
        useAssignInspection();
    const inspectionStatistic = useGetInspectionStatistics(claimType, countryCode, filterDays, interceptableClaimsOnly);
    const { newIncident } = useCreateIncident();
    const claimStatusFilter = [
        {
            label: t('inbox_page.quality_control.automated_claims'),
            selected: !interceptableClaimsOnly,
        },
        {
            label: t('inbox_page.quality_control.delayed_automated_claims'),
            selected: interceptableClaimsOnly,
        },
    ];
    const handleAssignNewAssessment = async () => {
        const claimRequestKey = await assignNewAssessment(countryCode);
        toVetView(claimRequestKey);
    };
    const handleAssignNewIncident = async () => {
        const claimRequestKey = await assignNewIncident(countryCode);
        toDataEntry(claimRequestKey);
    };
    const handleAssignReadyToReviewIncident = async () => {
        const claimRequestKey = await assignReadyToReviewIncident(countryCode);
        toDataEntry(claimRequestKey);
    };
    const handleAssignNewFastClaim = async () => {
        const claimRequestKey = await assignNewFastClaim(countryCode);
        toDataEntry(claimRequestKey);
    };

    const handleCountryCodeSelector = (value: string) => {
        setCountryCode(value);
        setCountryCodeLocalStorage(countryCodeValueToEnum(value)!);
    };

    const handleCreateNewIncident = async (policyNumber: string) => {
        const toCreate: CreateIncidentDto = {
            policyIdentifier: policyNumber,
            description: '...',
            reserve: true,
        };
        const claimRequestKey = await newIncident(toCreate);
        toDataEntry(claimRequestKey);
    };

    const handleSearchIncident = (policyNumber: string) => {
        toDocumentLibrary(policyNumber);
    };

    const handleAssignNewPreventionClaim = async () => {
        const claimRequest = await assignNewPreventionClaim(countryCode);
        if (claimRequest.status == ClaimRequestStatus.READY_FOR_VET_ANALYSIS) {
            history.pushState('', '', `/vetview/incidents/${claimRequest.key}`);
            toCoverages(claimRequest.key);
        } else toDataEntry(claimRequest.key);
    };

    const handleAssignPreventionClaimWithNewDocumentation = async () => {
        const claimRequestKey = await assignPreventionClaimWithNewDocumentation(countryCode);
        toDataEntry(claimRequestKey);
    };

    const handleAssignNextClaimToInspect = async (assignmentFunction: () => Promise<any>, service?: string) => {
        try {
            let claimRequest;
            if (service) {
                claimRequest = await assignNextInspectionWithService(
                    claimType,
                    countryCode,
                    filterDays,
                    service,
                    interceptableClaimsOnly
                );
            } else {
                claimRequest = await assignmentFunction();
            }
            toInspection(claimRequest.key);
        } catch (error) {
            setInspectionAssignationError(t('inbox_page.inspection_assignation_error'));
        }
    };

    const handleAssignNextClaimToInspectByLowerThreshold = () =>
        handleAssignNextClaimToInspect(() =>
            assignNextInspectionLower(claimType, countryCode, filterDays, interceptableClaimsOnly)
        );

    const handleAssignNextClaimToInspectByUpperThreshold = () =>
        handleAssignNextClaimToInspect(() =>
            assignNextInspectionUpper(claimType, countryCode, filterDays, interceptableClaimsOnly)
        );

    const handleAssignNextClaimToInspectByService = (service: string) =>
        handleAssignNextClaimToInspect(() => Promise.resolve(), service);

    const onErrorModalClose = () => {
        setInspectionAssignationError('');
    };

    const handleTabSelection = (tab: string) => {
        localStorage.setItem(TAB_SELECTED, tab);
        setSelectedTab(tab);
    };

    const claimManagementItems = [
        {
            title: t('inbox_page.new_incident'),
            count: numberOfPendingClaimRequest.numberOfNewIncidents,
            reservedCount: numberOfPendingClaimRequest.numberOfNewIncidentsReserved,
            onClick: handleAssignNewIncident,
            disabled: numberOfPendingClaimRequest.numberOfNewIncidents <= 0,
        },
        {
            title: t('inbox_page.fast_claim'),
            count: numberOfPendingClaimRequest.numberOfFastClaims,
            reservedCount: numberOfPendingClaimRequest.numberOfFastClaimsReserved,
            onClick: handleAssignNewFastClaim,
            disabled: numberOfPendingClaimRequest.numberOfFastClaims <= 0,
        },
        {
            title: t('inbox_page.new_document'),
            count: numberOfPendingClaimRequest.numberOfReadyToReviewIncidents,
            reservedCount: numberOfPendingClaimRequest.numberOfReadyToReviewIncidentsReserved,
            onClick: handleAssignReadyToReviewIncident,
            disabled: numberOfPendingClaimRequest.numberOfReadyToReviewIncidents <= 0,
        },
        {
            title: t('inbox_page.vet_assessment'),
            count: numberOfPendingClaimRequest.numberOfNewAssessments,
            reservedCount: numberOfPendingClaimRequest.numberOfNewAssessmentsReserved,
            onClick: handleAssignNewAssessment,
            disabled: numberOfPendingClaimRequest.numberOfNewAssessments <= 0,
        },
        {
            title: t('inbox_page.new_prevention'),
            count: numberOfPendingClaimRequest.numberOfNewPreventionClaims,
            reservedCount: numberOfPendingClaimRequest.numberOfNewPreventionClaimsReserved,
            onClick: handleAssignNewPreventionClaim,
            disabled: numberOfPendingClaimRequest.numberOfNewPreventionClaims <= 0,
        },
        {
            title: t('inbox_page.prevention_with_new_documentation'),
            count: numberOfPendingClaimRequest.numberOfPreventionClaimsWithNewDocumentation,
            reservedCount: numberOfPendingClaimRequest.numberOfPreventionClaimsWithNewDocumentationReserved,
            onClick: handleAssignPreventionClaimWithNewDocumentation,
            disabled: numberOfPendingClaimRequest.numberOfPreventionClaimsWithNewDocumentation <= 0,
        },
        {
            title: t('inbox_page.on_hold'),
            count: numberOfPendingClaimRequest.numberOfOnHoldClaimRequest,
            actionName: t('inbox_page.see_list'),
            onClick: () => toOnHold(countryCode),
            disabled: numberOfPendingClaimRequest.numberOfOnHoldClaimRequest <= 0,
        },
    ];

    const inPercentage = !interceptableClaimsOnly;

    const qualityControlItemsPrevention = [
        {
            title: t('inbox_page.quality_control.auto_partial_approval'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.autoPartialApproval,
            suffixCount: inPercentage && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.AUTO_PARTIAL_APPROVAL);
            },
            disabled: inPercentage
                ? inspectionStatistic.autoPartialApproval >= 100
                : inspectionStatistic.autoPartialApproval <= 0,
        },
        {
            title: t('inbox_page.quality_control.high_cost'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.higherThreshold,
            suffixCount: !interceptableClaimsOnly && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByUpperThreshold();
            },
            disabled: inPercentage
                ? inspectionStatistic.higherThreshold >= 100
                : inspectionStatistic.higherThreshold <= 0,
        },
        {
            title: t('inbox_page.quality_control.dubious_line_accepted'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.dubiousLineAccepted,
            suffixCount: !interceptableClaimsOnly && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.DUBIOUS_LINE_ACCEPTED);
            },
            disabled: inPercentage
                ? inspectionStatistic.dubiousLineAccepted >= 100
                : inspectionStatistic.dubiousLineAccepted <= 0,
        },
        {
            title: t('inbox_page.quality_control.diagnoses_higher_threshold'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.higherThreshold,
            suffixCount: !interceptableClaimsOnly && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.AUTO_HIGHER_THRESHOLD_DIAGNOSES_APPROVAL);
            },
            disabled: inPercentage
                ? inspectionStatistic.higherThreshold >= 100
                : inspectionStatistic.higherThreshold <= 0,
        },
        {
            title: t('inbox_page.quality_control.approval_threshold_adjusted'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.autoInvoiceWithThreshold,
            suffixCount: !interceptableClaimsOnly && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.AUTO_INVOICE_WITH_THRESHOLD);
            },
            disabled: inPercentage
                ? inspectionStatistic.autoInvoiceWithThreshold >= 100
                : inspectionStatistic.autoInvoiceWithThreshold <= 0,
        },
        {
            title: t('inbox_page.quality_control.standard'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.lowerThreshold,
            suffixCount: inPercentage && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByLowerThreshold();
            },
            disabled: inPercentage
                ? inspectionStatistic.lowerThreshold >= 100
                : inspectionStatistic.lowerThreshold <= 0,
        },
    ];

    const qualityControlItemsHealth = [
        {
            title: t('inbox_page.quality_control.auto_denial'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.autoDenial,
            suffixCount: inPercentage && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.AUTO_DENIAL);
            },
            disabled: inPercentage ? inspectionStatistic.autoDenial >= 100 : inspectionStatistic.autoDenial <= 0,
        },
    ];

    const qualityControlItemsFrance = [
        {
            title: t('inbox_page.quality_control.auto_approval_french'),
            actionName: t('inbox_page.inspect'),
            count: inspectionStatistic.autoApprovalFrench,
            suffixCount: inPercentage && '%',
            onClick: () => {
                handleAssignNextClaimToInspectByService(AutoApprovalServices.AUTO_APPROVAL_FRENCH);
            },
            disabled: inPercentage
                ? inspectionStatistic.autoApprovalFrench >= 100
                : inspectionStatistic.autoApprovalFrench <= 0,
        },
    ];

    const qualityControlItems = () => {
        var qualityControlItems = qualityControlItemsPrevention;
        if (claimType == ClaimRequestType.HEALTH)
            qualityControlItems = [...qualityControlItemsHealth, ...qualityControlItems];
        if (countryCode == CountryCode.FR) qualityControlItems = [...qualityControlItemsFrance, ...qualityControlItems];

        return qualityControlItems;
    };

    return (
        <section className={'inbox-page-container'}>
            <div className={'inbox-tab'}>
                <Button
                    variantName={
                        selectedTab == claimManagementTab ? ButtonVariant.TABLINK_ACTIVE : ButtonVariant.TABLINK
                    }
                    onClick={() => handleTabSelection(claimManagementTab)}
                    text={claimManagementTab}
                ></Button>
                <Button
                    variantName={
                        selectedTab == qualityControlTab ? ButtonVariant.TABLINK_ACTIVE : ButtonVariant.TABLINK
                    }
                    onClick={() => handleTabSelection(qualityControlTab)}
                    text={qualityControlTab}
                ></Button>
            </div>
            {selectedTab == claimManagementTab ? (
                <Inbox
                    title={t('inbox_page.page_title')}
                    menuItems={claimManagementItems}
                    onAdd={handleCreateNewIncident}
                    onSearch={handleSearchIncident}
                    countryCode={countryCode}
                    onUpdateCountryCode={handleCountryCodeSelector}
                    countryCodeOptions={CountryCodeOptions}
                />
            ) : (
                <Inbox
                    title={t('inbox_page.quality_control.title')}
                    menuItems={qualityControlItems()}
                    countryCode={countryCode}
                    onUpdateCountryCode={handleCountryCodeSelector}
                    countryCodeOptions={CountryCodeOptions}
                    claimType={claimType}
                    claimTypeOptions={ClaimTypeOptions}
                    onUpdateClaimType={(value) => setClaimType(value)}
                    selectorOptions={daySelectorOptions}
                    onSelectorOption={(day) => setFilterDays(day)}
                    claimStatusFilter={claimStatusFilter}
                    onClaimStatusFilter={(value) =>
                        setInterceptableClaimsOnly(value == t('inbox_page.quality_control.delayed_automated_claims'))
                    }
                />
            )}
            <Modal isOpen={inspectionAssignationError?.length != 0} onClose={onErrorModalClose} showCloseButton>
                <p>{inspectionAssignationError}</p>
            </Modal>
        </section>
    );
};

export { InboxPage };
