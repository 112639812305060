import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { UseCase } from '../../domain/utils';

export class FindContractIdsActiveOnClaimRequest implements UseCase<String[]> {
    constructor(private claimRequestRepository: ClaimRequestRepository) {}

    async execute(claimRequestKey: string): Promise<String[]> {
        try {
            const result = await this.claimRequestRepository.findContractIdsActiveOnClaimRequest(
                claimRequestKey,
            );
            return result;
        } catch (e) {
            throw new Error('Contract Ids not found');
        }
    }
}