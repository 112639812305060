import { SupportingDocumentType } from '@barkibu/noma-commons';
import { CostLineDto } from './dtos/CostLineDto';
import { InvoiceDto } from './dtos/InvoiceDto';
import CostLine from '../../../domain/entities/CostLine';
import Invoice from '../../../domain/entities/Invoice';
import { SupportingDocument } from '../../../domain/entities/supporting-document';
import { DigitisationStatus } from '../../../domain/values/DigitisationStatus';

export const toDomain = (invoiceDto: InvoiceDto): Invoice => {
    return new Invoice({
        key: invoiceDto.key,
        date: invoiceDto.date != null ? new Date(invoiceDto.date) : undefined,
        number: invoiceDto.number,
        taxIdNumber: invoiceDto.taxIdNumber,
        providerName: invoiceDto.providerName,
        reimbursablePercentage: invoiceDto.reimbursablePercentage,
        discountInCents: invoiceDto.discountInCents,
        supportingDocument: new SupportingDocument({
            key: invoiceDto.supportingDocument.key,
            type: SupportingDocumentType[invoiceDto.supportingDocument.type as keyof typeof SupportingDocumentType],
            location: invoiceDto.supportingDocument.location,
            date: new Date(invoiceDto.supportingDocument.createdAt),
            deleted: false,
            isNewlyAddedByParent: invoiceDto.supportingDocument.newlyAddedByParent,
        }),
        costLines: invoiceDto.costLines.map(costLineDtoToDomain),
        digitisationStatus: DigitisationStatus[invoiceDto.digitisationStatus as keyof typeof DigitisationStatus],
    });
};

const costLineDtoToDomain = (costLineDto: CostLineDto): CostLine => {
    return new CostLine({
        key: costLineDto.key,
        concept: costLineDto.concept,
        units: costLineDto.units,
        VAT: costLineDto.vat,
        priceInCents: costLineDto.priceInCents,
    });
};
