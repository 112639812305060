import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import * as de from './locales/translations/de.json';
import * as en from './locales/translations/en.json';
import * as es from './locales/translations/es.json';
import * as fr from './locales/translations/fr.json';

const resources = {
    en,
    de,
    es,
    fr,
};

i18n.use(initReactI18next).init({
    ns: ['commons', 'dataentry', 'veterinaryanalysis', 'missing_info'],
    defaultNS: 'commons',
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
