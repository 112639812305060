import { NoteDto } from './dtos/NoteDto';
import { Note } from '../../domain/entities/note';
import { NoteScope } from '../../domain/values';

export const toDomain = (el: NoteDto): Note => {
    return {
        key: el.key,
        type: el.type,
        description: el.description,
        scope: NoteScope[el.scope as keyof typeof NoteScope],
        updatedAt: new Date(el.updatedAt),
    };
};
