import EditableSupportingDocument from './EditableSupportingDocument';
import SupportingDocument from './SupportingDocument';

export default class SupportingDocumentToModify extends EditableSupportingDocument {
    document: SupportingDocument;

    constructor(document: SupportingDocument) {
        super(document.name, document.type!, document.location!);
        this.document = document;
        this.toModify = true;
    }
}
