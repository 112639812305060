import { DialogState } from './DialogState';

export const dialogOptionsReducer = (
    state: DialogState,
    action: { type: 'open' | 'close'; payload?: { text: string; details?: string[] } }
): DialogState => {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                isOpen: true,
                text: action.payload?.text || state.text,
                details: action.payload?.details,
            };
        case 'close':
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
};
