import { useEffect } from 'react';
import {
    GetOnHoldClaimRequestResult,
    GetOnHoldClaimRequests,
} from '../../../../application/ClaimRequest/GetOnHoldClaimRequests';
import { useAsync } from '../../../../domain/utils';
import { ClaimRequestNomaRepository } from '../../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';

const getOnHoldClaimRequestsList = new GetOnHoldClaimRequests(ClaimRequestNomaRepository);
export const useGetOnHoldClaimRequest = (countryCode: string) => {
    const { execute, status, value } = useAsync<GetOnHoldClaimRequestResult | undefined>(getOnHoldClaimRequestsList);

    useEffect(() => {
        execute(countryCode);
    }, []);

    return { claimRequestsResult: value, claimRequestsFetchStatus: status };
};
