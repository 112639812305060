import { ResolutionDto } from './dtos/ResolutionDto';
import { Resolution } from '../../domain/resolution/Resolution';
import { InspectionDto } from './dtos/InspectionDto';
import { Inspection } from '../../domain/resolution/Inspection';
import { useOverpayMapper } from '../../utils/useOverpayMapper';
import { InspectionErrorCodes } from '../../domain/values/InspectionErrorCodes';

const { getInspectionOverpay } = useOverpayMapper();
export const toDomain = (resolution: ResolutionDto): Resolution => {
    return {
        inspection: resolution.inspection ? inspectionToDomain(resolution.inspection) : undefined,
    };
};

const inspectionToDomain = (inspection: InspectionDto): Inspection => {
    return {
        isValid: inspection.valid,
        errorCodes: inspection.errorCodes?.map(
            (it) => InspectionErrorCodes[it.toUpperCase() as keyof typeof InspectionErrorCodes]
        ),
        detail: inspection.detail,
        overpay: getInspectionOverpay(inspection.lowerBoundErrorInCents, inspection.upperBoundErrorInCents),
    };
};
