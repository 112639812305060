import { useEffect, useMemo, useState } from 'react';
import {
    BlockingLoader,
    Button,
    ButtonVariant,
    Card,
    Chip,
    ChipGroup,
    ChipType,
    ClaimDetails,
    ClaimLifecycle,
    ClaimRequestNomaRepository,
    ClaimRequestStatus,
    ClaimRequestType,
    ClinicalHistoryCardStyle,
    ClinicalHistoryWrapper,
    CreationNote,
    IncidentNotes,
    Note,
    NoteScope,
    NoteTypeValue,
    ParentCard,
    PetProfile,
    Policy,
    PolicyCard,
    RequestDetailHeader,
    SupportingDocumentType,
    useClaimRequest as useClaimRequestWithRepository,
    useClaimRequestStatus,
    useClinicalHistory,
    useContractIdsActiveOnClaimRequest as useContractIdsActiveOnClaimRequestWithRepository,
    useFraudAlerts,
    useNotes,
    useReservation as useReservationWithRepository,
    useHref,
    DuplicateClaimConfirmationDialog,
} from '@barkibu/noma-commons';
import { SupportingDocument, SupportingDocuments } from '@barkibu/noma-data-entry';
import { useClaimRequestSummary as useClaimRequestSummaryWithRepository } from '../../../application/ClaimRequestSummary/useClaimRequestSummary';
import { useResolutionInspection as useResolutionInspectionWithRepository } from '../../../application/resolutioninspection/useResolutionInspection';
import { ClaimRequestSummary } from '../../../domain/ClaimRequestSummary';
import { ClaimRequestSummaryNomaRepository } from '../../../infrastructure/ClaimRequestSummary/ClaimRequestSummaryNomaRepository';
import { ResolutionNomaRepository } from '../../../infrastructure/resolution/ResolutionNomaRepository';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import { BillableConceptSummaryTable } from '../../components/organisms';
import './summary-view-page.modules.scss';
import InspectionModal from '../../components/organisms/inspection-modal/InspectionModal';
import { Inspection } from '../../../domain/resolution/Inspection';

export type SummaryViewPageProps = {
    claimRequestKey: string;
};

const SummaryViewPage = ({ claimRequestKey }: SummaryViewPageProps) => {
    const useClaimRequestSummary = useMemo(
        () => useClaimRequestSummaryWithRepository(ClaimRequestSummaryNomaRepository),
        []
    );
    const useReservation = useMemo(() => useReservationWithRepository(ClaimRequestNomaRepository), []);
    const useResolutionInspection = useMemo(() => useResolutionInspectionWithRepository(ResolutionNomaRepository), []);
    const useClaimRequest = useMemo(() => useClaimRequestWithRepository(ClaimRequestNomaRepository), []);
    const useContractIdsActiveOnClaimRequest = useMemo(
        () => useContractIdsActiveOnClaimRequestWithRepository(ClaimRequestNomaRepository),
        []
    );
    const { claimRequestSummary } = useClaimRequestSummary(claimRequestKey);
    const { hasStatusReservable } = useClaimRequestStatus(claimRequestKey);
    const { contractIdsActiveOnClaimRequest } = useContractIdsActiveOnClaimRequest(claimRequestKey);
    const { overturnVetAnalysisDecision, duplicateClaimRequest } = useClaimRequest(claimRequestKey);
    const { stealReservation } = useReservation();
    const { saveResolutionInspection } = useResolutionInspection();
    const [summary, setSummary] = useState<ClaimRequestSummary | undefined>(claimRequestSummary);
    const [policy, setPolicy] = useState<Policy | undefined>();
    const { clinicalHistory } = useClinicalHistory(claimRequestSummary?.policyIdentifier);
    const [documents, setDocuments] = useState<SupportingDocument[]>(claimRequestSummary?.supportingDocuments || []);
    const { t } = useModuleTranslation();
    const readOnly = true;
    const [loading, setLoading] = useState(false);
    const { fraudAlerts } = useFraudAlerts(claimRequestKey);
    const [currentUserReservation, setCurrentUserReservation] = useState(claimRequestSummary?.userReservation);
    const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
    const [showDuplicateClaimConfirmationDialog, setShowDuplicateClaimConfirmationDialog] = useState(false);

    const {
        notes: policyNotes,
        createNote: addPolicyNote,
        deleteNote: deletePolicyNote,
    } = useNotes(NoteScope.POLICY, summary?.key ?? undefined, summary?.policyIdentifier ?? undefined);
    const {
        notes: petParentNotes,
        createNote: addPetParentNote,
        deleteNote: deletePetParentNote,
    } = useNotes(NoteScope.PET_PARENT, summary?.key ?? undefined, summary?.policyIdentifier ?? undefined);
    const {
        notes: petNotes,
        createNote: addPetNote,
        deleteNote: deletePetNote,
    } = useNotes(NoteScope.PET, summary?.key ?? undefined, summary?.policyIdentifier ?? undefined);
    const handleAddNote = (note: CreationNote) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                addPolicyNote(note);
                break;
            case NoteScope.PET:
                addPetNote(note);
                break;
            case NoteScope.PET_PARENT:
                addPetParentNote(note);
                break;
        }
    };
    const handleDeleteNote = (note: Note) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                deletePolicyNote(note.key);
                break;
            case NoteScope.PET:
                deletePetNote(note.key);
                break;
            case NoteScope.PET_PARENT:
                deletePetParentNote(note.key);
                break;
        }
    };

    const handleDuplicateClaimRequest = async () => {
        const duplicatedClaimRequest = await duplicateClaimRequest();
        toDataEntry(duplicatedClaimRequest.key);
    };

    useEffect(() => {
        setSummary(claimRequestSummary);
        setPolicy(
            claimRequestSummary?.pet?.policies.find((p) => p.identifier == claimRequestSummary?.policyIdentifier)
        );
        const paymentLetterSupportingDocument = claimRequestSummary
            ? claimRequestSummary.paymentLetters.map(
                  (paymentLetter: string) =>
                      new SupportingDocument({ type: SupportingDocumentType.PAYMENT_LETTER, location: paymentLetter })
              )
            : [];
        setDocuments([...(claimRequestSummary?.supportingDocuments || []), ...paymentLetterSupportingDocument]);
        setCurrentUserReservation(claimRequestSummary?.userReservation);
    }, [claimRequestSummary]);

    const handleStealReservation = () => {
        stealReservation(claimRequestKey).then((user) => setCurrentUserReservation(user));
    };

    const handleSubmitInspection = async (inspection: Inspection) => {
        await saveResolutionInspection(claimRequestKey, inspection);
    };

    const { toDataEntry } = useHref();
    const handleOverturnVetDecision = async () => {
        await overturnVetAnalysisDecision();
        toDataEntry(claimRequestKey);
    };

    return (
        <>
            {!loading && summary?.pet ? (
                <div className="summary-view">
                    <section className="summary-view__pet-details">
                        <PetProfile
                            name={summary.pet?.name}
                            birth={summary.pet?.birthDate}
                            breed={summary.pet?.breed}
                            gender={summary.pet?.gender}
                            chip={summary.pet?.chip}
                            photo={summary.pet?.photo}
                            totalClaimRequestCount={summary.policyClaimRequestStats.totalClaimRequestCount}
                            openClaimRequestCount={summary.policyClaimRequestStats.openClaimRequestCount}
                            tags={petNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((petNote) => petNote.description)}
                        />
                        <PolicyCard
                            setLoading={setLoading}
                            fraudAlerts={fraudAlerts}
                            policyIdentifier={summary.policyIdentifier}
                            countryCode={summary.countryCode}
                            policies={summary.pet.policies}
                            petName={summary.pet.name}
                            tags={policyNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((policyNote) => policyNote.description)}
                            contractIdsActiveOnClaimRequest={contractIdsActiveOnClaimRequest}
                        />
                        <ParentCard
                            readOnly
                            email={summary.pet?.petParentEmail ?? ''}
                            iban={summary.iban ?? ''}
                            hideIban={summary.prepaidExpenseIds?.length > 0}
                            tags={petParentNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((petParentNote) => petParentNote.description)}
                        />
                    </section>
                    <section className="summary-view__request-details">
                        {claimRequestSummary?.key && (
                            <RequestDetailHeader
                                userReservation={currentUserReservation}
                                date={summary?.createdAt}
                                seePreviousUrl={`/dataentry/policy/${policy?.identifier}/incidents`}
                                onStealReservation={
                                    hasStatusReservable(summary.status) ? () => handleStealReservation() : undefined
                                }
                                policyNotes={policyNotes}
                                petNotes={petNotes}
                                petParentNotes={petParentNotes}
                                onAddNote={handleAddNote}
                                onDeleteNote={handleDeleteNote}
                                prepaidExpenseIds={claimRequestSummary.prepaidExpenseIds}
                            />
                        )}
                    </section>
                    <div className="summary-view__info-container">
                        <div className="summary-view__info-container__left-container">
                            <section className="summary-view__info-container__status">
                                <ClaimLifecycle
                                    status={summary.status}
                                    claimRequestKey={claimRequestKey}
                                    statusDetails={summary.statusDetails}
                                />
                            </section>
                            {summary.diagnosis.length > 0 && (
                                <section className="summary-view__info-container__symptoms">
                                    <Card title={t('summary_view.diagnoses')}>
                                        <ChipGroup>
                                            {summary.diagnosis.map((el) => (
                                                <Chip
                                                    key={`${el.key}`}
                                                    text={`${el.description}`}
                                                    type={ChipType.NEUTRAL}
                                                    className={'summary-view__chip-multiline'}
                                                />
                                            ))}
                                        </ChipGroup>
                                    </Card>
                                </section>
                            )}
                            {summary.symptoms.length > 0 && (
                                <section className="summary-view__info-container__diagnosis">
                                    <Card title={t('summary_view.symptoms')}>
                                        <ChipGroup>
                                            {summary.symptoms.map((el) => (
                                                <Chip
                                                    key={el.key}
                                                    text={el.description}
                                                    type={ChipType.NEUTRAL}
                                                    className={'multiline'}
                                                />
                                            ))}
                                        </ChipGroup>
                                    </Card>
                                </section>
                            )}
                            {summary.type == ClaimRequestType.HEALTH && (
                                <ClaimDetails
                                    visitReason={summary.visitReason}
                                    causeOfLoss={summary.causeOfLoss}
                                    readOnly
                                />
                            )}
                            <section className="summary-view__info-container__notes">
                                <IncidentNotes notes={summary.notes} readOnly={readOnly} />
                            </section>
                        </div>

                        <div className="summary-view__info-container__right-container">
                            <section>
                                <ClinicalHistoryWrapper
                                    clinicalHistory={clinicalHistory}
                                    policyIdentifier={summary.policyIdentifier}
                                    style={ClinicalHistoryCardStyle.BIG}
                                />
                            </section>
                            <section>
                                <SupportingDocuments documents={documents} allowEdit={false} showFooter={false} />
                            </section>
                            <section>
                                <BillableConceptSummaryTable
                                    title={'Cost Lines'}
                                    invoiceSummaryList={summary.invoices}
                                />
                            </section>
                        </div>

                        <div className="summary-view__info-container__actions">
                            {(summary.status == ClaimRequestStatus.VET_ANALYSIS_COMPLETED ||
                                summary.status == ClaimRequestStatus.RESOLUTION_COMPLETED ||
                                summary.status == ClaimRequestStatus.DISBURSEMENT_REGISTERED_IN_PAS ||
                                summary.status == ClaimRequestStatus.DISBURSEMENT_ACCEPTED_BY_PAS) && (
                                <>
                                    <Button
                                        variantName={ButtonVariant.SECONDARY}
                                        text={t('summary_view.review')}
                                        onClick={() => setInspectionDialogOpen(true)}
                                    ></Button>
                                    {summary.status == ClaimRequestStatus.VET_ANALYSIS_COMPLETED && (
                                        <Button
                                            variantName={ButtonVariant.SECONDARY}
                                            text={t('summary_view.overturn_vet_decision')}
                                            onClick={handleOverturnVetDecision}
                                        ></Button>
                                    )}
                                    <InspectionModal
                                        inspection={summary?.resolution?.inspection}
                                        isOpen={inspectionDialogOpen}
                                        onSubmit={handleSubmitInspection}
                                        onClose={() => setInspectionDialogOpen(false)}
                                    ></InspectionModal>
                                </>
                            )}
                            <Button
                                variantName={ButtonVariant.SECONDARY}
                                text={t('summary_view.duplicate_claim')}
                                onClick={() => setShowDuplicateClaimConfirmationDialog(true)}
                            ></Button>
                            <DuplicateClaimConfirmationDialog
                                isOpen={showDuplicateClaimConfirmationDialog}
                                onSave={() => {
                                    setShowDuplicateClaimConfirmationDialog(false);
                                    handleDuplicateClaimRequest();
                                }}
                                onCancel={() => setShowDuplicateClaimConfirmationDialog(false)}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <BlockingLoader />
            )}
        </>
    );
};

export default SummaryViewPage;
