import { getCookie } from '../utils/cookieUtil';

interface config {
    API_ENDPOINT: string;
    API_HEADERS: () => Headers;
    MULTIPART_REQUEST_API_HEADERS: () => Headers;
    APP_BACKEND_BASE_URL?: string;
}

export const config: config = {
    API_ENDPOINT: '',
    API_HEADERS: () => {
        const bearer = getCookie('access_token') ?? '';
        const refresh = localStorage.getItem('refresh_token') ?? '';
        return new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: bearer,
            refresh_token: refresh,
        });
    },
    MULTIPART_REQUEST_API_HEADERS: () => {
        const bearer = getCookie('access_token') ?? '';
        const refresh = localStorage.getItem('refresh_token') ?? '';
        return new Headers({
            Authorization: bearer,
            refresh_token: refresh,
        });
    },
    APP_BACKEND_BASE_URL: process.env.REACT_APP_APP_BACKEND_BASE_URL,
};
