import React, { useEffect, useState } from 'react';
import { Button, ButtonVariant, Divider, MoreIcon } from '@barkibu/noma-commons';
import { v4 as uuidv4 } from 'uuid';
import CostLine from '../../../../../domain/entities/CostLine';
import { useModuleTranslation } from '../../../../../utils/useModuleTranslation';
import InvoiceCostLine from '../../invoice-cost-line/InvoiceCostLine';

type InvoiceCostLinesProps = {
    readOnly: boolean;
    costLinesDefault: CostLine[];
    onUpdateCostLines: (costLines: CostLine[]) => void;
};

const InvoiceCostLines = ({ readOnly, costLinesDefault, onUpdateCostLines }: InvoiceCostLinesProps) => {
    const { t } = useModuleTranslation();
    const [costLines, setCostLines] = useState(costLinesDefault || []);
    const [editingLine, setEditingLine] = useState(false);
    useEffect(() => {
        setCostLines(costLinesDefault || []);
    }, [costLinesDefault]);

    useEffect(() => {
        setEditingLine(costLines.filter((el) => el.key == '').length > 0);
    }, [costLines]);

    const handleCostLineAddition = () => {
        const costLine = new CostLine({
            key: uuidv4(),
            concept: '',
            units: 1,
            VAT: 0,
            priceInCents: 0,
        });
        const updatedCostLines = [...costLines, costLine];
        setCostLines(updatedCostLines);
        onUpdateCostLines(updatedCostLines);
    };

    const saveCostLine = (costLine: CostLine) => {
        const updatableCostLines = costLines.map((el) => {
            if (costLine.key == el.key) return costLine;
            else return el;
        });
        setCostLines(updatableCostLines);
        onUpdateCostLines(updatableCostLines);
    };

    const handleCostLineDeletion = (costLine: CostLine) => {
        const updatableCostLines = costLines.filter((el) => {
            return el.key !== costLine.key;
        });
        setCostLines(updatableCostLines);
        onUpdateCostLines(updatableCostLines);
    };

    return (
        <div className={'invoice-details'}>
            <h3 className="invoice-details__header">{t('invoice.cost_lines')}</h3>

            {costLines.length > 0 && (
                <div className="invoice-details__grid">
                    <span className="header">{t('invoice.cost_line.concept')}</span>
                    <span className="header">{t('invoice.cost_line.quantity')}</span>
                    <span className="header">{t('invoice.cost_line.vat')}</span>
                    <span className="header">{t('invoice.cost_line.retail_price')}</span>
                    <span className="header"></span>
                    <div className="separator hide">{<Divider />}</div>

                    {costLines.map((costLine, index) => {
                        const showSeparator = index !== costLines.length - 1;
                        return (
                            <React.Fragment key={costLine.key}>
                                <InvoiceCostLine
                                    readOnly={readOnly}
                                    costLine={costLine}
                                    handleSave={saveCostLine}
                                    handleRemove={handleCostLineDeletion}
                                />
                                <div className={'separator'}>{showSeparator && <Divider />}</div>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            {!readOnly && (
                <Button
                    text={t('invoice.cost_line.add')}
                    variantName={ButtonVariant.SECONDARY}
                    icon={MoreIcon}
                    onClick={() => handleCostLineAddition()}
                    disabled={editingLine}
                ></Button>
            )}
        </div>
    );
};

export default InvoiceCostLines;
