import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';

export class AssignNextNewAssessment {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(countryCode: string): Promise<string> {
        return this.claimRequestRepository.assignNextNewAssessment(countryCode);
    }
}
