import { ClaimRequestStatus } from '@barkibu/noma-commons';
import { IncidentClaimRequestDto } from './dtos/IncidentClaimRequestDto';
import { incidentToDomain } from './IncidentFromClaimRequestMapper';
import { IncidentClaimRequest } from '../../../domain/entities/IncidentClaimRequest';

export const toDomain = (incidentClaimRequestDto: IncidentClaimRequestDto): IncidentClaimRequest => {
    return {
        key: incidentClaimRequestDto.key,
        countryCode: incidentClaimRequestDto.countryCode,
        policyIdentifier: incidentClaimRequestDto.policyIdentifier,
        dateOfLoss: new Date(incidentClaimRequestDto.originalDateOfLoss),
        causeOfLoss: incidentClaimRequestDto.causeOfLoss,
        incident: incidentToDomain(incidentClaimRequestDto.incident!, incidentClaimRequestDto.supportingDocuments),
        status: {
            description: incidentClaimRequestDto.status.status as ClaimRequestStatus,
            details: incidentClaimRequestDto.status.details,
        },
        userReservation: {
            name: incidentClaimRequestDto.userReservation?.firstName
                ? incidentClaimRequestDto.userReservation?.firstName +
                  ' ' +
                  incidentClaimRequestDto.userReservation?.lastName
                : '',
            email: incidentClaimRequestDto.userReservation?.email || '',
            privileges: [],
        },
    };
};
