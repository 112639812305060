import { ClaimRequest } from '../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';

export class AssignNextNewPreventionClaim {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(countryCode: string): Promise<ClaimRequest> {
        return this.claimRequestRepository.assignNextNewPreventionClaim(countryCode);
    }
}
