import React, { FunctionComponent, SVGProps } from 'react';
import { Icon, IconModes, IconSize } from '../icons';
import './button.modules.scss';

export enum ButtonVariant {
    INFO = 'info',
    BASIC_INFO = 'basic-info',
    PRIMARY = 'primary',
    SUCCESS = 'success',
    FILLED_NEUTRAL = 'filled-neutral',
    SECONDARY = 'secondary',
    DANGER = 'danger',
    TERTIARY = 'tertiary',
    TERTIARY_DESTROY = 'tertiary--destroy',
    TABLINK = 'tablink',
    TABLINK_ACTIVE = 'tablink--active',
}

export interface ButtonProps {
    variantName?: ButtonVariant;
    text?: string | null;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    type?: 'button' | 'submit' | 'reset' | undefined;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    iconMode?: IconModes;
    disabled?: boolean;
    trailingIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    accessibilityText?: string | null;
}

export const Button = ({
    variantName,
    icon,
    text,
    onClick,
    type = 'button',
    iconMode,
    disabled,
    trailingIcon,
    accessibilityText,
}: ButtonProps) => {
    return (
        <button
            disabled={disabled}
            aria-label={accessibilityText || undefined}
            className={`button button--${variantName || 'info'}`}
            type={type}
            onClick={onClick}
        >
            {icon != null && (
                <Icon
                    className="button--leading-icon"
                    icon={icon}
                    mode={iconMode}
                    size={IconSize.M}
                    accessibilityText="Leading button icon"
                ></Icon>
            )}
            {text && <span>{text}</span>}
            {trailingIcon != null && (
                <Icon
                    className="button--trailing-icon"
                    icon={trailingIcon}
                    mode={iconMode}
                    size={IconSize.M}
                    accessibilityText="Trailing button icon"
                ></Icon>
            )}
        </button>
    );
};

export default Button;
