import React, { useState } from 'react';
import { Button, ButtonVariant, Input } from '@barkibu/noma-commons';
import { AuthCredentials } from '../../../domain/auth/AuthCredentials';
import './login.modules.scss';

export interface LoginProps {
    handleSubmit: (credentials: AuthCredentials) => Promise<void>;
}

export const Login = ({ handleSubmit }: LoginProps) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const errorMessage = error ? 'Invalid email or password' : '';

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await handleSubmit({ email, rawPassword: password });
        } catch (e) {
            setError(true);
        }
    };

    return (
        <main className="login-page-container">
            <section className="login-container">
                <h1 className="login-title">Noma Claims</h1>
                <form onSubmit={onSubmit}>
                    <Input value={email} name="clientId" onChange={setEmail} label="Email" />
                    <Input
                        value={password}
                        name="password"
                        onChange={setPassword}
                        label="Password"
                        type="password"
                        errorMessage={errorMessage}
                    />
                    <Button text="Sign in" type="submit" variantName={ButtonVariant.SECONDARY} />
                </form>
            </section>
        </main>
    );
};
