import React, { FunctionComponent, SVGProps } from 'react';
import { IconModes } from './IconModes';
import { IconSize } from './IconSizes';
import stylesMode from '../../styles/StylesMode';
import AlertComponentIcon from './assets/alert.svg';
import ArrowLeftComponentIcon from './assets/arrow-left.svg';
import CalendarComponentIcon from './assets/calendar.svg';
import ChipComponentIcon from './assets/chip.svg';
import CLoseComponentIcon from './assets/close.svg';
import DirectionLeftComponentIcon from './assets/direction-left.svg';
import DirectionUpComponentIcon from './assets/direction-up.svg';
import DocumentComponentIcon from './assets/document.svg';
import DogComponentIcon from './assets/dog.svg';
import DownloadComponentIcon from './assets/download.svg';
import EyeComponentIcon from './assets/eye.svg';
import HistorialComponentIcon from './assets/historial.svg';
import InfoComponentIcon from './assets/info.svg';
import InvoiceComponentIcon from './assets/invoice.svg';
import LegalComponentIcon from './assets/legal.svg';
import MaleComponentIcon from './assets/male.svg';
import MoreComponentIcon from './assets/more.svg';
import PencilComponentIcon from './assets/pencil.svg';
import RefreshComponentIcon from './assets/refresh.svg';
import SearchComponentIcon from './assets/search.svg';
import SuccessComponentIcon from './assets/success.svg';
import TrashComponentIcon from './assets/trash.svg';
import './icon.modules.scss';

interface IconProps {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    mode: IconModes | undefined;
    size: IconSize | undefined;
    className?: string;
    accessibilityText?: string;
}

export function Icon(props: IconProps) {
    const style = stylesMode({
        with: 'icon',
        prefix: 'icon',
        modes: ['size', props.size, 'background', props.mode],
    });

    return (
        <div className={`${style} ${props.className}`}>
            <props.icon className={'icon'} aria-label={props.accessibilityText} />
        </div>
    );
}

const generateIcon = (Icon: React.FC) => {
    const result = (props: React.SVGAttributes<SVGElement>) => <Icon {...props} />;
    result.displayName = Icon.displayName || Icon.name;
    return result;
};

export const TrashIcon = generateIcon(TrashComponentIcon);
export const MoreIcon = generateIcon(MoreComponentIcon);
export const CalendarIcon = generateIcon(CalendarComponentIcon);
export const SuccessIcon = generateIcon(SuccessComponentIcon);
export const InfoIcon = generateIcon(InfoComponentIcon);
export const InvoiceIcon = generateIcon(InvoiceComponentIcon);
export const LegalIcon = generateIcon(LegalComponentIcon);
export const RefreshIcon = generateIcon(RefreshComponentIcon);
export const ChipIcon = generateIcon(ChipComponentIcon);
export const DogIcon = generateIcon(DogComponentIcon);
export const MaleIcon = generateIcon(MaleComponentIcon);
export const DocumentIcon = generateIcon(DocumentComponentIcon);
export const CloseIcon = generateIcon(CLoseComponentIcon);
export const PencilIcon = generateIcon(PencilComponentIcon);
export const AlertIcon = generateIcon(AlertComponentIcon);
export const EyeIcon = generateIcon(EyeComponentIcon);
export const DownloadIcon = generateIcon(DownloadComponentIcon);
export const ArrowLeftIcon = generateIcon(ArrowLeftComponentIcon);
export const DirectionLeftIcon = generateIcon(DirectionLeftComponentIcon);
export const DirectionUpIcon = generateIcon(DirectionUpComponentIcon);
export const SearchIcon = generateIcon(SearchComponentIcon);
export const HistorialIcon = generateIcon(HistorialComponentIcon);
