import { UploadFileToTemporaryStorage } from '../../../../application/UploadFileToTemporaryStorage/UploadFileToTemporaryStorage';
import { FilesNomaRepository } from '../../../../infraestructure/repositories/file/FilesRepository';

const uploadFileToTemporaryStorage = new UploadFileToTemporaryStorage(new FilesNomaRepository());

export const useUploadFileToTemporaryStorage = () => {
    return {
        uploadFileToTemporaryStorage,
    };
};
