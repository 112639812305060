import { AssignNextNewAssessment } from '../../../application/ClaimRequest/AssignNextNewAssessment';
import { AssignNextNewFastClaim } from '../../../application/ClaimRequest/AssignNextNewFastClaim';
import { AssignNextNewIncident } from '../../../application/ClaimRequest/AssignNextNewIncident';
import { AssignNextNewPreventionClaim } from '../../../application/ClaimRequest/AssignNextNewPreventionClaim';
import { AssignNextPreventionClaimWithNewDocumentation } from '../../../application/ClaimRequest/AssignNextPreventionClaimWithNewDocumentation';
import { AssignNextReadyToReviewIncident } from '../../../application/ClaimRequest/AssignNextReadyToReviewIncident';
import { ClaimRequestNomaRepository } from '../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';

const assignNextNewIncident = new AssignNextNewIncident(ClaimRequestNomaRepository);
const assignNextReadyToReviewIncident = new AssignNextReadyToReviewIncident(ClaimRequestNomaRepository);
const assignNextNewAssessment = new AssignNextNewAssessment(ClaimRequestNomaRepository);
const assignNextNewFastClaim = new AssignNextNewFastClaim(ClaimRequestNomaRepository);
const assignNextNewPreventionClaim = new AssignNextNewPreventionClaim(ClaimRequestNomaRepository);
const assignNextPreventionClaimWithNewDocumentation = new AssignNextPreventionClaimWithNewDocumentation(
    ClaimRequestNomaRepository
);

export const useAssignClaimRequest = () => {
    const assignNewAssessment = async (countryCode: string) => {
        return await assignNextNewAssessment.execute(countryCode);
    };
    const assignNewIncident = async (countryCode: string) => {
        return await assignNextNewIncident.execute(countryCode);
    };

    const assignReadyToReviewIncident = async (countryCode: string) => {
        return await assignNextReadyToReviewIncident.execute(countryCode);
    };

    const assignNewFastClaim = async (countryCode: string) => {
        return await assignNextNewFastClaim.execute(countryCode);
    };

    const assignNewPreventionClaim = async (countryCode: string) => {
        return await assignNextNewPreventionClaim.execute(countryCode);
    };

    const assignPreventionClaimWithNewDocumentation = async (countryCode: string) => {
        return await assignNextPreventionClaimWithNewDocumentation.execute(countryCode);
    };

    return {
        assignNewAssessment,
        assignNewIncident,
        assignReadyToReviewIncident,
        assignNewFastClaim,
        assignNewPreventionClaim,
        assignPreventionClaimWithNewDocumentation,
    };
};
