export class ServerError extends Error {
    private code: string;

    constructor(code: string, message: string) {
        super(message);
        Object.setPrototypeOf(this, ServerError.prototype);

        this.code = code;
    }

    public getCode() {
        return this.code;
    }
}
