import { InspectionStatistics, User } from '../../../domain/entities';
import { ClaimRequest } from '../../../domain/entities/ClaimRequest';
import { ClaimRequestRepository } from '../../../domain/repositories/ClaimRequestRepository';
import { CauseOfLoss } from '../../../domain/values';
import { NumberOfPendingClaimRequest } from '../../../domain/values/NumberOfPendingClaimRequest';
import {
    ApiDto,
    CollectionEntityResponse,
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '../../api';
import { toDomain } from '../../ClaimRequestMapper';
import { InspectionStatisticsDto } from '../dtos';
import { ClaimRequestDto } from '../dtos/ClaimRequestDto';
import { CreateIncidentDto } from '../dtos/CreateIncidentDto';
import { UserReservationDto } from '../userReservation/UserReservationDto';
import { toUserDomain } from '../userReservation/UserReservationMapper';
import { toInspectionStatisticsDomain } from './InspectionStatisticsMapper';

export const ClaimRequestNomaRepository: ClaimRequestRepository = {
    assignNextNewAssessment(countryCode: string): Promise<string> {
        return processFetchOperation('/v1/assessments/next-new', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((assessment: SingleEntityResponse<ApiDto>) => {
            return assessment.data.id;
        });
    },
    assignNextNewFastClaim(countryCode: string): Promise<string> {
        return processFetchOperation('/v1/incidents/next-fast', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((incident: SingleEntityResponse<ApiDto>) => {
            return incident.data.id;
        });
    },
    assignNextNewIncident(countryCode: string): Promise<string> {
        return processFetchOperation('/v1/incidents/next-new', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((incident: SingleEntityResponse<ApiDto>) => {
            return incident.data.id;
        });
    },
    assignNextReadyToReviewIncident(countryCode: string): Promise<string> {
        return processFetchOperation('/v1/incidents/next-ready-to-review', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((incident: SingleEntityResponse<ApiDto>) => {
            return incident.data.id;
        });
    },
    assignNextClaimToInspect(
        claimType: string,
        countryCode: string,
        days: number,
        option?: string | null,
        interceptableClaimsOnly?: boolean | null,
        thresholdInCents?: number | null,
        evaluateThresholdHigher?: boolean | null
    ): Promise<ClaimRequest> {
        return processFetchOperation('/v1/claim-requests/next-resolution-inspection', OperationMethod.POST, {
            body: JSON.stringify({
                days: days,
                claimType: claimType,
                countryCode: countryCode,
                strategy: option,
                interceptableClaimsOnly: interceptableClaimsOnly,
                threshold: thresholdInCents,
                claimRequestAmountHigherThanThreshold: evaluateThresholdHigher,
            }),
        }).then((claimRequest: SingleEntityResponse<ClaimRequestDto>) => {
            const data = flattenEntity(claimRequest.data, claimRequest.included || []);
            return toDomain(data);
        });
    },
    countPendingClaimRequest(countryCode: string): Promise<NumberOfPendingClaimRequest> {
        return processFetchOperation('/v1/claim-request/count-pending', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((dto: NumberOfPendingClaimRequest) => {
            return dto;
        });
    },
    createNewIncident(createIncidentDto: CreateIncidentDto): Promise<string> {
        return processFetchOperation('/v1/claim-requests', OperationMethod.POST, {
            body: JSON.stringify(createIncidentDto),
        }).then((dto: SingleEntityResponse<ApiDto>) => {
            return dto.data.id;
        });
    },
    getOnHoldClaimRequests(countryCode: string): Promise<ClaimRequest[]> {
        return processFetchOperation('/v1/claim-requests/on-hold', OperationMethod.GET, {
            params: { countryCode: countryCode },
        }).then((dto: CollectionEntityResponse<ClaimRequestDto>) => {
            const entities = dto.data.map((it) => {
                return flattenEntity(it, dto.included || []);
            });

            return entities.map((it) => toDomain(it));
        });
    },
    changePolicy: (key: string, policyId: string): Promise<ClaimRequest> => {
        return processFetchOperation(`/v1/claim-request/${key}/policy`, OperationMethod.PUT, {
            body: JSON.stringify({ policyId: policyId }),
        }).then((claimRequest: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(claimRequest.data, claimRequest.included || []);
            const prepaidExpenseIds = claimRequest.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    updateCauseOfLoss: (claimRequestKey: string, causeOfLoss: CauseOfLoss) => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/cause-of-loss`, OperationMethod.POST, {
            body: JSON.stringify({ causeOfLoss }),
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    updateDateOfLoss: (claimRequestKey: string, dateOfLoss: Date) => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/date-of-loss`, OperationMethod.POST, {
            body: JSON.stringify({ dateOfLoss }),
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    updateIban: (claimRequestKey: string, iban: string) => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/iban`, OperationMethod.POST, {
            body: JSON.stringify({ iban }),
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    updateEmail: (claimRequestKey: string, email: string) => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/email`, OperationMethod.POST, {
            body: JSON.stringify({ email }),
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    findClaimRequest: (claimRequestKey: string) => {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}`, OperationMethod.GET, {
            params: { include: 'userReservation' },
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    findClaimRequestRelatedByCoverage: (claimRequestKey: string, coverageKey: string) => {
        return processFetchOperation(
            `/v1/claim-request/${claimRequestKey}/coverage/${coverageKey}`,
            OperationMethod.GET,
            {}
        ).then((dto: CollectionEntityResponse<ClaimRequestDto>) => {
            const entities = dto.data.map((it) => {
                return flattenEntity(it, dto.included || []);
            });
            return entities.map((it) => toDomain(it));
        });
    },

    assignNextNewPreventionClaim(countryCode: string): Promise<ClaimRequest> {
        return processFetchOperation('/v1/claim-requests/next-new-prevention', OperationMethod.POST, {
            params: { countryCode: countryCode },
        }).then((dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
            const data = flattenEntity(dto.data, dto.included || []);
            const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
            return toDomain(data, prepaidExpenseIds);
        });
    },

    assignNextPreventionClaimWithNewDocumentation(countryCode: string): Promise<string> {
        return processFetchOperation('/v1/claim-requests/next-prevention-new-documentation', OperationMethod.POST, {
            params: { countryCode: countryCode },
        }).then((result: SingleEntityResponse<ApiDto>) => {
            return result.data.id;
        });
    },

    stealReservation(claimRequestKey): Promise<User> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/reservation`, OperationMethod.POST, {}).then(
            (result: SingleEntityResponse<UserReservationDto>) => {
                const data = flattenEntity(result.data, result.included || []);
                return toUserDomain(data);
            }
        );
    },

    delete(claimRequestKey: string): Promise<void> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}`, OperationMethod.DELETE);
    },
    duplicate(claimRequestKey: string): Promise<ClaimRequest> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/duplicate`, OperationMethod.POST).then(
            (dto: SingleEntityResponse<ClaimRequestDto, 'prepaidExpenses'>) => {
                const data = flattenEntity(dto.data, dto.included || []);
                const prepaidExpenseIds = dto.data.relationships?.prepaidExpenses?.data || [];
                return toDomain(data, prepaidExpenseIds);
            }
        );
    },
    overturnVetAnalysisDecision(claimRequestKey): Promise<void> {
        return processFetchOperation(
            `/v1/claim-request/${claimRequestKey}/overturn-vet-analysis`,
            OperationMethod.POST,
            {}
        );
    },

    getInspectionStatistics(
        claimType: string,
        countryCode: string,
        days: number,
        option?: string | null,
        interceptableClaimsOnly?: boolean | null,
        thresholdInCents?: number | null,
        evaluateThresholdHigher?: boolean | null
    ): Promise<InspectionStatistics> {
        return processFetchOperation('/v1/claim-requests/resolution-inspection-statistics', OperationMethod.GET, {
            params: mapInspectionParams(
                claimType,
                countryCode,
                days,
                option,
                interceptableClaimsOnly,
                thresholdInCents,
                evaluateThresholdHigher
            ),
        }).then((inspectionStatistics: SingleEntityResponse<InspectionStatisticsDto>) => {
            const data = flattenEntity(inspectionStatistics.data, inspectionStatistics.included || []);
            return toInspectionStatisticsDomain(data);
        });
    },

    findContractIdsActiveOnClaimRequest: function (claimRequestKey: string): Promise<string[]> {
        return processFetchOperation(`/v1/claim-request/${claimRequestKey}/contract-ids`, OperationMethod.GET, {
        }).then((contract: CollectionEntityResponse<ApiDto>) => {
            return contract.data.map((it) => it.id);
        });  
    }
};

const mapInspectionParams = (
    claimType: string,
    countryCode: string,
    days: number,
    option?: string | null,
    interceptableClaimsOnly?: boolean | null,
    thresholdInCents?: number | null,
    evaluateThresholdHigher?: boolean | null
) => {
    const params: { [key: string]: any } = {
        days: days,
        claimType: claimType,
        countryCode: countryCode,
    };

    if (option !== null && option !== undefined) {
        params.strategy = option;
    }

    params.interceptableClaimsOnly = interceptableClaimsOnly ? interceptableClaimsOnly : false;

    if (thresholdInCents !== null && thresholdInCents !== undefined) {
        params.threshold = thresholdInCents;
    }

    if (evaluateThresholdHigher !== null && evaluateThresholdHigher !== undefined) {
        params.claimRequestAmountHigherThanThreshold = evaluateThresholdHigher;
    }
    return params;
};
