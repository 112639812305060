import { DatePicker } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './incident-dates.modules.scss';

interface IncidentDatesProps {
    readOnly?: boolean;
    requestedDate?: Date;
    dateOfLoss?: Date | null;
    onUpdate: (date: Date | null) => void;
}

const IncidentDates = ({ readOnly, requestedDate, dateOfLoss, onUpdate }: IncidentDatesProps) => {
    const { t } = useModuleTranslation();
    return (
        <>
            <DatePicker
                value={requestedDate}
                label={t('incident.request_date') || undefined}
                name={t('incident.request_date') || undefined}
                disabled={true}
            />
            <DatePicker
                value={dateOfLoss}
                label={t('incident.origin_date') || undefined}
                name={t('incident.origin_date') || undefined}
                onChange={(date) => onUpdate(date)}
                disabled={readOnly}
            />
        </>
    );
};

export default IncidentDates;
