import { BlockingLoader, ButtonVariant, Dialog, getDialogDetailsFromError, useDialog } from '@barkibu/noma-commons';
import InvoiceBanner from './invoice-banner/InvoiceBanner';
import InvoiceBody from './invoice-body/InvoiceBody';
import InvoiceFooter from './invoice-footer/InvoiceFooter';
import CostLine from '../../../domain/entities/CostLine';
import { InvoicesDetails as InvoicesDetailsDomain } from '../../../domain/entities/InvoicesDetails';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import './invoices-details.scss';

export interface InvoicesDetailsProps {
    invoicesDetails?: InvoicesDetailsDomain;
    onDataEntryFinished: (moveToVetView: boolean) => Promise<void>;
    onRedirectToIncident: () => Promise<void>;
    onUpdateClinic: (key: string, clinic: string) => void;
    onUpdateCostLines: (key: string, costLines: CostLine[]) => void;
    onUpdateDate: (key: string, date: Date) => void;
    onUpdateDiscount: (key: string, discount: number) => void;
    onUpdateNumber: (key: string, number: string) => void;
    onUpdateReimbursablePercentage: (key: string, reimbursablePercentage: number) => void;
    onUpdateTaxIdNumber: (key: string, taxIdNumber: string) => void;
    loading: boolean;
    readOnly: boolean;
    isFullCoverage?: boolean;
    disableActions?: boolean;
    claimRequestKey?: string;
}

const InvoicesDetails = ({
    invoicesDetails,
    onDataEntryFinished,
    onRedirectToIncident,
    onUpdateClinic,
    onUpdateCostLines,
    onUpdateDate,
    onUpdateDiscount,
    onUpdateNumber,
    onUpdateReimbursablePercentage,
    onUpdateTaxIdNumber,
    loading,
    readOnly = false,
    isFullCoverage = false,
    disableActions = false,
    claimRequestKey,
}: InvoicesDetailsProps) => {
    const { t } = useModuleTranslation();
    const { dialogOptions, openDialog, closeDialog } = useDialog();

    const handleContinue = async (moveToVetView: boolean) => {
        if (!invoicesDetails?.areDigitalised) {
            openDialog(t('invoice.missing_cost_lines'));
            return;
        }
        try {
            await onDataEntryFinished(moveToVetView);
        } catch (e) {
            openDialog(t('invoice.move_to_vet_view_error'), getDialogDetailsFromError(e));
        }
    };

    return (
        <>
            {!loading && invoicesDetails && (
                <div className="invoice-details invoice-details--container">
                    <InvoiceBanner
                        petName={invoicesDetails.petName ?? t('value.unknown')}
                        handleIncidentRedirection={() => onRedirectToIncident()}
                    />
                    <InvoiceBody
                        invoicesDetails={invoicesDetails}
                        readOnly={readOnly}
                        onUpdateClinic={onUpdateClinic}
                        onUpdateCostLines={onUpdateCostLines}
                        onUpdateDate={onUpdateDate}
                        onUpdateDiscount={onUpdateDiscount}
                        onUpdateNumber={onUpdateNumber}
                        onUpdateReimbursablePercentage={onUpdateReimbursablePercentage}
                        onUpdateTaxIdNumber={onUpdateTaxIdNumber}
                        isFullCoverage={isFullCoverage}
                        claimRequestKey={claimRequestKey}
                    />
                    <InvoiceFooter handleContinueClick={handleContinue} disableActions={disableActions} />
                </div>
            )}
            <Dialog
                isOpen={dialogOptions.isOpen}
                text={dialogOptions.text}
                details={dialogOptions.details}
                onClose={() => closeDialog()}
                actions={[
                    {
                        text: t('modal.close', { ns: 'commons' }),
                        variantName: ButtonVariant.PRIMARY,
                        onClick: () => closeDialog(),
                    },
                ]}
            />
            {loading && <BlockingLoader />}
        </>
    );
};

export default InvoicesDetails;
