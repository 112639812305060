import { ReactNode } from 'react';
import './backdrop.modules.scss';

export interface BackdropProps {
    children: ReactNode;
}

const Backdrop = ({ children }: BackdropProps) => {
    return (
        <div className="backdrop" role="presentation">
            {children}
        </div>
    );
};

export default Backdrop;
