import { NoteScope } from './NoteScope';
import { useModuleTranslation } from '../../../ui/utils/useModuleTranslation';

export type NoteScopeOption = {
    value: string;
    placeholder: string;
    scope: NoteScope;
    selected?: boolean;
};

export const DefaultNoteScopeOptions: () => NoteScopeOption[] = () => {
    const { t } = useModuleTranslation();

    return [
        {
            value: t('note_scope_option.policy'),
            placeholder: t('note_scope_option.policy_placeholder'),
            selected: true,
            scope: NoteScope.POLICY,
        },
        {
            value: t('note_scope_option.pet_parent'),
            placeholder: t('note_scope_option.pet_parent_placeholder'),
            scope: NoteScope.PET_PARENT,
        },
        {
            value: t('note_scope_option.pet'),
            placeholder: t('note_scope_option.pet_placeholder'),
            scope: NoteScope.PET,
        },
    ];
};
