import { ClinicalHistory } from '../../domain/entities';
import { ClinicalHistoryRepository } from '../../domain/repositories';

export class UpdateClinicalHistoryNote {
    constructor(private clinicalHistoryRepository: ClinicalHistoryRepository) {}

    async execute(policyIdentifier: string, note: string): Promise<ClinicalHistory | undefined> {
        return await this.clinicalHistoryRepository.updateNote(policyIdentifier, { note });
    }
}
