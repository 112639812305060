import { createContext, FC, ReactElement, useEffect, useState } from 'react';
import { setUser as setSentryUser } from '@sentry/react';
import { User } from '../../../domain/entities/User';

interface UserProviderProps {
    children?: ReactElement;
    getUser: () => Promise<User>;
    isLoggedIn: boolean;
}

interface UserContextProps {
    user?: User;
}

export const UserContext = createContext<UserContextProps>({});

export const UserProvider: FC<UserProviderProps> = ({ children, getUser, isLoggedIn }) => {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (isLoggedIn) {
            getUser().then(setUser);
        }
    }, [isLoggedIn, getUser]);

    useEffect(() => {
        if (user != null) {
            setSentryUser({
                id: user.email,
                username: user.name,
                email: user.email,
            });
        } else {
            setSentryUser(null);
        }
    }, [user]);

    return (
        <UserContext.Provider
            value={{
                user,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
