import { ResolutionRepository } from '../../domain/resolution/ResolutionRepository';
import { Inspection } from '../../domain/resolution/Inspection';

export const useResolutionInspection = (repository: ResolutionRepository) => {
    return () => {
        const saveResolutionInspection = async (claimRequestKey: string, inspection: Inspection) => {
            return await repository.saveResolutionInspection(claimRequestKey, inspection);
        };

        return {
            saveResolutionInspection,
        };
    };
};
