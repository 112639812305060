import { ClaimRequestRepository } from '../../domain/repositories';
import { ClaimRequest } from '../../domain/entities';

export class AssignNextClaimToInspect {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(
        claimType: string,
        countryCode: string,
        days: number,
        service?: string | null,
        interceptableClaimsOnly?: boolean | null,
        threshold?: number | null,
        evaluateThresholdHigher?: boolean | null
    ): Promise<ClaimRequest> {
        return this.claimRequestRepository.assignNextClaimToInspect(
            claimType,
            countryCode,
            days,
            service,
            interceptableClaimsOnly,
            threshold,
            evaluateThresholdHigher
        );
    }
}
