import { ArrowLeftIcon } from '@barkibu/noma-commons';
import './CoverageHeader.scss';

type CoverageHeaderProps = {
    onGoBack: () => void;
    goBackText: string;
};

const CoverageHeader = ({ onGoBack, goBackText }: CoverageHeaderProps) => {
    return (
        <header className="coverage-page--header">
            <div className="coverage-page--header__go-back" onClick={onGoBack}>
                <ArrowLeftIcon className="coverage-page--header__go-back-icon" />
                <h3>{goBackText}</h3>
            </div>
        </header>
    );
};

export default CoverageHeader;
