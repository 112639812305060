import { Button, Modal, PreviewItem } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './preview-modal.scss';

export interface PreviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    previewItems: { title: string; content: string[] }[];
}

const PreviewModal = ({ isOpen, onClose, onSave, previewItems }: PreviewModalProps) => {
    const { t } = useModuleTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={t('preview_modal.title')} className="preview-modal">
            <div className="preview-modal-body">
                <div className="preview-modal-container">
                    {previewItems.map((item) => (
                        <div className="preview-modal-item" key={item.title}>
                            <PreviewItem title={item.title} content={item.content} />
                        </div>
                    ))}
                </div>
                <div className="preview-modal-actions">
                    <Button
                        text={t('preview_modal.save')}
                        accessibilityText={t('preview_modal.save_description')}
                        onClick={onSave}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PreviewModal;
