import { useEffect, useState } from 'react';
import { useAsync, UseAsyncStatus } from '@barkibu/noma-commons';
import { AddDiagnosisToAssessment } from './AddDiagnosisToAssessment';
import { AddSymptomToAssessment } from './AddSymptomToAssessment';
import { DeleteDiagnosisFromAssessment } from './DeleteDiagnosisFromAssessment';
import { DeleteSymptomFromAssessment } from './DeleteSymptomFromAssessment';
import { FindAssessment } from './FindAssessment';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';
import { DiagnosisType } from '../../domain/values/DiagnosisType';

const AsyncStatusPriority = [UseAsyncStatus.ERROR, UseAsyncStatus.PENDING, UseAsyncStatus.SUCCESS, UseAsyncStatus.IDLE];

export const useAssessment = (repository: AssessmentRepository) => {
    const findAssessment = new FindAssessment(repository);
    const addDiagnosisToAssessment = new AddDiagnosisToAssessment(repository);
    const deleteDiagnosisFromAssessment = new DeleteDiagnosisFromAssessment(repository);
    const addSymptomToAssessment = new AddSymptomToAssessment(repository);
    const deleteSymptomFromAssessment = new DeleteSymptomFromAssessment(repository);

    return (
        criteria: { assessmentId?: string; claimRequestId?: string },
        options: { timeout: number } = { timeout: 2000 }
    ) => {
        const maxAssessmentErrors = 4;
        const [countSyncError, setCountSyncError] = useState<number>(0);

        const {
            execute,
            status: assessmentStatus,
            value: assessment,
            error: assessmentError,
        } = useAsync<Assessment | undefined>(findAssessment);
        const {
            execute: addDiagnosis,
            status: addDiagnosisStatus,
            error: addDiagnosisError,
        } = useAsync<Assessment | undefined>(addDiagnosisToAssessment);
        const {
            execute: deleteDiagnosis,
            status: deleteDiagnosisStatus,
            error: deleteDiagnosisError,
        } = useAsync<Assessment | undefined>(deleteDiagnosisFromAssessment);
        const {
            execute: addSymptom,
            status: addSymptomStatus,
            error: addSymptomError,
        } = useAsync<Assessment | undefined>(addSymptomToAssessment);

        const {
            execute: deleteSymptom,
            status: deleteSymptomStatus,
            error: deleteSymptomError,
        } = useAsync<Assessment | undefined>(deleteSymptomFromAssessment);

        const syncStatus = [addDiagnosisStatus, deleteDiagnosisStatus, addSymptomStatus, deleteSymptomStatus].reduce(
            (status, useCaseStatus) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return AsyncStatusPriority.find((refStatus) => status == refStatus || useCaseStatus == refStatus)!;
            },
            UseAsyncStatus.IDLE
        );

        const syncError =
            (countSyncError > maxAssessmentErrors && assessmentError) ||
            addDiagnosisError ||
            deleteDiagnosisError ||
            addSymptomError ||
            deleteSymptomError;

        useEffect(() => {
            if (criteria.assessmentId != undefined || criteria.claimRequestId != undefined) {
                let timeout: NodeJS.Timeout;

                const assessmentCreated = assessment != null;
                const inProgress = assessmentStatus === UseAsyncStatus.PENDING;

                if (!inProgress) {
                    if (!assessmentCreated && countSyncError < maxAssessmentErrors) {
                        timeout = setTimeout(
                            () => {
                                execute(criteria);
                            },
                            countSyncError === 0 ? 0 : options.timeout
                        );
                    }

                    if (assessmentStatus === UseAsyncStatus.ERROR) {
                        setCountSyncError(countSyncError + 1);
                    }
                }

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [criteria.assessmentId, criteria.claimRequestId, assessment, assessmentStatus]);

        return {
            assessment: assessment,
            assessmentFetchStatus: assessmentStatus,
            addDiagnosis: (diagnosisKey: string, diagnosisType: DiagnosisType) =>
                addDiagnosis(assessment?.key, diagnosisKey, diagnosisType),
            deleteDiagnosis: (diagnosisKey: string, diagnosisType: DiagnosisType) =>
                deleteDiagnosis(assessment?.key, diagnosisKey, diagnosisType),
            addSymptom: (symptomKey: string) => addSymptom(assessment?.key, symptomKey),
            deleteSymptom: (symptomKey: string) => deleteSymptom(assessment?.key, symptomKey),
            syncStatus,
            syncError,
        };
    };
};
