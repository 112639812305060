import { Trans } from 'react-i18next';
import { LegalIcon } from '../../atoms/icons/Icon';
import IconTooltip from '../../molecules/icon-tooltip/IconTooltip';
import './FraudAlertTooltip.modules.scss';

export default function FraudAlertTooltip() {
    return (
        <IconTooltip icon={<LegalIcon />} position={'bottom'} className="fraud-alert-tooltip">
            <span>
                <Trans i18nKey="policy.fraud.alert" components={{ bold: <b /> }} />
            </span>
        </IconTooltip>
    );
}
