import { EventRecordDto } from './EventRecordDto';
import { EventRecord } from '../../../domain/entities/EventRecord';

export const toDomain = (el: EventRecordDto): EventRecord => {
    return {
        key: el.key,
        name: el.eventName,
        timestamp: new Date(el.timestamp),
        agentFirstName: el.agentFirstName,
        agentLastName: el.agentLastName,
        payload: el.payload,
    };
};
