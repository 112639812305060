import { TFunction } from 'i18next';
import { CauseOfLoss } from '../../../domain/values/CauseOfLoss';
import Dropdown, { DropdownProps } from '../../atoms/dropdown/Dropdown';
import { GroupedData, Option } from '../../atoms/dropdown/Dropdown.types';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

export type CauseOfLossDropdownType = Partial<DropdownProps<CauseOfLoss>> & {
    selectedValue: CauseOfLoss | undefined;
    handleCauseOfLossChange?: (value: CauseOfLoss) => void;
};

const causeOfLossValues = (t: TFunction<('dataentry' | 'commons')[], undefined>): GroupedData<CauseOfLoss>[] => {
    return [
        {
            group: t('cause_of_loss.type.veterinary'),
            options: [
                { label: t(`cause_of_loss.type.${CauseOfLoss.ACCIDENT}`), value: CauseOfLoss.ACCIDENT },
                { label: t(`cause_of_loss.type.${CauseOfLoss.DISEASE}`), value: CauseOfLoss.DISEASE },
                { label: t(`cause_of_loss.type.${CauseOfLoss.OTHER}`), value: CauseOfLoss.OTHER },
            ],
        },
        {
            group: t('cause_of_loss.type.non_veterinary'),
            options: [
                {
                    label: t(`cause_of_loss.type.${CauseOfLoss.LOST_PET_ADVERTISING}`),
                    value: CauseOfLoss.LOST_PET_ADVERTISING,
                },
                {
                    label: t(`cause_of_loss.type.${CauseOfLoss.LOST_PET_REWARD}`),
                    value: CauseOfLoss.LOST_PET_REWARD,
                },
                {
                    label: t(`cause_of_loss.type.${CauseOfLoss.HOSPITAL_ACCOMMODATION}`),
                    value: CauseOfLoss.HOSPITAL_ACCOMMODATION,
                },
                {
                    label: t(`cause_of_loss.type.${CauseOfLoss.LOSS_DOCUMENTS_ABROAD}`),
                    value: CauseOfLoss.LOSS_DOCUMENTS_ABROAD,
                },
                {
                    label: t(`cause_of_loss.type.${CauseOfLoss.SACRIFICE_AND_INCINERATION_EXPENSES}`),
                    value: CauseOfLoss.SACRIFICE_AND_INCINERATION_EXPENSES,
                },
                { label: t(`cause_of_loss.type.${CauseOfLoss.UNKNOWN}`), value: CauseOfLoss.UNKNOWN },
            ],
        },
    ];
};

const getSelectedOption = (
    causeOfLoss: CauseOfLossDropdownType['selectedValue'],
    causesOfLoss: GroupedData<CauseOfLoss>[]
) => {
    if (causeOfLoss == undefined) return undefined;
    let selectedCauseOfLoss: Option<CauseOfLoss> | undefined = undefined;
    for (const group of causesOfLoss) {
        const option = group.options.find((el) => el.value === causeOfLoss);
        if (option != null) {
            selectedCauseOfLoss = option;
            break;
        }
    }
    return selectedCauseOfLoss;
};

const CauseOfLossDropdown = ({ selectedValue, handleCauseOfLossChange, ...props }: CauseOfLossDropdownType) => {
    const { t } = useModuleTranslation();

    const causesOfLoss = causeOfLossValues(t);
    const selectedCauseOfLoss = getSelectedOption(selectedValue, causesOfLoss);

    return (
        <Dropdown
            {...props}
            values={causesOfLoss}
            selectedOption={selectedCauseOfLoss}
            handleOptionClicked={(option) => handleCauseOfLossChange?.(option.value)}
            name="causeOfLoss"
            label={t('cause_of_loss.label')}
        />
    );
};

export default CauseOfLossDropdown;
