import { DiagnosisDto } from './DiagnosisDto';
import { toDomain } from './DiagnosisMapper';
import { Diagnosis } from '../../../domain/entities/Diagnosis';
import { DiagnosisRepository } from '../../../domain/repositories/DiagnosisRepository';
import { CollectionEntityResponse, flattenEntity, OperationMethod, processFetchOperation } from '../../api/index';

export const DiagnosisNomaRepository = (): DiagnosisRepository => ({
    findAll: async (): Promise<Diagnosis[]> => {
        return processFetchOperation(`/v1/diagnosis`, OperationMethod.GET).then(
            (dto: CollectionEntityResponse<DiagnosisDto>) => {
                return dto.data.map((el) => toDomain(flattenEntity(el, [])));
            }
        );
    },
});
