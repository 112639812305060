import ClinicalHistoryBanner from './clinical-history-banner/ClinicalHistoryBanner';
import ClinicalHistoryBody from './clinical-history-body/ClinicalHistoryBody';
import { ClinicalHistory, ClinicalHistoryDocument, Diagnosis } from '../../../domain/entities';
import { BlockingLoader } from '../../../ui/molecules';

export interface ClinicalHistoryDetailsProps {
    clinicalHistory?: ClinicalHistory;
    onRedirectBack: () => Promise<void>;
    onUpdateValidation: (validation: boolean) => void;
    onUpdateNote: (note: string) => void;
    onAddDisease: (key: string) => void;
    onRemoveDisease: (key: string) => void;
    loading: boolean;
    readOnly: boolean;
    clinicalHistoryDocuments: ClinicalHistoryDocument[];
    diagnosisOptions: Diagnosis[];
}

const ClinicalHistoryDetails = ({
    clinicalHistory,
    onRedirectBack,
    onUpdateValidation,
    onUpdateNote,
    onAddDisease,
    onRemoveDisease,
    loading,
    readOnly = false,
    clinicalHistoryDocuments,
    diagnosisOptions,
}: ClinicalHistoryDetailsProps) => {
    return (
        <>
            {!loading && (
                <div className="clinical-history-details clinical-history-details--container">
                    <ClinicalHistoryBanner handleIncidentRedirection={() => onRedirectBack()} />
                    <ClinicalHistoryBody
                        clinicalHistory={clinicalHistory}
                        readOnly={readOnly}
                        onUpdateValidation={onUpdateValidation}
                        onUpdateNote={onUpdateNote}
                        onAddDisease={onAddDisease}
                        onRemoveDisease={onRemoveDisease}
                        clinicalHistoryDocuments={clinicalHistoryDocuments}
                        diagnosisOptions={diagnosisOptions}
                    />
                </div>
            )}

            {loading && <BlockingLoader />}
        </>
    );
};

export default ClinicalHistoryDetails;
