import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { NumberOfPendingClaimRequest } from '../../domain/values/NumberOfPendingClaimRequest';

export class CountPendingClaimRequest {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(countryCode: string): Promise<NumberOfPendingClaimRequest> {
        return this.claimRequestRepository.countPendingClaimRequest(countryCode);
    }
}
