import Invoice from './Invoice';
import { Pet } from './Pet';

export class InvoicesDetails {
    invoices: Invoice[];
    petName: Pet['name'];

    constructor(
        invoices: Invoice[],
        private pet: Pet,
    ) {
        this.invoices = invoices;
        this.petName = pet?.name;
    }

    updateInvoices(updatedInvoice: Invoice): InvoicesDetails {
        const updatedInvoices = this.invoices?.map((invoice) =>
            invoice.supportingDocument.key == updatedInvoice.supportingDocument.key ? updatedInvoice : invoice,
        );

        return new InvoicesDetails(updatedInvoices, this.pet);
    }

    get areDigitalised(): boolean {
        return this.invoices.every((invoice) => invoice.isDigitalised) || false;
    }
}
