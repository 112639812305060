import { useEffect, useMemo, useState } from 'react';
import ClinicalHistoryDetails from './ClinicalHistoryDetails';
import { useDiagnosis as useDiagnosisWithRepository } from '../../../application';
import { DiagnosisNomaRepository } from '../../../infraestructure';
import { useClinicalHistory, useHref } from '../../../ui/hooks';
import { useClinicalHistoryDocuments } from '../../hooks/useClinicalHistoryDocuments';

type ClinicalHistoryContainerProps = {
    policyIdentifier: string;
};

const ClinicalHistoryContainer = ({ policyIdentifier }: ClinicalHistoryContainerProps) => {
    const [loading, setLoading] = useState(true);
    const { clinicalHistory, updateValidation, updateNote, addDisease, removeDisease } =
        useClinicalHistory(policyIdentifier);
    const { clinicalHistoryDocuments } = useClinicalHistoryDocuments(policyIdentifier);
    const useDiagnosis = useMemo(() => useDiagnosisWithRepository(DiagnosisNomaRepository()), []);

    const { goBack } = useHref();

    const onRedirectBack = async () => {
        goBack();
    };

    const { diagnosis } = useDiagnosis();

    useEffect(() => {
        if (clinicalHistory) {
            setLoading(false);
        }
    }, [clinicalHistory]);

    return (
        <ClinicalHistoryDetails
            clinicalHistory={clinicalHistory}
            onRedirectBack={onRedirectBack}
            loading={loading}
            readOnly={clinicalHistory?.isValidated || false}
            onUpdateValidation={updateValidation}
            onUpdateNote={updateNote}
            onAddDisease={addDisease}
            onRemoveDisease={removeDisease}
            clinicalHistoryDocuments={clinicalHistoryDocuments ?? []}
            diagnosisOptions={diagnosis}
        />
    );
};

export default ClinicalHistoryContainer;
