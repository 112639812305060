import { useState } from 'react';
import clsx from 'clsx';
import { PolicyChip } from './PolicyChip';
import { useFindContractDocument } from '../../../application';
import { FraudAlert } from '../../../domain/entities';
import { Policy } from '../../../domain/entities/Policy';
import { CalendarIcon, Card, Chip, ChipType, RefreshIcon } from '../../../ui/atoms';
import { stylesMode } from '../../../ui/styles';
import PolicyCardPolicyIdentifierDetails from '../../molecules/policy-card/PolicyCardPolicyIdentifierDetails';
import { getFlagEmoji } from '../../utils/index';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import { getDialogDetailsFromError } from '../dialog/dialogUtils';
import useDialog from '../dialog/useDialog';
import FraudAlertTooltip from '../fraud-alert-tooltip/FraudAlertTooltip';
import PetPoliciesModal from '../pet-policies-modal/PetPoliciesModal';
import './policy-card.modules.scss';

interface PolicyCardProps {
    policyIdentifier: Policy['identifier'];
    fraudAlerts?: FraudAlert[];
    countryCode?: string;
    setLoading: (loading: boolean) => void;
    tags?: string[];
    petName: string;
    policies: Policy[];
    onPolicyClick?: (policy: Policy) => void;
    contractIdsActiveOnClaimRequest: string[];
}

export default function PolicyCard({
    policyIdentifier,
    setLoading,
    fraudAlerts,
    countryCode,
    tags,
    petName,
    policies,
    onPolicyClick,
    contractIdsActiveOnClaimRequest,
}: PolicyCardProps) {
    const { t } = useModuleTranslation();
    const unknownValue = t('value.unknown', { ns: 'commons' });
    const { openDialog } = useDialog();
    const { viewContractDocument, downloadContractDocument } = useFindContractDocument();

    const [showPoliciesModal, setShowPoliciesModal] = useState(false);

    const policy = policies.find((policy) => policy.identifier === policyIdentifier)!;

    const handleViewDocumentClick = async () => {
        try {
            setLoading(true);
            viewContractDocument(policyIdentifier).then(() => setLoading(false));
        } catch (e) {
            setLoading(false);
            openDialog(t('document.visualisation_error'), getDialogDetailsFromError(e));
        }
    };

    const handleDownloadDocumentClick = async () => {
        try {
            setLoading(true);
            downloadContractDocument(policyIdentifier).then(() => setLoading(false));
        } catch (e) {
            setLoading(false);
            openDialog(t('document.download_error'), getDialogDetailsFromError(e));
        }
    };

    const policyTitle = policy.productType
        ? getFlagEmoji(countryCode) +
          ' ' +
          t(`policy.${policy.productType.replace(/(.*)_\w{2}$/, '$1').toLowerCase()}.name`, policy.productType)
        : t(`policy.not_found`);

    const policyItemStyle = stylesMode({ with: 'policy', prefix: 'policy', modes: ['description-item'] });

    const startDateFormatted = policy.startDate ? Intl.DateTimeFormat('es').format(policy.startDate) : undefined;
    const expirationDateFormatted = policy.expirationDate
        ? Intl.DateTimeFormat('es').format(policy.expirationDate)
        : undefined;

    const details = [
        {
            icon: CalendarIcon,
            text: startDateFormatted ? t('policy.started_on', { startDate: startDateFormatted }) : unknownValue,
        },
        {
            icon: RefreshIcon,
            text: expirationDateFormatted
                ? t('policy.expires_on', { expirationDate: expirationDateFormatted })
                : unknownValue,
        },
    ];

    const fraudAlert = fraudAlerts && fraudAlerts.length > 0 ? <FraudAlertTooltip /> : undefined;
    const contractsApplying = policy?.getActiveContracts(contractIdsActiveOnClaimRequest);
    const policyInfo = contractsApplying ? (
        <>
            {contractsApplying.map((contract, index) =>
                contract ? <span key={index}>{contract.productVersionTag}</span> : undefined
            )}
        </>
    ) : undefined;
    const policyStatusChip = policy.status ? (
        <section>
            <PolicyChip status={policy.status}></PolicyChip>{' '}
            {tags && tags.length > 0 && (
                <div className="policy-tags">
                    {tags.map((tag, index) => (
                        <Chip type={ChipType.NEUTRAL} text={tag} accessibilityText={tag} key={index} />
                    ))}
                </div>
            )}
        </section>
    ) : undefined;
    const policyTitleComponent = (
        <div className="policy-card-title">
            <span>{policyTitle}</span>
            {fraudAlert}
        </div>
    );

    const policySubheader = (
        <div className="policy-card-sub-header">
            {policyStatusChip}
            <div className="policy-info-container">{policyInfo}</div>
        </div>
    );

    const handlePolicyClick = () => {
        return onPolicyClick != null
            ? (policy: Policy) => {
                  onPolicyClick(policy);
                  setShowPoliciesModal(false);
              }
            : undefined;
    };

    return (
        <Card
            title={policyTitleComponent}
            className={clsx('card--policy', policy.status == null && 'card--policy__not-found')}
            subheader={policySubheader}
        >
            <PolicyCardPolicyIdentifierDetails
                onViewDocument={handleViewDocumentClick}
                onDownloadDocument={handleDownloadDocumentClick}
                onShowPoliciesClick={() => setShowPoliciesModal(true)}
                policyIdentifier={policyIdentifier}
            />
            <div className="policy-details">
                {details.map((details, i) => (
                    <div key={i} className={policyItemStyle}>
                        <details.icon className="icon" />
                        {details.text}
                    </div>
                ))}
            </div>
            {showPoliciesModal && (
                <PetPoliciesModal
                    name={petName}
                    policies={policies}
                    onClose={() => setShowPoliciesModal(false)}
                    onPolicyClick={handlePolicyClick()}
                />
            )}
        </Card>
    );
}
