import { UseCase } from '@barkibu/noma-commons';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';

export class FindAssessment implements UseCase<Assessment> {
    private assessmentRepository: AssessmentRepository;

    constructor(assessmentRepository: AssessmentRepository) {
        this.assessmentRepository = assessmentRepository;
    }

    async execute(criteria: { assessmentId: string; claimRequestId: string }): Promise<Assessment> {
        if (criteria.assessmentId) {
            return this.assessmentRepository.findById(criteria.assessmentId);
        } else {
            return this.assessmentRepository.findByClaimRequestId(criteria.claimRequestId);
        }
    }
}
