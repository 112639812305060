import { UseCase } from '@barkibu/noma-commons';
import { Assessment } from '../../domain/assessment/Assessment';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';
import { DiagnosisType } from '../../domain/values/DiagnosisType';

export class AddDiagnosisToAssessment implements UseCase<Assessment> {
    private assessmentRepository: AssessmentRepository;

    constructor(assessmentRepository: AssessmentRepository) {
        this.assessmentRepository = assessmentRepository;
    }

    async execute(assessmentKey: string, diagnosisKey: string, diagnosisType: DiagnosisType): Promise<Assessment> {
        return this.assessmentRepository.addDiagnosis(assessmentKey, {
            diagnosisKey: diagnosisKey,
            diagnosisType: diagnosisType,
        });
    }
}
