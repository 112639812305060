import clsx from 'clsx';
import { ClinicalHistory } from '../../../domain/entities';
import { Button, ButtonVariant, Card, Chip, ChipType, EyeIcon } from '../../../ui/atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './clinical-history-card.modules.scss';

export type ClinicalHistoryCardProps = {
    clinicalHistory: ClinicalHistory;
    moveToClinicalHistoryScreen: () => void;
    style?: ClinicalHistoryCardStyle;
};

export enum ClinicalHistoryCardStyle {
    NORMAL,
    BIG,
}

const ClinicalHistoryCard = ({ clinicalHistory, moveToClinicalHistoryScreen, style }: ClinicalHistoryCardProps) => {
    const { t } = useModuleTranslation();

    return (
        <Card>
            <div
                className={clsx('clinical-history-card', {
                    'clinical-history-card--big': style == ClinicalHistoryCardStyle.BIG,
                })}
            >
                <span className="clinical-history-card__title">{t('clinical_history.title')}</span>
                <div className="actions">
                    <Button
                        text={clinicalHistory?.isValidated ? undefined : 'Validate'}
                        onClick={() => moveToClinicalHistoryScreen()}
                        variantName={ButtonVariant.BASIC_INFO}
                        icon={clinicalHistory?.isValidated ? EyeIcon : undefined}
                        accessibilityText={t('clinical_history.view_clinical_history')}
                    ></Button>
                </div>
                <div className="chips">
                    {clinicalHistory?.isValidated ? (
                        <Chip type={ChipType.SUCCESS} text={t('clinical_history.complete')} />
                    ) : (
                        <Chip type={ChipType.WARNING} text={t('clinical_history.incomplete')} />
                    )}
                    {clinicalHistory?.preexistentDiseases != undefined &&
                    clinicalHistory?.preexistentDiseases?.length > 0 ? (
                        clinicalHistory.preexistentDiseases.map((el) => (
                            <Chip key={el.key} type={ChipType.NEUTRAL} text={el.description} />
                        ))
                    ) : (
                        <Chip type={ChipType.WARNING} text={t('clinical_history.no_preexisting_conditions')} />
                    )}
                </div>
                {clinicalHistory?.note && (
                    <div className="note">
                        <label className="note__title">{t('clinical_history.note_label')}</label>
                        <p>{clinicalHistory.note}</p>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default ClinicalHistoryCard;
