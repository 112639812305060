import React from 'react';
import { Outlet } from 'react-router-dom';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import './layout.modules.scss';

export type LayoutProps = {
    children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    const { t } = useModuleTranslation();

    return (
        <main className="app-layout">
            <header className="app-layout-header">
                <h1 className="app-layout-header--logo">
                    <a href={'/claim/inbox'}>{t('app.title', { ns: 'commons' })}</a>
                </h1>
            </header>
            {children != null ? children : <Outlet />}
        </main>
    );
};

export default Layout;
