import { useEffect, useRef, useState } from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    Modal,
    MoreIcon,
    SupportingDocumentType,
    TrashIcon,
} from '@barkibu/noma-commons';
import { useUploadFileToTemporaryStorage } from './useUploadFileToTemporaryStorage';
import {
    EditableSupportingDocument,
    SupportingDocument,
    SupportingDocumentToModify,
    SupportingDocumentToUpload,
} from '../../../../domain/entities/supporting-document';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import SupportingDocumentTypeSelector from '../../molecules/supporting-document-type-selector/SupportingDocumentTypeSelector';
import './add-documents-modal.modules.scss';

export type AddDocumentsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (newDocuments: EditableSupportingDocument[]) => void;
    document?: SupportingDocument;
};

const AddDocumentsModal = ({ isOpen, onClose, onSave, document }: AddDocumentsModalProps) => {
    const { t } = useModuleTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const [documentType, setDocumentType] = useState<SupportingDocumentType>();
    const [documents, setDocuments] = useState<EditableSupportingDocument[]>([]);

    const { uploadFileToTemporaryStorage } = useUploadFileToTemporaryStorage();

    useEffect(() => {
        if (document) {
            setDocumentType(document.type);
            setDocuments([new SupportingDocumentToModify(document)]);
        } else {
            setDocumentType(undefined);
            setDocuments([]);
        }
    }, [isOpen]);

    const allowSave = documents.length > 0 && documentType != undefined;

    const onSaveClicked = () => {
        onSave(documents.map((document) => ({ ...document, type: documentType })));
    };

    const onDocumentTypeSelected = (newDocumentType: SupportingDocumentType) => {
        setDocumentType(newDocumentType);
    };

    const onDocumentsAdded = async (newDocumentFiles: FileList | null) => {
        if (newDocumentFiles) {
            const temporaryUrls = await Promise.all(
                Array.from(newDocumentFiles)
                    .filter((newDocument) => documents.every((doc) => doc.name !== newDocument.name || doc.toDelete))
                    .map(async (file: File) => await uploadFileToTemporaryStorage.execute(normalizeFile(file)))
            );
            const newDocuments = temporaryUrls.map((url) => new SupportingDocumentToUpload(url, documentType));
            setDocuments([...documents, ...newDocuments]);
        }
    };

    const onDocumentRemove = (documentName: string) => {
        setDocuments((documents) => documents.filter((doc) => doc.name != documentName));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('incident.document.add_modal_title')}
            className="add-documents-modal"
        >
            <div className="add-documents-modal__body">
                <div className="add-documents-modal__content">
                    <div className="add-documents-modal__upload">
                        <span className="label">{t('incident.document.upload')}</span>
                        <div className="add-documents-modal__upload__actions">
                            <Button
                                text={t('incident.document.add')}
                                variantName={ButtonVariant.SECONDARY}
                                icon={MoreIcon}
                                onClick={() => inputRef.current?.click()}
                            />
                            <div className="add-documents-modal__upload__documents">
                                {documents
                                    .filter((doc) => !doc.toDelete)
                                    .map((document) => (
                                        <Chip
                                            key={document.name}
                                            text={document.name}
                                            type={ChipType.ACTIONABLE}
                                            onClick={() => onDocumentRemove(document.name)}
                                            icon={TrashIcon}
                                        />
                                    ))}
                            </div>
                            <form ref={formRef}>
                                <input
                                    ref={inputRef}
                                    data-testid="upload-files-input"
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={(event) => onDocumentsAdded(event.target.files)}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="add-documents-modal__document-type">
                        <SupportingDocumentTypeSelector
                            documentType={documentType}
                            onDocumentTypeSelectedHandler={onDocumentTypeSelected}
                        />
                    </div>
                </div>
                <div className="add-documents-modal__actions">
                    <Button disabled={!allowSave} text={t('incident.document.save')} onClick={onSaveClicked} />
                </div>
            </div>
        </Modal>
    );
};

export default AddDocumentsModal;

function normalizeFile(file: File) {
    //eslint-disable-next-line no-useless-escape
    return new File([file], decodeURI(file.name.replace(/[^a-zA-Z0-9\.\_\-]/g, '')), { type: file.type });
}
