export const useVetTypeform = () => {
    const getTypeformLink = (countryCode: string) => {
        const url = process.env[`REACT_APP_VET_TYPEFORM_${countryCode.toUpperCase()}`];
        if (!url) {
            throw new Error(`No Vet form Typeform link found for country ${countryCode}`);
        }
        return url;
    };
    const getPrintableLink = (countryCode: string) => {
        const url = process.env[`REACT_APP_VET_PRINTABLE_FORM_${countryCode.toUpperCase()}`];
        if (!url) {
            throw new Error(`No printable vet form link found for country ${countryCode}`);
        }
        return url;
    };

    const getVetFormTag = (label: string, claimRequestKey: string, countryCode: string) =>
        `<a href='${getTypeformLink(countryCode)}#claimrequestid=${claimRequestKey}'>${label}</a>`;
    const getPrintableVetFormLink = (label: string, countryCode: string) =>
        `<a href='${getPrintableLink(countryCode)}'>${label}</a>`;
    const replaceVetTypeformTokens = (message: string, tokens: Map<string, string>): string => {
        let result = message;

        tokens.forEach((value, key) => {
            result = result.replaceAll(key, value);
        });

        return result;
    };

    return { replaceVetTypeformTokens, getVetFormTag, getPrintableVetFormLink };
};
