import { useEffect, useState } from 'react';
import { ClaimRequest, MultiApiError, OperationStatus, useAsync, UseAsyncStatus } from '@barkibu/noma-commons';
import { FindAdjustments } from './FindAdjustments';
import { Adjustment } from '../../domain/adjustment/Adjustment';
import { AdjustmentRepository } from '../../domain/adjustment/AdjustmentRepository';
import { AssessmentRepository } from '../../domain/assessment/AssessmentRepository';
import { FindAssessmentInitializationStatus } from '../assessment/FindAssessmentInitializationStatus';

export const useAdjustments = (
    assessmentRepository: AssessmentRepository,
    adjustmentRepository: AdjustmentRepository
) => {
    const findAdjustments = FindAdjustments(adjustmentRepository);
    const findAssessmentInitialization = new FindAssessmentInitializationStatus(assessmentRepository);

    return (claimRequestKey: string, options: { timeout: number } = { timeout: 500 }) => {
        const [syncError, setSyncError] = useState<Error | undefined>(undefined);
        const [status, setStatus] = useState(OperationStatus.IN_PROGRESS);
        const [pendingRequests, setPendingRequests] = useState<number>(0);

        const {
            execute: executeFindAssessmentInitialization,
            value: assessmentInitializationStatus,
            status: findAssessmentInitializationStatus,
            error: findAssessmentInitializationError,
        } = useAsync<OperationStatus>(findAssessmentInitialization);

        const {
            execute: executeFindAdjustments,
            status: findAdjustmentsStatus,
            error: findAdjustmentsError,
            value: adjustments,
        } = useAsync<Adjustment[] | undefined>(findAdjustments);

        useEffect(() => {
            setSyncError(findAdjustmentsError);
        }, [findAdjustmentsError]);

        useEffect(() => {
            if (assessmentInitializationStatus == OperationStatus.COMPLETED) {
                setStatus(assessmentInitializationStatus);
            } else if (claimRequestKey != null) {
                let timeout: NodeJS.Timeout;

                const inProgress = findAssessmentInitializationStatus === UseAsyncStatus.PENDING;

                if (!inProgress) {
                    if (adjustments == null) {
                        timeout = setTimeout(() => {
                            executeFindAssessmentInitialization(claimRequestKey);
                        }, options.timeout);
                    }
                }

                if (findAssessmentInitializationStatus === UseAsyncStatus.ERROR) {
                    if (findAssessmentInitializationError instanceof MultiApiError) {
                        if (findAssessmentInitializationError.status == 404) {
                            setStatus(OperationStatus.COMPLETED);
                        }
                    }
                }

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [claimRequestKey, adjustments, findAssessmentInitializationStatus, assessmentInitializationStatus]);

        useEffect(() => {
            if (status == OperationStatus.COMPLETED) {
                executeFindAdjustments(claimRequestKey);
            }
        }, [status]);

        const changeConceptRecommendation = async (conceptKey: string, recommendation: string, covered: boolean) => {
            const adjustment = adjustments?.find((el) => el.concepts?.some((concept) => concept.key == conceptKey));
            try {
                if (adjustment == null) throw new Error('Adjustment not found');
                setPendingRequests((prev) => prev + 1);
                await adjustmentRepository.changeConceptRecommendation(
                    adjustment?.key,
                    conceptKey,
                    recommendation,
                    covered
                );
            } catch (e) {
                if (e instanceof Error) {
                    setSyncError(e);
                }
            } finally {
                setPendingRequests((prev) => prev - 1);
            }
        };

        const findAdjustmentDuplicatedClaimRequests = async (adjustmentKey: string): Promise<ClaimRequest[]> => {
            return await adjustmentRepository.findAdjustmentDuplicatedClaimRequests(adjustmentKey);
        };

        return {
            adjustments,
            adjustmentFetchStatus: findAdjustmentsStatus,
            changeConceptRecommendation,
            changeAdjustmentConceptRecommendationStatus:
                pendingRequests > 0 ? UseAsyncStatus.PENDING : UseAsyncStatus.IDLE,
            syncError,
            findAdjustmentDuplicatedClaimRequests,
        };
    };
};
