import { AddClinicalHistoryDiseaseDto } from './AddClinicalHistoryDiseaseDto';
import { documentToDomain } from './ClinicalHistoryDocumentMapper';
import { toDomain } from './ClinicalHistoryMapper';
import { DeleteClinicalHistoryDiseaseDto } from './DeleteClinicalHistoryDiseaseDto';
import { UpdateClinicalHistoryNoteDto } from './UpdateClinicalHistoryNoteDto';
import { UpdateClinicalHistoryValidationDto } from './UpdateClinicalHistoryValidationDto';
import { ClinicalHistoryDocument } from '../../../domain/entities';
import { ClinicalHistory } from '../../../domain/entities/ClinicalHistory';
import { ClinicalHistoryRepository } from '../../../domain/repositories';
import {
    CollectionEntityResponse,
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '../../api';
import { ClinicalHistoryDocumentDto } from '../dtos/ClinicalHistoryDocument';
import { ClinicalHistoryDto } from '../dtos/ClinicalHistoryDto';

export class ClinicalHistoryNomaRepository implements ClinicalHistoryRepository {
    findByPolicyIdentifier(policyIdentifier: string): Promise<ClinicalHistory> {
        return processFetchOperation(
            `/v1/policies/${policyIdentifier}/clinical-histories`,
            OperationMethod.GET,
            {}
        ).then((dto: SingleEntityResponse<ClinicalHistoryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }
    findDocumentsByPolicyIdentifier(policyIdentifier: string): Promise<ClinicalHistoryDocument[]> {
        return processFetchOperation(
            `/v1/policies/${policyIdentifier}/clinical-histories/documents`,
            OperationMethod.GET,
            {}
        ).then((dto: CollectionEntityResponse<ClinicalHistoryDocumentDto>) => {
            return dto.data.map((el) => documentToDomain(flattenEntity(el, [])));
        });
    }
    updateValidation(
        policyIdentifier: string,
        updateClinicalHistoryValidationDto: UpdateClinicalHistoryValidationDto
    ): Promise<ClinicalHistory> {
        return processFetchOperation(
            `/v1/policies/${policyIdentifier}/clinical-histories/validation`,
            OperationMethod.POST,
            { body: JSON.stringify(updateClinicalHistoryValidationDto) }
        ).then((dto: SingleEntityResponse<ClinicalHistoryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }
    updateNote(
        policyIdentifier: string,
        updateClinicalHistoryNoteDto: UpdateClinicalHistoryNoteDto
    ): Promise<ClinicalHistory> {
        return processFetchOperation(`/v1/policies/${policyIdentifier}/clinical-histories/note`, OperationMethod.POST, {
            body: JSON.stringify(updateClinicalHistoryNoteDto),
        }).then((dto: SingleEntityResponse<ClinicalHistoryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }
    addDisease(
        policyIdentifier: string,
        addClinicalHistoryDiseaseDto: AddClinicalHistoryDiseaseDto
    ): Promise<ClinicalHistory> {
        return processFetchOperation(
            `/v1/policies/${policyIdentifier}/clinical-histories/disease`,
            OperationMethod.POST,
            { body: JSON.stringify(addClinicalHistoryDiseaseDto) }
        ).then((dto: SingleEntityResponse<ClinicalHistoryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }
    deleteDisease(
        policyIdentifier: string,
        deleteClinicalHistoryDiseaseDto: DeleteClinicalHistoryDiseaseDto
    ): Promise<ClinicalHistory> {
        return processFetchOperation(
            `/v1/policies/${policyIdentifier}/clinical-histories/disease`,
            OperationMethod.DELETE,
            { body: JSON.stringify(deleteClinicalHistoryDiseaseDto) }
        ).then((dto: SingleEntityResponse<ClinicalHistoryDto>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }
}
