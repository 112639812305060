import { ButtonVariant } from '../../atoms/button/Button';
import Dialog, { DialogType } from '../../organisms/dialog/Dialog';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

export type DuplicateClaimConfirmationDialogProps = {
    isOpen: boolean;
    onSave: () => void;
    onCancel: () => void;
};

const DuplicateClaimConfirmationDialog = ({ isOpen, onSave, onCancel }: DuplicateClaimConfirmationDialogProps) => {
    const { t } = useModuleTranslation();
    return (
        <Dialog
            isOpen={isOpen}
            showCloseButton
            type={DialogType.Confirmation}
            title={t('duplicate_claim_confirmation_dialog.title') || undefined}
            text={t('duplicate_claim_confirmation_dialog.text')}
            actions={[
                {
                    variantName: ButtonVariant.INFO,
                    text: t('duplicate_claim_confirmation_dialog.duplicate'),
                    onClick: () => onSave(),
                },
                {
                    variantName: ButtonVariant.BASIC_INFO,
                    text: t('duplicate_claim_confirmation_dialog.cancel'),
                    onClick: () => onCancel(),
                },
            ]}
            onClose={() => onCancel()}
        />
    );
};

export default DuplicateClaimConfirmationDialog;
