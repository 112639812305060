export enum CauseOfLoss {
    ACCIDENT = 'ACCIDENT',
    DISEASE = 'DISEASE',
    OTHER = 'OTHER',
    LOST_PET_ADVERTISING = 'LOST_PET_ADVERTISING',
    LOST_PET_REWARD = 'LOST_PET_REWARD',
    HOSPITAL_ACCOMMODATION = 'HOSPITAL_ACCOMMODATION',
    LOSS_DOCUMENTS_ABROAD = 'LOSS_DOCUMENTS_ABROAD',
    SACRIFICE_AND_INCINERATION_EXPENSES = 'SACRIFICE_AND_INCINERATION_EXPENSES',
    UNKNOWN = 'UNKNOWN',
}
