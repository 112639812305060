import { CloseClaimReason } from '../../domain/entities';
import { CloseClaimReasonRepository } from '../../domain/repositories';

export class CloseClaimReasonsStaticRepository implements CloseClaimReasonRepository {
    async findAll(): Promise<CloseClaimReason[]> {
        return Promise.resolve([
            {
                key: 'MISSING_INFO',
                description: 'Missing information',
            },
            {
                key: 'DUPLICATED',
                description: 'Duplicated claim',
            },
            {
                key: 'ERRONEOUS',
                description: 'Erroneous claim',
            },
            {
                key: 'PET_PARENT_ASK_TO_CLOSE',
                description: 'Pet parent asked to close the claim',
            },
            {
                key: 'POLICY_NOT_ACTIVE',
                description: 'Policy not active (canceled/suspended/closed) when the incident happened',
            },
        ]);
    }
}
