import { ReactNode } from 'react';
import { Tooltip } from '../../atoms/index';
import { TooltipProps } from '../../atoms/tooltip/Tooltip';
import './IconTooltip.modules.scss';

export type IconTooltipProps = {
    className: string;
    icon: ReactNode;
} & TooltipProps;

export default function IconTooltip({ icon, children, position, className }: IconTooltipProps) {
    return (
        <div className={`icon-tooltip ${className}`}>
            <i className="icon-tooltip__icon">{icon}</i>
            <Tooltip position={position}>{children}</Tooltip>
        </div>
    );
}
