import { SupportingDocumentDeletionReason } from '@barkibu/noma-commons';
import { SupportingDocument } from '../../domain/entities/supporting-document';
import { SupportingDocumentRepository } from '../../domain/repositories/SupportingDocumentRepository';

export interface DeleteIncidentSupportingDocumentResult {
    supportingDocument: SupportingDocument;
}
export class DeleteIncidentSupportingDocument {
    constructor(private supportingDocumentRepository: SupportingDocumentRepository) {}

    async execute(
        supportingDocumentKey: string,
        deletionReason: SupportingDocumentDeletionReason
    ): Promise<DeleteIncidentSupportingDocumentResult> {
        return {
            supportingDocument: await this.supportingDocumentRepository.delete(supportingDocumentKey, deletionReason),
        };
    }
}
