import { User } from '../../../domain/entities';
import { ClaimRequestRepository } from '../../../domain/repositories';

export class StealClaimRequestReservation {
    constructor(private claimRequestRepository: ClaimRequestRepository) {}

    async execute(claimRequestKey: string): Promise<User> {
        return await this.claimRequestRepository.stealReservation(claimRequestKey);
    }
}
