import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { UseCase } from '../UseCase';

export enum UseAsyncStatus {
    IDLE = 'idle',
    PENDING = 'pending',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface UseAsyncResult<T> {
    execute: (...args: any) => void;
    status: UseAsyncStatus;
    value: T | undefined;
    setValue: Dispatch<SetStateAction<T | undefined>>;
    error: Error | undefined;
}

export const useAsync = <T>(useCase: UseCase<T>): UseAsyncResult<T> => {
    const [status, setStatus] = useState<UseAsyncStatus>(UseAsyncStatus.IDLE);
    const [value, setValue] = useState<T | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const execute = useCallback(
        (...args: any) => {
            setStatus(UseAsyncStatus.PENDING);
            setValue(undefined);
            return useCase
                .execute(...args)
                .then((response: T) => {
                    setValue(response);
                    setStatus(UseAsyncStatus.SUCCESS);
                })
                .catch((e) => {
                    setError(e);
                    setStatus(UseAsyncStatus.ERROR);
                });
        },
        [useCase.execute]
    );
    return { execute, status, value, setValue, error };
};
