import { config, urlQueryParamsBuilder } from '@barkibu/noma-commons';
import { FilesRepository } from '../../../domain/repositories/FilesRepository';

export class FilesNomaRepository implements FilesRepository {
    uploadTemporary = async (file: File): Promise<string> => {
        const data = new FormData();
        data.append('file', file);

        return fetch(urlQueryParamsBuilder('/v1/upload/temporary', {}), {
            method: 'POST',
            headers: config.MULTIPART_REQUEST_API_HEADERS(),
            body: data,
        }).then((response) => {
            return response.text();
        });
    };
}
