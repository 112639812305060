import { ClaimRequestRepository } from '../../domain/repositories/ClaimRequestRepository';
import { CreateIncidentDto } from '../../infraestructure/repositories/dtos/CreateIncidentDto';

export class CreateIncident {
    private claimRequestRepository: ClaimRequestRepository;

    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }

    async execute(createIncidentDto: CreateIncidentDto): Promise<string> {
        return this.claimRequestRepository.createNewIncident(createIncidentDto);
    }
}
