import { useRef, useState } from 'react';
import { GroupedData, isGroupedDataArray, Option } from './Dropdown.types';
import { useDetectClickOutside } from '../../hooks';
import { includes } from '../../utils/StringUtils';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import Input from '../input/Input';
import PopoverList from '../popover-list/PopoverList';
import './dropdown.modules.scss';

export type DropdownProps<T> = {
    values: Option<T>[] | GroupedData<T>[];
    selectedOption?: Option<T>;
    handleOptionClicked: (option: Option<T>) => void;
    label: string;
    name?: string;
    errorMessage?: string;
    touched?: boolean;
    setTouched?: () => void;
    disabled?: boolean;
    hideDeletedOptions?: boolean;
};

const Dropdown = <T,>({
    selectedOption,
    values,
    handleOptionClicked,
    label,
    name,
    errorMessage,
    touched,
    setTouched,
    disabled = false,
    hideDeletedOptions = true,
}: DropdownProps<T>) => {
    const { t } = useModuleTranslation();
    const [showPopover, setShowPopover] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);
    const ref = useDetectClickOutside({
        onTriggered: () => {
            if (showPopover) {
                setTouched?.();
                inputRef.current?.blur();
                setSearchValue('');
                setShowPopover(false);
            }
        },
    });

    const filterOption = (option: Option<T>) =>
        includes(option.label, searchValue) && (!hideDeletedOptions || option.deletedAt == null);

    const displayedValue = showPopover ? searchValue : `${selectedOption?.label ?? ''}`;
    const filteredValues = isGroupedDataArray(values)
        ? (values
              .map((value) => ({ group: value.group, options: value.options.filter(filterOption) }))
              .filter((value) => value.options.length != 0) as GroupedData<T>[])
        : values.filter(filterOption);

    const popoverHandleClick = (option: Option<T>) => {
        setSearchValue('');
        setShowPopover(false);
        handleOptionClicked(option);
    };

    const openPopover = () => !disabled && setShowPopover(true);

    return (
        <div className="dropdown-container" onClick={openPopover} ref={ref}>
            <Input
                className={`dropdown-input ${displayedValue ? 'dropdown-input--display-value' : ''} ${
                    errorMessage ? 'dropdown-input--error' : ''
                }`}
                value={displayedValue}
                onChange={(newValue) => setSearchValue(newValue)}
                label={label}
                name={name}
                errorMessage={errorMessage}
                touched={touched}
                disabled={disabled}
                ref={inputRef}
            />
            {showPopover && (
                <div className="dropdown-popover-container">
                    <PopoverList
                        values={filteredValues}
                        handleClick={popoverHandleClick}
                        fallback={<span className={'popover-fallback-message'}>{t('dropdown.no_data')}</span>}
                    />
                </div>
            )}
        </div>
    );
};

export default Dropdown;
