import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import './button-group.modules.scss';
type NativeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type ButtonGroupProps = Omit<NativeProps, 'ref'> & {
    children: React.ReactNode;
    variantName?: string;
};

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(({ children, variantName }, ref) => {
    return (
        <div className={`button-group button-group--${variantName}`} ref={ref}>
            {children}
        </div>
    );
});
ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
