import { useEffect, useState } from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    Input,
    Modal,
    SupportingDocumentDeletionReason,
    SupportingDocumentType,
} from '@barkibu/noma-commons';
import {
    EditableSupportingDocument,
    SupportingDocument,
    SupportingDocumentToModify,
    SupportingDocumentToUpload,
} from '../../../../domain/entities/supporting-document';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import SupportingDocumentTypeSelector from '../../molecules/supporting-document-type-selector/SupportingDocumentTypeSelector';
import './update-document-modal.scss';

export type UpdateDocumentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (updatedDocuments: EditableSupportingDocument[]) => void;
    onRemove: (deletionReason: SupportingDocumentDeletionReason) => void;
    document?: SupportingDocument;
};

const UpdateDocumentModal = ({ isOpen, onClose, onSave, onRemove, document }: UpdateDocumentModalProps) => {
    const [documentName, setDocumentName] = useState<string>();
    const [errorMessage, setErrorMessage] = useState();
    const [documentType, setDocumentType] = useState<SupportingDocumentType>();
    const { t } = useModuleTranslation();

    useEffect(() => {
        if (document) {
            setDocumentName(document.name);
            setDocumentType(document.type);
        } else {
            setDocumentName(undefined);
            setDocumentType(undefined);
            setErrorMessage(undefined);
        }
    }, [isOpen]);

    const allowSave = (document?.name != documentName || document?.type != documentType) && !errorMessage;

    const onDocumentNameChange = (newDocumentName: string) => {
        setDocumentName(newDocumentName);
    };

    const onDocumentTypeChange = (newDocumentType: SupportingDocumentType) => {
        setDocumentType(newDocumentType);
    };

    const handleSave = () => {
        if (document?.key) {
            onSave([new SupportingDocumentToModify({ ...document, name: documentName!, type: documentType })]);
        } else {
            onSave([new SupportingDocumentToUpload(document?.location!, documentType, documentName)]);
        }
    };

    const [isDeletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
    const [deletionReason, setDeletionReason] = useState<SupportingDocumentDeletionReason | undefined>();
    const clearDeletionModal = () => {
        setDeletionModalOpen(false);
        setDeletionReason(undefined);
    };
    const confirmDeletionReason = () => {
        if (!deletionReason) return;
        setDeletionModalOpen(false);
        onRemove(deletionReason);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={t('incident.document.edit')} className="update-document-modal">
            <div className="update-document-modal__body">
                <div className="update-document-modal__content">
                    <div className="update-document-modal__upload">
                        <span className="label">{t('incident.document.edit_name')}</span>
                        <div className="update-document-modal__name">
                            <Input
                                value={documentName!}
                                onChange={onDocumentNameChange}
                                label={t('incident.document.name')}
                                errorMessage={errorMessage}
                            />
                        </div>
                    </div>
                    <div className="update-document-modal__document-type">
                        <SupportingDocumentTypeSelector
                            key={document?.key}
                            documentType={documentType}
                            onDocumentTypeSelectedHandler={onDocumentTypeChange}
                        />
                    </div>
                </div>
                <div className="update-document-modal__actions">
                    <Button disabled={!allowSave} text={t('incident.document.save')} onClick={() => handleSave()} />
                </div>
                <div className="update-document-modal__actions">
                    <Button
                        variantName={ButtonVariant.DANGER}
                        text={t('incident.document.deletion_modal.delete')}
                        onClick={() => setDeletionModalOpen(true)}
                    />
                </div>
            </div>
            <Modal
                isOpen={isDeletionModalOpen}
                onClose={clearDeletionModal}
                title={t('document.deletion_modal.title')}
                className="document-deletion-modal"
            >
                <div className="document-deletion-modal__body">
                    <div className="document-deletion-modal__content">
                        <div className="supporting-document-deletion-reason-selector">
                            {Object.values(SupportingDocumentDeletionReason).map((description) => (
                                <Chip
                                    key={description}
                                    type={ChipType.OPTION}
                                    text={t(`document.deletion_modal.reason.${description}`)}
                                    checked={deletionReason === description}
                                    onClick={() => setDeletionReason(description)}
                                />
                            ))}
                        </div>
                        <div className="document-deletion-modal__actions">
                            <Button
                                type="button"
                                variantName={ButtonVariant.DANGER}
                                text={t('document.deletion_modal.confirm')}
                                onClick={confirmDeletionReason}
                                disabled={!deletionReason}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </Modal>
    );
};

export default UpdateDocumentModal;
