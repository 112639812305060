import { useEffect, useState } from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    dateFormatter,
    Modal,
    StructuredContent,
    VetReportStructuredContent,
} from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './detail-document-modal.scss';

export type DetailDocumentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    documentDetail?: StructuredContent;
    onMarkAsMissingInfo?: () => void;
};

const DetailDocumentModal = ({ isOpen, onClose, documentDetail, onMarkAsMissingInfo }: DetailDocumentModalProps) => {
    const { t } = useModuleTranslation();

    const [structuredContent, setStructuredContent] = useState<StructuredContent | undefined>(undefined);
    useEffect(() => {
        setStructuredContent(documentDetail);
    }, [documentDetail]);

    const parseStringOrDate = (input: string): string => {
        return new Date(input).toString() !== 'Invalid Date' ? dateFormatter(new Date(input)) : input;
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('incident.document.detail.title')}
            className="detail-document-modal"
        >
            {structuredContent && (
                <div className="detail-document-modal__body">
                    <div className="detail-document-modal__content">
                        {structuredContent && (
                            <div className="detail-document-items">
                                {Object.keys(structuredContent).map((key) => {
                                    if (key == 'score' || key == 'isValid') return null;
                                    const vetReportContent = structuredContent as VetReportStructuredContent;
                                    const structuredContentKey =
                                        vetReportContent[key as keyof VetReportStructuredContent];

                                    return (
                                        <div className="item" key={key}>
                                            <div className="key">
                                                {t(
                                                    'incident.document.detail.vet_report.' +
                                                        key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
                                                )}
                                            </div>
                                            <div className="value">
                                                {Array.isArray(structuredContentKey) ? (
                                                    <ul>
                                                        {structuredContentKey.map((item: string, index: number) => (
                                                            <li key={index}>{parseStringOrDate(item)}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>
                                                        {structuredContentKey &&
                                                            parseStringOrDate(structuredContentKey.toString())}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    {structuredContent && 'score' in structuredContent && 'isValid' in structuredContent && (
                        <div className="detail-document-modal__document-score">
                            <Chip
                                text={t('incident.document.detail.score', { score: structuredContent['score'] })}
                                type={structuredContent['isValid'] ? ChipType.SUCCESS : ChipType.ERROR}
                            />
                        </div>
                    )}
                    {onMarkAsMissingInfo && (
                        <div className="detail-document-modal__actions">
                            <Button
                                variantName={ButtonVariant.SECONDARY}
                                text={t('incident.mark_as_missing_info')}
                                onClick={onMarkAsMissingInfo}
                            />
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default DetailDocumentModal;
