import { CauseOfLoss } from '../../../domain/values/CauseOfLoss';
import Card from '../../atoms/card/Card';
import NoteInput from '../../atoms/note/NoteInput';
import CauseOfLossDropdown from '../../organisms/cause-of-loss-dropdown/CauseOfLossDropdown';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './ClaimHealthDetails.modules.scss';

export type ClaimHealthDetailsProps = {
    visitReason: string | undefined;
    causeOfLoss: CauseOfLoss | undefined;
    handleCauseOfLossChange?: (causeOfLoss: CauseOfLoss) => void;
    readOnly?: boolean;
};

const ClaimHealthDetails = ({
    visitReason,
    causeOfLoss,
    handleCauseOfLossChange,
    readOnly = false,
}: ClaimHealthDetailsProps) => {
    const { t } = useModuleTranslation();
    return (
        <Card title={t('claim_details.details')} className="claim-details--container">
            <NoteInput disabled label={t('claim_details.visit_reason')} value={visitReason} />
            <CauseOfLossDropdown
                selectedValue={causeOfLoss}
                handleCauseOfLossChange={handleCauseOfLossChange}
                disabled={readOnly}
            />
        </Card>
    );
};

export default ClaimHealthDetails;
