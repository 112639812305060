import { EventRecord } from '../../../domain/entities/EventRecord';
import { EventAnalyticsRepository } from '../../../domain/repositories/EventAnalyticsRepository';

export class FindClaimRequestEventAnalytics {
    constructor(private eventAnalyticsRepository: EventAnalyticsRepository) {}

    async execute(claimRequestKey: string): Promise<EventRecord[] | undefined> {
        return await this.eventAnalyticsRepository.getClaimRequestLifecycle(claimRequestKey);
    }
}
