import { useState } from 'react';
import ClinicalHistoryForm from './clinical-history-form/ClinicalHistoryForm';
import ClinicalHistoryHeader from './clinical-history-header/ClinicalHistoryHeader';
import { ClinicalHistory, ClinicalHistoryDocument, Diagnosis } from '../../../../domain/entities';

export interface ClinicalHistoryBodyProps {
    clinicalHistory?: ClinicalHistory;
    readOnly: boolean;
    onUpdateValidation: (validation: boolean) => void;
    onUpdateNote: (note: string) => void;
    onAddDisease: (key: string) => void;
    onRemoveDisease: (key: string) => void;
    clinicalHistoryDocuments: ClinicalHistoryDocument[];
    diagnosisOptions: Diagnosis[];
}

const ClinicalHistoryBody = ({
    clinicalHistory,
    readOnly,
    onUpdateValidation,
    onUpdateNote,
    onAddDisease,
    onRemoveDisease,
    clinicalHistoryDocuments,
    diagnosisOptions,
}: ClinicalHistoryBodyProps) => {
    const [selectedClinicalHistoryDocumentIndex, setSelectedClinicalHistoryDocumentIndex] = useState<number>(0);

    const selectedClinicalHistoryDocument = clinicalHistoryDocuments[selectedClinicalHistoryDocumentIndex];

    return (
        <ClinicalHistoryForm
            clinicalHistory={clinicalHistory}
            clinicalHistoryDocument={selectedClinicalHistoryDocument}
            readOnly={readOnly}
            onUpdateValidation={onUpdateValidation}
            onUpdateNote={onUpdateNote}
            onAddDisease={onAddDisease}
            onRemoveDisease={onRemoveDisease}
            options={diagnosisOptions}
        >
            <ClinicalHistoryHeader
                selectedClinicalHistoryDocumentIndex={selectedClinicalHistoryDocumentIndex}
                clinicalHistoryCollectionLength={clinicalHistoryDocuments?.length ?? 0}
                handlePreviousClinicalHistoryClick={() =>
                    setSelectedClinicalHistoryDocumentIndex(selectedClinicalHistoryDocumentIndex - 1)
                }
                handleNextClinicalHistoryClick={() =>
                    setSelectedClinicalHistoryDocumentIndex(selectedClinicalHistoryDocumentIndex + 1)
                }
            />
        </ClinicalHistoryForm>
    );
};

export default ClinicalHistoryBody;
