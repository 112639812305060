export const removeTildes = (value: string) => value.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const includes = (
    root: string,
    value: string,
    options: { ignoreCase?: boolean; ignoreTildes?: boolean } = {
        ignoreCase: true,
        ignoreTildes: true,
    }
) => {
    let transformedRoot = root;
    let transformedValue = value;

    if (options.ignoreCase) {
        transformedRoot = transformedRoot.toLowerCase();
        transformedValue = transformedValue.toLowerCase();
    }

    if (options.ignoreTildes) {
        transformedRoot = removeTildes(transformedRoot);
        transformedValue = removeTildes(transformedValue);
    }

    return transformedRoot.includes(transformedValue);
};

export const getFlagEmoji = (countryCode?: string) => {
    const defaultFlag = '';
    if (countryCode == null) return defaultFlag;
    try {
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map((char) => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    } catch (e) {
        return defaultFlag;
    }
};
