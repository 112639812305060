import { useEffect, useMemo, useState } from 'react';
import { Note } from '../../../domain/entities/note';
import { NotesRepository } from '../../../domain/repositories';
import { NoteScope } from '../../../domain/values';
import { CreateNoteDto } from '../../../infraestructure/repositories/dtos/CreateNoteDto';
import { NotesNomaRepository } from '../../../infraestructure/repositories/NotesNomaRepository';

export const useNotes = (
    entityName: NoteScope,
    claimRequestKey: string | undefined,
    policyIdentifier: string | undefined = undefined
) => {
    const [notes, setNotes] = useState<Note[]>([]);

    const notesRepository: NotesRepository = useMemo(
        () => new NotesNomaRepository(entityName, claimRequestKey ?? '', policyIdentifier ?? ''),
        [entityName, claimRequestKey, policyIdentifier]
    );

    useEffect(() => {
        if (claimRequestKey || ((NoteScope.PET_PARENT || NoteScope.POLICY || NoteScope.PET) && policyIdentifier))
            notesRepository.findNotesByEntityKey(claimRequestKey!).then(setNotes);
    }, [claimRequestKey, policyIdentifier]);

    const createNoteFunction = async (values: CreateNoteDto) => {
        if (!claimRequestKey) throw new Error('Entity Key not provided');

        const newNote = await notesRepository.create(claimRequestKey, values);
        setNotes((oldNotes) => [newNote, ...oldNotes]);
        return newNote;
    };

    const deleteNoteFunction = async (noteKey: string) => {
        await notesRepository.delete(noteKey);
        setNotes((oldNotes) => oldNotes.filter((note) => note.key !== noteKey));
    };

    return {
        notes,
        createNote: createNoteFunction,
        deleteNote: deleteNoteFunction,
    };
};
