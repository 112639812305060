import { Button, ButtonVariant, DirectionLeftIcon, DirectionUpIcon } from '../../../../../ui/atoms';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './clinical-history-header.modules.scss';

export interface ClinicalHistoryHeaderProps {
    selectedClinicalHistoryDocumentIndex: number;
    clinicalHistoryCollectionLength: number;
    handlePreviousClinicalHistoryClick: () => void;
    handleNextClinicalHistoryClick: () => void;
}

const ClinicalHistoryHeader = ({
    selectedClinicalHistoryDocumentIndex,
    clinicalHistoryCollectionLength,
    handlePreviousClinicalHistoryClick,
    handleNextClinicalHistoryClick,
}: ClinicalHistoryHeaderProps) => {
    const { t } = useModuleTranslation();

    return (
        <div className="clinical-history-header">
            {clinicalHistoryCollectionLength > 0 ? (
                <div className="clinical-history-header--carrousel">
                    <Button
                        icon={DirectionLeftIcon}
                        variantName={ButtonVariant.BASIC_INFO}
                        onClick={() => handlePreviousClinicalHistoryClick()}
                        disabled={selectedClinicalHistoryDocumentIndex == 0}
                        accessibilityText={t('clinical_history.validation.go_to_previous')}
                    />
                    <h2 className={'title'}>
                        {t('clinical_history.validation.title', {
                            current: selectedClinicalHistoryDocumentIndex + 1,
                            totalCount: clinicalHistoryCollectionLength,
                        })}
                    </h2>
                    <Button
                        icon={DirectionUpIcon}
                        variantName={ButtonVariant.BASIC_INFO}
                        onClick={() => handleNextClinicalHistoryClick()}
                        disabled={selectedClinicalHistoryDocumentIndex >= clinicalHistoryCollectionLength - 1}
                        accessibilityText={t('clinical_history.validation.go_to_next')}
                    />
                </div>
            ) : (
                <h2 className={'title'}>{t('clinical_history.validation.no_documents')}</h2>
            )}
        </div>
    );
};

export default ClinicalHistoryHeader;
