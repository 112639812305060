import { SupportingDocumentType } from '@barkibu/noma-commons';
import EditableSupportingDocument from './EditableSupportingDocument';

export default class SupportingDocumentToUpload extends EditableSupportingDocument {
    location: string;

    constructor(
        location: string,
        type: SupportingDocumentType | undefined = undefined,
        name: string | undefined = undefined
    ) {
        super(name ?? location.split('/').pop()!, type, location);
        this.location = location;
        this.toUpload = true;
    }
}
