import { UserReservationDto } from './UserReservationDto';
import { User } from '../../../domain/entities';

export const toUserDomain = (el: UserReservationDto): User => {
    return {
        name: el.firstName + ' ' + el.lastName,
        email: el.email,
        privileges: [],
    };
};
