import { useEffect, useState } from 'react';
import { NoteScope, NoteTypeValue } from '../../../domain/values';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import Button from '../button/Button';
import { AlertIcon, MoreIcon } from '../icons';
import Textarea from '../textarea/Textarea';
import './note-input.modules.scss';

export type NoteInputProps = {
    label?: string | null;
    value?: string;
    error?: string;
    name?: string;
    placeholder?: string | null;
    onValueChange?: (value: string) => void;
    onAdd?: (value: string) => void;
    onDelete?: () => void;
    disabled?: boolean;
    setTouched?: () => void;
    lastModified?: Date;
    scope?: NoteScope;
    type?: NoteTypeValue;
};

const NoteInput = ({
    label,
    value,
    onValueChange,
    onAdd,
    onDelete,
    error,
    name,
    disabled = false,
    placeholder,
    setTouched,
    lastModified,
    scope,
    type,
}: NoteInputProps) => {
    const [inputValue, setInputValue] = useState(value);
    const textAreaName = name ?? `note${Math.random()}`;
    const { t } = useModuleTranslation();

    const handleValueChange = (value: string) => {
        setInputValue(value);
        onValueChange && onValueChange(value);
    };

    const handleValueAdd = () => {
        onAdd && inputValue && onAdd(inputValue);
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div className="note--container">
            <div className={`note--input-container ${disabled ? 'note--input-container__disabled' : ''}`}>
                <div className="note--header">
                    {label && <label htmlFor={textAreaName}>{label}</label>}
                    {error && <AlertIcon className="note--alert-icon" />}
                </div>
                <div className="note--main">
                    <Textarea
                        value={inputValue}
                        id={textAreaName}
                        placeholder={placeholder || undefined}
                        disabled={disabled}
                        onBlur={() => setTouched?.()}
                        onChange={(value) => handleValueChange(value)}
                        maxLength={type && type === NoteTypeValue.TAG ? 36 : undefined}
                    />
                    {onAdd != null && (
                        <Button
                            type="submit"
                            onClick={handleValueAdd}
                            trailingIcon={MoreIcon}
                            accessibilityText={t('note_input.add_note')}
                        ></Button>
                    )}
                </div>
                <div className="note--footer">
                    <div className="note--actions">
                        {onDelete != null && (
                            <button className="delete" onClick={onDelete}>
                                {t('note_input.delete')}
                            </button>
                        )}
                    </div>
                    <div className="note--details">
                        {scope != null && <span>{t('note_scope_option.' + scope.toLowerCase())}</span>}
                        {lastModified != null && <span>{lastModified.toLocaleDateString()}</span>}
                    </div>
                </div>
            </div>
            {error && <p className="note--error-message">{error}</p>}
        </div>
    );
};

export default NoteInput;
