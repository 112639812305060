import { useEffect, useState } from 'react';
import { CountPendingClaimRequest } from '../../../application/ClaimRequest/CountPendingClaimRequest';
import { NumberOfPendingClaimRequest } from '../../../domain/values/NumberOfPendingClaimRequest';
import { ClaimRequestNomaRepository } from '../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';

const countPendingClaimRequest = new CountPendingClaimRequest(ClaimRequestNomaRepository);
export const useCountPendingClaimRequests = (countryCode: string) => {
    const [numberOfPendingClaimRequest, setNumberOfPendingClaimRequest] = useState<NumberOfPendingClaimRequest>({
        numberOfNewAssessments: 0,
        numberOfNewAssessmentsReserved: 0,
        numberOfReadyToReviewIncidents: 0,
        numberOfReadyToReviewIncidentsReserved: 0,
        numberOfNewIncidents: 0,
        numberOfNewIncidentsReserved: 0,
        numberOfFastClaims: 0,
        numberOfFastClaimsReserved: 0,
        numberOfOnHoldClaimRequest: 0,
        numberOfNewPreventionClaims: 0,
        numberOfNewPreventionClaimsReserved: 0,
        numberOfPreventionClaimsWithNewDocumentation: 0,
        numberOfPreventionClaimsWithNewDocumentationReserved: 0,
    });

    useEffect(() => {
        countPendingClaimRequest.execute(countryCode).then(setNumberOfPendingClaimRequest);
    }, [countryCode]);

    return numberOfPendingClaimRequest;
};
