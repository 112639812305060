import { ArrowLeftIcon, Icon, IconModes, IconSize } from '@barkibu/noma-commons';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './policy-incidents-banner.modules.scss';

export type PolicyIncidentsBannerProps = {
    onGoBack: () => void;
};

const PolicyIncidentsBanner = ({ onGoBack }: PolicyIncidentsBannerProps) => {
    const { t } = useModuleTranslation();
    return (
        <div className="policy-incidents-banner__container">
            <div className="policy-incidents-banner__heading" onClick={onGoBack}>
                <Icon icon={ArrowLeftIcon} mode={IconModes.SHADOWED} size={IconSize.M} />
                <h2 className="title">{t('policy.claim_requests')}</h2>
            </div>
        </div>
    );
};

export default PolicyIncidentsBanner;
