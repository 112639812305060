import { EventRecord } from '../domain/entities/EventRecord';
import { EventAnalyticsRepository } from '../domain/repositories/EventAnalyticsRepository';
import { FindClaimRequestEventAnalytics } from '../infraestructure/repositories/eventAnalytics/FindClaimRequestEventAnalytics';

export const useEventAnalytics = (repository: EventAnalyticsRepository) => {
    const findClaimRequestEventAnalytics = new FindClaimRequestEventAnalytics(repository);

    return () => {
        const findLifecycle = async (claimRequestKey: string): Promise<EventRecord[] | undefined> => {
            return await findClaimRequestEventAnalytics.execute(claimRequestKey);
        };

        return {
            findLifecycle,
        };
    };
};
