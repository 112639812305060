import { useEffect, useState } from 'react';
import { BlockingLoader, useClinicalHistory } from '@barkibu/noma-commons';
import PolicyIncidentsDetails from './PolicyIncidentsDetails';
import { usePolicyIncidents } from './usePolicyIncidents';

export type PolicyIncidentsPageProps = {
    policyIdentifier: string;
};

const PolicyIncidentsPage = ({ policyIdentifier }: PolicyIncidentsPageProps) => {
    const { requestSummaries, removeDocument, updateDocument, markClaimRequestAsManuallyClosed } =
        usePolicyIncidents(policyIdentifier);
    const getRequestSummariesWithDocuments = requestSummaries
        ? requestSummaries.filter((it) => it.supportingDocuments?.length)
        : [];
    const [requestSummariesState, setRequestSummariesState] = useState(getRequestSummariesWithDocuments);
    const { clinicalHistory } = useClinicalHistory(policyIdentifier);
    useEffect(() => {
        if (requestSummaries) {
            setRequestSummariesState(getRequestSummariesWithDocuments);
        }
    }, [requestSummaries]);

    const handleCloseClaimRequest = async (claimRequestKey: string) => {
        if (claimRequestKey) {
            await markClaimRequestAsManuallyClosed(claimRequestKey);
            setRequestSummariesState(requestSummariesState.filter((el) => el.claimRequestKey != claimRequestKey));
        }
    };

    if (requestSummariesState != undefined) {
        return (
            <PolicyIncidentsDetails
                requestSummaries={requestSummariesState}
                onGoBack={() => history.back()}
                onRemoveDocument={removeDocument}
                onUpdateDocument={updateDocument}
                onCloseClaimRequest={handleCloseClaimRequest}
                clinicalHistory={clinicalHistory}
                policyIdentifier={policyIdentifier}
            />
        );
    } else {
        return <BlockingLoader />;
    }
};

export default PolicyIncidentsPage;
