import { useMemo, useState } from 'react';
import { t } from 'i18next';
import { useEventAnalytics as useEventAnalyticsWithRepository } from '../../../application';
import { EventRecord } from '../../../domain/entities';
import { ClaimRequestStatus } from '../../../domain/values';
import { EventAnalyticsNomaRepository } from '../../../infraestructure';
import { Button, ButtonVariant, Card, HistorialIcon, ProgressIndicator } from '../../atoms';
import ClaimStatusChip from '../claim-status-chip/ClaimStatusChip';
import Timeline from '../timeline/Timeline';
import './claim-lifecycle.modules.scss';

export type ClaimLifecycleProps = {
    status: ClaimRequestStatus;
    claimRequestKey: string;
    statusDetails?: string | string[];
};

const ClaimLifecycle = ({ status, claimRequestKey, statusDetails }: ClaimLifecycleProps) => {
    const useEventAnalytics = useMemo(() => useEventAnalyticsWithRepository(EventAnalyticsNomaRepository), []);
    const { findLifecycle } = useEventAnalytics();
    const [lifecycle, setLifecycle] = useState<EventRecord[] | undefined>(undefined);
    const [lifecycleLoader, setLifeCycleLoader] = useState(false);

    const toggleLifecycleView = () => {
        if (lifecycle && lifecycle.length > 0) {
            setLifecycle(undefined);
        } else {
            setLifeCycleLoader(true);
            findLifecycle(claimRequestKey).then((values) => {
                if (values) {
                    setLifecycle(values);
                }
                setLifeCycleLoader(false);
            });
        }
    };

    return (
        <Card className="lifecycle-card" title={t('claim_lifecycle.status')}>
            <ClaimStatusChip status={status} details={statusDetails} colored />
            <Button
                accessibilityText={t('claim_lifecycle.lifecycle')}
                icon={HistorialIcon}
                onClick={() => toggleLifecycleView()}
                variantName={ButtonVariant.BASIC_INFO}
            />
            <div className="lifecycle-container">
                {!lifecycleLoader ? (
                    lifecycle && (
                        <Timeline
                            items={lifecycle.map((event) => ({
                                name: event.name,
                                timestamp: event.timestamp,
                                subtitle: event.agentFirstName
                                    ? event.agentFirstName + ' ' + (event.agentLastName ?? '')
                                    : undefined,
                                payload: event.payload,
                            }))}
                        ></Timeline>
                    )
                ) : (
                    <ProgressIndicator />
                )}
            </div>
        </Card>
    );
};
export default ClaimLifecycle;
