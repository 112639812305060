import { useUser } from '../../infraestructure/context/user/useUser';

export const usePrivilege = () => {
    const { user } = useUser();

    const hasPrivilege = (privilege: string): boolean => {
        return user ? user?.privileges.some((el) => el.name == privilege) : false;
    };

    return { hasPrivilege };
};
