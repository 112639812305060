import { User } from '@barkibu/noma-commons';
import { TokenResponseDto } from './dtos/TokenResponseDto';
import { UserResponseDto } from './dtos/UserResponseDto';
import { AuthToken } from '../../domain/auth/AuthToken';

export const toDomainUser = (dto: UserResponseDto): User => {
    return {
        name: dto.name,
        email: dto.email,
        privileges: dto.privileges.map((privilege) => ({
            name: privilege.name,
        })),
    };
};

export const toDomainAuthToken = (dto: TokenResponseDto): AuthToken => {
    return {
        access: dto.accessToken,
        accessTimeLeft: dto.accessTokenTimeLeft,
        refresh: dto.refreshToken,
    };
};
